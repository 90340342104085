import Moment from 'moment';
import Essay from './essay';

export class Ad extends Essay {
  title = '';

  wrtier = {};

  datePublish = Moment(new Date()).format('LL');

  mediaType = '';

  thumbImg = '';

  content = '';
    
  tags = [];

  relatedTags = [];

  userListLike = [];

  likeCount = 0;

  scrapCount = 0;

  comments = [];

  relatedEssay = [];

  isCovered = false;

  }


export default Ad;
