import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BttContainer = styled(Link)`
  height: ${props => props.theme.size.height.regular};
  border-radius: ${props => `calc(${props.theme.size.height.regular} / 2)`};
  width: fit-content;
  padding: 0 24px;
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryBlock.enabled : '')}
  @media screen and (max-width: 480px) {
    
  }
`;

const BttBasic = ({ type, title, to, className, doSomething }) => {
  return (
    <BttContainer
      type={type}
      onClick={doSomething}
      to={to}
      className={`btt_txt ${className}`}
    >
      <p>{title}</p>
    </BttContainer>
  );
};

BttBasic.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  doSomething: PropTypes.func.isRequired,
};

export default BttBasic;
