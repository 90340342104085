import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p{
    color: ${props => props.isDark?props.theme.color.ui.white700:props.theme.color.ui.strong} !important;
  }
  padding: ${props => props.theme.size.space.medium2} 0;
  @media screen and (max-width: 480px) {
    padding: ${props => props.theme.size.space.medium1} 0;
  }
`;

const T1 = styled.p`
 ${props => props.theme.type.h5};
 
`;

const T2 = styled.p`
  ${props => props.theme.type.subtitle1};
`;

function SectionTitle({
    className,
    children,
    title1,
    title2,
    isDark
  }) {
  return (
    <Container isDark={isDark}>
      <T1>{title1}</T1>
      <T2>{title2}</T2>
      {children}
    </Container>
  );
}

SectionTitle.propTypes = {
  title1: PropTypes.string.isRequired,
  // title2: PropTypes.string.isRequired,
};

export default SectionTitle;
