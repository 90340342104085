import {
  FIRST_LOADED
} from '../actions';

export const initialState = {
  isFirstLoad: true,
  essayListContextData: {
    recentEssayList: [],
    lastCompetition: null,
    lastCompetitionDate: null,
    currentCompetition: null,
    currentBranded: null,
    lastBranded: null,
    brandedList: [],
    editorsPickList: [],
    allPrizedItem: [],
    recentTagList: [],
    interestedEssayList: []
  }
};

const essayListReducer = (state, body) => {
  console.log(body.action.type);
  switch (body.action.type) {
    case FIRST_LOADED:
      return {
        isFirstLoad: false,
        essayListContextData: {
          recentEssayList: body.recentEssayList,
          lastCompetition: body.lastCompetition,
          lastCompetitionDate: body.lastCompetitionDate,
          currentCompetition: body.currentCompetition,
          currentBranded: body.currentBranded,
          lastBranded: body.lastBranded ,
          brandedList: body.brandedList,
          editorsPickList: body.editorsPickList,
          allPrizedItem: body.allPrizedItem,
          recentTagList: body.recentTagList,
          interestedEssayList: body.interestedEssayList
        }
      }
    default:
  }
};

export default essayListReducer;