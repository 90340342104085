import React from 'react';
import CompetitionDetailPresenter from './CompetitionDetailPresenter';
import { useUserState } from '../../../context/currentUserContext';

function CompetitionDetailContainer({ location }) {
  const { loaded, username, userData } = useUserState();
  return (
    loaded && (
      <>
        <CompetitionDetailPresenter
          username={username}
          userData={userData}
          location={location}
        />
      </>
    )
  )
}

export default CompetitionDetailContainer;