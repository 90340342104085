import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import RewardImg from '../../../assets/img/ic/ic_reward.svg';
import AvatarM from '../../Avatar/AvatarM';
import Essay from '../../../_models/essay';

// Component
import PreviewMedium from '../Element/PreviewMedium';

const Title = styled(Link)`
  cursor: pointer;
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.h2.size};
  line-height: ${props => props.theme.type.h2.height};
  height: 132px;
`;

const Keyword = styled.div`
  max-width: 86px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top: 2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const CardRegularPrizedEssay = ({ essay, className, to }) => (
  <Link
    to={to}
    className={`card_regular flex_col col1 space_between ${className}`}
  >
    <div className="flex_row col1 space_between align_start">
      <Title
        to={`/post/${essay._id}`}
        className="bold primary"
        style={{ width: 'calc(100% - 56px)' }}
      >
        {essay.title}
      </Title>
      <div className="flex_col flex_center">
        <img className="margin_b_tiny" src={RewardImg} alt="" />
        {/* <p className='caption_s regular primary'>OO부문</p> */}
      </div>
    </div>
    <div className="col1">
      <div className="flex_row align_center col1 margin_b_2x">
        <AvatarM
          className="user margin_r_tiny"
          to={`/user/${essay.writer.username}`}
          writer={essay.writer}
          key={essay.writer._id}
        />
        <div>
          <p className="caption regular black300">{essay.writer.username}</p>
          <p className="caption regular black300">
            <Moment format="MMM/DD">{essay.datePublish}</Moment>
          </p>
        </div>
      </div>
      <PreviewMedium to={`/post/${essay._id}`} className="margin_b_1x">
        {essay.contentText}
      </PreviewMedium>
      <div className="flex_row col1 tag_area_card">
        {essay.tags.map(tag => {
          return (
            <Keyword className="margin_r_tiny margin_b_tiny tooltip_outlined_dark">
              {tag.tagName}
            </Keyword>
          );
        })}
      </div>
    </div>
  </Link>
);

CardRegularPrizedEssay.propTypes = {
  essay: PropTypes.instanceOf(Essay).isRequired,
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardRegularPrizedEssay;
