import colorList from '../atom/colorList';

const color = {
  brand: {
    primary700: colorList.blueBlack700,
    primary500: colorList.blueBlack500,
    primary300: colorList.blueBlack300,
    primary100: colorList.blueBlack100,
    primary50: colorList.blueBlack50,
    white500: colorList.white500,
    white700: colorList.white700,
  },
  ui: {
    background: colorList.paper,
    strong: colorList.gray700,
    middle1: colorList.gray500,
    middle2: colorList.gray300,
    low: colorList.gray200,
    background: colorList.paper,
    white500: colorList.white500,
    white700: colorList.white700,
    enable: colorList.blueBlack700,
    hover: colorList.blueBlack900
  }
}

export default color;