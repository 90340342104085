import React from 'react';
import styled from 'styled-components';

import CardWrapper from '../../Layout/Container/CardWrapper';

const Container = styled(CardWrapper)`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Top = styled.div`
  width: 100%;
  padding-right: 32px;
  height: 216px;
  ${props => props.theme.shape.borderBlock.primary300.bottom}
  @media screen and (max-width: 480px) {
    padding-right: 0px;
    height: auto;
    border: none !important;
  }
`;  

const Title = styled.p`
  ${props => props.theme.type.h3}
  word-break: keep-all;
  word-wrap: break-word;
  @media screen and (max-width: 480px) {
    ${props => props.theme.type.h4}
    margin-bottom: 32px;
  }
`;

const Bottom = styled.div`
  width: 100%;
  height: calc(100% - 216px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 480px) {
    justify-content: space-between;
    flex-direction: row-reverse;
    height: auto;
  }
`;

const DateOverline = styled.p`
  ${props => props.theme.type.subtitle2};
  display: none;
  color: ${props => props.theme.color.ui.middle2};
  @media screen and (max-width: 480px) {
    display: inline-block !important;
  }
`;

const Date = styled.div`
  ${props => props.theme.type.subtitle2}
  font-family: ${props => props.theme.type.bold};
  color: ${props => props.theme.color.ui.strong};
  text-align: center;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const Profile = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
  }
`;

const Presenter = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  text-align: center;
  @media screen and (max-width: 480px) {
    text-align: left;
  }
`;


const CardInformCompetition = ({competition}) => {
  return(
    <Container className={''}>
      <Top>
        <Title>{competition.question}</Title>
        <DateOverline>
          {`${competition.month}월 ${competition.week}번째 `}
          {`망월장`}
        </DateOverline>
      </Top>
      <Bottom>
        <Date>
          {`${competition.year}년`} <br/>
          {`${competition.month}월 ${competition.week}번째 `} <br/>
          {`망월장`}
        </Date>
        <Profile src={competition.master.profile}/>
        <Presenter>
          {`presented by`} <br />
          {competition.master.name}
        </Presenter>
      </Bottom>
    </Container>
  );
}

export default CardInformCompetition;