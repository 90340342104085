import React, { useState, useEffect } from 'react';
import Preview from '../../components/Helmet/Preview';
import styled from 'styled-components';

// API
import { competitionApi, searchApi} from '../../api/api';

// Component
import Loading from '../../components/Loading/Loading';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import CardEssayVote from '../../components/Cards/Essay/CardEssayVote';
import CardFullCompetitionStatus from '../../components/Cards/Competition/CardFullCompetitionStatus';

// Container
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import ContainerC from '../../components/Layout/Container/ContainerC';
import CardBigPrizedEssay from '../../components/Cards/Essay/CardBigPrizedEssay';

// Button
import BttBig from '../../components/Buttons/ButtonLink/BttBig';
import BttBigExternalLink from '../../components/Buttons/ButtonLink/BttBigExternalLink';

const BottomActionBar = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  @media screen and (max-width: 480px){
    margin-top: 20px;
    width: 100%;
  }
`

function PrizedItemFeedPresenter({ loged, location, username }) {
  // Local State
  const [competition, setCompetition] = useState({});
  const [prizedItem, setPrizedItem] = useState({});
  const [date, setDate] = useState();
  const [essayList, setEssayList] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET COMPETIION DATE
  const title = location.pathname.replace('/prizedItem/', '');

  const fetchData = async () => {
    try {
      // 진행중 망월장
      // await competitionApi
      //   .competitionByDate(month, week, year)
      //   .then(competitionSample => {
      //     console.log(competitionSample);
      //     console.log(competitionSample.data[0]);
      //     setCompetition(competitionSample.data[0]);
      //     console.log(competitionSample.data[0].essayList);
      //     setEssayList(competitionSample.data[0].essayList);
      //   });

      await searchApi.getPrizedItemList({
          keyword: title,
          pages: 1,
          size: 1
        }).then((res) => {
          console.log(res.data[0]);
          let dateTemp = `${res.data[0].competition.year}-${res.data[0].competition.month}-${res.data[0].competition.week}`;
          setDate(dateTemp);
          setPrizedItem(res.data[0]);
          setCompetition(
            {
              ...res.data[0].competition,
              prizeItem: {
                thumbnail: res.data[0].thumbnail,
                description: res.data[0].description,
              }
            }
          )
        });
  

      
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex_col col1 col_grid_mobile router_area_top">
          <div className="col1 margin_b_6x">
            <p className="body bold black700 text_center margin_b_1x">
             망월장 책
            </p>
            <ContainerC>
              <CardFullCompetitionStatus
                loged={loged}
                competition={competition}
                username={username}
                className=""
                isLinkToCompetitionPage={false}
              />
              <BottomActionBar className="flex_row col1_m space_between">
                <BttBig
                  type="outlined"
                  className="col2"
                  onClick={()=>{
                    console.log('click');
                    const {gtag} = window;
                    gtag('event', 'click', {
                      'event_category' : 'prized item to link',
                      'event_label' : prizedItem.title
                    });
                  }}
                  to={`/competition/${date}`}
                  title="해당 망월장으로 이동"
                />
                <BttBigExternalLink
                  type="filled_primary"
                  className="col2"
                  onClick={()=>{
                    console.log('click');
                    const {gtag} = window;
                    gtag('event', 'click', {
                      'event_category' : 'prized item to competition',
                      'event_label' : prizedItem.title
                    });
                  }}
                  to={`https://www.aladin.co.kr/search/wsearchresult.aspx?SearchTarget=All&SearchWord=${prizedItem.title}`}
                  title="책 정보 자세히 보기"
                />
              </BottomActionBar>
            </ContainerC>
          </div>
        </div>
      )}
    </>
  );
}

export default PrizedItemFeedPresenter;
