import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Ad from '../../_models/Ad'

const CardFullAd = ({ Ad, to }) => (
  <Link to={to} className="card_full ad flex_row space_between col1">
    <div className="col_p33 bold white padding_2x row1">
      <h1 className="margin_b_2x">{Ad.title}</h1>
      <p className="caption regular white margin_b_2x">{`${Ad.writer} | ${Ad.datePublish}`}</p>
      <p className="body preview regular white margin_b_4x">{Ad.content}</p>
      <div className="flex_row col1 margin_b_1x">
        {Ad.tags.map(tag => {
          return (
            <div
              className="tooltip_outlined margin_r_1x margin_b_1x"
              key={tag._id}
            >
              {tag.tagName}
            </div>
          );
        })}
      </div>
    </div>
    <div className="flex_col flex_center cover_img row1 col_p66 padding_l_2x">
      <img className="img_thumb" src={Ad.thumbImg} alt="" />
    </div>
  </Link>
);

CardFullAd.propTypes = {
  Ad: PropTypes.instanceOf(Ad).isRequired,
  to: PropTypes.string.isRequired,
};

export default CardFullAd;
