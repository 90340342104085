import React, { useState, useEffect } from 'react';
import Preview from '../../components/Helmet/Preview';

// API
import { brandedApi } from '../../api/api';

// Component
import Loading from '../../components/Loading/Loading';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import CardEssayVote from '../../components/Cards/Essay/CardEssayVote';
import CardFullBrandedStatusNew from '../../components/Cards/Branded/CardFullBrandedStatusNew';

import SectionTitle from '../../components/Layout/Title/SectionTitle';

// Container
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import ContainerO from '../../components/Layout/Container/ContainerO';
import CardBigPrizedEssay from '../../components/Cards/Essay/CardBigPrizedEssay';

function BrandedFeedPresenter({ loged, location, username }) {
  // Local State
  const [branded, setBranded] = useState({});
  const [essayList, setEssayList] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET COMPETIION DATE
  const url = location.pathname.replace('/branded/', '');
  const id = url.split('-')[0];


  const fetchData = async () => {
    try {
      // 진행중 망월장
      await brandedApi
        .getBrandeds([id])
        .then(branded => {
          console.log(branded);
          console.log(branded.data[0]);
          setBranded(branded.data[0]);
          let list = branded.data[0].essayList.filter(e => e !== null);
          console.log(list);
          setEssayList(list);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
          <div className="flex_col col1 col_grid_mobile router_area_top">
            <Preview
              description="우리들이 사랑하는 것들에 참여해보세요!"
              thumbnail="https://user-images.githubusercontent.com/51117133/95839825-6a932280-0d7e-11eb-8636-7d56fb8b7ddf.png"
              title={
                branded.question
                  ? branded.question
                  : '현재 진행중인 망월장이 없습니다.'
              }
            />
            <div className="col1 margin_b_6x">
              <p className="body bold black700 text_center">
                {branded.isEnd ? '지난 우리들이 사랑하는 것들' : '진행중 우리들이 사랑하는 것들'}
              </p>
              <p className="body black700 text_center margin_b_1x"></p>
              <ContainerO>
                <CardFullBrandedStatusNew
                  loged={loged}
                  branded={branded}
                  username={username}
                  className=""
                  isLinkToCompetitionPage={false}
                />
              </ContainerO>
              <div className="flex_row col1 margin_t_2x">
                {branded.isEnd &&
                  <>
                  <div className="section_top flex_col col1 flex_center">
                    <p className="body bold black700">우리들이 사랑하는 것들 당선작</p>
                  </div>
                    {
                      branded.prizedEssayList.map((essay, i) => {
                        return (
                          <GridMatrix colPC={3} colMb={1} index={i} key={essay._id}>
                            <CardBigPrizedEssay
                              to={`/post/${essay._id}`}
                              essay={essay}
                            />
                          </GridMatrix>
                        );
                      })}
                  </>
                }
              </div>
            </div>
            {essayList.length > 0 && !essayList.includes(null) && (
              <div className="flex_row col1 col_m margin_b_5x">
                <div className="section_top flex_col col1 flex_center">
                  <p className="body bold black700">모든 참여작</p>
                </div>
                {essayList.map((essay, i) => {
                  return (
                    <GridMatrix colPC={5} colMb={1} index={i} key={essay._id}>
                      {branded.isEnd ? (
                        <CardRegularEssay
                          to={`/post/${essay._id}`}
                          essay={essay}
                          key={essay.id}
                        />
                      ) : (
                          <CardRegularEssay to={`/post/${essay._id}`} essay={essay} key={essay.id} />
                        )}
                    </GridMatrix>
                  );
                })}
              </div>
            )}
          </div>
        )}
    </>
  );
}

export default BrandedFeedPresenter;
