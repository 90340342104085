/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import ContainerC from '../../components/Layout/Container/ContainerC'
import TextBannerTitle from '../../components/Layout/Title/TextBannerTitle';

const Container = styled(ContainerC)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 48px;
  @media screen and (max-width: 480px) {
    padding-bottom: 16px;
    margin-bottom: 32px;
  }
`;

const Caption = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle1};
`;

const BannerWriteForCompetition = ({ competition, className }) => (
  <Container>
   <TextBannerTitle
    title1={`${competition.month}월 ${competition.week}주차 망월장 참가글 `}
    title2={competition.question}
   />
   <Caption>
     by 블루블랙
   </Caption>
  </Container>
);

export default BannerWriteForCompetition;
