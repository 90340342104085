import React from 'react';
import WriteAdPresenter from './WriteInsPresenter';
import { useUserState } from '../../context/currentUserContext';

function WriteInsContainer({ location }) {
  const { loaded, username, userData } = useUserState();
  console.log(username);
  return (
    loaded &&
    userData.role === 'admin' && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged.

          Write Ad Presenter Mainly Consist of Editor for Writting.
          This is same as Write Presenter.
          Dante Editor2(Medium Immitation) is used for Editor library.
          https://github.com/michelson/dante2
        */}
        <WriteAdPresenter username={username} location={location} />
      </>
    )
  );
}
export default WriteInsContainer;
