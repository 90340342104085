import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Comment from '../../_models/comment';
import Moment from 'react-moment';
import AvatarM from '../Avatar/AvatarM';
import AvatarB from '../Avatar/AvatarB';

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 32px;
`;

const BttText = styled.button`
  padding: 4px 0;
  color: ${props => props.theme.color.tertiary300};
  /* text-decoration: underline; */
`;

const ChildCommentList = ({
  commentSchema,
  className,
  userData,
  isAdmin,
  deleteComment,
  loged,
}) => {
  // let text;
  // if (commentSchema.childComments.length) {
  //   text = '댓글보기';
  // } else {
  //   text = '댓글달기';
  // }

  return (
    <Container
      className={`flex_row space_between col1 bd_top_light ${className}`}
    >
      <AvatarB to={`/user/${commentSchema.writer.username}`} writer={commentSchema.writer} />
      <div
        style={{ width: 'calc(100% - 60px)' }}
        className="flex_col align_start"
      >
        <p
          style={{marginBottom: '12px'}}
          className="caption black300 regular">
          {`${commentSchema.writer.username} | `}
          <Moment format="MM월 DD일">{commentSchema.date}</Moment>
        </p>
        <p className="body black700 regular">{commentSchema.content}</p>
        {/* <div className="btt_txt primary">댓글달기</div> */}
        {loged && (isAdmin || commentSchema.writer._id === userData._id) && (
          <BttText style={{marginTop: '4px'}} onClick={() => deleteComment(commentSchema._id)}>
            삭제하기
          </BttText>
        )}
      </div>
    </Container>
  );
};

ChildCommentList.propTypes = {
  commentSchema: PropTypes.instanceOf(Comment).isRequired,
  className: PropTypes.string.isRequired,
};

export default ChildCommentList;
