import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Preview from '../../components/Helmet/Preview';

// API
import { essayApi, competitionApi, insApi, brandedApi, tagApi, searchApi } from '../../api/api';

// Context
import { useEssayListState, useEssayListDispatch } from '../../context/essayListContext';


//  Template
import LastCompetition from '../../template/Main/LastCompetition';
import CurrentCompetition from '../../template/Main/CurrentCompetition';
import WriteWithTag from '../../template/Main/WriteWithTag';
import EditorsPick from '../../template/Main/EditorsPick';
import PrizedItemList from '../../template/Main/PrizedItemList';
import InterestedEssayList from '../../template/Main/InterestedEssayList';
import RecentEssayList from '../../template/Main/RecentEssayList';

//  Component
//  Container & Section
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ConainerO from '../../components/Layout/Container/ContainerO';
import SpaceMatrix from '../../components/Layout/Grid/SpaceMatrix';
import Loading from '../../components/Loading/Loading';
import BannerAboutCompetition from '../../components/Banner/BannerAboutCompetition';
import CardBigBranded from '../../components/Cards/Branded/CardBigBranded';
import CardInfromBranded from '../../components/Cards/inform/CardInformBranded';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Img
import ImgGirlReading from '../../assets/img/ill/img_girl_reading_side.png';
import { FIRST_LOADED } from '../../actions';

const PositionSwitch = styled.div`
  display: flex;
  flex-direction: ${props => props.isSwitch ? 'column-reverse' : 'column'};
`

function Main({ loged, username, interestedTag }) {
  /*
  최초 로딩인지 확인하기 위한 essayList context 초기화
  */
  const { isFirstLoad, essayListContextData } = useEssayListState();
  const essayListDispatch = useEssayListDispatch();


  /*
  Local State for load data fetch from api of
  - Ongoing Competition,
  - Last Competition & Prized essaies,
  - Essaies picked by editor regardeless of competition,
  - List of Interested essay for loged user filtered by interestedTag
  */
  const [currentCompetition, setCurrentCompetition] = useState(null);
  const [lastCompetition, setLastCompetition] = useState(null);
  const [currentBranded, setCurrentBranded] = useState(null);
  const [lastBranded, setLastBranded] = useState(null);
  const [brandedList, setBrandedList] = useState([]);
  const [recentTagList, setRecentTagList] = useState([]);
  const [lastCompetitionDate, setLastCompetitionDate] = useState('');
  const [editorsPickList, setEditorsPickList] = useState([]);
  const [recentEssayList, setRecentEssayList] = useState([]);
  const [allPrizedItem, setAllPrizedItem] = useState([]);
  const [popularEssayList, setPopularEssayList] = useState([]);
  const [interestedEssayList, setInterestedEssayList] = useState([]);
  const [inspireList, setInspireList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDataFromApi = async () => {
    let recentEssayListLocal = [];
    let currentCompetitionLocal = null;
    let lastCompetitionLocal = null;
    let lastCompetitionDateLocal = null;
    let currentBrandedLocal = null;
    let lastBrandedLocal = null;
    let brandedListLocal = null;
    let editorsPickListLocal = [];
    let allPrizedItemLocal = [];
    let interestedEssayListLocal = [];
    let recentTagListLocal = [];

    try {

      // Get Last Tags
      await tagApi.getTagList({
        sort: 'count',
        pages: 1,
        size: 40
      }).then(tagList => {
        console.log(tagList);
        let list = [];
        tagList.data.forEach((tag) => {
          if (list.length < 10 && Math.random() < 0.4) {
            list.push(tag);
          }
        });
        recentTagListLocal = list;
        setRecentTagList(list);
      })

      await competitionApi.competitionLast().then(competition => {
        console.log(competition);
        setLastCompetition(competition.data);
        lastCompetitionLocal = competition.data;

        const year = competition.data.year;
        const month = competition.data.month;
        const week = competition.data.week;
        const date = `${year}-${month}-${week}`;
        lastCompetitionDateLocal = date;
        setLastCompetitionDate(date);
      });



      // Get Ongoing Competition
      await competitionApi.competitionCurrent().then(
        competition => {
          if (competition.status !== 404) {
            console.log(competition);
            setCurrentCompetition(competition.data);
            currentCompetitionLocal = competition.data;
          }
        },
        error => {
        },
      );

      // // Get Ongoing Branded Story
      await brandedApi.brandedCurrent().then(
        async currentBranded => {
          // 진행중 망월장이 있을 경우
          if (currentBranded.status !== 404 && currentBranded.data.length !== 0) {
            setCurrentBranded(currentBranded.data[0]);
            currentBrandedLocal = currentBranded.data[0];

            // 진행중 망월장 이전 망월장과 병합
            await brandedApi.brandedPrevious().then(list => {
              let allBrandedList = list.data;
              allBrandedList.push(currentBrandedLocal);
              setBrandedList(allBrandedList);
              brandedListLocal = allBrandedList;
              console.log(allBrandedList);
            });
          }
          // 진행중 망월장이 없을 경우
          else {
            await brandedApi.brandedLast().then(
              async lastBranded => {
                console.log(lastBranded);
                setLastBranded(lastBranded.data);
                lastBrandedLocal = lastBranded.data;

                // 진행중 망월장 이전 망월장과 병합
                await brandedApi.brandedPrevious().then(list => {
                  let allBrandedList = list.data;
                  // allBrandedList.push(currentBrandedLocal);
                  setBrandedList(allBrandedList);
                  brandedListLocal = allBrandedList;
                  console.log(allBrandedList);
                });
              }
            )
          }
        },
        error => {
        },
      );


      await searchApi.getPrizedItemList({
        keyword: '',
        pages: 1,
        size: isMobile ? 4 : 5
      }).then((res) => {
        console.log(res.data);
        allPrizedItemLocal = res.data;
        setAllPrizedItem(res.data);
      });


      // Get Essaies picked by editors
      await essayApi.coveredEssayList().then(
        essayList => {
          if (essayList.status !== 404) {
            // console.log(essayList.data.reverse());
            let reversedList = essayList.data.reverse();
            setEditorsPickList(reversedList);
            editorsPickListLocal = reversedList;
          } else {
          }
        },
        error => {
          console.log(error);
        },
      );
      // Get Essay list by likes count
      await essayApi
        .publishedEssayList({
          sort: 'like',
          tags: [],
          pages: 1,
          size: isMobile ? 4 : 5
        })
        .then(
          essayList => {
            if (essayList.status !== 404) {
              recentEssayListLocal = essayList.data;
              setPopularEssayList(essayList.data);
            }
          },
          error => {
            console.log(error);
          },
        );
      // Get Recent Essay
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [],
          pages: 1,
          size: 10,
        })
        .then(
          essayList => {
            if (essayList.status !== 404) {
              console.log('recent!!');
              console.log(essayList.data);
              setRecentEssayList(essayList.data);
              recentEssayListLocal = essayList.data;
            }
          },
          error => {
          },
        );
      // Get Interested Essay List
      const tags = [];
      interestedTag.forEach(tag => {
        tags.push(tag.tagName)
      });
      recentTagListLocal = interestedTag;
      // Body includes interest tag from usercontext.
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags,
          pages: 1,
          size: isMobile ? 4 : 5
        })
        .then(
          essayList => {
            if (essayList.status !== 404) {
              setInterestedEssayList(essayList.data);
              interestedEssayListLocal = essayList.data;
            }
          },
          error => {
            console.log(error);
          },
        );

      // Get Inspired Essay
      await insApi
        .getInspireList({
          sort: 'recent',
          tags: [],
          pages: 1,
          size: 5,
        })
        .then(insList => {
          setInspireList(insList.data);
        });
    } catch (e) {
      console.error(e);
    } finally {
      let essayListData = {
        action: {
          type: FIRST_LOADED
        },
        recentEssayList: recentEssayListLocal,
        lastCompetition: lastCompetitionLocal,
        lastCompetitionDate: lastCompetitionDateLocal,
        currentCompetition: currentCompetitionLocal,
        editorsPickList: editorsPickListLocal,
        allPrizedItem: allPrizedItemLocal,
        recentTagList: recentTagListLocal,
        interestedEssayList: interestedEssayListLocal,
        currentBranded: currentBrandedLocal,
        lastBranded: lastBrandedLocal,
        brandedList: brandedListLocal
      }
      console.log(essayListData);
      essayListDispatch(essayListData);
      setLoading(false);
    }
  };

  const fetchDataFromContext = () => {
    console.log(essayListContextData);
    setRecentEssayList(essayListContextData.recentEssayList);
    setLastCompetition(essayListContextData.lastCompetition);
    setLastCompetitionDate(essayListContextData.lastCompetitionDate);
    setCurrentCompetition(essayListContextData.currentCompetition);
    setCurrentBranded(essayListContextData.currentBranded);
    setLastBranded(essayListContextData.lastBranded);
    setBrandedList(essayListContextData.brandedList);
    setEditorsPickList(essayListContextData.editorsPickList);
    setAllPrizedItem(essayListContextData.allPrizedItem);
    setRecentTagList(essayListContextData.recentTagList);
    setInterestedEssayList(essayListContextData.interestedEssayList);
    setLoading(false);
  }

  useEffect(() => {
    console.log('is first Load?: ' + isFirstLoad);
    if (isFirstLoad) {
      fetchDataFromApi();
    }
    else if (!isFirstLoad) {
      fetchDataFromContext();
    }
  }, [interestedTag]);

  return (
    <>
      <Preview
        description="낮과 밤이 만나는 시간. 에세이 커뮤니티 블루블랙"
        //   thumbnail="https://user-images.githubusercontent.com/51117133/95183715-52ad2300-0801-11eb-8f47-3b63f1e975b9.png"
        title="blue-black"
      />
      {loading ? (
        <Loading />
      ) : (
          <div className="flex_col col1 col_grid_mobile">
            <SectionTitle
              title1={'블루블랙과 글쓰기'}
              title2={'망월장 질문과 다양한 주제가 재료로 준비되있습니다.'}
            />
            <Section className="flex_row">
              <ConainerO className="flex_row col_m">
                <SpaceMatrix
                  colPC={2}
                  colMb={1}
                  index={0}
                  className={''}
                >
                  <CurrentCompetition
                    competition={currentCompetition}
                    username={username}
                    lastCompetitionDate={lastCompetitionDate}
                  />
                </SpaceMatrix>
                <SpaceMatrix
                  colPC={2}
                  colMb={1}
                  index={1}>
                  <WriteWithTag
                    tagList={recentTagList}
                    username={username}
                  />
                </SpaceMatrix>
              </ConainerO>
            </Section>
            {/* 진행중 브랜디드 스토리 */}
            {
              currentBranded !== null &&
              <>
                <SectionTitle
                  title1={'우리가 사랑하는 것들'}
                  title2={'애정하는 기호품 또는 서비스에 대한 에세이를 씁니다.'}
                />
                <Section className="flex_row">
                  <ConainerO className="flex_row col_m">
                    <GridMatrix
                      colPC={2}
                      colMb={1}
                      index={0}
                    >
                      <CardBigBranded
                        branded={currentBranded}
                        username={username}
                      />
                    </GridMatrix>
                    <GridMatrix
                      colPC={2}
                      colMb={1}
                      index={1}
                    >
                      <CardInfromBranded
                        brandedList={brandedList}
                        username={username}
                      />
                    </GridMatrix>
                  </ConainerO>
                </Section>
              </>
            }
            {/* 지난 브랜디드 스토리 */}
            {
              lastBranded !== null &&
              <>
                <SectionTitle
                  title1={'우리가 사랑하는 것들'}
                  title2={'애정하는 기호품 또는 서비스에 대한 에세이를 씁니다.'}
                />
                <Section className="flex_row">
                  <ConainerO className="flex_row col_m">
                    <GridMatrix
                      colPC={2}
                      colMb={1}
                      index={0}
                    >
                      <CardBigBranded
                        branded={lastBranded}
                        username={username}
                      />
                    </GridMatrix>
                    <GridMatrix
                      colPC={2}
                      colMb={1}
                      index={1}
                    >
                      <CardInfromBranded
                        brandedList={brandedList}
                        username={username}
                      />
                    </GridMatrix>
                  </ConainerO>
                </Section>
              </>
            }
            {/* 지난 당선작 */}
            <PositionSwitch isSwitch={currentCompetition !== null}>
              {
                lastCompetition.prizedEssayList.length > 0 && <LastCompetition competition={lastCompetition} />
              }

              <Section className="flex_row">
                <SpaceMatrix colPC={1} colMb={1} index={0}>
                  <EditorsPick
                    essayList={editorsPickList}
                    num={4}
                  />
                </SpaceMatrix>
              </Section>
              {/* 진행중 백일장 && 에디터픽 */}
            </PositionSwitch>
            {/* 책 리스트 */}
            {
              allPrizedItem.length > 0 && <PrizedItemList prizedItemList={allPrizedItem} />
            }
            {/* 인기에세이 */}
            {/* <PopularEssayList essayList={popularEssayList} /> */}
            {/* 블루블랙 리뷰 */}
            {/* <RecommendInspireList insList={inspireList} /> */}
            {/* 관심 주제어 에세이 */}
            {loged &&
              interestedTag.length > 0 &&
              InterestedEssayList.length > 0 && (
                <InterestedEssayList
                  interestedTag={interestedTag}
                  essayListByTag={interestedEssayList}
                  username={username}
                />
              )}
            {/* 망월장 배너 */}
            <Section>
              <BannerAboutCompetition />
            </Section>
            {/* 최근에세이 */}
            <RecentEssayList essayList={recentEssayList} />
            {/* <MyActivity essay={essay} competition={competition} interestedTagList={interestedTagList} /> */}
          </div>
        )}
    </>
  );
}

export default Main;
