import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Grid = styled.div`
  position: relative;
  width: calc((100% - ${props => `${48 * (props.colPC - 1)}px`})/${props =>
  props.colPC});
  margin-right: ${props =>
    props.index % props.numCol === props.numCol - 1 ? '0px' : '48px'};
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    width: calc((100% - ${props => `${40 * (props.colMb - 1)}px`})/${props =>
  props.colMb});
  }
`;

function SpaceMatrix({ className, colPC, colMb, index, children }) {
  const numCol = isMobile ? colMb : colPC;

  return (
    <Grid
      colPC={colPC}
      colMb={colMb}
      index={index}
      numCol={numCol}
      className={className}
      key={index}
    >
      {children}
    </Grid>
  );
}

SpaceMatrix.propTypes = {
  colPC: PropTypes.number.isRequired,
  colMb: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default SpaceMatrix;
