import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import styled from 'styled-components';
import AvatarM from '../../components/Avatar/AvatarM';

// api & material ui
import { adminApi, essayApi, userpageApi } from '../../api/api';

import BttActionSub from '../../components/Buttons/ButtonAction/BttActionSub';

// Img
import IcMore from '../../assets/img/ic/ic_more_tool_primary.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  @media screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Title = styled.h2`
  margin-bottom: 48px;
  @media screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const BttMore = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const DropDown = styled.div`
  position: absolute;
  z-index: 99;
  top: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
`;

const Menu = styled.button`
  width: 128px;
  padding: 8px 24px;
  color: ${props => props.theme.color.tertiary300};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.size.bttText};
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

function EssayPostTop({
  loged,
  essayData,
  userData,
  isShowMore,
  deleteEssay,
  setIsShowMore,
  history,
}) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFollow, setIsFollow] = useState(false);

  const fetchData = async () => {
    if (!essayData.ispublished) {
      setIsEditable(true);
    }
    if (loged) {
      // await adminApi.checkIsAdmin(userData.email).then(res => {
      //   setIsAdmin(res.data);
      //   setIsEditable(res.data);
      // });
      if(userData.role === 'admin'){
          setIsAdmin(true);
          setIsEditable(true);
      }

      for(let i=0; i<userData.followingList.length; i++){
        if(userData.followingList[i].username === essayData.writer.username){
          setIsFollow(true);
          break;
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteEssayWithAccount = async () => {
    if (isAdmin) {
      // openSnackBar('에세이가 삭제되었습니다.');
      const deleteConfirm = window.confirm(
        '정말 에세이를 삭제하시겠습니까?',
      );

      if(deleteConfirm){
        await adminApi.deleteEssayByAdmin(essayData._id).then(res => {
          setIsDeleted(true);
          console.log(res);
          window.alert("에세이가 삭제되었습니다.");
        });
      }
    } else {
      const data = {
        essayId: essayData._id,
      };
      const deleteConfirm = window.confirm(
        '정말 에세이를 삭제하시겠습니까?',
        );
        if(deleteConfirm){
          await essayApi.essayDelete(data).then(res => {
            // openSnackBar('에세이가 삭제되었습니다.');
            window.alert("에세이가 삭제되었습니다.");
            setIsDeleted(true);
          });
        }
      }
  };

  const modifyEssayWithAccount = async () => {
    console.log(isEditable);
    setIsEdit(true);
  };

  const followUser = async () => {
    // TODO : 팔로우 api 호출
    await userpageApi.followUser({ targetName: essayData.writer.username });
    setIsFollow(!isFollow);
    const {gtag} = window;
    gtag('event', 'click', {
      'event_category' : 'user interaction on a post',
      'event_label' : 'follow'
    });
  }

  return (
    <Container className="flex_col flex_center">
      <Title className="primary margin_b_2x bold">{essayData.title}</Title>
      <AvatarM
        writer={essayData.writer}
        to={`/user/${essayData.writer.username}`}
        className="avatar_m primary_bg white margin_b_tiny flex_col flex_center"
      />
      <p className="black300 caption regular">{essayData.writer.username}</p>
      <p className="black300 caption regular margin_b_1x">
        <Moment format="MM월 DD일">{essayData.datePublish}</Moment>
      </p>
      {loged && userData._id!==essayData.writer._id && (
        <BttActionSub
          type="outlined"
          title={isFollow ?  `팔로잉` : `${essayData.writer.username}님 팔로우하기`}
          type={isFollow ? 'filled_primary' : 'outlined'}
          onClick={followUser}
        />
      )}
      {/* More function of Essay */}
      {/* 어드민 수정용 드롭다운 */}
      {userData && isAdmin && (
        <DropDownContainer>
          <BttMore
            onClick={() => {
              setIsShowMore(true);
            }}
          >
            <img src={IcMore} alt="" />
          </BttMore>
          {isShowMore && (
            <>
              <DropDown>
                <Menu onClick={deleteEssayWithAccount}>에세이 삭제</Menu>
                {isEditable && (
                  <>  
                    <Menu onClick={modifyEssayWithAccount}>에세이 수정</Menu>
                    <Menu onClick={modifyEssayWithAccount}>에세이 수정</Menu>
                  </>
                )}
              </DropDown>
              <FakeBackDrop
                onClick={() => {
                  setIsShowMore(false);
                }}
              />
            </>
          )}
        </DropDownContainer>
      )}
      {/* 내글 수정용 드롭다운 */}
      {userData && !isAdmin && userData._id === essayData.writer._id && (
        <DropDownContainer>
          <BttMore
            onClick={() => {
              setIsShowMore(true);
            }}
          >
            <img src={IcMore} alt="" />
          </BttMore>
          {isShowMore && (
            <>
              <DropDown>
                <Menu onClick={deleteEssayWithAccount}>에세이 삭제</Menu>
                {isEditable && (
                  <Menu onClick={modifyEssayWithAccount}>에세이 수정</Menu>
                )}
              </DropDown>
              <FakeBackDrop
                onClick={() => {
                  setIsShowMore(false);
                }}
              />
            </>
          )}
        </DropDownContainer>
      )}
      {isDeleted && <Redirect to="/mypage" />}
      {isEdit && <Redirect to={`/editEssay?essayId=${essayData._id}`} />}
    </Container>
  );
}

export default EssayPostTop;
