import React from 'react';
import styled from 'styled-components';
import Tag from '../../Tag/Tag';

const Container = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  overflow: hidden;
  div{
    margin: 0 0 4px 4px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
  }
`;

const TagList = ({
  tagList,
  isDark
}) => {
  return (
    <Container>
      {
        tagList.length > 0 && tagList.map((tag, i) => {
          if (tag!==null) {
            return <Tag 
              key={i}
              type={isDark?'outlined_white':null}
            >{tag.tagName}</Tag>
          }
        })
      }
    </Container>
  )
}

export default TagList;