import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import {isMobile} from "react-device-detect";

// API
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import {
  essayApi,
  alarmApi,
  mypageApi,
  userpageApi,
  adminApi,
  competitionApi,
  brandedApi
} from '../../api/api';

// Context
import {
  useUserState,
  useUserDispatch,
} from '../../context/currentUserContext';

import { LOAD_USER_DATA } from '../../actions';

// Component
import CommentList from '../../components/Comment/CommentList';
import InputComment from '../../components/Comment/InputComment';
import Loading from '../../components/Loading/Loading';

// From Material UI

// Templeate
import EssayPostTop from '../../template/EssayPost/EssayPostTop';
import EssayPostBody from '../../template/EssayPost/EssayPostBody';
import EssayPostAdminActionBar2 from '../../template/EssayPost/EssayPostAdminActionBar2';
import EssayPostUserActionBar from '../../template/EssayPost/EssayPostUserActionBar';
import EssayPostVoterActionBar from '../../template/EssayPost/EssayPostVoterActionBar';
import BannerVoteForBranded from '../../components/Banner/BannerVoteForBranded';
import BannerVoteForCompetition from '../../components/Banner/BannerVoteForCompetition';

import Preview from '../../components/Helmet/Preview';

const Container = styled.div`
//GNB와 간격 조정
margin-top: 40px;
@media screen and (max-width: 480px) {
 margin-top: -20px; 
}
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

function EssayPostPresenter({ loged, location, userData, history }) {
  const dispatch = useUserDispatch();

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // Local State
  const [loading, setLoading] = useState(true);
  const [essayData, setEssayData] = useState(null);
  const [comment, setComment] = useState('');

  const [isLike, setIsLike] = useState(false);
  const [isScrap, setIsScrap] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isEditorPick, setIsEditorPick] = useState(false);
  const [isPrized, setIsPrized] = useState(false);
  const [isBrandedPrized, setIsBrandedPrized] = useState(false);
  const [isVoted, setVote] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isBranded, setIsBranded] = useState(false);
  const [isCompeted, setIsCompeted] = useState(false);

  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });


  // MSG preset for snackbar when user interaction occured
  const SNACK_MSG_COMMENT = '댓글이 등록되었습니다.';
  const SNACK_MSG_COMMENT_DELETE = '댓글이 삭제되었습니다.';
  const SNACK_MSG_LIKE = '위 에세이에 공감을 보냈습니다.';
  const SNACK_MSG_LIKE_CANCEL = '위 에세이 공감을 취소하였습니다.';
  const SNACK_MSG_SCRAP = '위 에세이를 마이노트에 스크랩하였습니다.';
  const SNACK_MSG_SCRAP_CANCEL = '위 에세이 스크랩을 취소하였습니다.';
  const SNACK_MSG_FOLLOW = '위 에세이 작가를 팔로우하였습니다.';
  const SNACK_MSG_FOLLOW_CANCEL = '위 에세이 작가 팔로우를 취소하였습니다.';
  const SNACK_MSG_EDITOR = '위 에세이가 에디터픽으로 선정되었습니다.';
  const SNACK_MSG_EDITOR_CANCEL = '해당 에세이 에디터픽이 취소되었습니다.';
  const SNACK_MSG_PRIZED = '위 에세이를 백일장 당선작으로 선정하였습니다.';
  const SNACK_MSG_PRIZED_CANCEL = '해당 에세이의 백일장 당선이 취소되었습니다.';
  const SNACK_MSG_BRANDED_PRIZED = '위 에세이를 브랜디드 스토리 당선작으로 선정하였습니다.';
  const SNACK_MSG_BRANDED_PRIZED_CANCEL = '해당 에세이의 브랜디드 스토리 당선이 취소되었습니다.';
  const SNACK_MSG_VOTE = '위 에세이를 투표하였습니다.';
  const SNACK_MSG_VOTE_CANCEL = '해당 에세이의 투표가 취소되었습니다.';



  // SNACK BAR CONTROL
  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  // GET POST ID
  const id = location.pathname.replace('/post/', '');
  const data = {
    essayId: id,
  };

  // FECTHING DATA
  const fetchData = async () => {
    console.log(location.pathname);
    try {
      if (loged) {
        if (userData.role === 'admin') {
          setIsAdmin(true);
        }
        console.log('userData:', userData);
        console.log(data);
      }
      await essayApi.getPostedEssay(data).then(async essayData => {
        console.log(essayData);
        setEssayData(essayData.data);

        if (essayData === '') {
          window.alert('에세이가 삭제되었습니다.');
          history.goBack();
        }

        essayData.data.userListLike.forEach(user => {
          if (user._id === userData._id) {
            setIsLike(true);
          }
        });

        essayData.data.voteUserList.forEach(user => {
          if (user._id === userData._id) {
            setVote(true);
          }
        });

        if (essayData.data.recommended) {
          setIsEditorPick(true);
        }

        if (essayData.data.isPrized) {
          setIsPrized(true);
        }

        if (essayData.data.isBrandedPrized) {
          setIsBrandedPrized(true);
        }

        userData.scrapEssayList.forEach(essay => {
          if (essay._id === essayData.data._id) {
            console.log('yes!');
            setIsScrap(true);
          }
        });

        userData.followingList.forEach(user => {
          if (user._id === essayData.data.writer._id) {
            setIsFollow(true);
          }
        });

        // 망월장 브랜디드 스토리 정보 확인
        if (essayData.data.isCompeted){
          console.log("competed!!!!");
          competitionApi.getCompetitions(essayData.data.competitionId).then(async competition => {
            console.log(competition);
          })
        }
        // 망월장 브랜디드 스토리 정보 확인
        if (essayData.data.isBranded){
          
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('load essay!');
    fetchData();
  }, []);

  // USER INTERACTION
  // Like
  const likeUpDown = async () => {
    const data = {
      essayId: essayData._id,
    };
    await essayApi.essayLikeCount(data).then(async res => {
      console.log(res.data);
      setEssayData(res.data);
      setIsLike(false);
      console.log(isLike);
      res.data.userListLike.forEach(user => {
        if (user._id === userData._id) {
          setIsLike(true);
          console.log(isLike);
        }
      });
      if (isLike) {
        openSnackBar(SNACK_MSG_LIKE_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_LIKE);
        // Send Event to GA
        const { gtag } = window;
        gtag('event', 'click', {
          'event_category': 'user interaction on a post',
          'event_label': 'like'
        });
        // call like alarm api
        await alarmApi.likeAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
      }
    });
  };

  const voteEssay = async () => {
    const data = {
      essayID: essayData._id,
    };
    await essayApi.essayVote(data).then(res => {
      console.log(res.data);
      setEssayData(res.data);
      setVote(!isVoted);
    });
    if (isVoted) {
      openSnackBar(SNACK_MSG_VOTE_CANCEL);
    } else {
      openSnackBar(SNACK_MSG_VOTE);
      // Send Event to GA
      const { gtag } = window;
      gtag('event', 'click', {
        'event_category': 'user interaction on a post',
        'event_label': 'vote'
      });
    }
  };

  // Scarp
  const scrapEssay = async () => {
    const data = {
      essayId: essayData._id,
    };
    await essayApi.essayScrap(data).then(async res => {
      setEssayData(res.data);
      setIsScrap(!isScrap);

      if (isScrap) {
        openSnackBar(SNACK_MSG_SCRAP_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_SCRAP);
        // call like alarm api
        await alarmApi.scrapAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
        // Send Event to GA
        const { gtag } = window;
        gtag('event', 'click', {
          'event_category': 'user interaction on a post',
          'event_label': 'scrap'
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        console.log(info);
        dispatch(currentUser);
      });
    });
  };

  // follow
  const followWriter = async () => {
    const username = {
      targetName: essayData.writer.username,
    };
    const userId = {
      targetUserId: essayData.writer._id,
    };
    await userpageApi.followUser(username).then(async res => {
      console.log(res.data);
      setIsFollow(!isFollow);
      if (isFollow) {
        openSnackBar(SNACK_MSG_FOLLOW_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_FOLLOW);
        // call like alarm api
        await alarmApi.followAlarm(userId).then(newer => {
          console.log(newer.data);
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
      });
    });
  };

  // The More Options
  const showMoreTool = () => {
    // if (essayData.isCompeted && essayData.isPublished) {
    //   window.alert('백일장 출품작은 수정 또는 삭제할 수 없습니다.');
    // } else {
    //   setIsShowMore(!isShowMore);
    // }
    setIsShowMore(!isShowMore);
  };

  const deleteEssay = async () => {
    const data = {
      essayId: essayData._id,
    };

    await essayApi.essayDelete(data).then(async res => {
      console.log(res);
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
        window.alert('에세이가 삭제되었습니다.');
        history.goBack();
        // 에세이 삭제
      });
    });

    // window.alert("에세이가 삭제되었습니다.");
    // history.go(-1);
  };

  const goBack = () => {
    history.go(-2);
  };

  const setEditorPick = async () => {
    await adminApi.setCoverEssay(id).then(res => {
      setEssayData(res.data);
      setIsEditorPick(!isEditorPick);
      if (isEditorPick) {
        openSnackBar(SNACK_MSG_EDITOR_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_EDITOR);
      }
      console.log(res);
    });
  };

  const setBrandedPrized = async () => {
    console.log('test');
    await adminApi.setPrizeBrandedEssay(id).then(res => {
      setEssayData(res.data);
      setIsBrandedPrized(!isPrized);
      if (isPrized) {
        openSnackBar(SNACK_MSG_BRANDED_PRIZED_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_BRANDED_PRIZED);
      }
      console.log(res);
    });
  };

  const setCompetitionPrized = async () => {
    await competitionApi.competitionLast().then(async competition => {
      console.log(competition.data);
      if (competition.data.prizedEssayList.length >= 3) {
        console.log(
          `Current prized essays are ${competition.data.prizedEssayList.length}`,
        );
      }
      else {
        await adminApi.setPrizeEssay(id).then(res => {
          setEssayData(res.data);
          setIsPrized(!isPrized);
          if (isPrized) {
            openSnackBar(SNACK_MSG_PRIZED_CANCEL);
          } else {
            openSnackBar(SNACK_MSG_PRIZED);
          }
          console.log(res);
        });
      }
    });
  };

  // Comment
  const onCommentChange = event => {
    setComment(event.target.value);
  };

  const deleteComment = async commentId => {
    const data = {
      essayId: id,
      commentId,
    };
    await essayApi.deleteComment(data).then(res => {
      openSnackBar(SNACK_MSG_COMMENT_DELETE);
      console.log(res);
      setEssayData(res.data);
    });
  };

  const postComment = async () => {
    const data = {
      essayId: id,
      content: comment,
    };
    await essayApi.postComment(data).then(async res => {
      openSnackBar(SNACK_MSG_COMMENT);
      console.log(res);
      await alarmApi.commentAlarm({ commentID: res.data }).then(newer => {
        setComment('');
        console.log(newer.data);
        setEssayData(newer.data.contentObject[0]);
      });

      console.log("userData", userData);
      console.log("essayData", essayData);

      const emailData = {
        title: `${userData.username}님이 ${essayData.writer.username}님의 글 \"${essayData.title}\"에 댓글을 남겼습니다.`,
        content:
          `
        <div
        style="
          width: 100%;
          background-color: ##fcf6ee;
          padding:32px;
        "
      >
        <div
          style="
            width: 100%;
            color: #fff;
            background-color: ##fcf6ee;
            text-align: center;
            padding: 16px;
            "
        >
          <p
            style="
              font-size: 20px;
              line-height: 32.6px;
              color: #2a2a28;   
            "
          >
          ${userData.username}님이 ${essayData.writer.username}님의 글 \"${essayData.title}\"에 댓글을 남겼습니다.
          </p>
          <a style="width: 80%" href=http://blue-black.life/post/${essayData._id}>글 보러 가기</a>
        </div>
      </div>
        `,
        sendTo: essayData.writer.email,
      }
      await alarmApi.alarmEmailing(emailData).then(res => {
        console.log(res);
      })

    });
  };

  const postChildComment = async (id, childComment) => {
    console.log(id);
    const data = {
      commentId: id,
      content: childComment,
    };
    await essayApi.postChildComment(data).then(async res => {
      openSnackBar(SNACK_MSG_COMMENT);
      console.log(res);
      await alarmApi
        .childCommentAlarm({ childCommentID: res.data })
        .then(newer => {
          setComment('');
          console.log(newer.data);
          setEssayData(newer.data.contentObject[0]);
        });
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
          <>
            <Container className="flex_col col_narrow center_margin col_grid_mobile margin_b_2x">
              <Preview
                description={essayData.contentText}
                // thumbnail="https://user-images.githubusercontent.com/51117133/95839710-49cacd00-0d7e-11eb-8472-02019194f0f8.png"
                title={essayData.title}
              />
              {/* 에세이 타이틀 */}
              <EssayPostTop
                loged={loged}
                essayData={essayData}
                userData={userData}
                deleteEssay={deleteEssay}
                setIsShowMore={setIsShowMore}
                isShowMore={isShowMore}
                history={history}
              />
              {/* 에세이 본문 */}
              <EssayPostBody essayData={essayData} />
              {/* 에세이 기타 정보 */}
              {/* <EssayPostStatusBar essayData={essayData} /> */}
              {/* 투표자 기능 */}
              {loged &&
                userData &&
                essayData.isCompeted &&
                userData._id !== essayData.writer._id && (
                  <EssayPostVoterActionBar
                    isVoted={isVoted}
                    voteEssay={voteEssay}
                  />
                )}
              {/* 어드민 기능 */}
              {loged && isAdmin && (
                <EssayPostAdminActionBar2
                  essayData={essayData}
                  setEditorPick={setEditorPick}
                  setCompetitionPrized={setCompetitionPrized}
                  setBrandedPrized={setEditorPick}
                  setText={'test'}
                  title={'text'}
                />
              )}
              
              {/* 망월장 || 브랜디드 스토리 투표 */}
              {
                isCompeted &&
                <BannerVoteForCompetition
                  competition={essayData.competition}
                  onClick={() => {

                  }}
                />
              }
              {
                isBranded &&
                <BannerVoteForBranded
                  branded={essayData.branded}
                  onClick={() => {

                  }}
                />
              }

              {/* 액션바 */}
              {loged && (
                <EssayPostUserActionBar
                  likeUpDown={likeUpDown}
                  scrapEssay={scrapEssay}
                  followWriter={followWriter}
                  deleteEssay={deleteEssay}
                  essayData={essayData}
                  userData={userData}
                  isLike={isLike}
                  isScrap={isScrap}
                  isFollow={isFollow}
                  showMoreTool={showMoreTool}
                  isShowMore={isShowMore}
                />
              )}
              {/* 커멘트 */}
              <div className="flex_col col">
                {loged && userData && (
                  <InputComment
                    className="margin_b_2x"
                    id={id}
                    userData={userData}
                    onCommentChange={onCommentChange}
                    postComment={postComment}
                    comment={comment}
                  />
                )}
                {essayData.comments && (
                  <div className="flex_col_reverse col1 margin_b_8x">
                    {essayData.comments.map((commentSchema, i) => (
                      <CommentList
                        key={i}
                        commentSchema={commentSchema}
                        className=""
                        userData={userData}
                        postChildComment={postChildComment}
                        isAdmin={isAdmin}
                        deleteComment={deleteComment}
                        loged={loged}
                      />
                    ))}
                  </div>
                )}
              </div>
              {/* 스낵바 */}
              <Snackbar
                open={snackBarControl.open}
                autoHideDuration={1000}
                onClose={closeSnackBar}
                TransitionComponent={Fade}
                message={snackBarControl.message}
              />
            </Container>
          </>
        )}
    </>
  );
}

export default EssayPostPresenter;
