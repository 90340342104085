import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'querystring';
import { ImageBlockConfig } from 'Dante2/package/es/components/blocks/image';
import styled from 'styled-components';
import config from '../../config';

import {
  SET_AD_TITLE,
  SET_AD_CONTENT,
  SET_NUM_CURRENT_WORDS_AD,
  SET_AD_IMAGE,
} from '../../actions';

// API
// change getAd by Id
import { insApi } from '../../api/api';

// Context
import { useInsState, useInsDispatch } from '../../context/currentInsContext';

// Component
import Loading from '../../components/Loading/Loading';

// Styled Component
const AmountGuide = styled.div`
  width: 160px;
  position: fixed;
  top: 204px;
  text-align: center;
  right: 48px;
  white-space: break-spaces;
`;

// Dante widget for image upload
const Dante = require('Dante2');

function WriteIns({ location, username }) {
  /* Local State for managing writting essay
  - option for whether save or publush
  - options for whether set dueday or not
  - maximum words. (Global Setting for all user by blueblack)
  - check overflow of maximum words. save with overflow available, but publish with overflow is not available.
  */
  const isSave = false;
  const isDelete = false;
  const numMaxWords = 2200;

  const [loading, setLoading] = useState(true);
  const [numCurrentWords, setNumCurrentWords] = useState(0);
  const [isOverFlow, setIsOverFlow] = useState(false);

  // currentEssayContext dispatch;
  const insDispatch = useInsDispatch();

  // State for esssay before update context
  const [adDataLocal, setAdDataLocal] = useState({
    id: null,
    title: '',
    content: null,
    tags: [],
  });

  // currentInsContxt에서 갖고온 state;
  const { id, title, content, tags } = useInsState();

  // setState of currentEssaycontext;
  const setTitile = e => {
    setAdDataLocal({ ...adDataLocal, title: e.target.value });
    insDispatch({ type: SET_AD_TITLE, value: adDataLocal.title });
  };
  const setContent = data => {
    setAdDataLocal({ ...adDataLocal, content: data });
    insDispatch({ type: SET_AD_CONTENT, value: adDataLocal.content });
  };

  // Get Essay Id in case of Modification
  const { search } = location; // 문자열 형식으로 결과값이 반환된다.
  const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환

  const getContentText = contentSample => {
    let texts = '';
    contentSample.blocks.forEach(block => {
      texts += block.text;
    });
    return texts;
  };

  const chkStrLength = str => {
    let countKr = 0;
    let countEng = 0;
    let totalLength = 0;
    countKr = `${escape(str)}%u`.match(/%u/g).length - 1;
    countEng = str.length - countKr;
    totalLength = countEng + countKr;
    setNumCurrentWords(totalLength);
    return totalLength;
  };

  // Fetch Essay Data only In case of Modification
  const fetchData = async () => {
    if (queryObj['?adId'] !== undefined) {
      setAdDataLocal({ ...adDataLocal, id: queryObj['?adId'] });
    }

    try {
      // In case of Id Exist (not 1st writting)
      if (queryObj['?adId'] !== undefined) {
        console.log('EDIT MODE');
        const body = [queryObj['?adId']];
        const data = {
          id: body[0],
        };

        await insApi.getInspireById(data).then(async adData => {
          console.log(adData.data);
          setAdDataLocal({
            ...adDataLocal,
            id: adData.data._id,
            title: adData.data.title,
            content: JSON.parse(adData.data.content),
            tags: adData.data.tags,
          });
        });
      }
    } catch {
      // No Action
    } finally {
      setLoading(false);
    }
  };

  // Sync LocalEssayData with ContextEssayData
  const getAdContext = () => {
    setAdDataLocal({
      id,
      title,
      content,
      tags,
    });
  };

  useEffect(() => {
    fetchData();
    getAdContext();
  }, []);

  useEffect(() => {
    insDispatch({ type: SET_AD_TITLE, value: adDataLocal.title });
    insDispatch({ type: SET_AD_CONTENT, value: adDataLocal.content });
  }, [adDataLocal]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{ position: 'relative', marginTop: '124px' }}
          className="flex_col col1 router_area_top col_grid_mobile"
        >
          {/* 참여중인 백일장 배너 */}
          <div className="col_editor center_margin margin_b_4x">
            <input
              className="title col1 margin_b_4x"
              placeholder="제목을 적어주세요"
              onChange={setTitile}
              value={adDataLocal.title}
              type="text"
            />
            <div className="editor_ins col1 margin_b_2x">
              <React.StrictMode>
                <Dante
                  body_placeholder={`${username}님의 Inspire`}
                  content={adDataLocal.content}
                  widgets={[
                    ImageBlockConfig({
                      options: {
                        upload_url: `${config.apiUrl}/upload`,
                        upload_callback: ctx => {
                          console.log(ctx.data);
                          alert(`file uploaded: ${ctx.data.url}`);
                          insDispatch({
                            type: SET_AD_IMAGE,
                            value: ctx.data.url,
                          });
                        },
                        upload_error_callback: ctx => {
                          console.log(ctx);
                        },
                      },
                    }),
                  ]}
                  onChange={editor => {
                    const text = getContentText(editor.emitSerializedOutput());
                    const lengthChar = chkStrLength(text);
                    console.log(lengthChar);
                    setContent(editor.emitSerializedOutput());
                    setNumCurrentWords(lengthChar);
                    insDispatch({
                      type: SET_NUM_CURRENT_WORDS_AD,
                      value: numCurrentWords,
                    });
                    if (numMaxWords < numCurrentWords) {
                      setIsOverFlow(true);
                    } else {
                      setIsOverFlow(false);
                    }
                  }}
                />
              </React.StrictMode>
            </div>
            <div id="bottom_action_bar" className="flex_row  space_between">
              {/* Essay Length Count */}
              <AmountGuide>
                <p
                  className={`body_s regular margin_b_1x ${
                    isOverFlow ? 'primary' : 'black300'
                  }`}
                >
                  {!isOverFlow
                    ? '2200자 이하의 글만 \n Inspire로 출판 가능합니다.'
                    : '2200자가 넘었습니다. 출판전에 글자수를 줄여주세요.'}
                </p>
                <p className="body_s regular black300">
                  <span className="primary">{numCurrentWords}</span>
                  &nbsp;/&nbsp;
                  <span>{numMaxWords}</span>
                </p>
              </AmountGuide>
              {/* <div className="flex_row">
                {essayDataLocal.id && (
                  <BttActionBig
                    title="삭제하기"
                    onClick={deleteEssay}
                    type="outlined"
                    className="margin_r_1x"
                  />
                )}
                <BttActionBig
                  title="저장하기"
                  onClick={onSave}
                  type="filled_primary"
                />
              </div> */}
            </div>
          </div>
        </div>
      )}
      {isSave && <Redirect to="/save" />}
      {isDelete && <Redirect to="/mypage" />}
    </>
  );
}

export default WriteIns;
