import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// Reducer for Dispatch Currently Writting Essay Object
import essayReducer, { initialState } from '../reducer/essayReducer';

// Context Definition
const CurrentEssayContext = createContext();
const CurrentEssayProvider = ({ children }) => {
  const [state, dispatch] = useReducer(essayReducer, initialState);
  return (
    <CurrentEssayContext.Provider value={{ state, dispatch }}>
      {children}
    </CurrentEssayContext.Provider>
  );
};

CurrentEssayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Dispatch Definition
export const useEssayDispatch = () => {
  const { dispatch } = useContext(CurrentEssayContext);
  return dispatch;
};

// UseState Definition
export const useEssayState = () => {
  const { state } = useContext(CurrentEssayContext);
  return state;
};

export default CurrentEssayProvider;
