import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import EditablePage from '../../components/Editor/EditablePage';
import Loading from '../../components/Loading/Loading';

// Styled Component
const Container = styled.div`
  //GNB와 간격 조정
  margin-top: 80px;
  @media screen and (max-width: 480px) {
   margin-top: -20px; 
  }
`;

function WriteNotionTestPresenter({ location, username }) {
 
  return (
    <>
      {false ? (
        <Loading />
      ) : (
        <Container
          className="col1 col_grid_mobile"
        >
          
          <div className="col_editor center_margin margin_b_4x">
          <input
            className="title col1 margin_b_4x"
            placeholder="제목을 작성해주세요"
            type="text"
            onSelect={
              () => {
                console.log('select!');
              }
            }
          />
          <EditablePage />
          </div>
        </Container>
      )}
    </>
  );
}

export default WriteNotionTestPresenter;