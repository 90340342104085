import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  ${props => props.theme.layout.containerBlock}
`;

function ContainerC({ className, children }) {
  return (
    <Container className={`col1 bd_top_bold bd_bottom ${className}`}>
      {children}
    </Container>
  );
}

ContainerC.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContainerC;
