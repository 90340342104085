/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-await */
import axios from 'axios';
import qs from 'qs';
import config from '../config/';

// Axios Defualt Setting
export const api = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
  validateStatus(status) {
    return status >= 200 && status <= 409; // default
  },
});

// Session Api
export const sessionApi = {
  register: data => api.post(`/api/auth/register/local`, data),
  logIn: data => api.post(`/api/auth/login/local`, data),
  logOut: () => api.get(`/api/auth/logout`),
  kakaoValid: () => api.get(`/api/auth/login/kakao/validCheck`),
  kakaoRegister: data => api.post(`/api/auth/register/kakao`, data),
  getCurrentAccount: () => api.get(`/api/auth/getCurrentAccount`),
  syncToSocial: data => api.get(`/api/auth/syncToSocial`, {
    params: { social: data.social },
  }),
};

// Competition Api
export const competitionApi = {
  // Get Current Competition
  competitionCurrent: () => api.get(`/api/competition/current`),
  // Get Last Competition
  competitionLast: () => api.get(`/api/competition/last`),
  // Get Previous Competitions
  competitionPrevious: () =>
    api.get(`/api/competition/getPreviousCompetitions`),
  // Get Competition by Date
  competitionByDate: (month, week, year) =>
    api.get(`/api/competition/byDate`, {
      params: { month, week, year },
    }),
  // Get Competitions By Array of Competition I
  getCompetitions: data =>
    api.post(`/api/competition/getCompetitions`, data),

  // Finish Competitions By Competition ID
  endCompetition: id =>
    api.patch(`/api/competition/endCompetition`, null, {
      params: {
        competitionID: id,
      },
    }),
};

// Branded Api
export const brandedApi = {
  // Get Current Branded
  brandedCurrent: () => api.get(`/api/branded/current`),
  // Get Last Branded
  brandedLast: () => api.get(`/api/branded/last`),
  // Get Previous Brandeds
  brandedPrevious: () =>
    api.get(`/api/branded/getPreviousBrandeds`),
  // Get Branded by Date
  brandedByDate: (month, week, year) =>
    api.get(`/api/branded/byDate`, {
      params: { month, week, year },
    }),
  // Get Brandeds By Array of Branded ID
  getBrandeds: data =>
    api.post(`/api/branded/getBrandeds`, data),

  // Finish Brandeds By Branded ID
  endBranded: id =>
    api.patch(`/api/branded/endBranded`, null, {
      params: {
        brandedID: id,
      },
    }),
};

export const prizedItem = {
  getPrizedITemList: () => api.get(`/api/prizeItem/list`)
}

// Essay Api
export const essayApi = {
  // Get Covered EssayList
  coveredEssayList: () => api.get(`/api/essay/covered/list`),
  // Get Prized EssayList
  prizedEssayList: () => api.get(`/api/essay/prized/list`),
  // Get published EssayList by Options
  publishedEssayList: data =>
    api.get(`/api/essay/list`, {
      params: {
        sort: data.sort,
        tags: data.tags,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    }),
  // Not in Used in Beta Vrsion
  // applyEssayCompetition: (data) =>
  //   api.post(BASE_URL + "api/essay/apply/competition", data),
  // setRelatedTags: (data) => api.post(BASE_URL + "api/essay/set/tags", data),
  // recommendedTags: () => api.get(BASE_URL + "api/essay/tag/related"),

  // Get Follwer Essay List
  followerEssayList: (pages, size) =>
    api.get(
      `/api/essay/followers/list`,
      {
        params: { pages, size },
      },
      { withCredentials: true },
    ),

  // Save Essay
  saveEssay: async data =>
    await api.post(`/api/essay/writeNsave`, data).then(res => {
      console.log(res);
    }),
  // Publish Essay
  publishEssay: data => api.post(`/api/essay/post`, data),
  // Get Essay Data by ID
  getPostedEssay: data =>
    api.post(`/api/essay/get/essay/posted`, data),
  // POST COMMENT ON ESSAY
  postComment: data => api.post(`/api/essay/comment`, data),
  // POST CHILD COMMENT ON ESSAY
  postChildComment: data =>
    api.post(`/api/essay/child/comment`, data),

  deleteComment: data =>
    api.delete(`/api/essay/comment/delete`, {
      params: { essayId: data.essayId, commentId: data.commentId },
    }),

  // NOT IN USED IN BETA VERSION
  // postChildComment: (data) =>
  //   api.post(BASE_URL + "api/essay/child/comment", data),

  // LIKE ESSAY POST
  essayLikeCount: data =>
    api.patch(`/api/essay/like`, null, {
      params: { essayId: data.essayId },
    }),
  // SCRAP ESSAY TO MY PAGE
  essayScrap: data =>
    api.patch(`/api/essay/scrap`, null, {
      params: { essayId: data.essayId },
    }),
  // GET ESSAY LIST CORRESPONDING ID LIST
  getEssaysById: idList => api.post(`/api/essay/getEssays`, idList),
  // VOTE ESSAY FOR COMPETITION
  essayVote: data =>
    api.patch(`/api/essay/vote`, null, {
      params: { essayID: data.essayID },
    }),
  essayDelete: data => api.delete(`/api/essay/deleteNew`, { data }),
};

// TAG API
export const tagApi = {
  // Get Tag List By String
  getTagsByString: string =>
    api.get(`/api/essay/getTagsByString`, {
      params: { searchWord: string },
    }),

  getTagList: data =>
    api.get(`/api/essay/getAllTagsList`, {
      params: {
        sort: data.sort,
        pages: data.pages,
        size: data.size
      }
    })
};

// USER API
export const userApi = {
  // GET USER DATA
  getUserInfoByName: username =>
    api.get(`/api/userpage/Info?name=${username}`),
};

export const mypageApi = {
  // GET MY USER DATA
  getMyInfo: () => api.get(`/api/mypage/myInfo`),
  // GET ALARM LIST
  getAlarmList: (page, size) =>
    api.get(`/api/mypage/alarmList`, {
      params: { page, size },
    }),
  // CHANGE INTRODUCTION
  patchDescription: data =>
    api.patch(`/api/mypage/changeDescription`, data),
  usernameChecker: (data) => api.get(`/api/mypage/usernameChecker`, {
    params: { username: data.username },
  }),
  changeUsername: data => api.patch(`/api/mypage/changeUsername`, data)
};

export const alarmApi = {
  // COMMENT ALRAM TRIGGER
  commentAlarm: data =>
    api.post(`/api/alarm/alarmCommentOnMyEssay`, null, {
      params: { commentID: data.commentID },
    }),
  // COMMENT ALRAM TRIGGER
  childCommentAlarm: data =>
    api.post(`/api/alarm/alarmCommentOnMyComment`, null, {
      params: { childCommentID: data.childCommentID },
    }),
  // LIKE ALRAM TRIGGER
  likeAlarm: data =>
    api.post(`/api/alarm/alarmLikeOnMyEssay`, null, {
      params: { essayId: data.essayId },
    }),
  // FOLLOW ALRAM TRIGGER
  followAlarm: data =>
    api.patch(`/api/alarm/alarmFollowUser`, null, {
      params: { targetUserId: data.targetUserId },
    }),
  // SCRAP ALRAM TRIGGER
  scrapAlarm: data =>
    api.post(`/api/alarm/alarmScrapOnMyEssay`, data),
  // SET ALRAM READ
  setAlarmRead: (id, body) =>
    api.patch(`/api/mypage/readAlarm?id=${id}`, body),
  competitionStartAlarm: data => {
    console.log(data);
    api.post(`/api/alarm/alarmCompetitionStart`, null, {
      params: { competitionID: data._id },
    });
  },
  brandedStartAlarm: data => {
    console.log(data);
    api.post(`/api/alarm/alarmBrandedStart`, null, {
      params: { brandedID: data._id },
    });
  },
  alarmEmailing: data =>
    api.post(`/api/alarm/alarmEmailing`, data),
  alarmEmailingToGeneralUsers: data =>
    api.post(`/api/alarm/alarmEmailingToGeneralUsers`, data),

};

export const userpageApi = {
  followUser: data =>
    api.post(`/api/userpage/follow`, null, {
      params: { targetName: data.targetName },
    }),
  getUsers: data => api.post(`/api/userpage/getUsers`, data),
};

export const insApi = {
  getInspireById: data =>
    api.get(`/api/inspire/byId`, { params: { id: data.id } }),
  // Save Essay
  saveInspire: async data =>
    await api.post(`/api/inspire/save`, data).then(res => {
      console.log(res);
    }),
  // Get published inspireList by Options
  getInspireList: data =>
    api.get(`/api/inspire/list`, {
      params: {
        sort: data.sort,
        tags: data.tags,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
        // return qs.stringify(params);
      },
    }),
};

export const adminApi = {
  setCoverEssay: id =>
    api.patch(`/api/ADMIN/setCoverEssay?id=${id}`, {
      recommended: true,
    }),
  setPrizeEssay: id =>
    api.patch(`/api/ADMIN/setPrizeEssay?id=${id}`, {
      recommended: true,
    }),
  setPrizeBrandedEssay: id =>
    api.patch(`/api/ADMIN/setPrizeBrandedEssay?id=${id}`, {
      recommended: true,
    }),
  createCompetition: input =>
    api.patch(`/api/ADMIN/createCompetition`, {
      question: input,
    }),

  createCompetitionByDate: data =>
    api.post(`/api/ADMIN/createCompetitionWithDate`, data),

  createBranded: data =>
    api.post(`/api/ADMIN/createBranded`, data),

  sendPWChangeEmail: data =>
    api.post(`/api/ADMIN/sendPWChangeEmail`, {
      userEmail: data.email,
    }),

  resetPasswordTokenChecker: data =>
    api.post(`/api/ADMIN/resetPasswordTokenChecker`, {
      token: data.token,
      email: data.email,
    }),

  setPasswordWithToken: data =>
    api.post(`/api/ADMIN/setPasswordWithToken`, {
      token: data.token,
      email: data.email,
      password: data.password,
    }),

  deleteCompetition: data =>
    api.delete(
      `/api/ADMIN/deleteCompetition?competitionID=${data._id}`,
    ),

  modifyCompetitionByAdmin: data =>
    api.post(`/api/ADMIN/modifyCompetition`,data),

  deleteBranded: data =>
    api.delete(
      `/api/ADMIN/deleteBranded?brandedID=${data._id}`,
    ),

  modifyBrandedByAdmin: data =>
    api.post(`/api/ADMIN/modifyBranded`,data),

  modifyEssay: data =>
    api.post(`/api/ADMIN/modifyEssay`, {
      _id: data.essayId,
      title: data.title,
      isCompeted: data.isCompeted,
      competitionId: data.competitionId,
      brandedId: data.brandedId,
      tagNames: data.tagNames,
      content: data.content,
    }),

  checkIsAdmin: data =>
    api.get(`/api/ADMIN/AdminChecker?email=${data}`),

  deleteEssayByAdmin: data =>
    api.delete(`/api/ADMIN/deleteEssayByAdmin?essayId=${data}`),
  deleteCommentByAdmin: data =>
    api.delete(
      `/api/ADMIN/deleteCommentByAdmin?essayId=${data.essayId}&commentId=${data.commentId}`,
    ),

  showAllTags: () =>
    api.get(`/api/ADMIN/showTags`),

  getAllUsers: (data) => 
    api.get(`/api/ADMIN/getAllUsers`, {
      params: {
        size: data.size,
        page: data.page
      }
    }),
};

export const searchApi = {
  getUser: data =>
    api.get(`/api/user`, {
      params: {
        keyword: data.keyword,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
  }),
  getTag: data =>
    api.get(`/api/tag`, {
      params: {
        keyword: data.keyword,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
  }),
  getEssay: data =>
    api.get(`/api/essay`, {
      params: {
        keyword: data.keyword,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
  }),

  // 서치탭에서 사용
  getPrizedItem: data =>
    api.get(`/api/competition/prizeItem`, {
      params: {
        keyword: data.keyword,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
  }),

  // 메인에서 책 리스트용, 개별 데이터 호출용으로 사용
  getPrizedItemList: data =>
    api.get(`/api/competition/prizeItem/list`, {
      params: {
        keyword: data.keyword,
        pages: data.pages,
        size: data.size,
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
  }),
};