import React, { useState, useEffect } from 'react';
import CardFullAd from '../components/Cards/CardFullAd';
import CardRegularIns from '../components/Cards/CardRegularIns';

import Ad from '../_models/Ad';

// Api
import { insApi } from '../api/api';

// Component
import Loading from '../components/Loading/Loading';

function Ins(){

    // 더미데이터 생성
    const ad = new Ad();
    const adList = [];

    console.log(ad);

    for(let i=0; i<10; i+=1){
        adList.push(ad);
    }
    // 더미데이터 생성

    const [inspireList, setInspireList] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const fetchData = async () => {
      try {
        // Get Inspired Essay
        await insApi
        .getInspireList({
          sort: 'recent',
          tags: [],
          pages: 1,
          size: 30,
        }).then(
          insList => {
            setInspireList(insList.data);
            console.log(insList.data);
          }
        );
      } catch{ 

      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      fetchData()
    })

    return(
      <>
        {loading ? (
          <Loading />
        ) : (
          <div className="flex_col col1 margin_t_4x">
            {/* 커버광고 */}
            <div className="flex_row col1 space_between margin_b_4x">
              <p className="caption col1 regular black300 margin_b_2x">
                커버 리뷰
              </p>
              <CardFullAd Ad={ad} to="/post" />
            </div>
            {/* 광고 리스트 에세이 */}
            <div className="flex_row col1 col_m">
              <p className="caption col1 regular black300 margin_b_2x">
                리뷰 리스트
              </p>
              {inspireList.map((ins, i)=>{
                    return (
                      <div
                        className={
                            `col5 ${
                             i%5===0?'dv_left':(i%5===4?'dv_right':'dv_center')}`
                            }
                        key={i}
                      >
                        <CardRegularIns Ins={ins} to={`/inspire/${ins._id}`} />
                      </div>
                    )
                })}
            </div>
          </div>
        )}
      </>
      
    )
}

export default Ins;