import React from 'react';
import LiveArea from '../../components/Layout/LiveArea';
import styled from 'styled-components';
import ConainerO from '../../components/Layout/Container/ContainerO';

const Container = styled(LiveArea)`
  flex-direction: row !important;
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
    padding: 0 20px;
  }
`;

const Title = styled.div`
  color: ${ props => props.theme.color.primary500 };
  width: calc(100% - 784px);
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.h1Block}
  margin-bottom: 40px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Content = styled.div`
  p{
    width: 784px;
    font-family: ${props => props.theme.type.regular};
    font-size: ${props => props.theme.type.body3.size};
    line-height: ${props => props.theme.type.body3.height};
    margin-bottom: 48px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  } 
  h2{
    font-family: ${props => props.theme.type.bold};
    font-size: ${props => props.theme.type.body3.size};
    line-height: ${props => props.theme.type.body3.height};
  }
`

function ServiceAgreement() {
  return(
    <Container className={'router_area_top'}>
      <Title>
        서비스<br />
        이용약관
      </Title>
      <Content>
        <h2>제 1 조 (목적)</h2>
        <p>
          본 약관은 회원(본 약관에 동의한 자를 말합니다 이하 "회원"이라고 합니다.)이 주식회사 이디그나(이하 "회사"라고 합니다)가 제공하는 블루블랙(blue-black)서비스(이하 "서비스"라고 합니다)를 이용함에 있어 회사와 회원의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
        </p>
        <h2>제 2 조 (약관의 명시, 효력 및 개정)</h2>
        <p>
          <ol>
            <li>
              회사는 본 약관의 내용을 회원이 알 수 있도록 서비스 페이지(https://blue-black.life)에 게시함으로써 이를 공지합니다.
            </li>
            <li>
              회사는 콘텐츠산업 진흥법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 약관의 규제에 관한 법률, 소비자기본법 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 개정약관 및 개정약관의 시행일자와 개정사유를 명시하여 그 시행일자 15일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하며, 개정된 약관은 그 시행일에 효력이 발생합니다. 단, 개정 내용이 회원에게 불리한 경우에는 그 시행일자 30일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하거나 팝업화면을 게시하는 등 이용자가 충분히 인지할 수 있는 합리적으로 가능한 방법으로 공지하며, 개정된 약관은 그 시행일에 효력이 발생됩니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 개정약관 및 개정약관의 시행일자와 개정사유를 명시하여 그 시행일자 15일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하며, 개정된 약관은 그 시행일에 효력이 발생합니다. 단, 개정 내용이 회원에게 불리한 경우에는 그 시행일자 30일 전부터 시행일 이후 상당한 기간 동안 본 서비스 페이지에 게시하거나 팝업화면을 게시하는 등 이용자가 충분히 인지할 수 있는 합리적으로 가능한 방법으로 공지하며, 개정된 약관은 그 시행일에 효력이 발생됩니다.
            </li>
            <li>
              회사가 전항에 따라 회원에게 통지하면서 공지일로부터 개정약관 시행일까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 회원의 거부의 의사표시가 없는 경우에는 변경된 약관에 동의한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 제14조 제1항의 규정에 따라 이용계약을 해지할 수 있습니다.
            </li>
          </ol>
        </p>
        <h2>제 3 조 (약관 동의 및 서비스 이용계약의 체결))</h2>
        <p>
          <ol>
            <li>
              회사와 회원 간의 서비스 이용계약은 이용자가 본 약관 내용에 대해 동의하고 서비스 이용신청을 하면 회사가 그 신청을 승낙함으로써 성립합니다.
            </li>
            <li>
              회사는 이용자가 서비스 회원가입 페이지의 “동의함” 버튼을 클릭하거나 이용자가 본 서비스의 이용을 시작한 경우 본 약관에 동의한 것으로 간주합니다.
            </li>
            <li>
              회사는 이용자가 필수사항 등을 성실히 입력하여 서비스 이용신청을 완료하였을 때에는 신청 내용을 확인한 후 지체 없이 이를 승낙하여야 합니다. 단, 회사는 아래 각 호에 해당하는 경우에는 승낙을 유보할 수 있습니다.
              <ul>
                <li>
                  서비스의 설비용량에 현실적인 여유가 없는 경우
                </li>
                <li>
                  서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우
                </li>
              </ul>
            </li>
            <li>
              회사는 아래 각 호에 해당하는 경우에는 본 서비스 이용계약에 대한 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
              <ul>
                <li>
                  이용자가 서비스 이용신청 시 허위의 정보를 기재하거나 회사가 요청하는 사항을 기재하지 아니한 경우
                </li>
                <li>
                  제10조 제3항에 의해 회사가 본 서비스 이용계약을 해지했던 회원이 다시 서비스 이용신청을 하는 경우, 단 회사로부터 재가입 승낙을 받은 경우는 예외로 함
                </li>
                <li>
                  이용자의 귀책사유로 인하여 승낙이 불가능하거나 관련 법령 등에 위반하여 서비스 이용신청을 하는 경우
                </li>
              </ul>
            </li>
            <li>
              본 조 제3항 및 제4항에 따라 회사가 이용신청에 대해 승낙을 유보하거나 승낙을 하지 않을 경우, 회사는 그 사실을 이용자에게 알리도록 합니다.
            </li>
            <li>
              본 서비스 이용계약은 회사가 신청절차 상에서 가입 완료를 표시한 시점에 성립합니다.제 4 조 (개인정보의 보호 및 관리)
            </li>
            <li>
              회사는 서비스를 제공하기 위하여 회원으로부터 서비스 이용에 필요한 개인정보를 수집할 수 있습니다.
            </li>
            <li>
              회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 회원의 개인정보의 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보 처리방침을 적용합니다. 
            </li>
            <li>
              회사는 회사의 귀책 없이 회원의 귀책사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.
            </li>
          </ol>
        </p>
      </Content>
    </Container>
  )
}

export default ServiceAgreement;