import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
// Model
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Essay } from '../../_models/essay';

// Api
import { essayApi, mypageApi, userpageApi } from '../../api/api';

// Context
import { useUserState } from '../../context/currentUserContext';

// Container
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import PartitionHor from '../../components/Layout/Grid/PartitionHor';
import ContainerC from '../../components/Layout/Container/ContainerC';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import ButtSub from '../../components/Buttons/ButtonLink/BttSub';

// Component
import AvatarB from '../../components/Avatar/AvatarB';
import CardEssayOnProgress from '../../components/Cards/Essay/CardEssayOnProgress';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import ListUser from '../../components/List/ListUser';
import Loading from '../../components/Loading/Loading';
import BttActionText from '../../components/Buttons/ButtonAction/BttActionText';
import BttActionSub from '../../components/Buttons/ButtonAction/BttActionSub';

// Styled Component
const PageTop = styled(ContainerC)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const BttMore = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const DropDown = styled.div`
  position: absolute;
  z-index: 99;
  top: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
`;

const Menu = styled.button`
  width: 128px;
  padding: 8px 24px;
  color: ${props => props.theme.color.tertiary300};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.size.bttText};
`;

const ColLeftTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .avatar{
    margin-right: 8px;
  }
  .edit_name{
  }
  @media screen and (max-width: 480px) {
    .avatar{
      margin-right: 0px;
    }
    .edit_name{
      margin-top: 8px;
      /* display: none; */
    }
  }
`;

const Username = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    margin-bottom: 24px;
  }
`;

const UserActionBar = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`

const ColRightBottom = styled.div`
  width: 60%;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TagTitle = styled.p`
  width: 100%;
  text-align: left;
  color: ${props => props.theme.color.tertiary700};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.subtitle2.size};
  line-height: ${props => props.theme.type.subtitle2.height};
  margin-bottom: 12px;
`;


function MyPage({ loged, userData, interestedTag, writeTags }) {
  // Local State
  const [isShowMore, setIsShowMore] = useState(false);
  const [myNoteList, setMyNoteList] = useState([]);
  const [myScrapList, setMyScrapList] = useState([]);
  const [myEssayList, setMyEssayList] = useState([]);
  const [loading, setLoading] = useState(true);

  // follow list & modal
  const [followerList, setFollowerList] = useState([]);
  const [isFollowerModal, setIsFollowerModal] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [description, setDescription] = useState('');
  const [isFollowingModal, setIsFollowingModal] = useState(false);
  const [usernameChange, setUsernameChange] =useState(false);
  const [username, setUsername] = useState('');
  const [isDescriptionInput, setIsDescriptionInput] = useState(false);

  const fetchData = async () => {
    try {
      // Set Data as State from user context
      console.log(userData);
      setUsername(userData.username);
      setDescription(userData.description);
      if(userData.description===''){
        setIsDescriptionInput(true);
      }
      setFollowerList(userData.followerList);
      setMyNoteList(userData.noteList);

      // Get My Essay List by Array of ID
      // await essayApi.getEssaysById(userData.noteList).then(essayList => {
      //   console.log(essayList);
      //   setMyNoteList(essayList.data);
      // });
      
      await essayApi.getEssaysById(userData.scrapEssayList).then(scrapEssayList => {
        console.log(scrapEssayList.data);
        setMyScrapList(scrapEssayList.data);
      });

      // api오류로 null값으로 남아있는 기존 엘레머트 제거 
      // console.log(userData.essayList);
      let list = [];
      list = userData.essayList.filter((essayId) => {
        return essayId!==null;
      });

      await essayApi.getEssaysById(list.reverse()).then(essayList => { 
        setMyEssayList(essayList.data);
      });
    } catch(e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('loaded!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const closeFollwerModal = () => {
    setIsFollowerModal(false);
  };

  const showFollower = async () => {
    const data = {
      targetUserIDs: userData.followerList,
    };
    console.log(userData.followerList);

    await userpageApi.getUsers(data).then(res => {
      console.log(res.data);
      setFollowerList(res.data);
      setIsFollowerModal(true);
    });
  };

  const closeFollwingModal = () => {
    setIsFollowingModal(false);
  };

  const showFollowing = async () => {
    const data = {
      targetUserIDs: userData.followingList,
    };
    console.log(userData.followingList);

    await userpageApi.getUsers(data).then(res => {
      console.log(res.data);
      setFollowingList(res.data);
      setIsFollowingModal(true);
    });
  };

  const postUsernameToChange = async value => {
    const data = {
      username: value,
    };
    // 유저 닉넴 변경 API자리
    await mypageApi.changeUsername(data).then(res => {
      console.log(res);
      if(res.status === 400){
        // 이미 닉네임이 존재하는 경우
        window.alert("이미 존재하는 닉네임입니다.");
      }
      else if(res.status === 402){
        // window.alert("같은 닉네임입니다");
        setUsername(value);
        setUsernameChange(false);
      }
      else{
        window.alert("닉네임이 변경되었습니다.");
        setUsername(value);
        setUsernameChange(false);
      }
    });
  };

  const postDescription = async value => {
    const data = {
      description: value,
    };

    await mypageApi.patchDescription(data).then(res => {
      console.log(res);
      setDescription(value)
      setIsDescriptionInput(false);
      // setUsername(value);
      // setUsernameChange(false);
    });
  };

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: isMobile ? 'calc(100% - 32px)' : 768,
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div className="flex_col col_narrow center_margin col_grid_mobile router_area_top">
      {/* MY PAGE TOP */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Section>
            <PageTop className="flex_row space_between trans_all padding_ver_1x">
              <ColLeftTop colPC={2} colMb={1} index={0}>
                <AvatarB className="avatar" to={`/user/${userData.username}`} writer={userData} />
                {/* {유저닉네임} */}      
                {!usernameChange ? (
                  <Username>
                    <h3 className="primary bold">
                      {userData && username}
                    </h3>
                    <BttActionText
                      className="edit_name"
                      color="gray"
                      title="닉네임 수정"
                      onClick={() => setUsernameChange(!usernameChange)}
                    />

                  </Username>

                ) : (
                  <Username>
                    <textarea
                      className={`col1 h3 margin_b_1x ${isMobile?'margin_t_1x':''}`}
                      value={username}
                      placeholder={username}
                      onChange={event => {
                        setUsername(event.target.value);
                        console.log(event.target.value);
                      }}
                    />
                    <UserActionBar>
                      <BttActionSub
                        title="취소"
                        type="outlined"
                        className="margin_r_tiny"
                        onClick={() => {
                          setUsernameChange(false);
                          // setDescription(userData.description);
                        }}
                      />
                      <BttActionSub
                        title="수정 완료"
                        type="filled_primary"
                        onClick={() => postUsernameToChange(username)}
                      />
                    </UserActionBar>
                  </Username>
                )}   
              </ColLeftTop>
              <ColRightBottom
                className="flex_col align_start trans_all"
                colPC={2}
                colMb={1}
                index={1}
              >
                <p className="body regular margin_b_1x">
                  <span className="margin_r_1x">{`출판한 에세이: ${myEssayList.length}`}</span>
                  <span>{`기록중인 글: ${myNoteList.length}`}</span>
                </p>
                <UserActionBar>
                  <BttActionSub
                    className="margin_r_1x"
                    title="팔로워 보기"
                    type="outlined"
                    onClick={showFollower}
                  />
                  <BttActionSub
                    className=""
                    title="팔로잉 보기"
                    type="outlined"
                    onClick={showFollowing}
                  />
                  {/* <ButtSub to={""} title="설정하기" type={"outlined"} /> */}
                </UserActionBar>
                {!isDescriptionInput ? (
                  <>
                    <h3 className="regular">
                      {description === ''
                        ? '자기소개가 아직 없습니다.'
                        : description}
                    </h3>
                    <BttActionText
                      color="gray"
                      title="자기소개 수정"
                      onClick={() => setIsDescriptionInput(!isDescriptionInput)}
                    />
                  </>
                ) : (
                  <>
                    <textarea
                      className="col1 h3 margin_b_1x"
                      value={description}
                      placeholder={(description === '')?("자기소개를 입력해주세요") : description}
                      onChange={event => {
                        setDescription(event.target.value);
                        console.log(event.target.value);
                      }}
                    />
                    <UserActionBar>
                      <BttActionSub
                        title="취소"
                        type="outlined"
                        className="margin_r_tiny"
                        onClick={() => {
                          setIsDescriptionInput(false);
                          setDescription(userData.description);
                        }}
                      />
                      <BttActionSub
                        title="수정 완료"
                        type="filled_primary"
                        onClick={() => postDescription(description)}
                      />
                    </UserActionBar>
                  </>
                )}
              </ColRightBottom>
              <div className="flex_row col1 padding_ver_1x bd_top margin_t_1x">
                <ColLeftTop colPC={2} colMb={1} index={0}>
                  <TagTitle>
                    {`${userData.username}님이 읽은(${interestedTag.length})`}
                  </TagTitle>
                </ColLeftTop>
                <ColRightBottom colPC={2} colMb={1} index={1}>
                  <div className="flex_row align_center col1">
                    {interestedTag.map((tag, i) => (
                      <div
                        style={{marginRight: '4px', marginBottom: '4px'}}
                        key={i}
                        className="tooltip_outlined_dark margin_b_tiny"
                      >
                        {tag.tagName}
                      </div>
                    ))}
                  </div>
                </ColRightBottom>
              </div>
              <div className="flex_row col1 padding_ver_1x bd_top margin_t_1x">
                <ColLeftTop colPC={2} colMb={1} index={0}>
                  <TagTitle>
                    {`${userData.username}님이 사용한(${writeTags.length})`}
                  </TagTitle>
                </ColLeftTop>
                <ColRightBottom colPC={2} colMb={1} index={1}>
                  <div className="flex_row align_center col1">
                    {writeTags.map((tag, i) => (
                      <div
                        style={{marginRight: '4px', marginBottom: '4px'}}
                        key={i}
                        className="tooltip_outlined_dark margin_b_tiny margin_r_tiny"
                      >
                        {tag.tagName}
                      </div>
                    ))}
                  </div>
                </ColRightBottom>
              </div>
            </PageTop>
          </Section>
          {myNoteList.length > 0 && (
            <Section>
              <SectionTitle
                title1={`기록중인 ${myNoteList.length}개의 에세이`}
              />
              <ContainerO className="flex_row padding_ver_1x">
                {myNoteList.map((essay, i) => (
                  <GridMatrix
                    colPC={3}
                    colMb={1}
                    key={i}
                    index={i}
                  >
                    <CardEssayOnProgress to="" essay={essay} key={i} />
                  </GridMatrix>
                ))}
              </ContainerO>
            </Section>
          )}
          {myScrapList.length > 0 && (
            <Section>
              <SectionTitle
                title1={`스크랩한 ${myScrapList.length}개의 에세이`}
              />
              <ContainerO className="flex_row padding_ver_1x">
                {myScrapList.map((essay, i) => (
                  <GridMatrix
                    colPC={3}
                    colMb={1}
                    index={i}
                    onClick={()=>{
                      console.log('click');
                      const {gtag} = window;
                      gtag('event', 'click', {
                        'event_category' : 'my page behaviour',
                        'event_label' : 'read scraped essay'
                      });
                    }}
                  >
                    <CardRegularEssay to={`/post/${essay._id}`} essay={essay} />
                  </GridMatrix>
                ))}
              </ContainerO>
            </Section>
          )}
          {/* <Section>
            <SectionTitle 
              title1={'내가 투표한 3개의 에세이'}
            />
            <ContainerO className='flex_row space_between padding_ver_1x'>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={0}
              >
                <CardRegularEssay essay={essay} />
              </GridMatrix>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={1}
              >
                <CardRegularEssay essay={essay} />
              </GridMatrix>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={2}
              >
                <CardRegularEssay essay={essay} />
              </GridMatrix>
            </ContainerO>
          </Section> */}
          <Section>
            <SectionTitle title1={`출판한 ${myEssayList.length}개의 에세이`} />
            <ContainerO className="flex_row">
              {myEssayList.map((essay, i) => (
                <GridMatrix
                  colPC={4}
                  colMb={2}
                  index={i}
                  key={i}
                  onClick={()=>{
                    console.log('click');
                    const {gtag} = window;
                    gtag('event', 'click', {
                      'event_category' : 'my page behaviour',
                      'event_label' : 'read my essay'
                    });
                  }}
                >
                  <CardRegularEssay
                    to={`/post/${essay._id}`}
                    essay={essay}
                  />
                </GridMatrix>
              ))}
            </ContainerO>
          </Section>
          {/* 팔로워 리스트 모달 */}
          <Modal
            open={isFollowerModal}
            onClose={closeFollwerModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <h2 className="body bold black700" id="simple-modal-title">
                팔로워 리스트
              </h2>
              {followerList.length > 0 &&
                followerList.map(user => (
                  <ListUser
                    userData={user}
                    mark={user.username[0] + user.username[1]}
                  />
                ))}
            </div>
          </Modal>
          {/* 팔로잉 리스트 모달 */}
          <Modal
            open={isFollowingModal}
            onClose={closeFollwingModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={modalStyle} className={classes.paper}>
              <h2 className="body bold black700" id="simple-modal-title">
                팔로잉 리스트
              </h2>
              {followingList.length > 0 &&
                followingList.map(user => (
                  <ListUser
                    userData={user}
                    mark={user.username[0] + user.username[1]}
                  />
                ))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default MyPage;
