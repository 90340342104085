import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TabContainer = ({ children, className }) => {
  const Container = styled.div`
    display: flex;
    @media screen and (max-width: 480px) {
      display: -webkit-inline-box !important;
      flex-wrap: nowrap !important;
      overflow-y: scroll;
    }
  `;
  return <Container className={className}>{children}</Container>;
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default TabContainer;
