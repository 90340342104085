import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import EssayFeedPresenter from './EssayFeedPresenter';
import { useUserState } from '../../context/currentUserContext';

function EssayFeedContainer({ location }) {
  const { loged, loaded, username, userData, interestedTag } = useUserState();
  const lastInterestedTags = [];

  // 최근 3개만 전달
  interestedTag.forEach((tag,i) => {
    if(i<3){
      lastInterestedTags.push(tag);
    }
  })
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 

          EssayFeed Presenter includes essay list of 5 tabs categorized by context (recent, intersted, popular, Flowers)
          Each Tab us manged by different query parameters on same Route URL.
          loged: weather user log or not
          username: current loged username
          userData: user object model of current loged user
          interested tag: sorted tags by reading activity of current user.
        */}
        <EssayFeedPresenter
          loged={loged}
          username={username}
          userData={userData}
          interestedTag={lastInterestedTags}
          location={location}
        />
      </>
    )
  );
}

export default EssayFeedContainer;
