import Moment from 'moment';

export class Essay {

  title = '다른 곳에서 만났다면 어쩌면';

  writer = {
    username: "보글보글"
  };

  /* DATE */
  isDueday = false;

 
  datePublish = "2021-01-05T08:10:03.996Z";
  dateStart = "2021-01-05T08:10:03.996Z";
  dueDay = "2021-01-05T08:09:37.948Z";


  /* STATUS */
  isPublished = true;

  isCompeted = false;

  competionId = '';

  /* CONTENT */
  content = '';

  contentText = `자취를 시작하며 ‘이웃 사촌’이라는 정겨운 표현을 쓰지 않게 되었다. 첫 자취집은 셰어하우스였다. 같은 집에 살던 ‘이웃 사촌’은 내 방에 몰래 들어와 전신거울 앞에 자신의 화장품을 두고 나가는 흔적을 남겼고, 친절히 경찰을 불러 사태를 해결했다. 그 뒤로 셰어하우스가 나오는 드라마조차 거부했다. 3년 전, 서울에 올라와 처음 살게 된 집은 은퇴한 집주인 부부가 함께 사는 원룸이었다. 집주인 부부는 에너지가 넘치는 어린 손주들을 주기적으로 돌봐 주었고, 그들의 장성한 아들은 손흥민이 골만 넣었다 하면 새벽에도 환호를 곁들이며 소리를 질렀다. 손흥민은 쓸데없이 너무 잘했다. 환호 소리에 세 번쯤 깼을 무렵 나는 1층 입구에 메모를 붙였다. “안녕하세요. 이 건물에 손흥민 선수의 큰 팬이 사시나봐요^^ 응원하시는 마음은 이해하지만 저는 토트넘, 국가대표팀이 골을 넣을 때마다 환호 소리에 놀라 새벽에 깨곤 합니다. 아침에 출근하는 이웃을 위해 조금만 작게 기뻐해 주시면 감사하겠습니다. 좋은 하루 보내세요!”

  지금 사는 서울에서의 두 번째 집은 집주인이 같은 건물에 살지 않는 집으로 골랐다. 이웃들끼리는 적당히 얼굴을 모른다. 내 집의 위층에 공용공간이 한 칸 있지만 그 곳에서 누군가 마주친 적은 1년 동안 한 손에 꼽을 정도다. 또 내가 집에서 나오려는 순간 옆집의 도어락 소리가 들리면 발소리가 사라질 때까지 기다렸다가 나오는 것은 사회적 약속이 아니던가. 그래서 건물 앞에서 마주쳐도 이 사람이 이 건물의 주민인지 전혀 알 수 없다. 내가 원하는 편안한 생활이다.

  그러던 어느 날, 소리로 존재감을 드러내는 이웃이 생겼다. 유독 큰 발소리로 쿵쿵쿵 계단을 올라 도어락을 삑삑삑 누른 다음 무언가 우당탕 하는 소리가 들리는 루틴을 가진 사람이었다. 그가 돌아오는 시간은 저녁 10시 이후. 그가 쿵쿵거리는 소리는 마치 내 위층에서 들리는 것처럼 느껴졌다. 그러나 바로 위층은 아무도 살지 않는 공용 공간이고, 계단을 오르는 소리가 정확히 2층에서 멈췄다. 나는 그 시간에 보통 하루를 마무리하며 이불 속에서 아이패드를 끼고 누워있곤 한다. 그래서 그의 루틴을 정확히 파악할 수 있었다. 하지만 정확히 어느 집인지 알 수 없으니 그저 참는 수 밖에.

  아주 잠깐 코로나 상황이 좋아졌을 무렵이었다. 회사의 친한 동료들과 우리 집에서 홈파티를 하게 되었다. 초저녁 퇴근 직후에 모인 우리들은 배달 음식을 시켜 먹으며 신나게 회사 욕을 했다. 회사 욕을 하다 보니 열이 올라 창문까지 열고 이야기를 하게 되었다. 동료들을 배웅하려고 10시쯤 현관문을 연 순간 쪽지를 발견했다. “안녕하세요. 000호입니다. 친구들과 즐거운 시간을 보내고 계신가봐요. 대화 소리가 저희 집까지 다 들립니다. 평소에도 밤에 쿵쿵거리시고, 발소리도 크게 내시는데 조심해주세요. 그럼 좋은 하루 보내세요!”. 옆집이었다.

  동료들을 보내고 미안한 마음과 분한 마음을 동시에 안고 방에 앉았다. 내가 싫어하는 행동을 내가 하게 되어서 나 자신에게 화가 나지만, 한편으로는 아래층에서 나는 쿵쿵 소리를 그동안 나라고 오해했다는 것에도 화가 났다. 쇼핑백에 과자를 담아 옆집 현관문에 걸었다. 그리고 쪽지를 붙였다. “안녕하세요. 000호입니다. 오늘 저녁에 시끄럽게 하여 불편하게 해드린 점 죄송합니다. 그러나 밤에 쿵쿵거리는 소리는 저도 듣고 있습니다. 저희 집에서 내는 소리가 아닙니다. 발소리는 유의하겠습니다. 약소하지만 간식거리를 담았습니다. 즐거운 주말 보내세요!”

  이 사건 이후 한 동안 내가 움직이는 모든 소리가 소음이 될 까봐 거의 움직이지 않았다. 반면 여전히 아래층 사람은 활개를 치고 다녔다. 나는 배려심 깊은 좋은 이웃인데. 어떻게 저 소리를 내는 것을 나라고 오해할 수가 있지? 속이 부글부글 끓어올랐다. 더불어 옆집에서 작은 소리만 나도 귀를 쫑긋 세우고 짜증스럽게 반응을 하게 되었다.

  그렇게 지내던 어느 날 깨달았다. 옆집 사람과 나는 참 비슷하다. 일단 참아보고, 쪽지로 부드럽게 일침을 놓는 모습을 보면 나와 같은 결을 가진 사람이다. 그런 사람이라면 내가 동료들을 불러 시끄럽게 한 것에 대해서는 짜증나지만 본인이 헛다리를 짚은 것에는 미안해하고 있을 것이라는 생각이 들었다. 마음이 편해졌다. 우연히 나가는 타이밍이 비슷해 현관문을 열고 한 번 마주친 옆집 사람이 아니라 학교나 회사에서 만났다면 좋은 친구가 되었을텐데. 벽 하나를 사이에 두고 가장 사적인 영역을 공유하는 사이가 참 얄궂다. `;

  comments = [];

  /* META DATA */
  tags = [];

  relatedTags = [];

  userListLike = [];

  likeCount = 0;

  voteCount = 0;

  voteUserList = [];

  viewCount = 0;

  scrapCount = 0;

  relatedEssay = [];

  readerList = [];

  recommended = false;

  isPrized = false;
}

export default Essay;
