import React from 'react';
import styled from 'styled-components';

import IcSearch from "../../assets/img/ic/ic_search.svg";

const Container = styled.div`
  width: 577px;
  height: 48px;
  padding: 8px 24px;
  border-radius: 24px;
  border: solid 1px ${props => props.theme.color.brand.primary300};
  color: ${props => props.theme.color.tertiary300};
  ${props => props.theme.type.subtitle1}
  ${props => props.theme.layout.flexRowAlignCenterBlock}
  justify-content: space-between;
  input{
    width: calc(100% - 48px);
    border: none !important;
    outline: none;
  }
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const SearchBtt = styled.div`
  width: 32px;
  height: 32px;
  ${props => props.theme.layout.flexColCenterBlock}
  img{
    width: 32px;
    height: 32px;
  }
`;

const SearchBar = ({
  onSearch,
  onChange,
  value
}) => (
  <Container>
    <input
      placeholder='어떤 글을 보고 싶으세요?'
      type={'text'}
      value={value}
      onChange={
        (e) => {
          onChange(e.target.value);
        }
      }
      onKeyPress={
        (e) => {
          if(e.key==='Enter'){
            console.log("Enter!");
            onSearch();
          }
        }
      }
    />
    <SearchBtt onClick={() => {
      onSearch();
    }}>
      <img src={IcSearch} alt='' />
    </SearchBtt>
  </Container>
);

export default SearchBar;