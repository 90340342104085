import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// Reducer for Dispatch Essay List Object
import essayListReducer, { initialState } from '../reducer/essayListReducer';

const EssayListContext = createContext();
const EssayListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(essayListReducer, initialState);
  return (
    <EssayListContext.Provider value={{ state, dispatch}}>
      {children}
    </EssayListContext.Provider>
  );
};

EssayListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Dispatch Definition
export const useEssayListDispatch = () => {
  const { dispatch } = useContext(EssayListContext);
  return dispatch;
};

// UseState Definition
export const useEssayListState = () => {
  const { state } = useContext(EssayListContext);
  return state;
};

export default EssayListProvider;