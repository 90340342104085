import React from 'react';
import PropTypes from 'prop-types';
import User from '../../_models/user';
import Comment from '../../_models/comment';

import BttBig from "../Buttons/ButtonLink/BttBig";

const InputComment = ({
  className,
}) => {
  // const user = new User();
  return (
    <div
      className={`flex_row input_comment space_between col1 padding_ver_1x ${className}`}
    >
      <div className="avatar_b flex_col flex_center caption regular">
        bl
      </div>
      <div
        style={{ width: 'calc(100% - 60px)' }}
        className="flex_col align_end"
      >
        <textarea
          placeholder="회원 가입 후 댓글을 남겨주세요."
          className="col1 comment body regykar padding_tiny primary margin_b_1x"
          value={''}
          disabled
        />
        <BttBig
          type="outlined"
          to={'/signUp'}
          title="가입하기"
        />
      </div>
    </div>
  );
};

InputComment.propTypes = {
  userData: PropTypes.instanceOf(User).isRequired,
  onCommentChange: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  comment: PropTypes.instanceOf(Comment).isRequired,
};

export default InputComment;
