import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
  padding:0 8px;
  ${props => props.theme.container.linedPrimaryLightBlock.enabled}
  ${props => props.theme.type.caption3}
  height: 20px;
  border-radius: 10px;
  span{
    font-family: ui-rounded;
  }
`;

const Tag = ({
  children,
  onClick,
  enable
}) => {
  return (
    <Container
      onClick={onClick}>
      {children}
    </Container>
  );
}

export default Tag;