import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import ListEssay from '../../components/List/ListEssay';
import BttClose from '../../components/Buttons/ButtonAction/BttClose';
import PartitionVer from '../../components/Layout/Grid/PartitionVer';

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.12);
`;

const TagedEssayContainer = styled.div`
  width: calc(100% - 564px);
  height: calc(100% - 512px);
  width: flex;
  flex-direction: column;
  padding: 32px 0px 32px 32px;
  background-color: ${props => props.theme.color.brand.primary50};
  position: absolute;
  top: 256px;
  left: 282px;
  padding-top: 56px;
  --scrollbarBG: ${(props) => props.theme.color.brand.primary50};
	--thumbBG: ${(props) => props.theme.color.brand.primary100};
  @media screen and (max-width: 480px) {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    top: 16px;
    left: 16px;
  }
`;

const EssayList = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;
  overflow-y: auto;
`;

const EssayListModal = ({
  tagedEssayList,
  resetTagedEssay,
}) => {
  return (
    <BackDrop onClick={() => resetTagedEssay()}>
      <TagedEssayContainer>
        <div
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
        >
          <BttClose onClick={() => resetTagedEssay()} />
        </div>
        <EssayList>
          {tagedEssayList.map(essay => (
            <PartitionVer>
              <ListEssay
                essay={essay}
                to={`/post/${essay._id}`}
                className={''}
              />
            </PartitionVer>
          ))}
        </EssayList>
      </TagedEssayContainer>
    </BackDrop>
  )
}

export default EssayListModal;