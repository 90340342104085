import {
  SET_ESSAY_ID,
  SET_ESSAY_TITLE,
  SET_ESSAY_CONTENT_TEXT,
  SET_ESSAY_CONTENT,
  SET_ESSAY_TAGS,
  ADD_ESSAY_TAGS,
  REMOVE_ESSAY_TAGS,
  SET_IS_DUEDAY,
  SET_DUEDAY,
  SET_IS_COMPETED,
  SET_COMPETITION_ID,
  SET_COMPETITION_DATA,
  SET_IS_BRANDED,
  SET_BRANDED_ID,
  SET_BRANDED_DATA,
  SET_NUM_CURRENT_WORDS,
  RESET_ESSAY,
  SET_IS_PUBLISHED,
  SET_ATTEMPT_SAVE
} from '../actions';

export const initialState = {
  id: '',
  title: '',
  content: null,
  contentText: null,
  tags: [],
  isDueday: false,
  dueday: '',
  isPublished: false,
  isCompeted: false,
  competitionId: '',
  competitionData: {},
  isBranded: false,
  brandedId: '',
  brandedData: {},
  numCurrentWords: 0,
  isAttemptSave: false
};

const essayReducer = (state, action) => {
  switch (action.type) {
    case SET_ESSAY_ID:
      return {
        ...state,
        id: action.value,
      };
    case SET_ESSAY_TITLE:
      // 타이틀 string을 value로 받음
      return {
        ...state,
        title: action.value,
      };
    case SET_ESSAY_CONTENT_TEXT:
      console.log(`${SET_ESSAY_CONTENT_TEXT} ${action.value}`)
        return {
          ...state,
          contentText: action.value
        }
    case SET_ESSAY_CONTENT:
      // Content object를 value로 받음
      // console.log(SET_ESSAY_CONTENT);
      // console.log(action.value);
      return {
        ...state,
        content: action.value,
      };
    case SET_ESSAY_TAGS:
      return {
        ...state,
        tags: action.value,
      };
    case ADD_ESSAY_TAGS:
      // 태그 object를 value로 받음
      // console.log(ADD_ESSAY_TAGS);
      console.log(action.value);
      return {
        ...state,
        tags: [...state.tags, action.value],
      };
    case REMOVE_ESSAY_TAGS:
      // 태그 타이틀을 string을  value로 받음
      console.log(REMOVE_ESSAY_TAGS);
      console.log(action.value);
      console.log(state.tags.filter(tag => tag.tagName !== action.value));
      return {
        ...state,
        tags: state.tags.filter(tag => tag.tagName !== action.value),
      };
    case SET_IS_PUBLISHED:
      // Radio input의 value를 받음
      console.log(SET_IS_PUBLISHED);
      console.log(action.value);
      return {
        ...state,
        isPublished: action.value,
      };
    case SET_IS_DUEDAY:
      // Radio input의 value를 받음
      console.log(SET_IS_DUEDAY);
      console.log(action.value);
      return {
        ...state,
        isDueday: action.value,
      };
    case SET_DUEDAY:
      // Radio input의 value를 받음
      console.log(SET_DUEDAY);
      console.log(action.value);
      return {
        ...state,
        dueday: action.value,
      };
    // Competition
    case SET_IS_COMPETED:
      console.log(`${SET_IS_COMPETED}: ${action.value}`);
      return {
        ...state,
        isCompeted: action.value,
      };
    case SET_COMPETITION_ID:
      console.log(`${SET_COMPETITION_ID}: ${action.value}`);
      return {
        ...state,
        competitionId: action.value,
      };
    case SET_COMPETITION_DATA:
      console.log(`${SET_COMPETITION_DATA}: ${action.value}`);
      return {
        ...state,
        competitionData: action.value,
      };
    // Branded
    case SET_IS_BRANDED:
      console.log(`${SET_IS_BRANDED}: ${action.value}`);
      return {
        ...state,
        isBranded: action.value,
      };
    case SET_BRANDED_ID:
      console.log(`${SET_BRANDED_ID}: ${action.value}`);
      return {
        ...state,
        brandedId: action.value,
      };
    case SET_BRANDED_DATA:
      console.log(`${SET_BRANDED_DATA}: ${action.value}`);
      return {
        ...state,
        brandedData: action.value,
      };
    case SET_NUM_CURRENT_WORDS:
      console.log(action.value);
      return {
        ...state,
        numCurrentWords: action.value,
      };
    case RESET_ESSAY:
      console.log('RESET_ESSAY!!!!!!');
      return {
        title: '',
        content: null,
        tags: [],
        isDueday: false,
        dueday: '',
        isPublished: false,
        isCompeted: false,
        competitionId: '',
        competitionData: {},
        numCurrentWords: 0,
      };
    case SET_ATTEMPT_SAVE:
      return {
        ...state,
        isAttemptSave: action.value,
      };
    default:
      break;
  }
};

export default essayReducer;