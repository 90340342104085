import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmSWT({ alarm, setAlarmRead }) {
  let mark = '예준';
  let essay;
  let tag;
  let path;
  if (alarm.contentObject) {
    [essay, tag] = alarm.contentObject;
    mark = essay.writer.username[0] + essay.writer.username[1];
    path = `/post/${essay._id}`;
    console.log(alarm.mark);
  }
  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id, path)}
      className={`col1 ${alarm.isRead}` ? 'white_bg' : 'white_600bg'}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
      >
        {alarm.contentObject && (
          <>
            <p className="body regular black700">
              회원님과 동일한 주제어:
              <span className="bold">{tag.tagName}</span>
              으로 새로운 글이 작성되었습니다. :
              <span className="bold">{essay.title}</span>
              by
              <span className="bold">{essay.writer.username}</span>
            </p>
            <p className="body bold primary">{}</p>
          </>
        )}
      </ListAlarmBasic>
    </button>
  );
}

ListAlarmSWT.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmSWT;
