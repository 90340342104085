import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import queryString from 'querystring';
import Register from '../../template/SocialSignUp/Register';

function SocialSignUp({ location }) {
  // GET previous page
  const { search } = location; // 문자열 형식으로 결과값이 반환된다.
  const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환
  const from = queryObj['?from'];

  console.log(queryObj['?from']);

  useEffect(() => {}, []);

  return (
    <Container maxWidth="sm" className="padding_2x whte_bg margin_t_2x">
      <Paper elevation={0} variant="">
        <Register from={from, location} />
      </Paper>
    </Container>
  );
}

export default SocialSignUp;
