import React from 'react';

import AboutCompetitionPresent from './AboutCompetitionPresenter';

function AboutCompetitionContainer(){
    return(
        <AboutCompetitionPresent />
    )
}

export default AboutCompetitionContainer;