import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import SearchPresenter from './SearchPresenter';
import { useUserState } from '../../context/currentUserContext';

function SearchContainer({ location }) {
  const { loged, loaded, username, userData, interestedTag } = useUserState();

  return (
    <SearchPresenter
      loged={loged}
      username={username}
      userData={userData}
      location={location}
    />
  );
}

export default SearchContainer;