import 'date-fns';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

// Context
import { useEssayListState } from '../../context/essayListContext';

// Component
// Container & Section
import PartitionVer from '../../components/Layout/Grid/PartitionVer';

// Chip
import ChipBasic from '../../components/Chips/ChipBasic';

// Styled Component
const SectionTitleCol = styled.div`
  width: 200px;
`
const SectionContentCol = styled.div`
  width: calc(100% - 200px);
  padding-left: 24px;
  @media screen and (max-width: 480px) {
    margin-top: 32px;
    width: 100%;
    padding-left: 0px;
  }
`
const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const TagInputContainer = styled.div`
  width: 100%;
  position: relative;
  /* background-color: #fff; */
`;
const TagList = styled.div`
  position: absolute;
  z-index: 99;
  top: 52px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  ${props => props.theme.shape.elavation50Block}
`;
const RecentTagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;  
const Keyword = styled.div`
  max-width: 86px;
  line-height: 18px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top:2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;
const TextInputRound = styled.input`
  height: 44px;
  border-radius: 22px;
  padding-left: 16px;
`;


function SetBasicEssayInfo({
  selectedTag,
  addTag,
  removeTag,
  onTagPreview,
  previewTagList,
  isPreview,
  setIsPreview,
  isDueday,
  dueday,
  setIsDueday,
  setDueday,
}) {
  const [tagOnInput, setTagOnInput] = useState('');

  const { essayListContextData } = useEssayListState();

  return (
    <div
      className="flex_col col1"
      style={{ marginTop: '-20px' }}
    >
      <PartitionVer
        colPC={1}
        colMb={1}
        index={0}
        className="flex_row_col"
      >
        <SectionTitleCol>
          <h3 className="bold primary col1">주제어 등록</h3>
          {/* <p className="body_s col1 regular black400 margin_b_tiny">
            글의 내용을 나타낼 수 있는 주제어를 등록하주세요.
          </p> */}
        </SectionTitleCol>
        <SectionContentCol>
          <TagInputContainer>
            <TextInputRound
              className="col1 regular"
              type="text"
              placeholder="글의 주제어를 등록해주세요."
              value={tagOnInput}
              onChange={e => {
                let world;
                world = e.target.value;

                if (world.replace(/\s/g, '').length > 0) {
                  setTagOnInput(world);
                  onTagPreview(world);
                } else {
                  setTagOnInput('');
                }
                console.log(`current Tag on input${tagOnInput}`);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  console.log('Enter!');
                  if (tagOnInput) {
                    addTag(tagOnInput);
                    setTagOnInput('');
                  }
                }
              }}
            />
            {/* 주제어 자동완성 리스트 */}
            {isPreview && (
              <>
                <TagList>
                  <p className="caption black700 regular margin_b_1x margin_t_1x">
                    <span className="bold primary">*</span>
                    {' '}
                  다른 글쓴이들이
                  사용하고 있는 주제어입니다.
                </p>
                  {previewTagList.map(tag => (
                    <TagContainer
                      onClick={e => {
                        addTag(tag.tagName);
                        setTagOnInput('');
                      }}
                    >
                      <div className="tooltip_outlined_dark">{`${tag.tagName} (${tag.count})`}</div>
                      <div className="caption black300 regular">
                        <span className="bold">{tag.owner.username}</span>
                      님이 제시.
                    </div>
                    </TagContainer>
                  ))}
                </TagList>
                {/* preview를 닫기위한 fakeBackdrop */}
                <FakeBackDrop
                  onClick={() => {
                    setIsPreview(false);
                  }}
                />
              </>
            )}
          </TagInputContainer>
          {/* <div className="flex_row col1 row_regular align_center justify_end margin_b_1x">
            <BttTextMore title="주제어 모두 보기" to="" />
          </div> */}
          <div className="flex_row row_regular align_center col1">
            {selectedTag.map(tag => {
              return (
                <ChipBasic
                  className="margin_r_tiny"
                  title={tag.tagName}
                  onClickRemove={removeTag}
                />
              );
            })}
          </div>
        </SectionContentCol>
      </PartitionVer>
      <PartitionVer
        colPC={1}
        colMb={1}
        index={1}
        className="flex_row"
      >
        <SectionTitleCol>
          <h3 className="bold primary">최근 등록된 주제어</h3>
          {/* <p className="body_s regular black400 margin_b_tiny">
            에세이를 출한할 마감일을 지정합니다.
          </p>   */}
        </SectionTitleCol>
        <SectionContentCol>
          <RecentTagList>
            {essayListContextData.recentTagList.map((tag, i) => {
              return (
                <Keyword
                  className="tooltip_outlined_dark align_start"
                  key={(tag != null) ? tag._id : '123'}
                >
                  {(tag != null) ? tag.tagName : ''}
                </Keyword>
              );
            })}
          </RecentTagList>
          {/* <div className={`${isDueday ? '' : 'disabled '}col1`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  value={dueday}
                  onChange={setDueday}
                  KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
              </>
            </MuiPickersUtilsProvider>
          </div>
          <div className="flex_row align_center body_s regular row_regular">
            <div className="flex_row align_center margin_r_2x">
              <input
                type="radio"
                value={false}
                checked={isDueday === false}
                name="dday"
                onChange={setIsDueday}
              />
              <label htmlFor="no_dday">마감 미지정</label>
            </div>
            <div className="flex_row align_center">
              <input
                type="radio"
                value
                checked={isDueday === true}
                name="dday"
                onChange={setIsDueday}
              />
              <label htmlFor="yes_dday">마감 지정</label>
            </div>
          </div> */}
        </SectionContentCol>
      </PartitionVer>
    </div>
  );
}

export default SetBasicEssayInfo;
