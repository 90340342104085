import React from 'react';
import BttActionSub from '../../components/Buttons/ButtonAction/BttActionSub';

function EssayPostVoterActionBar({ voteEssay, isVoted }) {
  return (
    <div className="col1 flex_row flex_center align_center padding_ver_2x">
      <div
        onClick={() => {
          voteEssay();
        }}
      >
        <BttActionSub
          type={isVoted === true ? 'filled' : 'outlined'}
          title={isVoted === true ? '투표함' : '투표하기'}
        />
      </div>
    </div>
  );
}

export default EssayPostVoterActionBar;
