import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { createGlobalStyle } from "styled-components";
import Router from './router/router';
import theme from './assets/theme/theme';
import themeNew from './assets/theme/themeNew';
import { useHistory } from 'react-router-dom';
import gtag from 'ga-gtag';
import {useThemeTypeState} from './context/themeTypeContext';

function useScrollMemory(){
  const history = useHistory();

  React.useEffect(() => {
    const { push, replace } = history;

    // Override the history PUSH method to automatically set scroll state.
    history.push = (path) => {
      push(path, { scroll: window.scrollY });
    };
    // Override the history REPLACE method to automatically set scroll state.
    history.replace = (path) => {
      replace(path, { scroll: window.scrollY });
    };

    // Listen for location changes and set the scroll position accordingly.
    const unregister = history.listen((location, action) => {
      window.scrollTo(0, action !== 'POP' ? 0 : location.state?.scroll ?? 0);
    });

    console.log("mounted!");

    // Unregister listener when component unmounts.
    return () => {
      unregister();
    };
  }, [history]);

  React.useEffect(() => {
    console.log(window.localStorage.getItem('themeType'));
  },[window.localStorage.getItem('themeType')]);
}


function App() {
  const { themeType } = useThemeTypeState();  

  return (
    <ThemeProvider theme={themeNew}>
      <GlobalStyle themeType={themeType} />
      <Router />
    </ThemeProvider>
  );
}
const GlobalStyle = createGlobalStyle`
  html {
		--scrollbarBG: ${(props) => props.theme.color.ui.background};
		--thumbBG: ${(props) => props.theme.color.ui.low};
    overscroll-behavior: none;
	}
  body {
    overscroll-behavior: none;
    font-family: ${props => props.theme.type.regular};
    background-color: ${props => props.themeType==='dark'?props.theme.color.brand.primary700:props.theme.color.ui.background};
  }
	*::-webkit-scrollbar {
		width: 16px;
	}
	* {
		scrollbar-width: thin;
		scrollbar-color: var(--thumbBG) var(--scrollbarBG);
	}
	*::-webkit-scrollbar-track {
		background: var(--scrollbarBG);
	}
	*::-webkit-scrollbar-thumb {
		background-color: var(--thumbBG) ;
		border-radius: 2px;
		border: 5px solid var(--scrollbarBG);
	}
`;

export default App;
