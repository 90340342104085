const colorList = {
    blueBlack900: "#1A1AB2",
    blueBlack700: "#3838bf",
    blueBlack500: "#6a6acf",
    blueBlack300: "#9b9bdf",
    blueBlack100: "#cdcdef",
    blueBlack50: "#e4e1ef",
    gray700: "#2a2a28",
    gray500: "#5f5d5a",
    gray300: "#93908b",
    gray200: "#c7c3bc",
    paper: "#FCF6EE",
    white500: "#fafafa",
    white700: "#ffffff",
}

export default colorList;