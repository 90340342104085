import React from 'react';
import InsPostPresenter from './InsPostPresenter';
import { useUserState } from '../../context/currentUserContext';

function InsPostContainer({ location, history }) {
  const { loged, loaded, userData } = useUserState();
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <InsPostPresenter
          loged={loged}
          location={location}
          history={history}
          userData={userData}
        />
      </>
    )
  );
}

export default InsPostContainer;
