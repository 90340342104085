import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Part = styled.div`
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: ${props =>
      props.numRow !== 1 &&
      props.index % props.numRow !== props.numRow - 1 &&
      '20px;'}
    }
    // Vertical Border
    &:before{
      ${props =>
        props.numRow !== 1 &&
        props.index % props.numRow !== props.numRow - 1 &&
        props.theme.layout.parHorLine}
    }
  `;

function PartitionVer({ numRow, index, children, className }) {
  return (
    <Part className={className} index={index} numRow={numRow} key={index}>
      {children}
    </Part>
  );
}

PartitionVer.propTypes = {
  numRow: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default PartitionVer;
