import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Ad from '../../_models/Ad';

const Container = styled(Link)`
  width: 100%;
  height: 400px;
  display: inline-block;
`;

const Title = styled.h2`
  height: 54px !important;
  margin-bottom: 45px;
  line-height: 27px;
`

const ImgContainer = styled.div`
  width: 206px;
  height: 206px;
  position: relative;
  background-color: #f4f0ff;
  overflow: hidden;
  :before{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:9;
    /* background-color: ${props => props.theme.color.primary500};
    background-blend-mode: multiply; */
    content: '';
  }
`
const ImgWrapper = styled.div`
  width: fit-content;
  height: 206px;
  background-color: ${props => props.theme.color.primary500};
  img{
    width: auto;
    height: 206px;
    mix-blend-mode: luminosity;
    filter: grayscale(100%) brightness(1.32);
  }
`



const CardRegularIns = ({ Ins, to }) => {
  let imgRatio;
  const thumbImg = new Image();
  thumbImg.src = Ins.thumbImg;
  thumbImg.onload = e => {
    const width = e.target.naturalWidth;
    const height = e.target.naturalHeight;
    if (height < width) {
      imgRatio = 'horizon';
    } else {
      imgRatio = 'vertical';
    }
    console.log(imgRatio);
  };

  return (
    <Container to={to}>
      <Title className="bold col1 primary">{Ins.title}</Title>
      <ImgContainer className='flex_col flex_center margin_b_1x'>
        <ImgWrapper>
          <img src={Ins.thumbImg} alt="" />
        </ImgWrapper>
      </ImgContainer>
      {/* <p className="caption regular margin_b_1x">{`${Ins.writer.username} | ${Ins.datePublish}`}</p> */}
      <div className="flex_row col1">
        {Ins.tags.map(tag => {
                return (
                  <div style={{marginBottom:'4px', marginRight: '4px'}} className="tooltip_outlined_dark regular" key={tag._id}>
                    {tag.tagName}
                  </div>
                );
              })}
      </div>
    </Container>
  );
};

CardRegularIns.propTypes = {
  Ad: PropTypes.instanceOf(Ad).isRequired,
  to: PropTypes.string.isRequired,
};

export default CardRegularIns;
