import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Competition from '../../../_models/competition';
import { isMobile } from 'react-device-detect';
import Moment from 'react-moment';

// Component
import SectionTitle from '../../Layout/Title/SectionTitle';
import ConainerO from '../../Layout/Container/ContainerO';
import GridMatrix from '../../Layout/Grid/GridMatrix';
import SpaceMatrix from '../../Layout/Grid/SpaceMatrix';
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttActionBig from '../../Buttons/ButtonAction/BttActionBig';
import AvatarM from '../../Avatar/AvatarM';
import Modal from '../../Modal/Modal';

// Button
import BttTextSectionMore from '../../Buttons/ButtonLink/BttTextSectionMore';
import BttBigExtraFixedWidth from '../../Buttons/ButtonLink/BttBigExtraFixedWidth';

import imgEditor from '../../../assets/img/pic/img_editor_blbl.jpeg';

const Reward = styled.div`
  white-space: pre-line;
  text-align:center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  img{
    max-width: 186px;
    height: auto;
  }
  padding: 32px 64px;
  @media screen and (max-width: 480px) {
    padding: 32px 16px;
  }
`;


const Title = styled.p`
  ${props => props.theme.type.h3};
  color: ${props => props.theme.color.brand.primary700};
  word-break: keep-all;
  padding-right: 48px;
  @media screen and (max-width: 480px) {
    /* text-align: center; */
    padding-right: 0px;
    margin-bottom: 80px;
  }
`;

const Overline = styled.p`
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.overline};
  /* margin-bottom: 24px; */
`;

const ContentTitle = styled.p`
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.body3};
  margin-bottom: 32px;
`;


const DueDate = styled.p`
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.body2};
  color: ${props => props.theme.color.primary500};
  margin-bottom: 32px;
`;

const SectionRowCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    .b_80px{
      margin-bottom: 80px;
    }
    .remove_before:before{
      display: none;
    }
  }
`;


const Exp = styled.p`
  font-family: ${props => props.theme.type.regular};
  ${props => props.theme.type.body3};
  word-break: keep-all;
  white-space: break-spaces;
`;

const UserList = styled.div`
  width: 100%;
  margin-bottom: 88px;
  flex-wrap: wrap;
  @media screen and (max-width: 480px) {
    margin-bottom: 48px;
  }
`;

const CompetitionDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  @media screen and (max-width: 480px) {
    flex-direction: row;
  }
  p{
    margin-bottom: 32px;
  }
`;

const ImgBook = styled.img`
  width: 120px;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 90px;
    height: 100%;
  }
`;

const ImgMaster = styled.div`
  width: 120px;
  height: 120px;
  background-position: center;
  background-size: cover;
  background-image: url(${props => props.imgSrc});
  border-radius: 60px;
  @media screen and (max-width: 480px) {
    width: 90px;
    height: 90px;
    border-radius: 45px;
  }
`;

const ContentDetail = styled.div`
  width: calc(100% - 120px - 24px);
  padding-bottom: 60px;
  ${props => props.theme.type.body3};
  @media screen and (max-width: 480px) {
    width: calc(100% - 90px - 24px);
  }
`;



const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
  }
`;

const AutoSizedImage = styled.img`
  width: auto;
  height: auto;
  min-width: 180px !important;
  max-width: 500px !important;
  max-height: 300px !important;
  @media screen and (max-width: 480px) {
    min-width: 180px !important;
    max-width: 100% !important;
    max-height: 264px !important;
  }
`

const CardFullCompetitionStatus = ({
  loged,
  competition,
  to,
  className,
  username,
  isLinkToCompetitionPage
}) => {
  // Local State
  const [isRedirect, setIsRedirect] = useState(false);
  const [isToLogin, setIsToLogin] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rewardImageAspect, setRewardImageAepect] = useState('vertical');
  const [isMaster, setIsMaster] = useState(false);

  const url = require('url');
  const http = require('http');

  useEffect(() => {
    if(competition.master!==null){
      if(competition.master.masterType==='celebrity'){
        setIsMaster(true);
      }
      else{
        setIsMaster(false);
      }
    }
    else{
      setIsMaster(false);
    }
  },[])

  // const sizeOf = require('image-size');

  // const imgUrl = competition.prizeItem.thumbnail;
  // const options = url.parse(imgUrl);

  // http.get(options, function (response) {
  //   const chunks = [];
  //   response.on('data', function (chunk) {
  //     console.log(chunk);
  //     chunks.push(chunk);
  //   }).on('end', function() {
  //     const buffer = Buffer.concat(chunks);
  //     console.log(sizeOf(buffer));
  //   });
  // });

  // sizeOf(competition.prizeItem.thumbnail, function(err,dimensions){
  //   if(dimension.width<dimension.height){
  //     setRewardImageAepect('vertical');
  //   }
  //   else {
  //     setRewardImageAepect('horizon');
  //   }
  // })

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const moveToWrite = competitionId => {
    console.log('moveToWrite!');

    // competitionId가 인자값으로 들어오는데 쓰이는 부분이 없지만 함부로 지우기는 뭐해서 콘솔 찍었습니다.
    console.log(competitionId);

    // 아래 있는 코드 2줄이 비활성화라 안 쓰이는 value값들 import하는 것 지워버렸습니다.
    // essayDispatch({type: SET_IS_COMPETED, value: true});
    // essayDispatch({type: SET_COMPETITION_ID, value: competitionId});
    if (loged) {
      setIsRedirect(true);
    } else {
      setIsToLogin(true);
    }
  };

  // isRedirect가 true일경우 essayContext값이 갱신된 상태에서 write로 이동
  // const history = useHistory();
  if (isRedirect) {
    return <Redirect to={`/write?competitionId=${competition._id}`} />;
  }
  if (isToLogin) {
    window.alert('로그인이 필요한 서비스입니다.');
    return <Redirect to="/signIn" />;
  }

  return (
    <div className={`flex_col col1 ${className}`}>
      <Overline>
        망월장 질문
      </Overline>
      <SectionRowCol>
        <GridMatrix
          className={'flex_col space_between align_start bottom_24px remove_before'}
          colPC={2}
          colMb={1}
          index={0}
        >
          <Title className="primary bold margin_b_2x">{competition.question}</Title>
          {/* <DueDate className={'only_mobile'}>
            매주
          </DueDate> */}
          <UserList className={'only_mobile'}>
            {competition.participantList && competition.participantList.length > 0 ? (
              <>
                <div className="flex_row col1 margin_b_1x">
                  {competition.participantList.map(user => {
                    return (
                      <AvatarM
                        className={'margin_avatar'}
                        to={`/user/${user.username}`}
                        writer={user}
                      />
                    );
                  })}
                </div>
                <Exp className="black300 col1">{`${competition.participantList[0].username} 외 ${competition.participantList.length - 1}명이 참여중입니다.`}</Exp>
              </>
            ) : (
                username !== null && (
                  <p className="body_s black300 regular col1 ">
                    {`'${username}'님 아직 참가자가 없습니다.`}
                    <br />
                    {`${competition.month}월 ${competition.week}주차의 망월장의 첫번째 참가자가 되어 보세요!`}
                  </p>
                )
              )}
          </UserList>
          <BttTextSectionMore
            to={loged?`/write?competitionId=${competition._id}`:'/signup?from=write'}
            title={'망월장 참여하기'}
            className={'force_to_static only_pc'}
          />
        </GridMatrix>
        <GridMatrix
          colPC={2}
          colMb={1}
          index={1}
          className={'bottom_24px'}
        >
          {/* <DueDate className={'only_pc'}>
            망월장은 매월 1일, 15일 시작하여 2 주동안 진행합니다.
          </DueDate> */}
          <UserList className={'only_pc'}>
            {competition.participantList && competition.participantList.length > 0 ? (
              <>
                <div className="flex_row col1 margin_b_1x">
                  {competition.participantList.map(user => {
                    return (
                      <AvatarM
                        className={'margin_avatar'}
                        to={`/user/${user.username}`}
                        writer={user}
                      />
                    );
                  })}
                </div>
                <Exp className="black300 col1">{`${competition.participantList[0].username} 외 ${competition.participantList.length - 1}명이 참여중입니다.`}</Exp>
              </>
            ) : (
                username !== null && (
                  <p className="body_s black300 regular col1 margin_b_3x">
                    {`'${username}'님 아직 참가자가 없습니다.`}
                    <br />
                    {`${competition.month}월 ${competition.week}주차의 망월장의 첫번째 참가자가 되어 보세요!`}
                  </p>
                )
              )}
          </UserList>
          <Exp className="">{competition.description}</Exp>
        </GridMatrix>
      </SectionRowCol>
      <SectionRowCol>
        <SpaceMatrix
          colPC={2}
          colMb={1}
          index={0}
          className={'b_80px'}
        >
          <SectionTitle className={''} title1="망월장에 영감을 준 책" />
          <ConainerO className={'flex_col'}>
            <div className="flex_col col1 dv_left no_ver_line_mobile">
              <ContentTitle className='text_center'>
              {
                competition.prizeList.length>0?competition.prizeList[0].title:competition.prizeItem.title
              }
              </ContentTitle>
              <CompetitionDetail>
                {
                  competition.prizeList.length>0?
                  <>
                    <ImgBook src={competition.prizeList[0].thumbnail} alt={''} />
                    <ContentDetail>
                      {competition.prizeList[0].description}
                    </ContentDetail>
                  </>:
                  <>
                    <ImgBook src={competition.prizeItem.thumbnail} alt={''} />
                    <ContentDetail>
                      {competition.prizeItem.description}
                    </ContentDetail>
                  </>
                }
                
              </CompetitionDetail>
            </div>
          </ConainerO>
        </SpaceMatrix>
        <SpaceMatrix
          colPC={2}
          colMb={1}
          index={1}
          className={'flex_col'}
        >
          <SectionTitle title1="망월장 진행자" />
          <ConainerO className={'flex_col row1'}>
            <div className="flex_col justify_start col1 dv_right no_ver_line_mobile row1">
              <ContentTitle className='text_center'>
                {isMaster ? competition.master.name : '안야'}
              </ContentTitle>
              <CompetitionDetail className={''}>
                <ImgMaster imgSrc={
                  isMaster ? competition.master.profile : imgEditor} />
                <ContentDetail>
                  {isMaster ? competition.master.about : '책과 영화를 사랑하는 사람,  음악과 미술에 관심이 많은 사람, 다양한 글쓰기 현장을 경험한 사람, 매일 읽고 쓰는 사람, 블루블랙 에디터.'}
                </ContentDetail>
              </CompetitionDetail>
            </div>
          </ConainerO>
        </SpaceMatrix>
      </SectionRowCol>
      <div className="flex_row col1 flex_center padding_2x">
        <ActionBar>
          {
            !competition.isEnd && (
              <BttBigExtraFixedWidth
                width={isMobile ? '100%' : '400px'}
                to={loged?`/write?competitionId=${competition._id}`:'/signup?from=write'}
                type="filled_primary"
                title="망월장 참여하기"
                className=''
              />
            )
          }
          {
            isLinkToCompetitionPage && (
              <BttBigExtraFixedWidth
                width={isMobile ? '100%' : '400px'}
                to={`/competition/${competition.year}-${competition.month}-${competition.week}`}
                type="outlined"
                title="모든 참여작 보러가기"
                className=''
              />
            )
          }
        </ActionBar>
      </div>
    </div>
  );
};

CardFullCompetitionStatus.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default CardFullCompetitionStatus;
