import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Component
import AvatarH from "../../Avatar/AvatarH"; 
import Preview5lines from '../Element/Preview5lines';
import ButtActionSub from "../../Buttons/ButtonAction/BttActionSub";

const Container = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const Left = styled.div`
  width: 52px;
  height: 100%;
`;

const Right = styled.div`
  width: calc(100% - 52px - 16px);
`;

const Title = styled.h2`
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  ${props => props.theme.type.h2}
  font-family: ${props => props.theme.type.bold};
  color: ${props => props.theme.color.primary500};
`;

const CardUserSmall = ({
  writer,
  followUser,
  loged,
  isFollow,
  className
}) => {
  return (
    <Container className={className}>
      <Left>
        <AvatarH
          writer={writer}
          to={`/user/${writer.username}`}
        />
      </Left>
      <Right>
        <Link to={`/user/${writer.username}`}>
          <Title>
            {writer.username}
          </Title>
        </Link>
        <Preview5lines className={'margin_b_1x'}>
          {writer.description}
        </Preview5lines>
        {loged && (
          <ButtActionSub
            title={isFollow ? '팔로잉' : '팔로우 하기'}
            type={isFollow ? 'filled' : 'outlined'}
            onClick={followUser}
          />
        )}
      </Right>
    </Container>
  )
}

export default CardUserSmall;