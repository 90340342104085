import React from 'react';
import styled from 'styled-components';

import ic_reward from '../../../assets/img/ic/ic_reward.svg';
import ic_recommend from '../../../assets/img/ic/ic_recommend.svg';

const Container = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const Reward = styled.img`
  width: 32px;
  height: 26px;
`;

const Recommend = styled.img`
  object-fit: fill;
  width: auto;
  height: 32px;
  margin-right: -8px;
`;

const MarkEssay = ({type}) => {
  return(
    <Container>
      {type==='reward'&&<Reward src={ic_reward} alt='' />}
      {type==='recommend'&&<Recommend src={ic_recommend} alt='' />}
    </Container>
  )
}

export default MarkEssay;