import React, { useState, useEffect } from "react";
import { matchSorter } from "match-sorter";

const MENU_HEIGHT = 150;
const allowedTags = [
  {
    id: "editor-chapter-title",
    tag: "p",
    label: "챕터제목 적용",
    className: 'editor-chapter-title'
  },
  {
    id: "editor-quotation",
    tag: "p",
    label: "인용문 적용",
    className: 'editor-quotation'
  },
  {
    id: "editor-highlight",
    tag: "p",
    label: "강조하기",
    className: 'editor-highlight'
  },
  {
    id: "editor-paragraph",
    tag: "p",
    label: "본문",
    className: 'editor-paragraph'
  }
];

function SelectMenu({
  position,
  onSelect,
  close
}){

  const [positionValue,setPositionValue] = useState({ top: 0, left: 0 });
  const [command, setCommand] = useState("");
  const [items, setItems] = useState(allowedTags);
  const [selectedItem, setSelectedItem] = useState(null);

  // Set Position
  useEffect(() => {
    setPositionValue({ top: position.y, left: position.x })
  },[position]);


  // Listerner Handling
  useEffect(() => {
    // console.log('select menu mounted!');
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      // console.log('select menu unmounted!');
      document.removeEventListener("keydown", keyDownHandler);
    }
  },[items]);


  return (
    <div 
      click={
        (e) => {
          e.stopPropagation();
          e.preventDefault();
        }
      }
      className="SelectMenu"
      style={positionValue}>
        <div className="Items">
          {items.map((item, key) => {
            // const selectedItem = selectedItem;
            const isSelected = items.indexOf(item) === selectedItem;
            return (
              <div
                className={isSelected ? "Selected" : null}
                key={key}
                role="button"
                tabIndex="0"
                onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onSelect(item)
                    }
                  }
                >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
  );

  function keyDownHandler(e) {
    // const items = this.state.items;
    const selected = selectedItem;
    // const command = this.state.command;

    switch (e.key) {
      case "Enter":
        e.preventDefault();
        onSelect(items[selected]);
        break;
      case "Backspace":
        if (!command) close();
        setCommand(command.substring(0, command.length - 1))
        // this.setState({ command: command.substring(0, command.length - 1) });
        break;
      case "ArrowUp":
        e.preventDefault();
        const prevSelected = selected === 0 ? items.length - 1 : selected - 1;
        setSelectedItem(prevSelected);
        // this.setState({ selectedItem: prevSelected });
        break;
      case "ArrowDown":
      case "Tab":
        e.preventDefault();
        const nextSelected = selected === items.length - 1 ? 0 : selected + 1;
        setSelectedItem(nextSelected);
        // this.setState({ selectedItem: nextSelected });
        break;
      default:
        if (!command) close();
        setCommand(command.substring(0, command.length - 1));
        // this.setState({ command: command.substring(0, command.length - 1) });
        break;
    }
  }
}

export default SelectMenu;