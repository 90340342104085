/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import ContainerC from '../../components/Layout/Container/ContainerC'
import TextBannerTitle from '../../components/Layout/Title/TextBannerTitle';

const Container = styled(ContainerC)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 48px;
  @media screen and (max-width: 480px) {
    padding-bottom: 16px;
    margin-bottom: 32px;
  }
`;

const Caption = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle1};
`;

const BannerWriteForBranded = ({ branded, className }) => (
  <Container>
   <TextBannerTitle
    title1={'브랜디드 스토리 참가글'}
    title2={branded.question}
   />
   <Caption>
     by {branded.master.name}
   </Caption>
  </Container>
);


export default BannerWriteForBranded;
