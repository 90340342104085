import React from "react";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";
import theme from '../../assets/theme/theme';

// import "./styles.css";
// import SelectMenu from "./selectMenu";
import SelectionControl from './selectionControl';
import SelectMenu from "./selectMenuHook";

import { getCaretCoordinates, setCaretToEnd } from "./utils/caretHelpers";


const CMD_KEY = "/";
const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const Block = styled(ContentEditable)`
  background-color: ${
    props => props.isSelect?theme.color.primary100:'none'
  };
`;

class EditableBlock extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this);
    this.openSelectionControlHandler = this.openSelectionControlHandler.bind(this);
    this.closeSelectionControlHandler = this.closeSelectionControlHandler.bind(this);
    this.openSelectMenuHandler = this.openSelectMenuHandler.bind(this);
    this.closeSelectMenuHandler = this.closeSelectMenuHandler.bind(this);
    this.selectionControlHandler = this.selectionControlHandler.bind(this);
    this.tagSelectionHandler = this.tagSelectionHandler.bind(this);
    this.contentEditable = React.createRef();
    this.state = {
      htmlBackup: null, // needed to store the html temporarely
      html: "",
      tag: "p",
      className: "",
      previousKey: "",
      isSelect: false,
      selectMenuIsOpen: false,
      selectMenuPosition: {
        x: null,
        y: null
      }
    };
  }

  componentDidMount() {
    this.setState({ html: this.props.html, tag: this.props.tag });

  }

  // Update the page component if one of the following is true:
  // 1. user has changed the html content
  // 2. user has changed the tag
  componentDidUpdate(prevProps, prevState) {
    const htmlChanged = prevState.html !== this.state.html;
    const tagChanged = prevState.tag !== this.state.tag;
    if (htmlChanged || tagChanged) {
      this.props.updatePage({
        id: this.props.id,
        html: this.state.html,
        tag: this.state.tag
      });
    }
  }

  onChangeHandler(e) {
    this.setState({ html: e.target.value });
  }

  onKeyDownHandler(e) {
    // console.log('on key handler');
    e.stopPropagation();

    if (e.key === CMD_KEY) {
      // If the user starts to enter a command, we store a backup copy of
      // the html. We need this to restore a clean version of the content
      // after the content type selection was finished.
      this.setState({ htmlBackup: this.state.html });
    }
    if (e.key === "Enter") {
      // While pressing "Enter" should add a new block to the page, we
      // still want to allow line breaks by pressing "Shift-Enter"
      if (this.state.previousKey !== "Shift" && !this.state.selectMenuIsOpen) {
        e.preventDefault();
        this.props.addBlock({
          id: this.props.id,
          ref: this.contentEditable.current
        });
      }
    }
    if (e.key === "Backspace" && !this.state.html) {
      // If there is no content, we delete the block by pressing "Backspace",
      // just as we would remove a line in a regular text container
      e.preventDefault();
      this.props.deleteBlock({
        id: this.props.id,
        ref: this.contentEditable.current
      });
    }
    // Store the key to detect combinations like "Shift-Enter" later on
    this.setState({ previousKey: e.key });
  }

  // The openSelectMenuHandler function needs to be invoked on key up. Otherwise
  // the calculation of the caret coordinates does not work properly.
  onKeyUpHandler(e) {
    if (e.key === CMD_KEY) {
      // this.openSelectMenuHandler();
    }
  }

  // After openening the select menu, we attach a click listener to the dom that
  // closes the menu after the next click - regardless of outside or inside menu.

  openSelectionControlHandler(){
    const { x, y } = getCaretCoordinates();
    this.setState({
      isSelect: true,
      selectMenuPosition: { x, y: y + 20}
    });
  }

  closeSelectionControlHandler(){
    const { x, y } = getCaretCoordinates();
    this.setState({
      htmlBackup: null,
      isSelect: false,
      selectMenuPosition: { x: null, y: null }
    });
  }

  openSelectMenuHandler() {
    console.log('open select menu');
    const { x, y } = getCaretCoordinates();
    this.setState({
      selectMenuIsOpen: true,
      selectMenuPosition: { x, y }
    });
  }

  closeSelectMenuHandler() {
    console.log('close select menu');
    this.setState({
      htmlBackup: null,
      selectMenuIsOpen: false,
      isSelect: false,
      selectMenuPosition: { x: null, y: null }
    });
  }

  // Restore the clean html (without the command), focus the editable
  // with the caret being set to the end, close the select menu
  selectionControlHandler(item) {
    console.log(item);
    if(item.id==='decorate-selection'){
      this.setState({selectMenuIsOpen: true});
    }
    else if(item.id==='delete-selection'){
      this.props.deleteBlock({
        id: this.props.id,
        ref: this.contentEditable.current
      });
      this.setState({isSelect: false});
    }
  }
  
  tagSelectionHandler(item) {
    console.log(item);
    this.setState({ tag: item.tag, html: this.state.htmlBackup, className: item.className }, () => {
      setCaretToEnd(this.contentEditable.current);
      this.closeSelectMenuHandler();
    });
  }

  render() {
    return (
      <>
        {this.state.isSelect && (
          <FakeBackDrop
            onClick={
              () => {
                console.log('click backdrop');
                this.setState({isSelect: false});
              }
            }
          >
            {!this.state.selectMenuIsOpen && 
              <SelectionControl
                position={this.state.selectMenuPosition}
                onSelect={this.selectionControlHandler}
                close={this.closeSelectionControlHandler}
              />
            }
            {this.state.selectMenuIsOpen && 
              <SelectMenu
                position={this.state.selectMenuPosition}
                onSelect={this.tagSelectionHandler}
                close={this.closeSelectMenuHandler}
              />
            }
          </FakeBackDrop>
          
        )}
        <Block
          placeholder={this.props.index===0?'글을 작성해주세요':''}
          innerRef={this.contentEditable}
          html={this.state.html}
          // style={{backgroundColor:this.state.isSelect?theme.color.primary200:'none'}}
          tagName={this.state.tag}
          className={`Block ${this.state.className}`}
          onChange={(this.onChangeHandler)}
          onKeyDown={this.onKeyDownHandler}
          onKeyUp={this.onKeyUpHandler}
          isSelect={this.state.isSelect}
          onSelectCapture={
            (e) => {
              let type = window.getSelection().type;
              // console.log(window.getSelection());
              if(type==='Range'){
                this.setState({ htmlBackup: this.state.html });
                this.openSelectionControlHandler();
              }
              else{
                // this.closeSelectionControlHandler();
              }
            }
          }
          // onClick={
          //   () => {
          //     console.log('click');
          //   }
          // }
          // onMouseDown={
          //   () => {
          //     console.log('down!');
          //     this.props.onSelectBlock(true);
          //   }
          // }
          onMouseUp={
            (e) => {
              e.stopPropagation();
              console.log('click block');
              let type = window.getSelection().type;
              // console.log(type);
              if(type==='Range'){
                this.setState({ htmlBackup: this.state.html });
                this.openSelectionControlHandler();
              }
            }
          }
        />
      </>
    );
  }
}

export default EditableBlock;