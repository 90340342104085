/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import PropTypes from 'prop-types';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation
} from 'react-router-dom';

// API
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { brandedApi, adminApi, alarmApi } from '../../../api/api';

// Component
import Section from '../../../components/Layout/Section/Section';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import ContainerC from '../../../components/Layout/Container/ContainerC';
import Loading from '../../../components/Loading/Loading';
import BttActionBig from '../../../components/Buttons/ButtonAction/BttActionBig';

// Template
import BrandedPrizeStatus from '../../../template/Admin/BrandedPrizeStatus';

import config from '../../../config';

// From Material UI
function BrandedDetailPresenter({ location }) {
  // Local State
  const [competition, setCompetition] = useState({});
  const [essayList, setEssayList] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET COMPETIION DATE
  const url = location.pathname.replace('/admin/brandedDetail/', '');
  const id = url.split('-')[0];


  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });
  const [isShowMore, setIsShowMore] = useState(false);


  const SNACK_MSG_EXIST_COMPETITION = '이미 해당 주차에 백일장이 존재합니다.';
  const SNACK_MSG_COMPETITION_CREATED = '백일장이 생성되었습니다.';
  const SNACK_MSG_COMPETITION_END = '백일장이 종료되었습니다.';


  // SNACK BAR CONTROL
  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const fetchData = async () => {
    try {
      // 진행중 망월장
      await brandedApi.getBrandeds([id])
        .then(competitionSample => {
          console.log(competitionSample);
          console.log(competitionSample.data[0]);
          setCompetition(competitionSample.data[0]);
          console.log(competitionSample.data[0].essayList);
          setEssayList(competitionSample.data[0].essayList);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };


  useEffect(() => {
    fetchData();
  }, []);


  const endCompetition = async () => {
    console.log('delete competition');
    console.log('deleteID :', competition._id);
    await brandedApi
      .endBranded(id)
      .then(res => console.log(res));
      openSnackBar(SNACK_MSG_COMPETITION_END);
  };

  const fileUpload = async targretFile => {
    const url = `${config.apiUrl}/upload`;
    const formData = new FormData();
    formData.append('file', targretFile);
    const header = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const result = await post(url, formData, header);
    return result;
  };

  return (
      <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex_col margin_center col_grid_mobile router_area_top">
          {competition  && (
            <BrandedPrizeStatus
              competition={competition}
              endCompetition={endCompetition}
              isShowMore={isShowMore}
              setIsShowMore={setIsShowMore}
            />
          )}
        </div>
      )}
    </>
  );
}

BrandedDetailPresenter.propTypes = {
  username: PropTypes.string.isRequired,
  userData: PropTypes.string.isRequired
};

export default BrandedDetailPresenter;
