import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useUserState } from '../../context/currentUserContext';
import styled from 'styled-components';

const ContainerLink = styled(Link)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  ${props => props.theme.type.caption2}
  ${props => props.isDark?props.theme.container.filledWhiteBlock.enabled:props.theme.container.filledPrimaryBlock.enabled}
  ${props => props.theme.layout.flexColCenterBlock}
  @media screen and (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const ContainerDiv = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  ${props => props.theme.type.caption2}
  ${props => props.isDark?props.theme.container.filledWhiteBlock.enabled:props.theme.container.filledPrimaryBlock.enabled}
  ${props => props.theme.layout.flexColCenterBlock}
  @media screen and (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const AvatarM = ({
  isButton,
  to,
  writer,
  className,
  isDark
}) => {
  const { userData } = useUserState();
  return (
    <>
      {
        isButton &&
        <ContainerDiv
          className={`${className}`}
          isDark={isDark}
        >
          {writer.username && writer.username.length > 1 && writer.username[0] + writer.username[1]}
          {writer.username && writer.username.length == 1 && writer.username[0]}
          {isDark}
        </ContainerDiv>
      }
      {
        !isButton &&
        <ContainerLink
          to={
            // 로그인하고 글쓴이가 나일 경우
            userData !== null && userData._id === writer._id ? 'mypage' : to
          }
          className={`${className}`}
          isDark={isDark}
        >
          {writer.username && writer.username.length > 1 && writer.username[0] + writer.username[1]}
          {writer.username && writer.username.length == 1 && writer.username[0]}
        </ContainerLink>
      }
    </>
  );
};

AvatarM.propTypes = {
  to: PropTypes.string.isRequired,
  // writer: PropTypes.instanceOf(User).isRequired,
};

export default AvatarM;
