import color from "./color";

const type = {
  light: 'sopoqa_han_sans_light',
  regular: 'sopoqa_han_sans_regular',
  bold: 'sopoqa_han_sans_bold',
  h1: `
      font-size: 60px;
      line-height: 80px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 40px;
        line-height: 56px;
      }
    `,
  h2: `
      font-size: 40px;
      line-height: 60px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 34px;
        line-height: 48px;
      }
    `,
  h3: `
      font-size: 30px;
      line-height: 42px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 24px;
        line-height: 32px;
      }
    `,
  h4: `
      font-size: 24px;
      line-height: 34px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 20px;
        line-height: 30px;
      }
    `,
  h5: `
      font-size: 18px;
      line-height: 26px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 15px;
        line-height: 22px;
      }
    `,
  h6: `
      font-size: 16px;
      line-height: 24px;
      font-family: 'sopoqa_han_sans_bold';
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 20px;
      }
    `,
  subtitle1: `
      font-size: 17px;
      line-height: 26px;
      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 22px;
      }
    `,
  subtitle2: `
      font-size: 16px;
      line-height: 24px;
      @media screen and (max-width: 480px) {
        font-size: 13px;
        line-height: 20px;
      }
    `,
  body1: `
      font-size: 20px;
      line-height: 36px;
      @media screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 34px;
      }
    `,
  body2: `
      font-size: 16px;
      line-height: 24px;
      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 22px;
      }
    `,
  body3: `
      font-size: 14px;
      line-height: 22px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 18px;
      }
    `,
  btt1: `
      font-size: 14px;
      line-height: 20px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 18px;
      }
    `,
  btt2: `
      font-size: 12px;
      line-height: 18px;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        line-height: 18px;
      }
    `,
  caption1: `
      font-size: 15px;
      line-height: 20px;
      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    `,
  caption2: `
      font-size: 13px;
      line-height: 18px;
      @media screen and (max-width: 480px) {
        font-size: 11px;
        line-height: 14px;
      }
    `,
  caption3: `
      font-size: 11px;
      line-height: 14px;
      @media screen and (max-width: 480px) {
        font-size: 10px;
        line-height: 14px;
      }
    `,
  overline: `
      font-family: 'sopoqa_han_sans_bold';
      font-size: 18px;
      line-height: 26px;
      color: ${color.brand.primary700};
      @media screen and (max-width: 480px) {
        font-size: 15px;
        line-height: 22px;
      }
    `
}

export default type;