import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
// import Store from "../store";
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import Login from '../template/SignIn/Login';
// 안녕
function SignIn() {
  const [success, setSuccess] = useState(false);
  const [isForgotPW, setIsForgotPW] = useState(false);
  const [isSentLink, setIsSentLink] = useState(false);
  const [isRecievedLink, setIsRecievedLink] = useState(false);

  const googleLogin = () => {
    const success = axios.get('/api/auth/login/google');
    console.log(success);
    if (success) {
      setSuccess(true);
    }
  };

  useEffect(() => {
    googleLogin();
  }, []);

  return (
    <Container maxWidth="sm" className="padding_2x whte_bg margin_t_2x">
      <Paper elevation={0} vpwariant="">
        <Login
          isForgotPW={isForgotPW}
          setIsForgotPW={setIsForgotPW}
          isSentLink={isSentLink}
          setIsSentLink={setIsSentLink}
          isRecievedLink={isRecievedLink}
          setIsRecievedLink={setIsRecievedLink}
        />
      </Paper>
    </Container>
  );
}

export default SignIn;
