import React from 'react';
import styled from 'styled-components';
import BttBasic from '../../Buttons/ButtonAction/BttAction';
import LoadingSmall from '../../Loading/LoadingSmall';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const Title = styled.h2`
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: ${props => props.theme.color.primary500};
  margin-bottom: 24px;
`;


const Username = styled.p`
  width: calc(100%);
  color: ${props => props.theme.color.tertiary500};
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 32px;
`

const Owner = styled.div`
  width: 100%;
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.caption1.size};
  line-height: ${props => props.theme.type.caption1.height};
  color: ${props => props.theme.color.tertiary300};
`;

const Count = styled.div`
  width: 100%;
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.caption1.size};
  line-height: ${props => props.theme.type.caption1.height};
  color: ${props => props.theme.color.tertiary300};
  span{
    color: ${props => props.theme.color.primary400};
    font-family: ${props => props.theme.type.bold};
  }
`;

const CardRegularTag = ({
    tag,
    className,
    tagedEssayLoading,
    searchedTag,
    onTagedEssaySearch
  }) => (
  <Container
    className={`card_regular ${className}`}
  >
    <Content>
      <Title>{tag.tagName}</Title>
      <Owner>
        <Username>{tag.owner.username}</Username>
      </Owner>
      <Count>
        <span>{tag.count}&nbsp;</span>  
        번 언급됨
      </Count>
    </Content>
    {
      tagedEssayLoading&&searchedTag===tag.tagName?
        <LoadingSmall />
        :
        <BttBasic
          type={'outlined'}
          title={
            !tagedEssayLoading&&searchedTag===tag.tagName?
            '선택됨':
            '에세이 탐색'
          }
          onClick={onTagedEssaySearch}
        />
    }
    
  </Container>
);

export default CardRegularTag;