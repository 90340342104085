import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { ScatterChart } from 'react-charts-d3';

import LiveArea from '../../../components/Layout/LiveArea';

// Api
import { adminApi } from '../../../api/api';

// Component
import Loading from '../../../components/Loading/Loading';
import CardRegularEssay from '../../../components/Cards/Essay/CardRegularEssay';
import FeedGrid from '../../../template/Feed/FeedGrid';

const Container = styled.div`
  width: 100%;
`;

const data = [
  { key: 'Group 1', values: [ { x: 'A', y: 23 }, { x: 'B', y: 8 } ] },
  { key: 'Group 2', values: [ { x: 'A', y: 15 }, { x: 'B', y: 37 } ] },
];

function ExploreUserPresenter() {
  const [loading, setLoading] = useState(true);
  const [recentEssayList, setRecentEssayList] = useState([]);
  const [userWriteLog, setUserWriteLog] = useState([]);
  const [wholeUser, setwholeUser] = useState(0);
  const [activeUser, setActiveUser] = useState(0);
  const [wholeEssay, setwholeEssay] = useState(0);
  const [avgEssay, setAvgEssay] = useState(0);

  const fetchData = async () =>{
    try {
      // Recent Essay
      await adminApi
        .getAllUsers({
          pages: 0,
          size: 20000,
        })
        .then(userList => {
          if (userList.status !== 404) {
            let list = userList.data;
            let tempList = [];
            console.log(list);
            setwholeUser(list.length);
            let numActive = 0;
            let numEssay = 0;
            list.forEach((user,i) => {
              let values = [];
              
              if(user.publishingDateList.length!==0){
                numActive++;
                numEssay += user.publishingDateList.length;
                user.publishingDateList.forEach((str,j) => {
                  const date = new Date(str);
                  let value = {
                    x: date,
                    y: i
                  }
                  values.push(value);
                });
                let object = {
                  key: `Group${i}`,
                  values: values
                }
                tempList.push(object);
              }
            });
            setActiveUser(numActive);
            setwholeEssay(numEssay);
            setAvgEssay(numEssay/numActive);
            
            tempList = tempList.filter((item,i) => {
              return 4 < i;
            });
            console.log(tempList);
            setUserWriteLog(tempList);
          }
        });

    } catch {

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  },[])



  return (
    <Container>
      {
        loading?<Loading /> : 
        <ScatterChart
          data={userWriteLog}
          width={'100%'}
          height={'1000'}
        />
      }
      <p>
        <span>총 가입 유저</span>:
        {wholeUser}
      </p>
      <p>
        <span>활동 유저</span>:
        {activeUser}
      </p>
      <p>
        <span>총 에세이수</span>:
        {wholeEssay}
      </p>
      <p>
        <span>유저별 병균 에세이수</span>:
        {avgEssay}
      </p>
    </Container>
  )
}

export default ExploreUserPresenter;