import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BttContainer = styled(Link)`
  height: ${props => props.theme.size.height.huge};
  min-width: 144px;
  padding: 0 32px;
  border-radius: ${props => `calc(${props.theme.size.height.huge}/2)`};
  ${props => props.theme.type.btt1}
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryLightBlock.enabled : '')}

    @media screen and (max-width: 480px) {
      height: 60px;
      border-radius: 30px;
    }
`;

const BttPrimary = ({ type, title, to, className }) => {
  return (
    <BttContainer type={type} to={to} className={`btt_txt ${className}`}>
      <p>{title}</p>
    </BttContainer>
  );
};

BttPrimary.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  // className: PropTypes.string.isRequired,
};

export default BttPrimary;
