import sizeList from '../atom/sizeList';

const size = {
  unit:{
    small: sizeList.unitSmall,
    regular: sizeList.unitRegular
  },
  gridSmall: {
    x1: sizeList.unitSmall*1 + 'px',
    x2: sizeList.unitSmall*2 + 'px',
    x3: sizeList.unitSmall*3 + 'px',
    x4: sizeList.unitSmall*4 + 'px',
    x5: sizeList.unitSmall*5 + 'px',
    x6: sizeList.unitSmall*6 + 'px',
    x7: sizeList.unitSmall*7 + 'px',
    x8: sizeList.unitSmall*8 + 'px',
    x9: sizeList.unitSmall*9 + 'px',
    x10: sizeList.unitSmall*10 + 'px',
  },
  gridRegular: {
    x1: sizeList.unitRegular*1 + 'px',
    x2: sizeList.unitRegular*2 + 'px',
    x3: sizeList.unitRegular*3 + 'px',
    x4: sizeList.unitRegular*4 + 'px',
    x5: sizeList.unitRegular*5 + 'px',
    x6: sizeList.unitRegular*6 + 'px',
    x7: sizeList.unitRegular*7 + 'px',
    x8: sizeList.unitRegular*8 + 'px',
    x9: sizeList.unitRegular*9 + 'px',
    x10: sizeList.unitRegular*10 + 'px',
  },
  space: {
    tiny: `2px`,
    small: `4px`,
    regular: `8px`,
    medium1: `16px`,
    medium2: `24px`,
    big1: `32px`,
    big2: `48px`,
    huge: `80px`
  },
  height: {
    small: `32px`,
    regular: `36px`,
    medium: `48px`,
    big: `52px`,
    huge: `56px`
  },
  liveAreaWidth: '1200px',
  cardPaddingBlock: `
    padding-bottom:24px;
    @media screen and (max-width: 480px) {
      padding-bottom: 16px;
    }
  `
}

export default size;