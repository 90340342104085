import React, { useState, useEffect } from 'react';

// Component
// Container & Section
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import SectionTitleColor from '../../components/Layout/Title/SectionTitleColor';
import Section from '../../components/Layout/Section/Section';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

import CardRegularPrizedItem from '../../components/Cards/PrizedItem/CardRegularPrizedItem';

function PrizedItemList({prizedItemList}){
  return(
    <Section>
      <SectionTitle
        title1={'망월장에 영감을 준 책들'}
        title2={'당선자분들께 망월장에 영감을준 작품들을 나눠드립니다.'}
      />
      <ContainerO className="flex_row col1 col_m">
        {
          prizedItemList.map((prizedItem, i) => (
            <GridMatrix 
              colPC={5}
              colMb={2}
              index={i}
              key={i}
              onClick={()=>{
                console.log('click');
                const {gtag} = window;
                gtag('event', 'click', {
                  'event_category' : 'prized item click',
                  'event_label' : prizedItem.title
                });
              }}
            >
              <CardRegularPrizedItem
               to={`prizedItem/${prizedItem.title}`}
               key={i}
               prizedItem={prizedItem}
              />
            </GridMatrix>
          ))
        }
      </ContainerO>
    </Section>
  )
}

export default PrizedItemList;