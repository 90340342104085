import React from 'react';

// Component
import FeedTitle from '../../components/Layout/Title/FeedTitle';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Card
import CardRegularEssayNewTab from '../../components/Cards/Essay/CardRegularEssayNewTab';

function FeedGrid({ type, title, listTitle, essayList, colPC, colMb }) {
  // console.log("essayList :", essayList);
  return (
    <>
      <FeedTitle type={type} listTitle={listTitle} title={title} />
      <ContainerO>
        <div className="flex_row col1 col_m">
          {essayList.map((essay, i) => {
            return (
              <GridMatrix colPC={colPC} colMb={colMb} index={i} key={i}>
                <CardRegularEssayNewTab
                  to={`/post/${essay._id}`}
                  essay={essay}
                  key={i}
                />
              </GridMatrix>
            );
          })}
        </div>
      </ContainerO>
    </>
  );
}

export default FeedGrid;
