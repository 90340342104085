import color from './token/color';
import type from './token/type';
import size from './token/size';
import layout from './token/layout';
import shape from './token/shape';
import container from './token/container';


const theme = {
  themeType: 'light', // Light | Dark
  color: color,
  type: type,
  size: size,
  layout: layout,
  shape: shape,
  container: container,
  switchTheme: (theme,value) => {
    theme.themeType = value;
  }
};

export default theme;