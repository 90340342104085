import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";
import theme from '../../assets/theme/theme';

// import "./styles.css";
// import SelectMenu from "./selectMenu";
import SelectionControl from './selectionControl';
import SelectMenu from "./selectMenuHook";

import { getCaretCoordinates, setCaretToEnd } from "./utils/caretHelpers";


const CMD_KEY = "/";
const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const Block = styled(ContentEditable)`
  background-color: ${
    props => props.isSelect?theme.color.primary100:'none'
  };
`;

function EditableBlockHook({
  index,
  id,
  tag,
  html,
  updatePage,
  addBlock,
  deleteBlock,
  onSelectBlock
}){

  const [htmlBackup, setHtmlBackup] = useState(null);
  const [localBackupHtml, setLocalBackupHtml] = useState("");
  const [localHtml, setLocalHtml] = useState("");
  const [localTag, setLocalTag] = useState("p");
  const [className, setClassName] = useState("");
  const [previousKey, setPrevioustKey] = useState("");
  const [isSelect, setIsSelect] = useState(false);
  const [selectMenuIsOpen, setSelectMenuIsOpen] = useState(false);
  const [selectMenuPosition, setSelectMenuPosition] = useState({x: null, y: null});

  const contentEditable = React.createRef();

  // initial setting
  useEffect(() => {
    setLocalHtml(html);
    setLocalTag(tag);
  },[]);

  // Update HTML
  useEffect(() => {
    updatePage({
      id: id,
      html: localHtml,
      tag: localTag
    })
  },[localHtml, localTag]);

  return(
    <>
        {isSelect && (
          <FakeBackDrop
            onClick={
              () => {
                console.log('click backdrop');
                setIsSelect(false);
              }
            }
          >
            {!selectMenuIsOpen && 
              <SelectionControl
                position={selectMenuPosition}
                onSelect={selectionControlHandler}
                close={closeSelectionControlHandler}
              />
            }
            {selectMenuIsOpen && 
              <SelectMenu
                position={selectMenuPosition}
                onSelect={tagSelectionHandler}
                close={closeSelectMenuHandler}
              />
            }
          </FakeBackDrop>
          
        )}
        <Block
          placeholder={index===0?'글을 작성해주세요':''}
          innerRef={contentEditable}
          html={localHtml}
          tagName={localTag}
          className={`Block ${className}`}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          onKeyUp={onKeyUpHandler}
          isSelect={isSelect}
          onSelectCapture={
            (e) => {
              let type = window.getSelection().type;
              // console.log(window.getSelection());
              if(type==='Range'){
                setLocalBackupHtml(localHtml);
                // openSelectionControlHandler();
              }
              else{
                // closeSelectionControlHandler();
              }
            }
          }
          // onClick={
          //   () => {
          //     console.log('click');
          //   }
          // }
          // onMouseDown={
          //   () => {
          //     console.log('down!');
          //     onSelectBlock(true);
          //   }
          // }
          onMouseUp={
            (e) => {
              e.stopPropagation();
              console.log('click block');
              let type = window.getSelection().type;
              // console.log(type);
              if(type==='Range'){
                setLocalBackupHtml(localHtml);
                openSelectionControlHandler();
              }
            }
          }
        />
      </>
  );

  function onChangeHandler(e) {
    setLocalHtml(e.target.value)
  }

  function onKeyDownHandler(e) {
    // console.log('on key handler');
    console.log(e.key);
    e.stopPropagation();

    if (e.key === CMD_KEY) {
      // If the user starts to enter a command, we store a backup copy of
      // the html. We need this to restore a clean version of the content
      // after the content type selection was finished.
      setLocalBackupHtml(localHtml);
    }
    if (e.key === "Enter") {
      // While pressing "Enter" should add a new block to the page, we
      // still want to allow line breaks by pressing "Shift-Enter"
      e.preventDefault();
      addBlock({
        id: id,
        ref: contentEditable.current
      });
    }
    if (e.key === "Backspace" && !html) {
      // If there is no content, we delete the block by pressing "Backspace",
      // just as we would remove a line in a regular text container
      e.preventDefault();
      deleteBlock({
        id: id,
        ref: contentEditable.current
      });
    }
    // Store the key to detect combinations like "Shift-Enter" later on
    // setPrevioustKey(e.key);
  }

  // The openSelectMenuHandler function needs to be invoked on key up. Otherwise
  // the calculation of the caret coordinates does not work properly.
  function onKeyUpHandler(e) {
    if (e.key === CMD_KEY) {
      // openSelectMenuHandler();
    }
  }

  // After openening the select menu, we attach a click listener to the dom that
  // closes the menu after the next click - regardless of outside or inside menu.

  function openSelectionControlHandler(){
    const { x, y } = getCaretCoordinates();
    setIsSelect(true);
    setSelectMenuPosition({x,y});
  }

  function closeSelectionControlHandler(){
    const { x, y } = getCaretCoordinates();

    setLocalBackupHtml(null);
    setIsSelect(false);
    setSelectMenuPosition({x: null,y: null});
  }

  function openSelectMenuHandler() {
    setSelectMenuIsOpen(true);
  }

  function closeSelectMenuHandler() {
    setLocalBackupHtml(null);
    setIsSelect(false);
    setSelectMenuIsOpen(false);
    setSelectMenuPosition({x: null,y: null});
  }

  // Restore the clean html (without the command), focus the editable
  // with the caret being set to the end, close the select menu
  function selectionControlHandler(item) {
    console.log(item);
    if(item.id==='decorate-selection'){
      setSelectMenuIsOpen(true);

    }
    else if(item.id==='delete-selection'){
      deleteBlock({
        id: id,
        ref: contentEditable.current
      });
      setIsSelect(false);
    }
  }
  
  function tagSelectionHandler(item) {
    console.log(item);
    setLocalTag(item.tag);
    setLocalHtml(htmlBackup);
    setClassName(item.className);

    setCaretToEnd(contentEditable.current);
    closeSelectMenuHandler();
  }

}

export default EditableBlockHook;