import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmLME({ alarm, setAlarmRead }) {
  // const test = '{test:"test"}';
  let essay;
  let user;
  // let comment;
  let mark = '기본';
  let path;

  if (alarm.contentObject) {
    [essay, user] = alarm.contentObject;
    // comment = alarm.contentObject[2];
    mark = user.username[0] + user.username[1];
    path = `/post/${essay._id}`;
    console.log(alarm.mark);
  }

  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id, path)}
      className={`col1 ${alarm.isRead ? 'white_bg' : 'white_600bg'}`}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender={user.username}
        msg="님이 회원님의 글을 공감합니다."
        content={essay.title}
      />
    </button>
  );
}

ListAlarmLME.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmLME;
