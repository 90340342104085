/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import config from '../../../config';
import { competitionApi, brandedApi } from '../../../api/api';

// Component
import ContainerC from '../../../components/Layout/Container/ContainerC';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import CardFullCompetitionStatus from '../../../components/Cards/Competition/CardFullCompetitionStatus';
import Loading from '../../../components/Loading/Loading';
import PreviousBrandedStatusAdmin from '../../../template/Competition/PreviousBrandedStatusAdmin';


function ManageBrandedPresenter({
}){

  // Local State
  const [currentBranded, setCurrentBranded] = useState(null);
  const [previousBranded, setpreviousBranded] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // 진행중 백일장
      await brandedApi.brandedCurrent().then(currentBranded => {
        if (currentBranded.status !== 404) {
          setCurrentBranded(currentBranded.data);
        }
        console.log(currentBranded.data);
      });

      // 지난 백일장
      await brandedApi.brandedPrevious().then(previousBranded => {
        if (previousBranded.status !== 404) {
          setpreviousBranded(previousBranded.data);
        }
        console.log(previousBranded.data);
      });
    } catch {
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
      <Loading />
      ) : (
        <div className="flex_col col1 col_grid_mobile">
          <SectionTitle title1="모든 브래디드 스토리" />
          {
            currentBranded!==null && (
              <PreviousBrandedStatusAdmin
                previousBranded={currentBranded}
              />  
            )
          }
          {
            previousBranded.length!==0 && (
              <PreviousBrandedStatusAdmin
              previousBranded={previousBranded}
              />     
            )
          }
        </div>
      )}
    </>
  );
}

export default ManageBrandedPresenter;