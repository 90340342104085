import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListBasic from './ListBasic';
import AvatarB from '../Avatar/AvatarB';
import User from '../../_models/user';

const Container = styled(ListBasic)`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 16px 0;
`;

const Section = styled.div`
  width: 30%;
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.typeBlock.subtitle1}
  color: ${props => props.theme.color.primary500};
  height: 100%;
  border-right: ${props => props.isBorder?`solid 1px ${props.theme.color.primary200}`:'none'};
`;

function ListTag({ tagName }) {

  return (
    <Container>
      <Section isBorder={true}>
        {tagName}
      </Section>
    </Container>
  );
}

export default ListTag;
