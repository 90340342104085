import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Competition from '../../../_models/competition';

// Button
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttBigExtraFixedWidth from '../../Buttons/ButtonLink/BttBigExtraFixedWidth';
import AvatarM from '../../Avatar/AvatarM';

// Img
import imgGirlReadingFront from '../../../assets/img/ill/img_girl_writing_front.png';


const Container = styled.div`
  position: relative;
  /* height: 321px; */
  padding-bottom: 24px;
  /* padding: 20px 0px; */
  @media screen and (max-width: 480px){
    height: auto;
  }
`

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const ImgSection = styled.div`
  width: 200px; 
  height: 186px;
  padding-top: 48px;
  img {
    width: 122px;
    height: 219px;
  }
  @media screen and (max-width: 480px){
    width: 118px; 
    margin-bottom: 16px;
    height: auto;
    img {
      width: 102px;
      height: 182px;
    }
  }
`
const Overline = styled.p`
  ${props => props.theme.type.overline};
`;


const Title = styled.p`
  cursor: pointer;
  display: inline-block;
  word-break: keep-all;
  width: 100%;
  height: 84px;
  ${props => props.theme.type.h3};
  margin-bottom: 74px;
  @media screen and (max-width: 480px){
    height: auto;
    margin-bottom: 44px;
  }
`;

const Exp = styled.p`
  ${props => props.theme.type.body2}
  color: ${props => props.theme.color.ui.strong};
  margin-bottom: ${props => props.theme.size.gridSmall.x3};
  height: 48px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
    margin-bottom: ${props => props.theme.size.gridSmall.x3};
  }
`;

const Participants = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;

const BottomActionBar = styled.div`
  /* position: absolute;
  left: 0;
  bottom: 20px;
  width: calc(100% - 186px); */
  @media screen and (max-width: 480px){
    position: static;
    margin-top: 20px;
    width: 100%;
  }

`

const CardBigCompetition = ({ lastCompetitionDate }) => {

  return (
    <Container className="space_between flex_row col1">
      <ContentSection>
        <Overline>
          망월장 쓰기
        </Overline>
        <Title>다음 망월장을 기다려주세요.</Title>
        <Exp>
          매월 2번씩 블루블랙 에디터가 엄선한 질문을 던집니다.
        </Exp>
        <Participants>
          다가올 질문과 참가자들을 기대해주세요.
        </Participants>
      </ContentSection>
      <BottomActionBar className="flex_row col1_m space_between">
        <BttBigExtraFixedWidth
          type="outlined"
          className="col2"
          width={'256px'}
          to={`/competition/${lastCompetitionDate}`}
          title="지난 망월장 보기"
        />
        <BttBigExtraFixedWidth
          type="filled_primary"
          className="col2"
          to={`/competitions`}
          title="모든 망월장 보기"
          width={''}
        />
      </BottomActionBar>
    </Container>
  );
};

export default CardBigCompetition;