import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  ${props => props.theme.size.cardPaddingBlock}
`;

const CardWrapper = ({children, className}) => {
  return (
    <Container className={className}>
      {children}
    </Container>
  );
}

export default CardWrapper;