import React from 'react';

// Component
// Container & Section
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import SectionTitleColor from '../../components/Layout/Title/SectionTitleColor';
import Section from '../../components/Layout/Section/Section';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Card
import CardMiddleEssay from '../../components/Cards/Essay/CardMiddleEssay';

// Button
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';

function RecentEssayList({ 
    essayListByTag,
    interestedTag,
    username
  }) {
  let interestedTagString = '';
  interestedTag.map((tag, i) => {
    if (i < 3) {
      interestedTagString += `"${tag.tagName}"`;
      if (i < 2) {
        interestedTagString += ', ';
      }
    }
  });
  console.log(interestedTag);
  return (
    <Section>
      <SectionTitle
        title1={`${username}님을 위한 추천 주제`}
        title2={`${interestedTagString}에 대한 에세이`}
      />
      <ContainerO className="flex_row col1 col_m">
        {essayListByTag.map((essay, i) => {
          return (
            <GridMatrix colPC={5} colMb={2} index={i} key={i}>
              <CardMiddleEssay
                to={`/post/${essay._id}`}
                essay={essay}
                key={essay.id}
                onClick={()=>{
                  const {gtag} = window;
                  gtag('event', 'click', {
                    'event_category' : 'essay card click',
                    'event_label' : 'interested'
                  });
                }}
              />
            </GridMatrix>
          );
        })}
      </ContainerO>
      <BttTextSectionMore
        to="/feed?tabId=recommend"
        title="관심 주제어 에세이 더보기"
      />
    </Section>
  );
}
export default RecentEssayList;
