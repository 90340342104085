import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding:0 8px;
  /* ${props => props.theme.container.linedPrimaryLightBlock.enabled} */
  ${props => props.theme.type.caption3}
  height: 20px;
  padding-top: 3px;
  border-radius: 10px;
  ${
    props => {
      switch(props.type) {
        case 'filled_primary':
          return props.theme.container.filledPrimaryBlock.enabled;
        case 'filled_white':
          return props.theme.container.filledWhiteBlock.enabled;
        case 'outlined_white':
          return props.theme.container.linedWhiteBlock.enabled;
        default:
          return props.theme.container.linedPrimaryLightBlock.enabled;
      }
    }
  }
`;

const Tag = ({
  children,
  type
}) => {
  return (
    <Container type={type}>
      {children}
    </Container>
  );
}

export default Tag;