/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import logo_img from '../../assets/img/logo/blbl_logo.svg';

function Modal({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
  closePath,
  redirectPath,
  buttonName,
}) {
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = e => {
    if (onClose) {
      onClose(e);
    }
  };
  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          {closable && (
            <CloseIcon className="modal-close" onClick={close}>
              {closePath && <Link to={`${closePath}`} />}
            </CloseIcon>
          )}
          <p className="body1 regular black700 margin_b_2x text_center">
            <img src={logo_img} className='margin_b_2x' alt="" />
            {children}

            {redirectPath && (
              <Link to={`${redirectPath}`}>
                <h2 className="primary">{buttonName}</h2>
              </Link>
            )}
          </p>
        </ModalInner>
      </ModalWrapper>
    </>
  );
}

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  maskClosable: PropTypes.bool.isRequired,
  closable: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  height: 100%;
  overflow: hidden;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  height: 100%;
  overflow: hidden;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  width: 360px;
  max-width: 480px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 40px 20px;
  max-height: calc(100% - 32px);
  overflow-y: scroll;
`;

export default Modal;
