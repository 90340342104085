import React from 'react';
import styled from 'styled-components';

// Component
// Container & Section
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ConainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Card
import CardBigPrizedEssay from '../../components/Cards/Essay/CardBigPrizedEssay';
import CardInformCompetition from '../../components/Cards/inform/CardInformCompetition';

// Button
import BttBig from '../../components/Buttons/ButtonLink/BttBig';
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';

// Img
import imgCharacter from "../../assets/img/ill/character_male.png";
import imgReadingGirl from "../../assets/img/ill/img_girl_writing_side.png";

// 카드 사이즈
const CardBigSize = styled.div`
    height: ${props => props.theme.size.cardBigHeight};
    @media screen and (max-width: 480px) {
      height: 320px;
    }
`

function LastCompetition({ competition }) {

  return (
    <Section>
      {
        (competition !== null) ? (
          <>
            <SectionTitle
              title1="지난 망월장 당선작"
              title2={`${competition.question}`}
            >
              {/* <img className='img_char_male img_right' src={imgCharacter} alt='' /> */}
            </SectionTitle>
            <ConainerO>
              {/* 메인 상단 큐레이션 */}
              <div className="flex_row col1 col_m">
                <GridMatrix
                  colPC={4}
                  colMb={2}
                  index={0}
                  key={0}
                >
                  <CardInformCompetition
                    competition={competition}
                  />
                </GridMatrix>
                {/* 망월장 컬럼 */}
                {competition.prizedEssayList.map((essay, i) => {
                  return (
                    <GridMatrix
                      colPC={4}
                      colMb={2}
                      index={i+1}
                      key={i}
                      onClick={() => {
                        console.log('click');
                        const { gtag } = window;
                        gtag('event', 'click', {
                          'event_category': 'essay card click',
                          'event_label': 'prized'
                        });
                      }}
                    >
                      <CardBigPrizedEssay
                        to={`/post/${essay._id}`}
                        essay={essay}
                      />
                    </GridMatrix>
                  );
                })}
              </div>
              <BttTextSectionMore to="/competitions" title='지난 망월장 모두 보기' />
            </ConainerO>
          </>
        ) : (
            <>
              <SectionTitle
                title1="지난 망월장 당선작"
              >
                <img className='img_char_male img_right' src={imgCharacter} alt='' />
              </SectionTitle>
              <ConainerO>
                {/* 메인 상단 큐레이션 */}
                <div className="flex_row col1 col_m">
                  {/* 망월장 컬럼 */}
                  <GridMatrix
                    colPC={1}
                    colMb={1}
                    index={1}
                    key={0}
                    className='padding_4x'
                  >
                    <h1 className='text_center regular black300'>
                      망월장이 존재하지 않습니다.
                      </h1>
                  </GridMatrix>
                </div>
              </ConainerO>
            </>
          )
      }
    </Section>
  )
}
export default LastCompetition;