import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListBasic from './ListBasic';
import AvatarB from '../Avatar/AvatarB';
import User from '../../_models/user';

const Mark = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 108px;
  @media screen and (max-width: 480px) {
    width: 96px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: calc(100% - 108px);
  @media screen and (max-width: 480px) {
    width: calc(100% - 96px);
  }
`;

function ListUser({ userData }) {
  const [relatedTag, setRelatedTag] = useState([]);

  const counts = {};
  const countsArray = [];

  useEffect(() => {
    console.log(userData.readTags);
    userData.readTags.forEach(function (x) {
      if (!counts[x.tagName]) {
        counts[x.tagName] = { count: 1 };
      } else {
        counts[x.tagName].count += 1;
      }
    });
    // 위의 object를 배열로 변환(sorting을 위한)
    Object.keys(counts).forEach(key => {
      countsArray.push({
        tagName: key,
        count: counts[key].count,
      });
    });
    console.log(counts);
    setRelatedTag(countsArray);
  }, []);

  return (
    <ListBasic>
      <Mark>
        <AvatarB to={`/user/${userData.username}`} writer={userData} />
      </Mark>
      <Content>
        <p className="body_s regular margin_b_tiny">
          <span className="bold black700 margin_r_1x">{userData.username}</span>
          <span className="black300">{`출판한 에세이: ${userData.essayList.length}`}</span>
        </p>
        {relatedTag.length > 0 && (
          <div className="flex_row col1">
            {relatedTag.map((tag, i) => {
              return (
                <div
                  className={`tooltip_outlined_dark margin_b_tiny ${
                    i !== relatedTag.length - 1 ? 'margin_r_tiny ' : ' '
                  }`}
                  key={tag._id}
                >
                  {tag.tagName}
                </div>
              );
            })}
          </div>
        )}
      </Content>
    </ListBasic>
  );
}

ListUser.propTypes = {
  userData: PropTypes.instanceOf(User).isRequired,
};

export default ListUser;
