import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

// Model
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Essay } from '../../_models/essay';

// Api
import { essayApi, userApi, userpageApi } from '../../api/api';

// Container
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import PartitionHor from '../../components/Layout/Grid/PartitionHor';
import ContainerC from '../../components/Layout/Container/ContainerC';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import ButtSub from '../../components/Buttons/ButtonLink/BttSub';

// Component
import CardEssayOnProgress from '../../components/Cards/Essay/CardEssayOnProgress';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import Loading from '../../components/Loading/Loading';
import ListUser from '../../components/List/ListUser';

// Modal

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// Styled Component
const ColLeft = styled.div`
  width: 40%;
`
const ColRight = styled.div`
  width: 60%;
`

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: isMobile ? 'calc(100% - 32px)' : 768,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function UserPagePresenter({ location, myData, loged}) {
  // userContext

  // Local State
  const [userData, setUserdata] = useState({});
  const [userEssayList, setUserEssayList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [interestedTag, setInterestedTag] = useState([]);
  const [writeTags, setWriteTags] = useState([]);

  // follow list & modal
  const [followerList, setFollowerList] = useState([]);
  const [isFollowerModal, setIsFollowerModal] = useState(false);

  // whether follow this user or not
  const [isFollow, setIsFollow] = useState(false);

  const username = location.pathname.replace('/user/', '');

  // Dummy Data
  const essay = new Essay();

  const fetchData = async () => {
    try {
      // id값으로 유저 데이터 호출
      await userApi.getUserInfoByName(username).then(async userData => {
        console.log(userData.data);
        console.log(userData.data.essayList);
        // 리턴받은 데이터로 userData (로컬 스테이트) 설정
        setUserdata(userData.data);
        setInterestedTag(makeInterestedTag(userData.data.readTags));
        let original = userData.data.writeTags;
        const uniqueArray = original.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return index === original.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        });
        setWriteTags(uniqueArray);

        //  유저가 출판한 에세이 불러오기
        const { essayList } = userData.data;
        console.log(userData.data.essayList);

        // api오류로 null값으로 남아있는 기존 엘레머트 제거 
        // console.log(userData.essayList);
        let list = [];
        list = userData.data.essayList.filter((essayId) => {
          return essayId!==null;
        });
        
        await essayApi
          .getEssaysById(userData.data.essayList.reverse())
          .then(essayList => {
            console.log(essayList.data);
            setUserEssayList(essayList.data);
          });

        /* 팔로우 여부 확인
        / BAD CODE: forEach, filter가 async안에 있을경우 동기처리를 따로 해줘야함. 위의 코드와 순서가 바뀌면 오류발생. 추후 해결방안 고민.
        / followList를 체크하기 위한
        */
        userData.data.followerList.forEach(user => {
          if (user._id === myData._id) {
            setIsFollow(true);
          }
        });
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const makeInterestedTag = readTags => {
    const counts = {};
    const countsArray = [];
    readTags.forEach(function (x) {
      if (!counts[x.tagName]) {
        counts[x.tagName] = { count: 1 };
      } else {
        counts[x.tagName].count = counts[x.tagName].count + 1;
      }
    });
    // 위의 object를 배열로 변환(sorting을 위한)
    Object.keys(counts).forEach(key => {
      countsArray.push({
        tagName: key,
        count: counts[key].count,
      });
    });
    return countsArray;
  };

  /**
   * 팔로우 토글 및 정보 갱신
   * BAD CODE: 컴퍼넌트 최초 로드시 확인하는 로직과 다름. 갱신되는 리턴값이 다르기때문에.
   */
  const followUser = async () => {
    await userpageApi.followUser({ targetName: username }).then(res => {
      console.log(res.data.followerList);
      setUserdata(res.data);
      // 팔로우 여부 확인
      if (res.data.followerList.includes(myData._id)) {
        setIsFollow(true);
        const {gtag} = window;
        gtag('event', 'click', {
          'event_category' : 'user page behaviour',
          'event_label' : 'follow'
        });
      } else {
        setIsFollow(false);
      }
    });
  };

  const showFollower = async () => {
    const data = {
      targetUserIDs: userData.followerList,
    };
    console.log(userData.followerList);

    await userpageApi.getUsers(data).then(res => {
      console.log(res.data);
      setFollowerList(res.data);
      setIsFollowerModal(true);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openFollowerModal = () => {
    console.log('modal ready!');
    setIsFollowerModal(true);
  };

  const closeFollwerModal = () => {
    setIsFollowerModal(false);
  };

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 className="body bold black700" id="simple-modal-title">
        팔로워 리스트
      </h2>
      {followerList.length > 0 &&
        followerList.map(user => (
          <ListUser
            userData={user}
            mark={user.username[0] + user.username[1]}
          />
        ))}
    </div>
  );

  return (
    <div className="flex_col col_narrow center_margin col_grid_mobile router_area_top">
      {/* MY PAGE TOP */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Section>
            <ContainerC className="flex_row space_between padding_ver_1x">
              <PartitionHor colPC={2} colMb={1} index={0}>
                <div className="avatar_m margin_b_4x">
                  {userData &&  userData.username.length>1 && userData.username[0] + userData.username[1]}
                  {userData &&  userData.username.length==1 && userData.username[0]}
                </div>
                <h3 className="primary bold">
                  {userData && userData.username}
                </h3>
              </PartitionHor>
              <PartitionHor colPC={2} colMb={1} index={1}>
                <p className="body regular margin_b_1x">
                  <span className="margin_r_1x">{`출판한 에세이: ${userData.essayList.length}`}</span>
                  <span>{`기록중인 글: ${userData.noteList.length}`}</span>
                </p>
                <div className="flex_row col1 margin_b_2x">
                  <ButtSub
                    className="margin_r_1x"
                    title="팔로워 보기"
                    type="outlined"
                    onClick={showFollower}
                  />
                  {loged && (
                    <ButtSub
                      title={isFollow ? '팔로잉' : '팔로우'}
                      type={isFollow ? 'filled' : 'outlined'}
                      onClick={followUser}
                    />
                  )}
                </div>
                <p className="body black300 regular">
                 <span>{userData.description? userData.description : "아직 자기 소개글이 없습니다."}</span>
                </p>
              </PartitionHor>
              <div className="flex_row col1 padding_ver_1x bd_top margin_t_1x">
                <ColLeft colPC={2} colMb={1} index={0}>
                  <p className="body regular">{`${userData.username}님이 읽은(${interestedTag.length}) 주제어`}</p>
                </ColLeft>
                <ColRight colPC={2} colMb={1} index={1}>
                  <div className="flex_row align_center col1">
                    {interestedTag.map(tag => (
                      <div className="tooltip_outlined_dark margin_b_tiny margin_r_tiny">
                        {tag.tagName}
                      </div>
                    ))}
                  </div>
                </ColRight>
              </div>
              <div className="flex_row col1 padding_ver_1x bd_top margin_t_1x">
                <ColLeft colPC={2} colMb={1} index={0}>
                  <p className="body regular">
                    {`${userData.username}님이 사용한(${writeTags.length} 주제어)`}
                  </p>
                </ColLeft>
                <ColRight colPC={2} colMb={1} index={1}>
                  <div className="flex_row align_center col1">
                    {writeTags.map((tag, i) => (
                      <div
                        key={i}
                        className="tooltip_outlined_dark margin_b_tiny margin_r_tiny"
                      >
                        {tag.tagName}
                      </div>
                    ))}
                  </div>
                </ColRight>
              </div>
            </ContainerC>
          </Section>

          {/* <Section> */}
          {/* <SectionTitle 
              title1={'스크랩한 2개의 에세이'}
            />
            <ContainerO className='flex_row space_between padding_ver_1x'>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={0}
              >
                <CardRegularEssay to={''} essay={essay} />
              </GridMatrix>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={1}
              >
                <CardRegularEssay to={''} essay={essay} />
              </GridMatrix>
              <GridMatrix
                colPC={3}
                colMb={1}
                index={2}
              >
                <CardRegularEssay to={''} essay={essay} />
              </GridMatrix>
            </ContainerO> */}
          {/* </Section> */}
          <Section>
            <SectionTitle
              title1={
                userEssayList.length > 0
                  ? `출판한 ${userEssayList.length}개의 에세이`
                  : `출판한 에세이`
              }
            />
            <ContainerO className="flex_row">
              {userEssayList.length > 0 ? (
                <>
                  {userEssayList.map((essay, i) => (
                    <GridMatrix
                      colPC={4}
                      colMb={2}
                      index={i}
                      key={i}
                      onClick={()=>{
                        console.log('click');
                        const {gtag} = window;
                        gtag('event', 'click', {
                          'event_category' : 'user page behaviour',
                          'event_label' : 'read user essay'
                        });
                      }}          
                    >
                      <CardRegularEssay to={`/post/${essay._id}`} essay={essay} />
                    </GridMatrix>
                  ))}
                </>
              ) : (
                <p className="body regular black300">
                  아직 출판한 에세이가 없습니다.
                </p>
              )}
            </ContainerO>
          </Section>
          {/* 팔로워 리스트 모달 */}
          <Modal
            open={isFollowerModal}
            onClose={closeFollwerModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </>
      )}
    </div>
  );
}

export default UserPagePresenter;
