import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Preview from '../../components/Helmet/Preview';

// API
import { competitionApi } from '../../api/api';

// Model
import Essay from '../../_models/essay';
import Competition from '../../_models/competition';

// Component
import ContainerC from '../../components/Layout/Container/ContainerC';
import CardFullCompetitionStatusNew from '../../components/Cards/Competition/CardFullCompetitionStatusNew';
import Loading from '../../components/Loading/Loading';

// Template
import CurrentCompetitionStatus from '../../template/Competition/CurrentCompetitionStatus';
import PreviousCompetitionStatus from '../../template/Competition/PreviousCompetitionStatus';

function CompetitionsPresenter({ username, userData, loged, interestedTag }) {
  // Local State
  const [currentCompetition, setCurrentCompetition] = useState(null);
  const [previousCompetition, setPreviousCompetition] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // 진행중 망월장
      await competitionApi.competitionCurrent().then(currentCompetition => {
        if (currentCompetition.status !== 404) {
          setCurrentCompetition(currentCompetition.data);
        }
        console.log(currentCompetition);
      });
      console.log(currentCompetition);

      // 지난 망월장
      await competitionApi.competitionPrevious().then(previousCompetition => {
        if (previousCompetition.status !== 404) {
          setPreviousCompetition(previousCompetition.data);
        }
        console.log(previousCompetition.data);
      });
    } catch {
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
      <Loading />
      ) : (
        <div className="flex_col col1 col_grid_mobile">
          <Preview
            description="망월장에 참여해보세요!"
            thumbnail="https://user-images.githubusercontent.com/51117133/95839825-6a932280-0d7e-11eb-8636-7d56fb8b7ddf.png"
            title={'블루블랙 망월장'}
          />
          {currentCompetition && (
            <ContainerC className={"margin_b_6x"}>
              <CardFullCompetitionStatusNew
                loged={loged}
                competition={currentCompetition}
                username={username}
                isLinkToCompetitionPage={true}
              />
            </ContainerC>     
          )}
          {previousCompetition && (
            <PreviousCompetitionStatus
              previouscompetition={previousCompetition}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CompetitionsPresenter;
