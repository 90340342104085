import React from 'react';
import MainPresenter from './MainPresenter';
import { useUserState } from '../../context/currentUserContext';

function MainContainer() {
  // data regarding useseeion is managed by user context
  const { loged, loaded, username, userData, interestedTag } = useUserState();
  return (
    loaded && (
      <>
        {/*
          Presenter render after loaded user data,
          'loaded' event includes when users are not loged.
          
          Main Presenter includes series of recent content of essay and competition.
          loged: weather user log or not
          username: current loged username
          userData: user object model of current loged user
          interested tag: sorted tags by reading activity of current user.
        */}
        <MainPresenter
          loged={loged}
          username={username}
          userData={userData}
          interestedTag={interestedTag}
        />
      </>
    )
  );
}
export default MainContainer;
