import React from 'react';
import CompetitionPresenter from './CompetitionsPresenter';
import { useUserState } from '../../context/currentUserContext';

function CompetitionsContainer() {
  const { loaded, loged, username, userData, interestedTag } = useUserState();
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <CompetitionPresenter
          username={username}
          userData={userData}
          loaded={loaded}
          loged={loged}
          interestedTag={interestedTag}
        />
      </>
    )
  );
}

export default CompetitionsContainer;
