import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import * as d3 from 'd3';
import WordCloud from 'react-d3-cloud';

// Component
// Container & Section

// Button
import BttBigExtraFixedWidth from '../../components/Buttons/ButtonLink/BttBigExtraFixedWidth';
import ill_girl from '../../assets/img/ill/img_girl_writing_front.png';
import TagListInteractive from '../../components/Tag/TagListInteractive';

// Api
import { adminApi } from '../../api/api';

const Container = styled.div`
  position: relative;
  /* height: 321px; */
  padding-bottom: ${props => props.theme.size.space.medium2};
  @media screen and (max-width: 480px){
    height: auto;
    padding-bottom: ${props => props.theme.size.space.medium1};
  }
`;

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const ImgSection = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding-top: 26px;
  img{
    width: 160px;
    height: 254px;
  }
  @media screen and (max-width: 480px){
    display: none;
  }
`;

const Overline = styled.p`
  ${props => props.theme.type.overline};
`;

const Title = styled.p`
  cursor: pointer;
  display: inline-block;
  word-break: keep-all;
  width: 100%;
  ${props => props.theme.type.h3};
  height: 84px;
  margin-bottom: 74px;
  @media screen and (max-width: 480px){
    height: auto;
    margin-bottom: 44px;
  }
`;

const Exp = styled.p`
  ${props => props.theme.type.body2}
  color: ${props => props.theme.color.ui.strong};
  margin-bottom: ${props => props.theme.size.gridSmall.x3};
  height: 48px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
    margin-bottom: ${props => props.theme.size.gridSmall.x3};
  }
`;

const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;  

const Keyword = styled.div`
  max-width: 86px;
  line-height: 18px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top:2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const Content = styled.div`
  position: relative;
  img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 264px;
    height: auto;
    @media screen and (max-width: 480px){
      width: 124px;
      height: auto;
    }
  }
  height: 184px;
  svg>g>tagName{
    fill: #9B9BDF !important;
  }
`;

const BottomActionBar = styled.div`
  width: calc(100% - 0px);
  /* ${props=> props.theme.layout.flexRowCenterBlock} */
  /* position: absolute; */
  left: 0;
  bottom: 20px;
  @media screen and (max-width: 480px){
    margin-top: 20px;
    width: 100%;
    ${props=> props.theme.layout.flexRowCenterBlock}
  }
`;

const Center = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
`;

function WriteWithTagBanner({ username, tagList }) {

  return (
    <>
        <div className="col1 dv_right no_ver_line_mobile margin_b_2x_mobile">
          <Container className='col1 flex_row'>
            <ContentSection>
              <Overline>
                마음대로 쓰기
              </Overline>
              <Title>
                나의 이야기를 글로 만들어보세요
              </Title>
              <Exp>
                블루블랙의 이웃들은 지금 다양한 주제어로 에세이를 쓰고 있습니다.
              </Exp>
              <TagListInteractive tagList={tagList}/>
            </ContentSection>
            <ImgSection>
              <img src={ill_girl} alt='' />
            </ImgSection>        
            <BottomActionBar>
            {
              username === null ? (
                <Center>
                  <BttBigExtraFixedWidth
                    width={'256px'}
                    type="filled_primary"
                    className=""
                    to={`/signup?from=write`}
                    title="마음대로 쓰기"
                  />
                </Center>
              ) : (
                <Center>
                  <BttBigExtraFixedWidth
                    width={'256px'}
                    type="filled_primary"
                    className=""
                    to={`/write`}
                    title="마음대로 쓰기"
                    onClick={() => {
                      console.log('click');
                      const { gtag } = window;
                      gtag('event', 'click', {
                        'event_category': 'write with tag click',
                        'event_label': 'main page'
                      });
                    }}
                  />
                </Center>
              )
            }
          </BottomActionBar>
          </Container>
        </div>
    </>
  )
}

export default WriteWithTagBanner;