import React from 'react';
import LiveArea from '../../components/Layout/LiveArea';
import styled from 'styled-components';
import ConainerO from '../../components/Layout/Container/ContainerO';

const Container = styled(LiveArea)`
  flex-direction: row !important;
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
    padding: 0 20px;
  }
`;

const Title = styled.div`
  color: ${ props => props.theme.color.primary500 };
  width: calc(100% - 784px);
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.h1Block}
  margin-bottom: 40px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Content = styled.div`
  p{
    width: 784px;
    font-family: ${props => props.theme.type.regular};
    font-size: ${props => props.theme.type.body3.size};
    line-height: ${props => props.theme.type.body3.height};
    margin-bottom: 48px;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  } 
  h2{
    font-family: ${props => props.theme.type.bold};
    font-size: ${props => props.theme.type.body3.size};
    line-height: ${props => props.theme.type.body3.height};
  }
`

function PrivacyPolicy() {
  return(
    <Container className={'router_area_top'}>
      <Title>
        개인정보<br />
        처리방침
      </Title>
      <Content>
        <p>
        '이디그나'('http://blue-black.life/'이하 '블루블랙')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        '이디그나'('블루블랙') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다. 
        <br />
         ○ 본 방침은부터 2020년 10월 1일부터 시행됩니다.
        </p>
        <h2>1. 개인정보의 처리 목적</h2>
        <p>
          '이디그나'('http://blue-black.life/'이하 '블루블랙')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
          가. 홈페이지 회원가입 및 관리
          회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증 등을 목적으로 개인정보를 처리합니다.
          나. 마케팅 및 광고에의 활용
          신규 서비스(제품) 개발 및 맞춤 서비스 제공 등을 목적으로 개인정보를 처리합니다.
        </p>
        <h2>2. 개인정보 파일 현황</h2>
        <p>
          <ol>
            <li>
              개인정보 파일명 : 개인정보 처리
            </li>
            <ul>
                <li>
                  개인정보 항목 : 이메일
                </li>
                <li>
                  수집방법 : 홈페이지
                </li>
                <li>
                  보유근거 : 회원관리
                </li>
                <li>
                  보유기간 : 영구
                </li>
                <li>
                  관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
                </li>
              </ul>
          </ol>
        </p>
        <h2>제 3 조 (약관 동의 및 서비스 이용계약의 체결))</h2>
        <p>
          <ol>
            <li>
              회사와 회원 간의 서비스 이용계약은 이용자가 본 약관 내용에 대해 동의하고 서비스 이용신청을 하면 회사가 그 신청을 승낙함으로써 성립합니다.
            </li>
            <li>
              회사는 이용자가 서비스 회원가입 페이지의 “동의함” 버튼을 클릭하거나 이용자가 본 서비스의 이용을 시작한 경우 본 약관에 동의한 것으로 간주합니다.
            </li>
            <li>
              회사는 이용자가 필수사항 등을 성실히 입력하여 서비스 이용신청을 완료하였을 때에는 신청 내용을 확인한 후 지체 없이 이를 승낙하여야 합니다. 단, 회사는 아래 각 호에 해당하는 경우에는 승낙을 유보할 수 있습니다.
              
            </li>
            <li>
              회사는 아래 각 호에 해당하는 경우에는 본 서비스 이용계약에 대한 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
              <ul>
                <li>
                  이용자가 서비스 이용신청 시 허위의 정보를 기재하거나 회사가 요청하는 사항을 기재하지 아니한 경우
                </li>
                <li>
                  제10조 제3항에 의해 회사가 본 서비스 이용계약을 해지했던 회원이 다시 서비스 이용신청을 하는 경우, 단 회사로부터 재가입 승낙을 받은 경우는 예외로 함
                </li>
                <li>
                  이용자의 귀책사유로 인하여 승낙이 불가능하거나 관련 법령 등에 위반하여 서비스 이용신청을 하는 경우
                </li>
              </ul>
            </li>
            <li>
              본 조 제3항 및 제4항에 따라 회사가 이용신청에 대해 승낙을 유보하거나 승낙을 하지 않을 경우, 회사는 그 사실을 이용자에게 알리도록 합니다.
            </li>
            <li>
              본 서비스 이용계약은 회사가 신청절차 상에서 가입 완료를 표시한 시점에 성립합니다.제 4 조 (개인정보의 보호 및 관리)
            </li>
            <li>
              회사는 서비스를 제공하기 위하여 회원으로부터 서비스 이용에 필요한 개인정보를 수집할 수 있습니다.
            </li>
            <li>
              회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 회원의 개인정보의 보호 및 사용에 대해서는 회사가 별도로 고지하는 개인정보 처리방침을 적용합니다. 
            </li>
            <li>
              회사는 회사의 귀책 없이 회원의 귀책사유로 인하여 회원의 정보가 노출된 경우 이에 대해서는 책임을 지지 않습니다.
            </li>
          </ol>
        </p>
      </Content>
    </Container>
  )
}

export default PrivacyPolicy;