import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BttContainer = styled(Link)`
  height: ${props => props.theme.size.height.medium};
  width: ${props => props.width};
  padding: 0 32px;
  border-radius: ${props => `calc(${props.theme.size.height.medium}/2)`};
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.bttFilledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryBlock.enabled : '')}
  @media screen and (max-width: 480px) {
  }
`;

const BttBigFixedWidth = ({ width, type, title, to, className, onClick }) => {
  return (
    <BttContainer
      width={width}
      type={type}
      to={to}
      className={`btt_txt ${className}`}
      onClick={onClick}>
      <p>{title}</p>
    </BttContainer>
  );
};

BttBigFixedWidth.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  // className: PropTypes.string.isRequired,
};

export default BttBigFixedWidth;