import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LiveAreaSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.layout.liveAreaSmall.width};
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
const LiveAreaSmall = ({ children, className }) => (
  <LiveAreaSmallContainer className={className}>{children}</LiveAreaSmallContainer>
);

LiveAreaSmall.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default LiveAreaSmall;
