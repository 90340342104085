/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import ContainerC from '../Layout/Container/ContainerC'
import TextBannerTitle from '../Layout/Title/TextBannerTitle';
import BttAction from '../Buttons/ButtonAction/BttAction';

const Container = styled(ContainerC)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  /* margin-bottom: 48px; */
  @media screen and (max-width: 480px) {
    padding-bottom: 16px;
    /* margin-bottom: 32px; */
  }
  button{
    margin-top: 16px;
  }
`;

const BannerWriteForBranded = ({
  branded,
  isVoted,
  voteEssay
}) => (
  <Container>
   <TextBannerTitle
    title1={'브랜디드 스토리 참가글'}
    title2={branded.question}
   />
   <BttAction
    type={isVoted?'filled_primary':'outlined'}
    onClick={voteEssay}
    title={isVoted?'위 에세이에 투표함':'위 에세이에 1표 주기'}
   />
  </Container>
);


export default BannerWriteForBranded;
