import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const TabBasicContainer = styled(Link)`
  position: relative;
  height: 28px;
  width: fit-content;
  /* min-width: 74px; */
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: sopoqa_han_sans_bold;
  color: #78786e;
  cursor: pointer;
  &.selected {
    color: #443cba;
  }
  &.selected:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${props => props.theme.shape.borderBold};
    background-color: ${props => props.theme.color.primary500};
  }
  @media screen and (max-width: 480px) {
    // min-width: 96px;
  }
`;

const TabBasic = ({ title, selected, className, onClick, to }) => (
  <TabBasicContainer
    to={to}
    className={`tab_basic ${selected ? 'selected ' : ' '}${className}`}
    onClick={onClick}
  >
    <p>{title}</p>
  </TabBasicContainer>
);

TabBasic.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

export default TabBasic;
