import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BttContainer = styled.a`
  height: ${props => props.theme.size.height.medium};
  border-radius: ${props => `calc(${props.theme.size.height.medium} / 2)`};
  width: fit-content;
  padding: 0 24px;
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryBlock.enabled : '')}
`;

const BttBigExternalLink = ({ type, title, to, className, onClick }) => {
  return (
    <BttContainer type={type} href={to} target='_blank' className={`btt_txt ${className}`} onClick={onClick}>
      <p>{title}</p>
    </BttContainer>
  );
};

BttBigExternalLink.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  // className: PropTypes.string.isRequired,
};

export default BttBigExternalLink;
