import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation
} from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import styled from 'styled-components';
import GNB from '../components/GNB/GNB';
import Footer from '../components/Footer';

// PAGE COMPONENT
import SingIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';
import Search from '../pages/Search';
import Main from '../pages/Main';
import AboutCompetition from '../pages/AboutCompetition';
import EssayFeed from '../pages/EssayFeed';
import EssayPost from '../pages/EssayPost';
import EssayPostPlain from '../pages/EssayPostPlain';
import PrizedItemFeed from '../pages/PrizedItemFeed';
import InsPost from '../pages/InsPost';
import Ins from '../pages/Ins';
import Write from '../pages/Write';
import WritePlain from '../pages/WritePlain';
import WriteTest from '../pages/WriteTest';
import WriteNotionTest from '../pages/WriteNotionTest';
import WriteByAdmin from '../pages/WriteByAdmin';
import WriteIns from '../pages/WriteIns';
import Save from '../pages/Save';
import SaveByAdmin from '../pages/SaveByAdmin';
import SaveIns from '../pages/SaveIns';
import Competitions from '../pages/Competitions';
import CompetitionFeed from '../pages/CompetitionFeedNew';
import BrandedFeed from '../pages/BrandedFeed';
import MyPage from '../pages/MyPage';
import Alarms from '../pages/Alarms';
import UserPage from '../pages/UserPage';
import AdminMainContainer from '../pages/Admin/AdminMain/';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import ServiceAgreement from '../pages/ServiceAgreement/ServiceAgreement';
import AboutBrandedPresenter from '../pages/AboutCompetition/AboutBrandedPresenter';

// COMPONENT
import LiveArea from '../components/Layout/LiveArea';
import SocialSignUp from '../pages/SocialSignUp/SocialSignUpPresenter';

const RouteArea = styled(LiveArea)`
  padding-top: calc(80px + 91px);
  @media screen and (max-width: 480px) {
    padding-top: calc(60px + 61px);
  }
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default () => (
  <Router>
    <ScrollMemory />
    <>
      <ScrollToTop />
      <GNB />
      <RouteArea>
        <Switch>
          <Route path="/signIn" exact component={SingIn} />
          <Route path="/signUp" exact component={SignUp} />
          <Route path="/resetpw" exact component={ResetPassword} />
          {/* Search page */}
          <Route path="/search" exact component={Search} />
          {/* Main page of the site */}
          <Route path="/" exact component={Main} />
          {/* Main Test2 of the site */}
          {/* <Route path="/main" exact component={MainTest} /> */}
          {/* About Competition */}
          <Route path="/AboutCompetition" exact component={AboutCompetition} />
          {/* About Branded */}
          <Route path="/AboutWeLove" exact component={AboutBrandedPresenter} />
          {/* Essay Feed Composed by 4 Tabs (Recent, Followers, Interested Tag tab) */}
          <Route path="/feed" exact component={EssayFeed} />
          {/* Essay Post Page */}
          <Route path="/post/:essayId" exact component={EssayPostPlain} />
          {/* <Route path="/postPlain/:essayId" exact component={EssayPostPlain} /> */}
          {/* Inspire Post Page */}
          <Route path="/inspire/:insId" exact component={InsPost} />
          {/* List of All the Competitons Page */}
          <Route path="/competitions" exact component={Competitions} />
          {/* Page of Specific Competition */}
          <Route path="/competition/:date" exact component={CompetitionFeed} />
          <Route path="/branded/:date" exact component={BrandedFeed} />
          <Route path="/prizedItem/:date" exact component={PrizedItemFeed} />
          {/* Advertisement Page. Currently not in Used */}
          <Route path="/Ins" exact component={Ins} />
          {/* Write Page in Case Of 1st Write */}
          <Route path="/write" exact component={WritePlain} />
          {/* <Route path="/writePlain" exact component={WritePlain} /> */}
          {/* Write Page in Case Of 1st WriteTest */}
          <Route path="/writeTest" exact component={WriteTest} />
          <Route path="/writeNotionTest" exact component={WriteNotionTest} />
          {/* Write Page in Case Of 1st Write Ad */}
          <Route path="/WriteIns" exact component={WriteIns} />
          {/* Write Page in Case Of Editing Exist Essay */}
          <Route path="/write/:essayId" exact component={Write} />
          {/* Write Page in Case Of Editing Exist Ad */}
          <Route path="/WriteIns/:adId" exact component={WriteIns} />
          {/* Save Page for Specific Essay. User can Save or Publish */}
          <Route path="/save" exact component={Save} />
          {/* Save Page for Specific Ad. Admin can only Publish */}
          <Route path="/SaveIns" exact component={SaveIns} />
          {/* My Page for Managing Essays */}
          <Route path="/mypage" exact component={MyPage} />
          {/* Alarm Page, Showing relavant Competitions, Users, Essay information */}
          <Route path="/alarm" exact component={Alarms} />
          {/* Other User Page, Similar Structure with My Page */}
          <Route path="/user/:username" exact component={UserPage} />
          {/* Privacy Policy */}
          <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
          {/* Service Agreement */}
          <Route path="/ServiceAgreement" exact component={ServiceAgreement} />
          {/* Admin Page for select Prized Essay and Creat/Complete Competitoin Event */}
          <Route path="/admin" component={AdminMainContainer} />
          {/* edit essay by admin */}
          <Route path="/editEssay" exact component={WritePlain} />
          {/* Save Page for Specific Essay. User can Save or Publish */}
          <Route path="/saveByAdmin" exact component={SaveByAdmin} />
          {/* edit competition for admin */}
          {/* <Route path="/editCompetition/:competitionId" exact component={EditCompetition} /> */}
          <Route path="/kakao/validCheck" exact component={SocialSignUp} />
          <Redirect from="*" to="/" />
        </Switch>
      </RouteArea>
      <Footer></Footer>
    </>
  </Router>
);