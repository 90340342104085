import React from 'react';
import CreatNewCompetitionPresenter from './CreatNewCompetitionPresenter';
import { useUserState } from '../../../context/currentUserContext';

function CreatNewCompetitionContainer() {
  const { loaded, username, userData, interestedTag } = useUserState();
  return (
    loaded && (
      <>
        <CreatNewCompetitionPresenter
          username={username}
          userData={userData}
          interestedTag={interestedTag}
        />
      </>
    )
  );
}

export default CreatNewCompetitionContainer;