import React from 'react';
import MyPagePresenter from './MyPagePresenter';
import { useUserState } from '../../context/currentUserContext';

function MyPageContainer() {
  const { loged, userData, interestedTag, writeTags } = useUserState();
  return (
    loged && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <MyPagePresenter
          loged={loged}
          userData={userData}
          interestedTag={interestedTag}
          writeTags={writeTags}
        />
      </>
    )
  );
}

export default MyPageContainer;
