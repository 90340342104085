
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { isMobile } from 'react-device-detect';

// API
import queryString from 'querystring';
import { essayApi, userpageApi, prizedItem, searchApi } from '../../api/api';

// Dummydata
import { EssayDummy } from '../../stories/dummyData/essay';
import { UserDummy } from '../../stories/dummyData/user';
import { TagDummy } from '../../stories/dummyData/tag';
import { BookDummy } from '../../stories/dummyData/book';

// Component
import LiveAreaSmall from '../../components/Layout/LiveAreaSmall';
import SearchBar from '../../components/SearchBar/SearchBar';
import TabContainer from '../../components/Tabs/Tabcontainer';
import TabBasicAction from '../../components/Tabs/TabBasicAction';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import CardUserSmall from '../../components/Cards/User/CardUserSmall';
import CardRegularTag from '../../components/Cards/Tag/CardRegularTag';
import CardRegularPrizedItem from '../../components/Cards/PrizedItem/CardRegularPrizedItem';
import BttClose from '../../components/Buttons/ButtonAction/BttClose';
import PartitionVer from '../../components/Layout/Grid/PartitionVer';
import Loading from '../../components/Loading/Loading';
import LoadingSmall from '../../components/Loading/LoadingSmall';
import FeedGrid from '../../template/Feed/FeedGrid';

const Container = styled(LiveAreaSmall)`
  /* width: 100%; */
  min-height: 560px;
`;

const Top = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
  margin-bottom: 48px;
`;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.12);
`;

const TagedEssayContainer = styled.div`
  width: calc(100% - 564px);
  height: calc(100% - 512px);
  
  width: flex;
  flex-direction: column;
  padding: 32px 0px 32px 32px;
  background-color: ${props => props.theme.color.primary100};
  position: absolute;
  top: 256px;
  left: 282px;
  @media screen and (max-width: 480px){
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }
  padding-top: 56px;
`;

const EssayList = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;
  overflow-y: auto;
`;

function SearchPresenter({
  loged,
  username,
  userData,
  location,
}) {

  let tabList = [
    { name: '에세이', id: 'essay', num: 0 },
    { name: '글쓴이', id: 'writer', num: 0 },
    { name: '주제어', id: 'tag', num: 0 },
    { name: '책', id: 'book', num: 0 }
  ];


  // Search Option
  const [size, setSize] = useState(30);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState(null);

  const [activeTab, setActiveTab] = useState(tabList[0].id);
  const [numEssay, setNumEssay] = useState(0);
  const [numUser, setNumUser] = useState(0);
  const [numTag, setNumTag] = useState(0);
  const [numBook, setNumBook] = useState(0);
  const [essayList, setEssayList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [loading, setLoading] = useState(true);

  // Search Essay by Tag
  const [tagedEssayLoading, setTagedEssayLoading] = useState(false);
  const [tagedEssayList, setTagedEssayList] = useState([]);
  const [searchedTag, setSearchedTag] = useState(null);

  // Follow User
  const [isFollow, setIsFollow] = useState(false);

  const fetchData = async () => {
    try {
      // Searched Essay
      await searchApi.getUser({
        keyword: '제육',
        pages: 1,
        size: 30
      }).then((data) => {
        console.log(data);
      });
      // Searched Essay
      // Searched Essay
      // Searched Essay
    }
    catch {

    }
    finally {

    }
  }

  const onSearch = async () => {
    try {
      // Search Essay
      console.log(keyword);
      await searchApi.getEssay({
        keyword: keyword,
        pages: 1,
        size: 30
      }).then((res) => {
        console.log(res);
        setEssayList(res.data);
        setNumEssay(res.data.length);
      });
      // Search User
      await searchApi.getUser({
        keyword: keyword,
        pages: 1,
        size: 30
      }).then((res) => {
        console.log(res.data);
        setUserList(res.data)
        setNumUser(res.data.length);
      });

      // Search Keyword
      await searchApi.getTag({
        keyword: keyword,
        pages: 1,
        size: 30
      }).then((res) => {
        console.log(res.data);
        setTagList(res.data)
        setNumTag(res.data.length);
      });

      await searchApi.getPrizedItem({
        keyword: keyword,
        pages: 1,
        size: 30
      }).then((res) => {
        console.log(res.data);
        setBookList(res.data)
        setNumBook(res.data.length);
      });
    }
    catch {

    }
    finally {
      console.log([
        essayList.length,
        userList.length,
        tagList.length,
        bookList.length
      ]);
    }
  }

  const getTagedEssayList = async tagName => {
    try {
      setSearchedTag(tagName);
      setTagedEssayLoading(true);
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [tagName],
          pages: 1,
          size: 30,
        })
        .then(
          essayList => {
            if (essayList.status != 404) {
              console.log(essayList.data);
              setTagedEssayList(essayList.data);
            }
          },
          error => {
            console.log(error);
          },
        );
    } catch {
    } finally {
      setTagedEssayLoading(false);
      document.body.style.overflow = 'hidden';
    }
  };

  const resetTagedEssay = () => {
    setSearchedTag(null);
    document.body.style.overflow = 'auto';
  };

  const followUser = async (targetName) => {
    // TODO : 팔로우 api 호출
    await userpageApi.followUser({ targetName });
    setIsFollow(!isFollow);
    const { gtag } = window;
    gtag('event', 'click', {
      'event_category': 'user interaction on a post',
      'event_label': 'follow'
    });
  }


  return (
    <Container className={'col_grid_mobile'}>
      <Top>
        <SearchBar
          onSearch={() => {
            onSearch()
          }}
          value={keyword}
          onChange={setKeyword}
        />
      </Top>
      {/* TABS */}
      <TabContainer className="margin_b_2x">
        {tabList.map((tab, i) => {
          let num = 0;
          if(i===0) num = numEssay;
          else if(i===1) num = numUser;
          else if(i===2) num = numTag;
          else if(i===3) num = numBook;
          return (
            <TabBasicAction
              title={(tab.name + (num !== 0 ? `(${num})` : ''))}
              selected={tab.id === activeTab}
              className="col4"
              onClick={() => setActiveTab(tabList[i].id)}
              key={i}
            />
          )
        })}
      </TabContainer>
      <ContainerO>
        {/* Essay Results */}
        {
          activeTab === 'essay' &&
          <div className='flex_row col1'>
            {
              essayList.map((essay, i) =>
                <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                  <CardRegularEssay
                    essay={essay}
                    to={`/post/${essay._id}`}
                    className={''}
                  />
                </GridMatrix>
              )
            }
          </div>
        }
        {/* Writer Results */}
        {
          activeTab === 'writer' &&
          <div className='flex_row col1'>
            {
              userList.map((user, i) =>
                <GridMatrix colPC={4} colMb={1} index={i} key={i}>
                  <CardUserSmall
                    writer={user}
                    to={`/user/${user._id}`}
                    followUser={() => {
                      followUser(user.username)
                    }}
                    isFollow={isFollow}
                    className={''}
                    loged={true}
                  />
                </GridMatrix>
              )
            }
          </div>
        }
        {/* Keyword Results */}
        {
          activeTab === 'tag' &&
          <div className='flex_row col1'>
            {
              tagList.map((tag, i) =>
                <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                  <CardRegularTag
                    isLoading
                    tag={tag}
                    searchedTag={searchedTag}
                    tagedEssayLoading={tagedEssayLoading}
                    onTagedEssaySearch={() => {
                      getTagedEssayList(tag.tagName);
                    }}
                  />
                </GridMatrix>
              )
            }
          </div>
        }
        {/* 검색된 태그 에세이 보여주기 */}
        {searchedTag && !tagedEssayLoading && 0 < tagedEssayList.length && (
          <BackDrop onClick={() => resetTagedEssay()}>
            <TagedEssayContainer>
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                }}
              >
                <BttClose onClick={() => resetTagedEssay()} />
              </div>
              <EssayList>
                {tagedEssayList.map(essay => (
                  <PartitionVer>
                    <CardRegularEssay
                      essay={essay}
                      to={`/post/${essay._id}`}
                      className={''}
                    />
                  </PartitionVer>
                ))}
              </EssayList>
            </TagedEssayContainer>
          </BackDrop>
        )}
        {/* Book Results */}
        {
          activeTab === 'book' &&
          <div className='flex_row col1'>
            {
              bookList.map((competition, i) =>
                <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                  <CardRegularPrizedItem
                    to={`prizedItem/${competition.year}-${competition.month}-${competition.week}`}
                    key={i}
                    prizedItem={competition.prizeItem}
                  />
                </GridMatrix>
              )
            }
          </div>
        }
      </ContainerO>
    </Container>
  )
}

export default SearchPresenter;