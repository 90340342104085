import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import WordCloud from 'react-d3-cloud';
// import WordCloud from '../../../library/react-d3-cloud/src/WordCloud';

import LiveArea from '../../../components/Layout/LiveArea';

// Api
import { adminApi, essayApi } from '../../../api/api';

// Component
import Loading from '../../../components/Loading/Loading';
import Section from '../../../components/Layout/Section/Section';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import ContainerC from '../../../components/Layout/Container/ContainerC';
import ListTag from '../../../components/List/ListTag';
import GridMatrix from '../../../components/Layout/Grid/GridMatrix';
import CardRegularTag from '../../../components/Cards/Tag/CardRegularTag';
import CardRegularEssay from '../../../components/Cards/Essay/CardRegularEssay';
import BttClose from '../../../components/Buttons/ButtonAction/BttClose';
import PartitionVer from '../../../components/Layout/Grid/PartitionVer';
import EssayListModal from '../../../template/Modal/EssayListModal';

const Container = styled(LiveArea)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.12);
`;

const TagedEssayContainer = styled.div`
  width: calc(100% - 564px);
  height: calc(100% - 512px);
  width: flex;
  flex-direction: column;
  padding: 32px 0px 32px 32px;
  background-color: ${props => props.theme.color.brand.primary50};
  position: absolute;
  top: 256px;
  left: 282px;
  padding-top: 56px;
`;

const EssayList = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;
  overflow-y: auto;
`;

function ExploreTagPresenter() {
  const [tagList, setTagList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tagedEssayLoading, setTagedEssayLoading] = useState(false);
  const [tagedEssayList, setTagedEssayList] = useState([]);
  const [searchedTag, setSearchedTag] = useState(null);
  const [tagListForCloud, setTagListForCloud] = useState([]);
  const svgRef = useRef();
  let svg;
  const fontSizeMapper = word => Math.log10(word.value) * 12;
  const rotate = word => {
    const offset = word.value % 360;
    if (offset < 90) {
      return 0;
    }
    if (offset > 90 && offset < 180) {
      return 90;
    }
    if (offset > 180 && offset < 270) {
      return 0;
    }
    if (offset > 270 && offset < 360) {
      return 90;
    }
  };

  const data = [
    { text: 'Hey', value: 1000 },
    { text: 'lol', value: 200 },
    { text: 'first impression', value: 800 },
    { text: 'very cool', value: 1000000 },
    { text: 'duck', value: 10 },
    { text: 'very cool', value: 123 },
    { text: 'duck', value: 10 },
    { text: 'very cool', value: 123123 },
    { text: 'duck', value: 10 },
    { text: '하하호호', value: 122 },
    { text: 'dadsuck', value: 10 },
    { text: 'very coggol', value: 212123 },
    { text: 'ducggk', value: 10 },
    { text: 'verggy cool', value: 3213123 },
    { text: 'dudck', value: 10 },
    { text: 'veasdry cool', value: 2212 },
    { text: 'dudasdck', value: 10 },
    { text: 'asdas cool', value: 100211220000 },
    { text: 'duasdck', value: 10 },
  ];

  const fetchData = async () => {
    try {
      await adminApi.showAllTags().then(tags => {
        console.log(tags.data);
        // console.log(JSON.Stringify(tags.data));

        setTagList(tags.data);
        const tagListTemp = [];
        tags.data.forEach(tag => {
          tagListTemp.push({
            text: tag.tagName,
            value: tag.count * 30,
          });
          setTagListForCloud(tagListTemp);
        });
      });
    } catch {
    } finally {
      console.log(tagListForCloud);
      setLoading(false);
    }
  };

  const getTagedEssayList = async tagName => {
    try {
      setSearchedTag(tagName);
      setTagedEssayLoading(true);
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [tagName],
          pages: 1,
          size: 30,
        })
        .then(
          essayList => {
            if (essayList.status != 404) {
              console.log(essayList.data);
              setTagedEssayList(essayList.data);
            }
          },
          error => {
            console.log(error);
          },
        );
    } catch {
    } finally {
      setTagedEssayLoading(false);
      document.body.style.overflow = 'hidden';
    }
  };

  const resetTagedEssay = () => {
    setSearchedTag(null);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    fetchData();
    svg = d3.select(svgRef.current);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {/* <svg
            ref={svgRef}
            width="100%"
            height="320px"
          /> */}
          <Section>
            <SectionTitle title1="활발한 주제어 구름" />
            <ContainerC>
              <div className={'margin_b_1x'}>
                <WordCloud
                  data={tagListForCloud}
                  width={768}
                  height={268}
                  fontSizeMapper={fontSizeMapper}
                  rotate={0}
                />
              </div>
            </ContainerC>
          </Section>
          <Section>
            <SectionTitle title1="모든 주제어" />
            <ContainerC>
              <div className="flex_row col1">
                {tagList.map((tag, i) => (
                  <>
                    <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                      <CardRegularTag
                        isLoading
                        tag={tag}
                        searchedTag={searchedTag}
                        tagedEssayLoading={tagedEssayLoading}
                        onTagedEssaySearch={() => {
                          getTagedEssayList(tag.tagName);
                        }}
                      />
                    </GridMatrix>
                  </>
                ))}
              </div>
              {/* 검색된 태그 에세이 보여주기 */}
              {searchedTag && !tagedEssayLoading && 0 < tagedEssayList.length && (
                <EssayListModal
                  tagedEssayList={tagedEssayList}
                  resetTagedEssay={resetTagedEssay}
                />
              )}
            </ContainerC>
          </Section>
        </Container>
      )}
    </>
  );
}

export default ExploreTagPresenter;
