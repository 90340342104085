import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BttContainer = styled.button`
  width: fit-content;
  padding: 16px 0;
  display: flex;
  img {
    margin-bottom: 4px;
  }
`;
const Title = styled.p`
  margin-left: 8px;
  color: #93908b;
  ${props => props.theme.type.btt1}
`;

const BttIconWithStatus = ({ title, isOn, imgOn, imgOff, onClick }) => {
  return (
    <BttContainer onClick={onClick} className="flex_center">
      {isOn ? <img src={imgOn} alt="" /> : <img src={imgOff} alt="" />}
      <Title>{title}</Title>
    </BttContainer>
  );
};

BttIconWithStatus.propTypes = {
  title: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  imgOn: PropTypes.string.isRequired,
  imgOff: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BttIconWithStatus;
