import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// Api
import { essayApi, competitionApi, tagApi, mypageApi, adminApi } from '../../api/api';

// Component
import Loading from '../../components/Loading/Loading';
import BttActionBig from '../../components/Buttons/ButtonAction/BttActionBig';
import BttBig from '../../components/Buttons/ButtonLink/BttBig';

// Container
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ContainerC from '../../components/Layout/Container/ContainerC';
import ContainerO from '../../components/Layout/Container/ContainerO';

// Template
import SetBasicEssayInfo from '../../template/Save/SetBasicEssayInfo';
import SetCompetitionJoin from '../../template/Save/SetCompetitionJoin';

// Context
import {
  useEssayState,
  useEssayDispatch,
} from '../../context/currentEssayContext';
import { useUserDispatch } from '../../context/currentUserContext';

import {
  ADD_ESSAY_TAGS,
  REMOVE_ESSAY_TAGS,
  SET_IS_DUEDAY,
  SET_DUEDAY,
  SET_IS_COMPETED,
  SET_COMPETITION_ID,
  RESET_ESSAY,
  LOG_IN_MAINTAIN,
} from '../../actions';

function SaveByAdminPresenter({ userData }) {
  // currentEssayContxt에서 갖고온 state;
  const {
    id,
    title,
    content,
    tags,
    isDueday,
    dueday,
    isCompeted,
    competitionId,
    numCurrentWords,
  } = useEssayState();
  const essayDispatch = useEssayDispatch();
  const userDispatch = useUserDispatch();

  // Local State
  const [currentCompetition, setCurrentCompetition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRedirect, setIsRedirect] = useState(false);
  const [previewTagList, setPreviewTagList] = useState([]);
  const [numMaxWords, setNumMaxwords] = useState(2200);
  const [isPreview, setIsPreview] = useState(false);
  const [goToMain, setGoToMain] = useState(false);

  const fetchData = async () => {
    try {
      await competitionApi.competitionCurrent().then(currentCompetition => {
        if (currentCompetition.status !== 404) {
          setCurrentCompetition(currentCompetition.data);
        }
        console.log(currentCompetition);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  // Fetch Data of current Competition and dispatch the competition data
  useEffect(() => {
    fetchData();
    const dateDefualt = new Date();
    essayDispatch({ type: SET_DUEDAY, value: dateDefualt });
  }, []);

  // essayContext값 변경 함수
  // Tag
  const addTag = tagName => {
    setIsPreview(false);
    essayDispatch({ type: ADD_ESSAY_TAGS, value: { tagName } });
  };

  const removeTag = tagName => {
    essayDispatch({ type: REMOVE_ESSAY_TAGS, value: tagName });
  };

  // Dueday
  const setIsDueday = e => {
    let value;
    if (e.target.value === 'true') {
      value = true;
    } else {
      value = false;
    }
    essayDispatch({ type: SET_IS_DUEDAY, value });
  };
  const setDueday = dueday => {
    essayDispatch({ type: SET_DUEDAY, value: dueday });
  };

  // Competition
  const setIsCompeted = e => {
    const value = e.target.checked;
    console.log(value);
    essayDispatch({ type: SET_IS_COMPETED, value });
    if (value) {
      setCompetitionId(currentCompetition._id);
      console.log(currentCompetition);
    } else {
      setCompetitionId(null);
    }
  };

  const setCompetitionId = competitionId => {
    essayDispatch({ type: SET_COMPETITION_ID, value: competitionId });
  };

  // Tag Preview
  const onTagPreview = async string => {
    await tagApi.getTagsByString(string).then(tagList => {
      console.log(tagList.data);

      setPreviewTagList(tagList.data);
      if (tagList.data.length) {
        setIsPreview(true);
      }
    });
  };

  const essayData = {
    essayId: id,
    title,
    content,
    contentTest: '',
    tagNames: tags,
    isDueday,
    dueday,
    isPublished: false,
    isCompeted,
    competitionId: '',
  };

  const saveEssay = async () => {
    if (isDueday) {
      essayData.dueday = dueday;
    }
    if (isCompeted) {
      essayData.competitionId = competitionId;
    }
    essayData.contentText = getContentText(essayData.content);
    console.log(essayData);

    /*
    @우석 06/19
    Redirect to mypage after save
    */

    console.log(essayData);
    // await essayApi.saveEssay(essayData).then(async res => {
    //   await mypageApi.getMyInfo().then(res => {
    //     console.log(res);
    //     const currentUser = {
    //       action: {
    //         type: null,
    //       },
    //       username: null,
    //     };
    //     if (res.data !== '') {
    //       currentUser.userData = res.data;
    //       currentUser.action.type = LOG_IN_MAINTAIN;
    //       userDispatch(currentUser);
    //       window.alert('에세이가 수정되었습니다.');
    //       essayDispatch({ type: RESET_ESSAY, value: '' });
    //       setIsRedirect(true);
    //     }
    //   });
    // });
  };

  if (isRedirect) {
    return <Redirect to={`/post/${id}`} />;
  }

  if (goToMain) {
    return <Redirect to="/" />;
  }

  const publishEssay = async () => {
    essayData.isPublished = true;
    if (isDueday) {
      essayData.dueday = dueday;
    }
    if (isCompeted) {
      essayData.competitionId = competitionId;
    }
    // essayData.contentText = getContentText(essayData.content);
    console.log(essayData);

    if (numMaxWords < numCurrentWords) {
      window.alert(
        '최대 글자수(2200)이하의 에세이만 출판 가능합니다. 출판을 원하시면 글을 다시 수정해주세요.',
      );
    } else {
      console.log(essayData);
      await adminApi.modifyEssay(essayData).then(res => {
        if(res.status === 401){
          window.alert(
            '잘못된 접근입니다.',
          );
          setGoToMain(true);  
        }
        else{
          console.log("resData:", res.data);
          window.alert(
            '에세이가 수정되었습니다.',
          );
          setIsRedirect(true);
        }
      })
      /* Redirect to mypage after save */
      // await essayApi.saveEssay(essayData).then(async res => {
      //   await mypageApi.getMyInfo().then(res => {
      //     console.log(res);
      //     const currentUser = {
      //       action: {
      //         type: null,
      //       },
      //       username: null,
      //     };
      //     if (res.data !== '') {
      //       currentUser.userData = res.data;
      //       currentUser.action.type = LOG_IN_MAINTAIN;
      //       userDispatch(currentUser);
      //       window.alert(
      //         '작성하신 에세이가 출판되었습니다. 출판된 에세이를 마이페이지에선 확인하세요.',
      //       );
      //       essayDispatch({ type: RESET_ESSAY, value: '' });
      //       setIsRedirect(true);
      //     }
      //   });
      // });
    }
  };

  const getContentText = content => {
    let texts = '';
    content.blocks.forEach(block => {
      texts += `${block.text  }\n`;
    });
    return texts;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex_col col_narrow col_grid_mobile router_area_top center_margin margin_b_6x">
          <Section>
            <SectionTitle title1={`'${title}'\u00A0 수정하기`} />
            <ContainerO className="flex_col col1">
              {/* 기본정보 설정 */}
              <SetBasicEssayInfo
                selectedTag={tags}
                addTag={addTag}
                removeTag={removeTag}
                onTagPreview={onTagPreview}
                previewTagList={previewTagList}
                isPreview={isPreview}
                setIsPreview={setIsPreview}
                isDueday={isDueday}
                dueday={dueday}
                setIsDueday={setIsDueday}
                setDueday={setDueday}
              />
              {/* 백일장 참여여부 설정 */}
              {currentCompetition && (
                <SetCompetitionJoin
                  competition={currentCompetition}
                  setIsCompeted={setIsCompeted}
                  isCompeted={isCompeted}
                  username={userData.username}
                  title={title}
                />
              )}
              {/* 저장 정보 서머리 */}
              {/* <EssaySaveInfoSummary /> */}
            </ContainerO>
          </Section>
          {/* 액션바 */}
          <div className="flex_row col1 space_between">
            <BttBig
              type="outlined"
              title="다시 수정하기"
              to="/write"
              className="margin_r_1x"
            />
            <div className="flex_row">
              {/* <BttActionBig
                onClick={() => {
                  saveEssay();
                }}
                type="outlined"
                title="마이노트에 저장"
                className="margin_r_1x"
              /> */}
              <BttActionBig
                onClick={() => {
                  publishEssay();
                }}
                type="filled_primary"
                title="수정하기"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SaveByAdminPresenter;
