import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

// Component
import CardWrapper from '../../Layout/Container/CardWrapper';
import MarkEssay from '../Element/MarkEssay';
import PreviewBig from "../Element/PreviewBig";
import TagList from "../Element/TagList";
import WriterInfo from "../Element/WriterInfo";

const Top = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled(Link)`
  cursor: pointer;
  width: calc(100% - 48px);
  ${props => props.theme.type.h4}
  margin-bottom: 80px;
  height: 136px;
  word-break: keep-all;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
  @media screen and (max-width: 480px) {
    -webkit-line-clamp: 4;
    height: 120px;
    margin-bottom: 32px;
  }
`;

const Keyword = styled.div`
  max-width: 86px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top:2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;


const CardBigPrizedEssay = ({ essay, className }) => (
  <CardWrapper className={`flex_col col1 ${className}`}>
    <Top>
      <Title
        to={`/post/${essay._id}`}
        className="bold primary"
      >
        {essay.title}
      </Title>
      <MarkEssay type={'reward'} />
    </Top>
    <PreviewBig
      to={`/post/${essay._id}`}
    >
      {essay.contentText}
    </PreviewBig>
    <TagList tagList={essay.tags} />
    <WriterInfo writer={essay.writer} />
  </CardWrapper>
);

CardBigPrizedEssay.propTypes = {
  // essay: PropTypes.instanceOf(Essay).isRequired,
  // className: PropTypes.string.isRequired,
};

export default CardBigPrizedEssay;
