import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import queryString from 'querystring';
import { ImageBlockConfig } from 'Dante2/package/es/components/blocks/image';
import styled from 'styled-components';
import config from '../../config';

import {
  SET_ESSAY_ID,
  SET_ESSAY_TITLE,
  SET_ESSAY_CONTENT,
  SET_ESSAY_TAGS,
  SET_IS_COMPETED,
  SET_COMPETITION_ID,
  SET_COMPETITION_DATA,
  SET_NUM_CURRENT_WORDS,
  LOAD_USER_DATA,
  SET_IS_PUBLISHED,
} from '../../actions';

import { Prompt } from 'react-router';

// API
import { essayApi, mypageApi, competitionApi } from '../../api/api';

// Img
import ImgCat from '../../assets/img/ill/AboutCompetition/04.png';

// Context
import {
  useEssayState,
  useEssayDispatch,
} from '../../context/currentEssayContext';
import { useUserDispatch } from '../../context/currentUserContext';

// Component
import Loading from '../../components/Loading/Loading';
import BttBig from '../../components/Buttons/ButtonLink/BttBig';
import BannerWriteForCompetition from '../../components/Banner/BannerWriteForCompetition';

// Styled Component
const Container = styled.div`
  //GNB와 간격 조정
  margin-top: 80px;
  @media screen and (max-width: 480px) {
   margin-top: -20px; 
  }
`;
const AmountGuide = styled.div`
  width: 160px;
  position: fixed;
  top: calc(171px + 84px);
  text-align: center;
  right: 48px;
  white-space: break-spaces;
`;

// Dante widget for image upload
const Dante = require('Dante2');

function WriteTestPresenter({ location, username }) {
  /* Local State for managing writting essay
  - option for wheather save or publush
  - options for wheater set dueday or not
  - maximum words. (Global Setting for all user by blueblack)
  - check overflow of maximum words. save with overflow available, but publish with overflow is not available.
  */
  // prevent Prompt when nothing has change from initial state
  const [isChange, setIsChange] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numMaxWords, setNumMaxwords] = useState(2200);
  const [numCurrentWords, setNumCurrentWords] = useState(0);
  const [isOverFlow, setIsOverFlow] = useState(false);

  // currentEssayContext dispatch;
  const essayDispatch = useEssayDispatch();
  const userDispatch = useUserDispatch();
  // State for esssay before update context
  const [essayDataLocal, setEssayDataLocal] = useState({
    id: null,
    title: '',
    content: null,
    tags: [],
    competitionData: {},
    isCompeted: false,
    competitionId: null,
    isPublished: false
  });

  // currentEssayContxt에서 갖고온 state;
  const {
    id,
    title,
    content,
    tags,
    isDueday,
    dueday,
    isCompeted,
    competitionId,
    competitionData,
    isAttemptSave
  } = useEssayState();

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // setState of currentEssaycontext;
  const setTitle = e => {
    console.log(e);
    setIsChange(true);
    // const dummy = e.target.value;
    setEssayDataLocal({ ...essayDataLocal, title: e.target.value });
    // console.log(essayDataLocal.title);
    // essayDispatch({ type: SET_ESSAY_TITLE, value: essayDataLocal.title });
  };
  const setContent = data => {
    setIsChange(true);
    // setEssayDataLocal({ ...essayDataLocal, content: data });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: data });
  };

  // post saved essay to server and update essay context
  const saveEssay = () => {
    setIsSave(true);
    console.log(essayDataLocal);
    essayDispatch({
      type: SET_COMPETITION_DATA,
      value: essayDataLocal.competitionData,
    });
    essayDispatch({ type: SET_IS_COMPETED, value: true });
    essayDispatch({ type: SET_ESSAY_ID, value: essayDataLocal.id });
    essayDispatch({ type: SET_ESSAY_TITLE, value: essayDataLocal.title });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: essayDataLocal.content });
    essayDispatch({ type: SET_ESSAY_TAGS, value: essayDataLocal.tags });
    essayDispatch({ type: SET_NUM_CURRENT_WORDS, value: numCurrentWords });
    essayDispatch({
      type: SET_IS_COMPETED,
      value: essayDataLocal.isCompeted,
    });
    essayDispatch({
      type: SET_COMPETITION_ID,
      value: essayDataLocal.competitionId,
    });
  };

  // Delete Essay
  const deleteEssay = async () => {
    const data = {
      essayId: essayDataLocal.id,
    };

    await essayApi.essayDelete(data).then(async res => {
      console.log(res);
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        userDispatch(currentUser);
        window.alert('에세이가 삭제되었습니다.');
        setIsDelete(true);
        // 에세이 삭제
      });
    });
  };

  // Get Essay Id in case of Modification
  const { search } = location; // 문자열 형식으로 결과값이 반환된다.
  const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환

  const getContentText = content => {
    let texts = '';
    content.blocks.forEach(block => {
      texts += block.text;
    });
    console.log(texts);
    return texts;
  };

  const chkStrLength = str => {
    let countKr = 0;
    let countEng = 0;
    let totalLength = 0;
    countKr = `${escape(str)}%u`.match(/%u/g).length - 1;
    countEng = str.length - countKr;
    totalLength = countEng + countKr;
    setNumCurrentWords(totalLength);
    return totalLength;
  };

  // Fetch Essay Data only In case of Modification
  const fetchData = async () => {
    if (queryObj['?essayId'] !== undefined) {
      setEssayDataLocal({ ...essayDataLocal, id: queryObj['?essayId'] });
    }
    if (queryObj['?competitionId'] !== undefined) {
      setEssayDataLocal({
        ...essayDataLocal,
        competitionId: queryObj['?competitionId'],
      });
    }

    try {
      // In case of Id Exist (not 1st writting)
      if (queryObj['?essayId'] !== undefined) {
        console.log('EDIT MODE');
        const body = [queryObj['?essayId']];

        await essayApi.getEssaysById(body).then(async essayData => {
          console.log(essayData.data[0]);
          setEssayDataLocal({
            ...essayDataLocal,
            id: essayData.data[0]._id,
            title: essayData.data[0].title,
            content: JSON.parse(essayData.data[0].content),
            tags: essayData.data[0].tags,
            isCompeted: essayData.data[0].isCompeted,
            isPublished: essayData.data[0].isPublished
          });
          const text = getContentText(JSON.parse(essayData.data[0].content));
          const lengthChar = chkStrLength(text);
          setNumCurrentWords(lengthChar);
          essayDispatch({ type: SET_NUM_CURRENT_WORDS, value: lengthChar });
          essayDispatch({
            type: SET_IS_COMPETED,
            value: essayData.data[0].isCompeted,
          });
          essayDispatch({
            type: SET_IS_PUBLISHED,
            value: essayData.data[0].isPublished,
          });
          essayDispatch({ type: SET_ESSAY_ID, value: essayData.data[0]._id });
          essayDispatch({
            type: SET_ESSAY_TITLE,
            value: essayData.data[0].title,
          });
          essayDispatch({
            type: SET_ESSAY_CONTENT,
            value: JSON.parse(essayData.data[0].content),
          });
          essayDispatch({
            type: SET_ESSAY_TAGS,
            value: essayData.data[0].tags,
          });

          if (
            essayData.data[0].competitionId !== null &&
            essayData.data[0].isCompeted
          ) {
            console.log(essayData.data[0]);
            console.log(essayData.data[0].competitionId);
            // In case of the essay participated Competition
            await competitionApi
              .getCompetitions([essayData.data[0].competitionId])
              .then(competition => {
                console.log(competition.data[0]);
                // BAD CODE: 위의 setState부분을 반복하지 않으면 에디터 데이터라 로드되지 않음.
                setEssayDataLocal({
                  ...essayDataLocal,
                  id: essayData.data[0]._id,
                  title: essayData.data[0].title,
                  content: JSON.parse(essayData.data[0].content),
                  tags: essayData.data[0].tags,
                  isCompeted: true,
                  competitionData: competition.data[0],
                  competitionId: essayData.data[0].competitionId,
                });
                essayDispatch({
                  type: SET_IS_COMPETED,
                  value: true,
                });
                essayDispatch({
                  type: SET_COMPETITION_ID,
                  value: essayData.data[0].competitionId,
                });
                essayDispatch({
                  type: SET_COMPETITION_DATA,
                  value: competition.data[0],
                });
              });
          }
        });
      }
      // In case Write by Competition Banner
      else if (queryObj['?competitionId'] !== undefined) {
        await competitionApi
          .getCompetitions([queryObj['?competitionId']])
          .then(competition => {
            console.log(competition.data[0]);
            setEssayDataLocal({
              ...essayDataLocal,
              isCompeted: true,
              competitionId: queryObj['?competitionId'],
              competitionData: competition.data[0],
            });
            essayDispatch({
              type: SET_IS_COMPETED,
              value: true,
            });
            essayDispatch({
              type: SET_COMPETITION_ID,
              value: competition.data[0]._id,
            });
            essayDispatch({
              type: SET_COMPETITION_DATA,
              value: competition.data[0],
            });
          });
      }
    } catch {
      // No Action
    } finally {
      setLoading(false);
    }
  };

  // Sync LocalEssayData with ContextEssayData
  const getEssayContext = () => {
    setEssayDataLocal({
      id,
      title,
      content,
      tags,
      competitionData,
      isCompeted,
      competitionId,
    });
  };
  
  useEffect(() => {
    fetchData();
    getEssayContext();
  }, []);

  // // for context after mounted
  // useEffect(() => {
  //   console.log(essayDataLocal.title);
  //   essayDispatch({ type: SET_ESSAY_TITLE, value: essayDataLocal.title });
  //   essayDispatch({ type: SET_ESSAY_CONTENT, value: essayDataLocal.content });
  // }, [essayDataLocal]);

  function keyDownHandler(e) {
    if ((e.key === 'ArrowUp' && e.shiftKey) 
    ||(e.key === 'ArrowDown' && e.shiftKey)){
      e.stopPropagation();
    }

    if(e.keyCode === 32 ){
      console.log('space');
      e.stopPropagation();
    }
  }

  useEffect(() => {
   window.addEventListener('keydown', keyDownHandler, true);
   return () => {
       window.removeEventListener('keydown', keyDownHandler);
   }
  },[]);

  const [isNextSave, setIsNextSave] = useState(false);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container
          className="flex_col col1 col_grid_mobile"
        >
          <Prompt
            when={!isAttemptSave&&isChange}
            message={(location) => {
              return `작성중인 글이 저장되지 않았습니다. 현재 페이지를 떠나시겠습니까?`;
            }}
          />
          {
            false?(
              <div className='flex_col col1'>
                <img style={{width:'100%', height:'auto', marginTop:'-64px'}} src={ImgCat} alt='' />
                <p className="regular body text_center padding_2x">
                  모바일용 에디터는 현재 최적화 작업중입니다.
                  {' '}
                  에세이 쓰기는 
                  {' '}
                  <span className='primary bold'>데스크탑 </span>
                  {' '}
                  또는 
                  {' '}
                  <span className='primary bold'>노트북</span>
                  을 사용해 주시면 감사하겠습니다.
                </p>
                <div className="flex_row col1_m space_between">
                  <BttBig
                    type="outlined"
                    className="col2"
                    to={`/`}
                    title="메인으로 가기"
                  />
                  <BttBig
                    type="filled_primary"
                    className="col2"
                    to={`/competitions`}
                    title="망월장 보러가기"
                  />
                </div>
              </div>
            ):(
              <div className="col_editor center_margin margin_b_4x">
                {essayDataLocal.isCompeted && (
                <BannerWriteForCompetition
                  className="margin_b_3x"
                  competition={essayDataLocal.competitionData}
                />
            )}
                <input
                  className="title col1 margin_b_4x"
                  placeholder="제목을 작성해주세요"
                  onChange={setTitle}
                  value={essayDataLocal.title}
                  type="text"
                />
                <div className="editor col1 margin_b_2x">
                  <React.StrictMode>
                    <Dante
                      body_placeholder={'글을 작성해주세요'}
                      
                      widgets={[
                    ImageBlockConfig({
                      options: {
                        upload_url: `${config.apiUrl}/upload`,
                        upload_callback: (ctx, img) => {
                          console.log(ctx.data);
                          alert(`file uploaded: ${ctx.data.url}`);
                        },
                        upload_error_callback: (ctx, img) => {
                          console.log(ctx);
                        },
                      },
                    }),
                  ]}
                      onChange={editor => {
                        const text = getContentText(editor.emitSerializedOutput());
                        const lengthChar = chkStrLength(text);
                     // console.log(lengthChar);
                        setContent(editor.emitSerializedOutput());
                        setNumCurrentWords(lengthChar);
                        essayDispatch({
                          type: SET_NUM_CURRENT_WORDS,
                          value: numCurrentWords,
                        });
                        if (numMaxWords < numCurrentWords) {
                          setIsOverFlow(true);
                        } else {
                          setIsOverFlow(false);
                        }
                      }}
                    />
                    <div contentEditable={true} className={'col_editor'} />
                  </React.StrictMode>
                </div>
                <div id="bottom_action_bar" className="flex_row  space_between">
                  {/* Essay Length Count */}
                  <AmountGuide>
                    <p
                      className={`body_s regular margin_b_1x ${
                    isOverFlow ? 'primary' : 'black300'
                  }`}
                    >
                      {!isOverFlow
                    ? '2200자 이하의 글만 \n 에세이로 출판 가능합니다.'
                    : '2200자가 넘었습니다. 출판전에 글자수를 줄여주세요.'}
                    </p>
                    <p className="body_s regular black300">
                      <span className="primary">{numCurrentWords}</span>
                        &nbsp;/&nbsp;
                      <span>{numMaxWords}</span>
                    </p>
                  </AmountGuide>
                </div>
              </div>
            )
          }
        </Container>
      )}
      {isSave && <Redirect to="/save" />}
      {isDelete && <Redirect to="/mypage" />}
    </>
  );
}

export default WriteTestPresenter;
