import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// material ui
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { useUserState } from '../../../context/currentUserContext';

// Component
import BttActionBig from '../../Buttons/ButtonAction/BttActionBig';
import Competition from '../../../_models/competition';

// Api
import { adminApi, alarmApi } from '../../../api/api';

// Img
import IcMore from '../../../assets/img/ic/ic_more_tool_primary.svg';

const BttMore = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const DropDown = styled.div`
  position: absolute;
  z-index:99;
  top: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.24);
`;

const Menu = styled.button`
  width: 128px;
  padding: 8px 24px;
  color: ${props => props.theme.color.tertiary300};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.size.bttText};
`

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

function CardFullCurrentCompetitionAdmin({
  competition,
  className,
  children,
  endCompetition,
  isShowMore,
  setIsShowMore
}) {
  // State from user context
  const { username } = useUserState();

  // SNACK BAR CONTROL
  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });

  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  const deleteBrandedByAdmin = async (data) => {
    openSnackBar('해당 브랜드 스토리이 삭제되었습니다.');
    const id = data._id;
    console.log(id);
    await adminApi.deleteBranded(data).then(res => {
      console.log(res);
    })
  };

  // const resendCompetitionAlarm = async (data) => {
  //   const id = data._id;
  //   console.log(id);

  //   // 백일장 시작 알람 생성
  //   await alarmApi.competitionStartAlarm(competition).then(async res => {
  //     console.log(res);
  //     openSnackBar('브랜드 스토리 알람이 재 전송되었습니다.');
  //   });
  // };

  return (
    <div className={`flex_col flex_center col1 ${className}`}>
      <h1 className="primary bold margin_b_2x">{competition.question}</h1>
      {competition.participantList.length > 0 ? (
        <>
          <p className="body black300 text_center regular col1 margin_b_1x">{`${competition.participantList[0].username} 외 ${competition.participantList.length}명이 참여중입니다.`}</p>
          <div className="flex_row flex_center col1 margin_b_2x">
            {competition.participantList.map((user, i) => {
              return (
                <div
                  className={`avatar_m flex_col flex_center ${i < competition.participantList.length - 1
                      ? 'margin_r_1x'
                      : ''
                    }`}
                  key={user._id}
                >
                  {user.username[0] + user.username[1]}
                </div>
              );
            })}
          </div>
        </>
      ) : (
          username !== null && (
            <>
              <p className="body_s black300 text_center regular col1 margin_b_1x">
                아직 참가자가 없습니다.
            </p>
            </>
          )
        )}
      {
        !competition.isEnd &&
        <BttActionBig
          onClick={endCompetition}
          type="outlined"
          title="브랜드 스토리 종료하기"
        />
      }
      {/* More function of Essay */}
      <DropDownContainer>
        <BttMore onClick={() => { setIsShowMore(true) }}>
          <img src={IcMore} alt='' />
        </BttMore>
        {
          isShowMore && (
            <>
              <DropDown>
                {/* <Menu
                  onClick={() => { resendCompetitionAlarm(competition) }}
                >
                  브랜드 스토리 알람 재전송
                </Menu> */}
                <Menu
                  onClick={() => { deleteBrandedByAdmin(competition) }}
                >
                  브랜드 스토리 삭제
              </Menu>
                <Link to={`/admin/editBranded/${competition._id}`}>
                  <Menu>브랜드 스토리 수정</Menu>
                </Link>
              </DropDown>
              <FakeBackDrop
                onClick={() => {
                  setIsShowMore(false);
                }}
              />
            </>
          )
        }
      </DropDownContainer>
      {children}
      {/* 스낵바 */}
      <Snackbar
        open={snackBarControl.open}
        autoHideDuration={1000}
        onClose={closeSnackBar}
        TransitionComponent={Fade}
        message={snackBarControl.message}
      />
    </div>
  );
}

CardFullCurrentCompetitionAdmin.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  endCompetition: PropTypes.func.isRequired,
};

export default CardFullCurrentCompetitionAdmin;
