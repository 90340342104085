import React from 'react';
import styled from 'styled-components';

import AvatarM from '../../Avatar/AvatarM';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const UserName = styled.p`
  color: ${props => props.isDark?props.theme.color.ui.white700:props.theme.color.ui.middle2} !important;
  ${props => props.theme.type.caption2}
`;

const WriterInfo = ({
  writer,
  isDark
}) => {
  return (
    <Container>
      <UserName isDark={isDark}>
        {`by ${writer.username}`}
      </UserName>
      <AvatarM
        to={`/user/${writer.username}`}
        writer={writer}
        isDark={isDark}
      />
    </Container>
  );
}

export default WriterInfo;