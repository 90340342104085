import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Competition from '../../../_models/competition';

// Button
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttBigExtraFixedWidth from '../../Buttons/ButtonLink/BttBigExtraFixedWidth';
import AvatarM from '../../Avatar/AvatarM';

const Container = styled.div`
  position: relative;
  /* height: 380px; */
  padding-bottom: ${props => props.theme.size.space.medium2};
  @media screen and (max-width: 480px){
    height: auto;
    padding-bottom: ${props => props.theme.size.space.medium1};
  }
`;

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const Overline = styled.p`
  ${props => props.theme.type.overline};
`;

const Title = styled(Link)`
  cursor: pointer;
  display: inline-block;
  word-break: keep-all;
  width: 100%;
  height: 84px;
  ${props => props.theme.type.h3};
  margin-bottom: 74px;
  @media screen and (max-width: 480px){
    height: auto;
    margin-bottom: 44px;
  }
`;

const Exp = styled.p`
  ${props => props.theme.type.body2}
  color: ${props => props.theme.color.ui.strong};
  margin-bottom: ${props => props.theme.size.gridSmall.x3};
  height: 48px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
    margin-bottom: ${props => props.theme.size.gridSmall.x3};
  }
`;

const Participants = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;

const BottomActionBar = styled.div`
  /* position: absolute; */
  bottom: 20px;
  left: 0;
  width: calc(100% - 0px);
  @media screen and (max-width: 480px){
    position: static;
    margin-top: 20px;
    width: 100%;
    ${props => props.theme.layout.flexRowCenterBlock}
  }
`;

const Center = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
`;

const CardBigCompetition = ({ competition, username, className }) => {

  const path = `${competition.year}-${competition.month}-${competition.week}`;

  return (
    <Container className={"space_between flex_row col1 " + className}>
      <ContentSection className='flex_col'>
        <Overline>
          망월장 쓰기
        </Overline>
        <Title
          to={`/competition/${path}`}
          className="primary bold"
        >
          {competition.question}
        </Title>
        <Exp>
          {`${competition.master.name}님이 던지는 질문입니다.`}
        </Exp>
        <Participants>
          {competition.participantList.length > 0 ? (
            <>
              {
                competition.participantList.length === 1 ? (
                  <>
                    {`${competition.participantList[0].username}님이 참여중입니다.`}
                  </>
                ) : (
                    <>
                      {`${competition.participantList[0].username} 외 ${competition.participantList.length - 1}명이 참여중입니다.`}
                    </>
                  )
              }
            </>
          ) : (
              username !== null && (
                <>
                  {`'${username}'님 아직 참가자가 없습니다.`}
                  <br />
                  {`${competition.month}월 ${competition.week}주차 망월장의 첫번째 참가자가 되어 보세요!`}
                </>
              )
            )}
        </Participants>
      </ContentSection>
      <BottomActionBar className="flex_row">
        {
          username === null ? (
            <Center>
              <BttBigExtraFixedWidth
                width={'256px'}
                type="filled_primary"
                className=""
                to={`/competition/${path}`}
                title="에세이 쓰기"
              />
            </Center>
          ) : (
              <>
                <BttBigExtraFixedWidth
                  type="outlined"
                  className="col2"
                  to={`/competition/${path}`}
                  title="투표하기"
                />
                <BttBigExtraFixedWidth
                  type="filled_primary"
                  className="col2"
                  to={`/competition/${path}`}
                  title="에세이 쓰기"
                />
              </>
            )
        }

      </BottomActionBar>
    </Container>
  );
};

CardBigCompetition.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  username: PropTypes.string.isRequired,
};

export default CardBigCompetition;
