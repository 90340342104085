import React from 'react';
import CompetitionFeedPresenter from './CompetitionFeedPresenter';
import { useUserState } from '../../context/currentUserContext';

function CompetitionFeedContainer({ location }) {
  const { loaded, loged, username, userData, interestedTag } = useUserState();
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <CompetitionFeedPresenter
          loged={loged}
          location={location}
          username={username}
          userData={userData}
          interestedTag={interestedTag}
        />
      </>
    )
  );
}

export default CompetitionFeedContainer;
