import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const T1 = styled.span`
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.h2.size};
  line-height: ${props => props.theme.type.h2.height};
  color: ${props => props.theme.color.primary500};
`;

const T2 = styled.span`
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.h2.size};
  line-height: ${props => props.theme.type.h2.height};
  color: ${props => props.theme.color.tertiary500};
`;

function FeedTitle({ type, listTitle, title }) {
  const { length } = listTitle;
  return (
    <p
      className="flex_row col1 flex_center padding_ver_1x margin_b_1x"
      style={{ position: 'relative' }}
    >
      {listTitle.length > 0 &&
        listTitle.map((item, i) => (
          <>
            <T1>
              {(type === 'tag' ? item.tagName : '') +
                (type === 'user' ? item.username : '') +
                (i !== length - 1 ? ', ' : '')}
            </T1>
          </>
        ))}
      <T2>{title}</T2>
    </p>
  );
}

FeedTitle.propTypes = {
  type: PropTypes.string.isRequired,
  listTitle: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string))
    .isRequired,
  title: PropTypes.string.isRequired,
};

export default FeedTitle;
