const layout = {
  liveArea: {
    width: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  liveAreaSmall: {
    width: '948px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  sectionBlock: `
    width: 100%;
    margin-bottom: 80px;
    @media screen and (max-width: 480px) {
      margin-bottom: 68px;
    }
  `,
  sectionBttMoreBlock: `
    position: absoulte;
    right:0;
    bottom:0;
  `,
  flexRowBlock: `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  flexRowAlignCenterBlock: `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  flexColCenterBlock: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  flexRowCenterBlock: `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  `,
  containerBlock: `
    padding-top: 24px;
    padding-bottom: 0px;
    @media screen and (max-width: 480px) {
      padding-top: 16px;
    }
  `,
  gridHorLine: `
    content: '';
    position: absolute;
    bottom: 0;
    left:0;
    height:1px;
    width:calc(100%);
    background-color: #9b9bdf;
  `,
  gridVerRightLine: `
    content: '';
    position: absolute;
    top: 0px;
    right:-24px;
    height: calc(100% - 24px);
    width: 1px;
    background-color: #9b9bdf;
    @media screen and (max-width: 480px) {
      right:-16px;
      height: calc(100% - 16px);
    }
  `,
  parHorLine: `
    content: '';
    position: absolute;
    bottom: 0;
    left:0;
    height:1px;
    width:calc(100%);
    background-color: #9b9bdf;
  `,
  parVerRightLine: `
    content: '';
    position: absolute;
    top: 0px;
    right:-24px;
    height: calc(100% - 0px);
    width: 1px;
    background-color: #9b9bdf;
  `,
}

export default layout;