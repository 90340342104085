/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';

import ContainerC from '../Layout/Container/ContainerC'
import TextBannerTitle from '../Layout/Title/TextBannerTitle';
import BttAction from '../Buttons/ButtonAction/BttAction';

const Container = styled(ContainerC)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  /* margin-bottom: 48px; */
  @media screen and (max-width: 480px) {
    padding-bottom: 16px;
    /* margin-bottom: 32px; */
  }
  button{
    margin-top: 16px;
  }
`;

const BannerWriteForBranded = ({
  competition,
  isVoted,
  voteEssay
}) => (
    <Container>
      <TextBannerTitle
        title1={`${competition.month}월 ${competition.week}주차 망월장 참가글 `}
        title2={competition.question}
      />
      {
        !competition.isEnd &&
        <BttAction
          type={isVoted ? 'filled_primary' : 'outlined'}
          onClick={voteEssay}
          title={isVoted ? '위 에세이에 투표함' : '위 에세이에 1표 주기'}
        />
      }
    </Container>
  );


export default BannerWriteForBranded;
