import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TagInteractive from '../../components/Tag/TagInteractive';
import EssayListModal from '../../template/Modal/EssayListModal';

// Api
import { essayApi } from '../../api/api';

const Container = styled.div`
  height: 44px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;
  overflow: hidden;
  button{
    margin: 0 0 4px 4px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
  }
`;


const TagListOnPost = ({ tagList }) => {

  const [tagedEssayLoading, setTagedEssayLoading] = useState(false);
  const [tagedEssayList, setTagedEssayList] = useState([]);
  const [searchedTag, setSearchedTag] = useState(null);

  const getTagedEssayList = async tagName => {
    try {
      setSearchedTag(tagName);
      setTagedEssayLoading(true);
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [tagName],
          pages: 1,
          size: 30,
        })
        .then(
          essayList => {
            if (essayList.status != 404) {
              console.log(essayList.data);
              setTagedEssayList(essayList.data);
            }
          },
          error => {
            console.log(error);
          },
        );
    } catch {
    } finally {
      setTagedEssayLoading(false);
      document.body.style.overflow = 'hidden';
    }
  };

  const resetTagedEssay = () => {
    setSearchedTag(null);
    document.body.style.overflow = 'auto';
  };


  return (
    <Container>
      {
        tagList.length > 0 && tagList.map((tag, i) => {
          if (tag !== null && tag.count>1) {
            return <TagInteractive
              key={i}
              onClick={() => {
                getTagedEssayList(tag.tagName);
              }}
              >
                {`${tag.tagName} `}
                <span>
                {`(${tag.count})`}
                </span>
              </TagInteractive>
          }
        })
      }
      {/* 검색된 태그 에세이 보여주기 */}
      {searchedTag && !tagedEssayLoading && 0 < tagedEssayList.length && (
        <EssayListModal
          tagedEssayList={tagedEssayList}
          resetTagedEssay={resetTagedEssay}
        />
      )}
    </Container>
  )
}

export default TagListOnPost;