import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Button
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttBigExtraFixedWidth from '../../Buttons/ButtonLink/BttBigExtraFixedWidth';
import AvatarM from '../../Avatar/AvatarM';

const Container = styled.div`
  position: relative;
  /* height: 380px; */
  padding-bottom: ${props => props.theme.size.space.medium2};
  @media screen and (max-width: 480px){
    height: auto;
    padding-bottom: ${props => props.theme.size.space.medium1};
  }
`;

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const Overline = styled.p`
  ${props => props.theme.type.overline};
`;

const Title = styled(Link)`
  cursor: pointer;
  display: inline-block;
  word-break: keep-all;
  width: 100%;
  height: 84px;
  ${props => props.theme.type.h3};
  margin-bottom: 74px;
  @media screen and (max-width: 480px){
    height: auto;
    margin-bottom: 44px;
  }
`;

const Exp = styled.p`
  ${props => props.theme.type.body2}
  color: ${props => props.theme.color.ui.strong};
  margin-bottom: ${props => props.theme.size.gridSmall.x3};
  height: 48px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
    margin-bottom: ${props => props.theme.size.gridSmall.x3};
  }
`;

const Participants = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;

const BottomActionBar = styled.div`
  /* position: absolute; */
  bottom: 20px;
  left: 0;
  width: calc(100% - 0px);
  ${props => props.theme.layout.flexRowCenterBlock}
  @media screen and (max-width: 480px){
    position: static;
    margin-top: 20px;
    width: 100%;
    ${props => props.theme.layout.flexRowCenterBlock}
  }
`;

const Center = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
`;

const BrandAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 8px;
`;

const CardInformBranded = ({ brandedList, username, className }) => {


  return (
    <Container className={"space_between flex_row col1 " + className}>
      <ContentSection className='flex_col'>
        <Overline>
          {/* 그런데 '또' 말이죠 */}
          <br />
        </Overline>
        <Title
          to={`/branded/`}
          className="primary bold"
        >
          '우리가 사랑하는 것들'은 무엇인가요?
        </Title>
        <Exp>
          {/* {
            brandedList.map((branded) =>
              <BrandAvatar
                src={branded.master.profile}
              />
            )
          } */}
        </Exp>
        <Participants>
          {/* {
            brandedList.length === 1 ? `${brandedList[0].master.name}가 \n여러분의 이야기를 지켜보고 있습니다.` :
              `${brandedList[0].master.name}외 ${brandedList.length - 1}개의 브랜드가 \n여러분의 이야기를 지켜보고 있습니다.`
          } */}

        </Participants>
      </ContentSection>
      <BottomActionBar className="flex_row">
        <BttBigExtraFixedWidth
          width={'256px'}
          type="outlined"
          className=""
          to={`/AboutWeLove`}
          title={`우리가 사랑하는 것들 알아보기`}
        />
      </BottomActionBar>
    </Container>
  );
};


export default CardInformBranded;
