/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ic_cancel from '../../assets/img/ic/ic_remove_primary300.svg';

const Cancel = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  margin-left: 0px;
  img {
    width: 16px;
    height: 16px;
  }
`;

const ChipBasic = ({ title, className, onClickRemove }) => {
  return (
    <div className={`flex_row align_center tooltip_outlined_dark ${className}`}>
      <span>{title}</span>
      <Cancel
        onClick={() => {
          onClickRemove(title);
        }}
        className="flex_center"
      >
        <img src={ic_cancel} alt="" />
      </Cancel>
    </div>
  );
};

ChipBasic.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func.isRequired,
};

export default ChipBasic;
