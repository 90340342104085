import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Button
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttBigExtraFixedWidth from '../../Buttons/ButtonLink/BttBigExtraFixedWidth';
import AvatarM from '../../Avatar/AvatarM';

const Container = styled.div`
  position: relative;
  /* height: 380px; */
  padding-bottom: ${props => props.theme.size.space.medium2};
  @media screen and (max-width: 480px){
    height: auto;
    padding-bottom: ${props => props.theme.size.space.medium1};
  }
`;

const ContentSection = styled.div`
  width: calc(100% - 224px);
  @media screen and (max-width: 480px){
    width: 100%;
  }
`;

const ImageSection = styled.div`
  width: 224px;
  height: 232px;
  background-image: url(${props => props.image});
  background-size: contain;
  background-position: center 100%;
  background-repeat: no-repeat;
  /* img{
    width: 100%;
    height: auto;
    object-fit: cover;
  } */
  @media screen and (max-width: 480px){
    display: hidden;
  }
`;

const Overline = styled.p`
  ${props => props.theme.type.overline};
`;

const Title = styled(Link)`
  cursor: pointer;
  display: inline-block;
  word-break: keep-all;
  width: 100%;
  height: 84px;
  ${props => props.theme.type.h3};
  margin-bottom: 74px;
  @media screen and (max-width: 480px){
    height: auto;
    margin-bottom: 44px;
  }
`;

const Exp = styled.p`
  ${props => props.theme.type.body2}
  font-family: ${props => props.theme.type.regular};
  font-family:  -apple-system;
  color: ${props => props.theme.color.ui.strong};
  margin-bottom: ${props => props.theme.size.gridSmall.x3};
  height: 48px;
  margin-bottom: 24px;
  white-space: pre-line;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
    margin-bottom: ${props => props.theme.size.gridSmall.x3};
  }
`;

const Participants = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  margin-bottom: ${props => props.theme.size.gridSmall.x2};
  height: 44px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px){
    margin-bottom: 16px;
  }
`;

const BottomActionBar = styled.div`
  /* position: absolute; */
  bottom: 20px;
  left: 0;
  width: calc(100% - 0px);
  ${props => props.theme.layout.flexRowCenterBlock}
  @media screen and (max-width: 480px){
    position: static;
    margin-top: 20px;
    width: 100%;
    ${props => props.theme.layout.flexRowCenterBlock}
  }
`;

const Center = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexColCenterBlock}
`;

const CardBigBranded = ({ branded, username, className }) => {


  return (
    <Container className={"space_between flex_row col1 " + className}>
      <ContentSection className='flex_col'>
        <Overline>
          {/* {`${branded.master.name} 이야기`} */}
          우사것 쓰기
        </Overline>
        <Title
          to={`/branded/${branded._id}`}
          className="primary bold"
        >
          {branded.question}
        </Title>
        <Exp>
          {`${branded.master.name + ' '}와 함께 합니다.`}
        </Exp>
        <Participants>
          {/* 진행중 */}
          {
            !branded.isEnd &&
            <>
               {/* 참가자가 없을경우 */}
              {username !== null && branded.participantList.length === 0 && (
                <>
                  {`'${username}'님 아직 참가자가 없습니다.`}
                  <br />
                  {`첫번째 참가자가 되어 보세요!`}
                </>
              )}
              {/* 1명의 참가자가 있을 경우 */}
              {branded.participantList.length === 1 &&
                <>
                  {`${branded.participantList[0].username}님이 참여중입니다.`}
                </>
              }
              {/* 1명 이상의 참가자가 있을 경우 */}
              {branded.participantList.length > 1 &&
                <>
                  {`${branded.participantList[1].username} 외 ${branded.participantList.length - 1}명이 참여중입니다.`}
                </>
              }
            </>
          }
           {/* 종료 */}
           {
            branded.isEnd &&
            <>
               {/* 참가자가 없을경우 */}
              {username !== null && branded.participantList.length === 0 && (
                <>
                </>
              )}
              {/* 1명의 참가자가 있을 경우 */}
              {branded.participantList.length === 1 &&
                <>
                  {`${branded.participantList[0].username}님이 이야기를 남겼습니다.`}
                </>
              }
              {/* 1명 이상의 참가자가 있을 경우 */}
              {branded.participantList.length > 1 &&
                <>
                  {`${branded.participantList[1].username} 외 ${branded.participantList.length - 1}명이 이야기를 남겼습니다.`}
                </>
              }
            </>
          }

        </Participants>
      </ContentSection>
      <ImageSection image={branded.image}>

      </ImageSection>
      <BottomActionBar className="flex_row">
        {
          username === null ? (
            <Center>
              <BttBigExtraFixedWidth

                type="filled_primary"
                className=""
                to={`/branded/${branded._id}`}
                title={branded.isEnd ? `에세이 보러가기` : `에세이 쓰기`}
              />
            </Center>
          ) : (
              <>
                <BttBigExtraFixedWidth
                  width={'256px'}
                  type="filled_primary"
                  className=""
                  to={`/branded/${branded._id}`}
                  title={branded.isEnd ? `에세이 보러가기` : `에세이 쓰기`}
                />
              </>
            )
        }

      </BottomActionBar>
    </Container>
  );
};


export default CardBigBranded;
