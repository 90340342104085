import React from 'react';
import styled from 'styled-components';
import BttActionFixedWidth from '../../components/Buttons/ButtonAction/BttActionFixedWidth';

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.brand.primary50};
  padding: 24px;
  ${props => props.theme.layout.flexRowCenterBlock}
  button{
    margin: 8px;
  }
`;

const Exp = styled.p`
  width: 100%;
  color: ${props => props.theme.color.brand.primary300};
  ${props => props.theme.type.body3}
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
`;

function EssayPostAdminActionBar({
  essayData,
  setEditorPick,
  setCompetitionPrized,
  setBrandedPrized,
  setText,
  title
}) {
  console.log(setText);
  return (
    <Container>
      <Exp>
        <strong>브랜디드 스토리 참여작 관리: </strong>관리자에게만 보이는 영역입니다....
      </Exp>
      {/* 망월장 참여작 관리 */}
      {
        essayData.isCompeted &&
        <>
          <BttActionFixedWidth
            onClick={() => {
              setEditorPick();
            }}
            width={'224px'}
            type={essayData.recommended === true ? 'filled_primary' : 'outlined'}
            title={essayData.recommended === true ? '에디터 픽' : '에디터 픽하기'}
          />
          <BttActionFixedWidth
            onClick={() => {
              setCompetitionPrized();
            }}
            width={'224px'}
            title2={'222'}
            type={essayData.isPrized === true ? 'filled_primary' : 'outlined'}
            title={essayData.isPrized === true ? '망월장 당선작' : '망월장 당선하기'}
          />
        </>
      }
      {/* 브랜디드 스토리 참여작 관리 */}
      {
        essayData.isBranded &&
        <>
          <BttActionFixedWidth
            onClick={() => {
              setEditorPick();
            }}
            width={'224px'}
            type={essayData.recommended === true ? 'filled_primary' : 'outlined'}
            title={essayData.recommended === true ? '에디터 픽' : '에디터 픽하기'}
          />
          <BttActionFixedWidth
            onClick={() => {
              console.log(setBrandedPrized);
              console.log(setText);
              // setBrandedPrized();
            }}
            width={'224px'}
            type={essayData.isBrandedPrized === true ? 'filled_primary' : 'outlined'}
            title={essayData.isBrandedPrized === true ? '브랜디드 스토리 당선작' : '브랜디드 스토리 당선하기'}
          />
        </>
      }
    </Container>
  );
}

export default EssayPostAdminActionBar;
