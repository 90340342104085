import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmMD({ alarm, setAlarmRead }) {
  const mark = 'B';
  let essay;

  if (alarm.contentObject) {
    [essay] = alarm.contentObject;

    console.log(alarm.mark);
  }

  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id)}
      className={`col1 ${alarm.isRead ? 'white_bg' : 'white_600bg'}`}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender=""
        msg="회원님이 노트 마감이 2일 남았습니다."
        content={essay.title}
      />
    </button>
  );
}

ListAlarmMD.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmMD;
