import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const T1 = styled.p`
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.subtitle2.size};
  line-height: ${props => props.theme.type.subtitle2.height};
  color: ${props => props.theme.color.tertiary500};
`;

const T2 = styled.p`
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.subtitle2.size};
  line-height: ${props => props.theme.type.subtitle2.height};
  color: ${props => props.theme.color.tertiary500};
`;

function SectionTitle({ children, value, title1, title2 }) {
  return (
    <div
      className="flex_col col1 align_center padding_ver_1x"
      style={{ position: 'relative' }}
    >
      <T1>
        <span className="primary">{value}</span>
        {title1}
      </T1>
      <T2>{title2}</T2>
      {children}
    </div>
  );
}

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
};

export default SectionTitle;
