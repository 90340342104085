/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useRouteMatch
} from 'react-router-dom';
import styled from 'styled-components';

// Page Component
// Competition
import CreatNewCompetition from '../CreatNewCompetition/';
import ManageCompetition from '../ManageCompetition/';
import EditCompetition from '../EditCompetition/';
import CompetitionDetail from '../CompetitionDetail/';
import ManageBranded from '../ManageBranded/';
import EditBranded from '../EditBranded/';
import BrandedDetail from '../BrandedDetail/';

// Branded
import CreatNewBranded from '../CreatNewBranded/';

// User Data
import ExploreTag from '../ExploreTag/';
import ExploreEssay from '../ExploreEssay/';
import ExploreUser from '../ExploreUser';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-top: -80px;
`;

const MenuArea = styled.div`
  width: calc(256px - 80px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid ${props => props.theme.color.brand.primary300};
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(256px - 80px);
  padding-left: 16px;
  padding-top: 48px;
  padding-right: 24px;
  /* margin-right: 96px; */
  position: fixed;
  top: 90px;
  left: calc(50% - 1195px/2);
  
`
const RouteArea = styled.div`
  width: calc(100% - 424px);
  width: 100%;
  padding-left: 212px;
  padding-top: 64px;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
  }
`;

const MenuLink = styled(Link)`
  width: 100%;
  padding: 24px 8px;
  color: ${props => props.theme.color.ui.middle1};
  ${props => props.theme.type.btt1}
  text-align: left;
`;

// From Material UI
function AdminMainPresenter({userData}) {

  let { path, url } = useRouteMatch();
  
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    if(userData===null || userData.role!=='admin'){
      setIsAdmin(false);
    }else{
      setIsAdmin(true);
    }
  },[isAdmin])
  const AdminMenuList = [
    {
      title: '망월장 생성',
      path: 'creatCompetition'
    },
    {
      title: '망월장 관리',
      path: 'manageCompetition'
    },
    {
      title: '브랜드 스토리 생성',
      path: 'creatBranded'
    },
    {
      title: '브랜드 스토리 관리',
      path: 'manageBranded'
    },
    {
      title: '유저 탐색',
      path: 'explore'
    },
    {
      title: '태그 탐색',
      path: 'exploreTags'
    },
    {
      title: '모든 에세이',
      path: 'ExploreEssay'
    },
    {
      title: '유저탐색',
      path: 'ExploreUser',
    }
  ]
  return (
    <Container>
      {
        // isAdmin===false &&
        false &&
        <Redirect to={'/'} />
      }
      <MenuArea>
        <MenuList>
          {
            AdminMenuList.map((menu) => 
              <MenuLink to={`${path}/${menu.path}`}>
                {menu.title}
              </MenuLink>
            )
          }
        </MenuList>
      </MenuArea>
      <RouteArea>
        <Switch>
          <Route path={`${path}`} exact component={CreatNewCompetition} />
          <Route path={`${path}/creatCompetition`} exact component={CreatNewCompetition} />
          <Route path={`${path}/manageCompetition`} exact component={ManageCompetition} />
          <Route path={`${path}/editCompetition/:date`} exact component={EditCompetition} />
          <Route path={`${path}/creatBranded`} exact component={CreatNewBranded} />
          <Route path={`${path}/manageBranded`} exact component={ManageBranded} />
          <Route path={`${path}/editBranded/:id`} exact component={EditBranded} />
          <Route path={`${path}/exploreTags`} exact component={ExploreTag} />
          <Route path={`${path}/competitionDetail/:date`} exact component={CompetitionDetail} />
          <Route path={`${path}/brandedDetail/:date`} exact component={BrandedDetail} />
          <Route path={`${path}/ExploreEssay`} exact component={ExploreEssay} />
          <Route path={`${path}/ExploreUser`} exact component={ExploreUser} />
        </Switch>
      </RouteArea>
    </Container>
  );
}

export default AdminMainPresenter;