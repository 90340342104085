/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import gtag from 'ga-gtag';
import { Link, withRouter, Redirect, useHistory, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

// Api
import { sessionApi, mypageApi, essayApi } from '../../api/api';

// Theme
import themeNew from '../../assets/theme/themeNew';

// Context
import {
  SET_TEST_VER,
  LOG_IN_MAINTAIN,
  LOG_OUT,
  LOG_IN_FAILED,
  SET_ESSAY_ID,
  SET_ESSAY_TITLE,
  SET_ESSAY_CONTENT,
  SET_ESSAY_CONTENT_TEXT,
  SET_ESSAY_TAGS,
  SET_IS_COMPETED,
  SET_COMPETITION_ID,
  SET_COMPETITION_DATA,
  SET_NUM_CURRENT_WORDS,
  RESET_ESSAY,
  LOAD_USER_DATA,
  SET_AD_ID,
  SET_AD_TITLE,
  SET_AD_CONTENT,
  SET_AD_TAGS,
  SET_NUM_CURRENT_WORDS_AD,
  SET_MEDIATYPE,
  RESET_AD,
  SET_IS_PUBLISHED,
  SET_ATTEMPT_SAVE,
} from '../../actions';

import {
  useUserState,
  useUserDispatch,
} from '../../context/currentUserContext';
import {
  useEssayState,
  useEssayDispatch,
} from '../../context/currentEssayContext';
import { useInsState, useInsDispatch } from '../../context/currentInsContext';

// Component
import LiveArea from '../Layout/LiveArea';
import BttAction from '../Buttons/ButtonAction/BttAction';
import BttActionFixedWidth from '../Buttons/ButtonAction/BttActionFixedWidth';
import AvatarM from '../Avatar/AvatarM';
// import BttBasic from '../Buttons/ButtonLink/BttBasic';

// IMG
import logo_img from '../../assets/img/logo/blbl_logo.svg';
import logo_img_white from '../../assets/img/logo/blbl_logo_white.svg';
import logo_simple_img from '../../assets/img/logo/blbl_logo_simple.svg';
import ic_search from '../../assets/img/ic/ic_search.svg';
import ic_alarm from '../../assets/img/ic/ic_alarm.svg';
import ic_alarm_white from '../../assets/img/ic/ic_alarm_white.svg';
import ic_home from '../../assets/img/ic/navigation/ic_home.svg';
import IcMore from '../../assets/img/ic/ic_more_tool_primary.svg';
import img_btt_main from '../../assets/img/shape/bg_write_outline.svg';
import img_btt_main_white from '../../assets/img/shape/bg_write_outline.svg';


import BttBasic from '../Buttons/ButtonLink/BttBasic';


const LiveAreaWide = styled.div`
  width: calc(100%);
  padding-left: 48px;
  padding-right: 48px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.color.brand.primary300};
  border-bottom-width: ${props => props.theme.shape.borderLight};
  @media screen and (max-width: 480px) {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
`;

const GNB = styled.header`
  position: fixed;
  z-index: 999;
  top: 0;
  transition: top 0.3s ease-out;
  left:0;
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in;
  background-color: ${props => props.theme.themeType==='light'?'rgba(252,246,238, 0.92)':props.theme.color.brand.primary700};
  @media screen and (max-width: 480px) {
    /* top: ${props =>
    props.scrollDirection === 'down' ? (
      '-60px'
    ) : (
        '0px'
      )
  }; */
    margin-top: 0px;
    height: 60px;
    padding: 0 20px;
    border-top: none;
  }
`;

const MenuContainer = styled(LiveArea)`
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.color.brand.primary300};
  border-bottom-width: ${props => props.theme.shape.borderLight};
  height: 100%;
  ${props =>
    (
      props.isExtendMode === true ?
        `
          width: 100% !important;
          padding-left: 48px !important;
          padding-right: 48px !important;
          @media screen and (max-width: 480px) {
            padding-left: 0px !important;
            padding-right: 0px !important;
            width: 100%;
          }
        `: ``
    )
  }
`;

const MenuGNB = styled.div`
  ${props => props.theme.layout.flexRowBlock}
  position: relative;
  align-items: center !important;
  height: 100%;
  width: calc(100%/3);
  padding-top: ${props => props.theme.size.gridRegular.x1};
  padding-bottom: 19px;
  &:before{
    opacity: 0.64;
  }
  @media screen and (max-width: 480px){
    width: calc((100% - 116px - 32px)/4);
    padding:0 16px;
    height: 60px;
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .border{
    width: 1px;
    height: 24px;
    background-color: #9b9bdf;
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .border{
    width: 1px;
    height: 24px;
    background-color: #9b9bdf;
  }
`

const MenuWrite = styled.div`
 ${props => props.theme.layout.flexRowBlock}
  position: relative;
  align-items: center !important;
  justify-content: center;
 @media screen and (max-width: 480px){
  width: calc(116px + 32px);
  height: 60px;
 }
`
const MarkAlarm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:0 10px;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.themeType==='light'?props.theme.color.brand.primary700:props.theme.color.ui.white700};
  color: ${props => props.theme.themeType==='dark'?props.theme.color.brand.primary700:props.theme.color.ui.white700};
  font-family: ${props => props.theme.type.regular};
  ${props => props.theme.type.caption2};
  position: absolute;
  top: 0px;
  left: 23px;
`;

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const DropDown = styled.div`
  position: absolute;
  z-index: 99;
  top: 48px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
`;

const Menu = styled.button`
  width: 128px;
  padding: 8px 24px;
  color: ${props => props.theme.color.ui.middle1};
  ${props => props.theme.type.btt1}
  text-align: center;
`;

const MenuLink = styled(Link)`
  width: 128px;
  padding: 8px 24px;
  color: ${props => props.theme.color.ui.middle1};
  ${props => props.theme.type.btt1}
  text-align: center;
`;

const BttMore = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const MainButton = styled(Link)`
  width: 134px;
  height: 48px;
  font-family: sopoqa_han_sans_bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  color: ${props => props.theme.themeType==='light'?props.theme.color.brand.primary700:props.theme.color.ui.white700};
  background-image: ${props => props.theme.themeType==='light'?`url(${img_btt_main})`:`url(${img_btt_main_white})`};
  @media screen and (max-width: 480px) {
    width: 113px;
    height: 40px;
  }
`;

// Design of GNB changed depeond on Route URL from location
export default withRouter(({ location }) => {
  // currentUserContxt에서 갖고온 state;
  const { loged, userData } = useUserState();
  const userDispatch = useUserDispatch();

  // currentEssayContext dispatch;
  const essayDispatch = useEssayDispatch();

  // currentAdContext dispatch;
  const adDispatch = useInsDispatch();

  // state for setting redirect
  const [isRedirect, setRedirect] = useState(false);
  // state for setting simple version of GNB in case of writting page
  const [isSimpleMode, setIsSimpleMode] = useState(false);
  const [isExtendMode, setisExtendMode] = useState(false);

  // state for alarm
  const [numUnreadAlarm, setNumUnreadAlarm] = useState(0);

  // state for saving essay or ad
  const [isWrite, setIsWrite] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isSaveByAdmin, setIsSaveByAdmin] = useState(false);
  const [isSaveIns, setisSaveIns] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // FOR TEST VERSION OF SIGNUP IMPROVE!!
  // const [isVer2, setIsVer2] = useState(false);
  // state for my page option
  const [isShowMore, setIsShowMore] = useState(false);


  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  let alarmList;

  // Logout
  const logout = async () => {
    // localStorage.setItem('username', null);
    await sessionApi.logOut().then(res => {
      currentUser.action.type = LOG_OUT;
      currentUser.username = null;
      userDispatch(currentUser);
      setRedirect(true);
      setRedirect(false);
      setIsAdmin(false);
    });
  };

  // cookie로 내 정보 갖고오기
  const getUserInfo = async () => {
    await mypageApi.getMyInfo().then(
      async res => {
        console.log(res);
        if (res.data !== '') {
          // Dispatch User Data
          currentUser.userData = res.data;
          currentUser.action.type = LOG_IN_MAINTAIN;
          userDispatch(currentUser);

          // Get Unread Aralm
          alarmList = res.data.alarmList;

          let recentAlarmList = [];
          let numUnread = 0;

          alarmList.forEach((alarm, i) => {
            recentAlarmList.push(alarm);
            if (!alarm.isRead) {
              numUnread += 1;
            }
          });

          setNumUnreadAlarm(numUnread);
          if (res.data.role === 'admin') {
            setIsAdmin(true);
            console.log(res.data.role);
          }
        } else {
          currentUser.action.type = LOG_IN_FAILED;
          userDispatch(currentUser);
        }
      },
      error => {
        currentUser.action.type = LOG_IN_FAILED;
        userDispatch(currentUser);
      },
    );
  };

  let GNBcondition;
  if (!loged) {
    GNBcondition = (
      <MainButton to="/signup?from=write">
        기록하기
      </MainButton>
    );
  } else if (
    loged &&
    isAdmin &&
    location.pathname !== '/Admin'
  ) {
    GNBcondition = (
      <MainButton to="/Admin">
        ADMIN
      </MainButton>
    );
  } else if (
    loged &&
    isAdmin &&
    location.pathname === '/Admin'
  ) {
    GNBcondition = (
      <MainButton to="/writeIns">
        Inspire 게재
      </MainButton>
    );
  } else if (loged) {
    GNBcondition = (
      <MainButton to="/write">
        기록하기
      </MainButton>
    );
  }

  // Catch Route Event by hook
  useEffect(() => {
    // Init
    setIsSave(false);
    setisSaveIns(false);
    setIsDelete(false);
    // Set mode of GNB by location string
    const isRouteHome = (location.pathname === '/');
    const isRouteFeed = (location.pathname === '/feed/');
    const isRouteSignIn = (location.pathname === '/signIn');
    const isRouteSignUp = (location.pathname === '/signUp');
    const isRouteWrite = location.pathname.includes('write');
    const isRouteEdit = location.pathname.includes('edit');
    const isRouteSearch = location.pathname.includes('search');
    const isRouteSave = location.pathname.includes('save');
    const isRoutePost = location.pathname.includes('post/');
    // MY Page 적용 GNB적용 라우터로 확인
    const isMyPage = location.pathname.includes('/mypage');
    const isRouteCompetition = location.pathname.includes('competition/');
    const isRouteCompetitions = location.pathname.includes('competitions');


    if (isRouteWrite) {
      setIsWrite(true);
    }
    else {
      setIsWrite(false);
    }

    // Simple Mode GNB적용여부 라우터로 확인
    if (isRouteWrite || isRouteSave || isRouteEdit || isRouteSearch) {
      setIsSimpleMode(true);
    }
    else {
      setIsSimpleMode(false);
      // Reset Essay or Ad Context to Prevent Data Remains
      /* 
      Oct 21
      유저들이 글을 실수로 지우는 문제 때문에
      draft저장 기능 추가전까지 임시로 RESET dispatch 해제
      */
      // essayDispatch({ type: RESET_ESSAY, value: null });
      adDispatch({ type: RESET_AD, value: null });
    }

    // My Page Mode 적용 여부 확인
    if (isMyPage || isRoutePost) {
      setisExtendMode(true);
    }
    else {
      setisExtendMode(false);
    }

    // Fetch currently loged user data from api
    getUserInfo();

    // Essay Reset if router is not SAVE
    if (!isRouteSave) {
      // essayDispatch({ type: RESET_ESSAY, value: '' });
    }

    // Send pageview event to GA
    let pathName;
    let pageTitle;
    if (isRouteHome) {
      pageTitle = 'home';
      pathName = '/';
    } else if (isRouteFeed) {
      pageTitle = 'feed';
      pathName = '/feed';
    } else if (isRouteWrite) {
      pageTitle = 'write page';
      pathName = '/write';
    } else if (isRouteSignUp) {
      pageTitle = 'signup page';
      pathName = '/signUp';
    } else if (isRouteSignIn) {
      pageTitle = 'signin page';
      pathName = '/signIn';
    } else if (isRouteEdit) {
      pageTitle = 'edit page';
      pathName = '/edit';
    } else if (isRouteSave) {
      pageTitle = 'save page';
      pathName = '/save';
    } else if (isRoutePost) {
      pageTitle = 'essay post page';
      pathName = '/post';
    } else if (isRouteCompetition) {
      pageTitle = 'competition detail page';
      pathName = '/competition';
    } else if (isRouteCompetitions) {
      pageTitle = 'competition feed';
      pathName = '/competitions';
    }
    gtag('config', 'UA-184109384-1', {
      'page_title': pageTitle,
      'page_path': pathName
    });
  }, [location]);

  // Simple mode (Writing)
  // State of Essay Context
  const {
    id,
    title,
    content,
    contentText,
    tags,
    // isDueday,
    // dueday,
    isCompeted,
    competitionId,
    competitionData,
    numCurrentWords,
    isPublished
  } = useEssayState();

  // State of Ad Context
  const {
    id: adId,
    title: adTitle,
    content: adContent,
    tags: adTags,
    numCurrentWords: adNumCurrentWords,
    mediaType,
  } = useInsState();

  const history = useHistory();

  // Function for Simple mode (Writting mode)
  const saveEssay = () => {
    // dispatch for notice save attempting to write presenter
    essayDispatch({ type: SET_ATTEMPT_SAVE, value: true });
    console.log(content);
    essayDispatch({
      type: SET_COMPETITION_DATA,
      value: competitionData,
    });
    essayDispatch({ type: SET_IS_PUBLISHED, value: isPublished });
    essayDispatch({ type: SET_IS_COMPETED, value: true });
    essayDispatch({ type: SET_ESSAY_ID, value: id });
    essayDispatch({ type: SET_ESSAY_TITLE, value: title });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: content });
    // essayDispatch({ type: SET_ESSAY_CONTENT_TEXT, value: contentText });
    essayDispatch({ type: SET_ESSAY_TAGS, value: tags });
    essayDispatch({ type: SET_NUM_CURRENT_WORDS, value: numCurrentWords });
    essayDispatch({
      type: SET_IS_COMPETED,
      value: isCompeted,
    });
    essayDispatch({
      type: SET_COMPETITION_ID,
      value: competitionId,
    });
    setIsSave(true);
  };

  const saveEssayByAdmin = () => {
    essayDispatch({
      type: SET_COMPETITION_DATA,
      value: competitionData,
    });
    essayDispatch({ type: SET_IS_COMPETED, value: true });
    essayDispatch({ type: SET_ESSAY_ID, value: id });
    essayDispatch({ type: SET_ESSAY_TITLE, value: title });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: content });
    essayDispatch({ type: SET_ESSAY_TAGS, value: tags });
    essayDispatch({ type: SET_NUM_CURRENT_WORDS, value: numCurrentWords });
    essayDispatch({
      type: SET_IS_COMPETED,
      value: isCompeted,
    });
    essayDispatch({
      type: SET_COMPETITION_ID,
      value: competitionId,
    });
    setIsSaveByAdmin(true);
  };

  const saveIns = () => {
    adDispatch({ type: SET_AD_ID, value: adId });
    adDispatch({ type: SET_AD_TITLE, value: adTitle });
    adDispatch({ type: SET_AD_CONTENT, value: adContent });
    adDispatch({ type: SET_AD_TAGS, value: adTags });
    adDispatch({ type: SET_MEDIATYPE, value: mediaType });
    adDispatch({ type: SET_NUM_CURRENT_WORDS_AD, value: adNumCurrentWords });
    setisSaveIns(true);
  };

  // On Save Function
  const onSave = () => {
    // dispatch for notice save attempting to write presenter
    essayDispatch({ type: SET_ATTEMPT_SAVE, value: true });

    // Check Exception
    const isTitle = title !== '' || adTitle !== '';
    const isContent = content !== null || adContent !== '';
    const isOverFlow = numCurrentWords >= 2200 || adNumCurrentWords >= 2200;
    const adCondition =
      title === '' && adTitle !== '' && content === null && adContent !== '';

    // Warning MSG for null title
    if (!isTitle) {
      window.alert('글의 제목을 입력해주세요');
    }
    // Warning MSG for null content
    else if (!isContent) {
      window.alert('글의 내용을 입력해주세요');
    }
    // Warning MSG for overflow
    else if (isOverFlow) {
      if (
        window.confirm(
          '에세이 길이가 최대 글자수(2200)를 넘었습니다. 그대로 저장하시겠습니까? \n ( 출판을 원하시는 경우 취소 후 글자수를 줄여주세요.)',
        )
      ) {
        // saveEssay();
        if (adCondition) {
          saveIns();
        } else {
          saveEssay();
        }
      } else {
        // No action

      }
    } else if (adCondition) {
      saveIns();
    } else {
      saveEssay();
    }
  };

  // On Save by admin Function
  const onSaveByAdmin = () => {
    // dispatch for notice save attempting to write presenter
    essayDispatch({ type: SET_ATTEMPT_SAVE, value: true });

    // Check Exception
    const isTitle = title !== '' || adTitle !== '';
    const isContent = content !== null || adContent !== '';
    const isOverFlow = numCurrentWords >= 2200 || adNumCurrentWords >= 2200;
    const adCondition =
      title === '' && adTitle !== '' && content === null && adContent !== '';

    // Warning MSG for null title
    if (!isTitle) {
      window.alert('글의 제목을 입력해주세요');
    }
    // Warning MSG for null content
    else if (!isContent) {
      window.alert('글의 내용을 입력해주세요');
    }
    // Warning MSG for overflow
    else if (isOverFlow) {
      if (
        window.confirm(
          '에세이 길이가 최대 글자수(2200)를 넘었습니다. 그대로 저장하시겠습니까? \n ( 출판을 원하시는 경우 취소 후 글자수를 줄여주세요.)',
        )
      ) {
        // saveEssay();
        if (adCondition) {
          saveIns();
        } else {
          saveEssayByAdmin();
        }
      } else {
        // No action
      }
    } else if (adCondition) {
      saveIns();
    } else {
      saveEssayByAdmin();
    }
  };

  // Reset Essay Data
  const onReset = () => {
    if (window.confirm('작성중인 글을 초기화 하시겠습니까? 모든 내용과 저장 옵션들이 초기화 됩니다.')) {
      essayDispatch({ type: RESET_ESSAY, value: '' });
    }
  }
  // Delete Essay
  const deleteEssay = async () => {
    const data = {
      essayId: id,
    };

    await essayApi.essayDelete(data).then(async res => {
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        userDispatch(currentUser);
        window.alert('에세이가 삭제되었습니다.');
        setIsDelete(true);
        // 에세이 삭제
      });
    });
  };

  const [scrollDirection, setScrollDirection] = useState('up')
  const [prevOffset, setPrevOffset] = useState(0)
  const toggleScrollDirection = () => {
    const { scrollY } = window;
    if (scrollY === 0) {
      setScrollDirection("up");
    }
    if (scrollY > prevOffset) {
      setScrollDirection("down");
    } else if (scrollY < prevOffset) {
      setScrollDirection("up");
    }
    setPrevOffset(scrollY);
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleScrollDirection)
    return () => {
      window.removeEventListener("scroll", toggleScrollDirection)
    }
  });

  return (
    <GNB scrollDirection={scrollDirection}>
      {/* <Prompt
        when={isWrite&&!isSave}
        message={(location) => {
          return `작성중인 글이 저장되지 않았습니다. 현재 페이지를 떠나시겠습니까?`;
        }}
      /> */}
      {isRedirect && <Redirect to={"/"} />}
      {/* GERNERAL MODE  */}
      {!isSimpleMode && (
        <MenuContainer
          isExtendMode={isExtendMode}
          className="row1 flex_row space_between">
          {/* PC버전 메뉴 */}
          <MenuGNB id="left" className="only_pc">
            <Link
              style={{ paddingLeft: '3px', paddingRight: '16px' }}
              className="flex_row align_center row1"
              to={{ pathname: "/", state: 9 }}
            >
              <img src={themeNew.themeType==='light'?logo_img:logo_img_white} alt="" />
            </Link>
            {/* <div className='border' /> */}
            <Link
              style={{ paddingLeft: '20px', paddingTop: '8px', opacity: 0.64 }}
              className="flex_row flex_center row1 regular primary hide"
              to="/"
            >
              beta version
              {/* <img style={{ marginTop: '8px' }} src={logo2_img} alt="" /> */}
            </Link>
          </MenuGNB>
          <MenuGNB id="center" className="only_pc flex_center">
            {/* {!loged ? (
                <Link className="btt_write primary" to="/signup?from=write">
                  기록하기
                </Link>
              ) : userData.email === 'blueblack@gmail.com' ? (
                <Link className="btt_write primary" to="/Admin">
                  ADMIN
                </Link>
              ) : (
                <Link className="btt_write loged white" to="/write">
                  기록하기
                </Link>
              )} */}
            {/* to avoid nest ternary */}
            {GNBcondition}
          </MenuGNB>
          <MenuGNB id="right" className="margin_r_0 justify_end only_pc">
            {/* 로그인전 */}
            <Link to={'/search'}>
              <img src={ic_search} className="search" alt="" />
            </Link>
            {!loged && (
              <>
                <BttBasic
                  title={'시작하기'}
                  to={"/signup"}
                  type={'filled_primary'}
                />
              </>
            )}
            {/* 로그인후 */}
            {loged && userData && (
              <>
                <Link
                  style={{ position: 'relative' }}
                  className="alarm"
                  to="/alarm"
                >
                  <img style={{ marginBottom: '-2px' }} src={themeNew.themeType==='light'?ic_alarm:ic_alarm_white} alt="" />
                  <MarkAlarm>{numUnreadAlarm}</MarkAlarm>
                </Link>
                <DropDownContainer>
                  <button
                    type="button"
                    className=""
                    onClick={() => {
                      setIsShowMore(true);
                    }}
                  >
                    <AvatarM
                      writer={userData}
                      isButton={true}
                      isDark={themeNew.themeType==='dark'?true:false}
                    />
                  </button>
                  {isShowMore && (
                    <>
                      <DropDown>
                        <MenuLink
                          onClick={() => {
                            setIsShowMore(false);
                          }}
                          to="/mypage"
                        >
                          마이페이지

                      </MenuLink>
                        <Menu onClick={() => {
                          setIsShowMore(false);
                          logout();
                        }}
                        >
                          로그아웃
                      </Menu>
                      </DropDown>
                      <FakeBackDrop
                        onClick={() => {
                          setIsShowMore(false);
                        }}
                      />
                    </>
                  )}
                </DropDownContainer>

                {/* <Link onClick={logout} className="btt_txt" to="/signIn">
                  로그아웃
                </Link> */}
              </>
            )}
          </MenuGNB>
          {/* MOBILE 버전 메뉴 */}
          <MobileHeader>
            <div id="left" className="flex_row only_mobile align_center row1">
              <Link
                className="margin_r_tiny flex_row align_center"
                to={"/"}
              >
                <img className="logo" src={logo_img} alt="" />
              </Link>
              {/* <div className='border margin_r_tiny' /> */}

            </div>
            <div id="right" className="flex_row align_center only_mobile">
              {!loged && (
                <>
                  <BttBasic
                    title={'시작하기'}
                    to={"/signup"}
                    type={'filled_primary'}
                  />
                </>
              )}
              {
                loged &&
                <>
                  {
                    location.pathname.includes('mypage') && (
                      <DropDownContainer>
                        <BttMore
                          onClick={() => {
                            setIsShowMore(true);
                          }}
                        >
                          <img src={IcMore} alt="" />
                        </BttMore>
                        {isShowMore && (
                          <>
                            <DropDown>
                              <Menu onClick={() => {
                                setIsShowMore(false);
                                logout();
                              }}
                              >
                                로그아웃
                            </Menu>
                            </DropDown>
                            <FakeBackDrop
                              onClick={() => {
                                setIsShowMore(false);
                              }}
                            />
                          </>
                        )}
                      </DropDownContainer>
                    )
                  }
                </>
              }
              {/* <img src={ic_search} alt="" /> */}
            </div>
          </MobileHeader>
          {/* Bottom Navigation */}
          {
            loged && (
              <nav className="bottom_navigation flex_row align_start col1 vanila_white_bg only_mobile">
                <MenuGNB>
                  <Link to="/">
                    <img src={ic_home} alt="" />
                  </Link>
                </MenuGNB>
                <MenuGNB>
                  <Link to="/search">
                    <img src={ic_search} alt="" />
                  </Link>
                </MenuGNB>
                <MenuWrite>
                  <Link className="btt_write primary" to="/write">
                    기록하기
                    </Link>
                </MenuWrite>
                <MenuGNB>
                  <Link
                    style={{ position: 'relative' }}
                    className="alarm"
                    to="/alarm"
                  >
                    <img style={{ marginBottom: '-2px' }} src={ic_alarm} alt="" />
                    <MarkAlarm>{numUnreadAlarm}</MarkAlarm>
                  </Link>
                </MenuGNB>
                <MenuGNB>
                  <Link
                    to="/mypage"
                    className="avatar_s flex_col flex_center primary_bg white body2 bold"
                  >
                    {
                      loged &&
                      userData && userData.username.length > 1 &&
                      userData.username[0] + userData.username[1]
                    }
                    {
                      loged &&
                      userData && userData.username.length == 1 &&
                      userData.username[0]
                    }
                  </Link>
                </MenuGNB>
              </nav>
            )
          }
          {
            !loged && (
              <nav className="bottom_navigation flex_row align_start space_between col1 vanila_white_bg only_mobile">
                <MenuGNB>
                  <Link to="/">
                    <img src={ic_home} alt="" />
                  </Link>
                </MenuGNB>

                <MenuWrite>
                  <Link className="btt_write primary" to="/signup?from=write">
                    기록하기
                    </Link>
                </MenuWrite>
                <MenuGNB>
                  <Link to={'/search'}>
                    <img src={ic_search} className="search" alt="" />
                  </Link>
                </MenuGNB>
              </nav>
            )
          }
        </MenuContainer>
      )}
      {/* SIMPLE MODE  */}
      {isSimpleMode && (
        <>
          <LiveAreaWide className="row1 flex_row space_between only_pc">
            {/* PC버전 메뉴 */}
            <MenuGNB id="left" className="only_pc">
              <Link className="flex_row align-center row1" to="/">
                <img src={logo_simple_img} alt="" />
              </Link>
            </MenuGNB>
            <MenuGNB
              id="right"
              className="flex_row align-center justify_end row1"
            >
              {id && (
                <BttAction
                  title="삭제하기"
                  onClick={deleteEssay}
                  type="outlined"
                  className="margin_r_1x"
                />
              )}
              {/* 어드민 수정과 작성자 수정 분기처리 */}
              {location.pathname.includes('editEssay') && (
                <>
                  {
                    isAdmin ? (
                      <BttAction
                        type="filled_primary"
                        title="수정하기"
                        onClick={onSaveByAdmin}
                      />
                    ) : (
                        <BttAction
                          type="filled_primary"
                          title="수정하기"
                          onClick={saveEssay}
                        />
                      )
                  }
                </>
              )}
              {location.pathname.includes('write') && (
                <>
                  {/* <BttAction
                    className="margin_r_1x"
                    type="outlined"
                    title="초기화"
                    onClick={onReset}
                  /> */}
                  <BttActionFixedWidth
                    className={''}
                    type="filled_primary"
                    title="저장하기"
                    width={'160px'}
                    onClick={onSave}
                  />
                </>
              )}
            </MenuGNB>
          </LiveAreaWide>
          {/* MOBILE 버전 메뉴 */}
          <MobileHeader className="only_mobile">
            <div id="left" className="flex_row">
              <Link className="flex_row align-center row1" to="/">
                <img src={logo_simple_img} alt="" />
              </Link>
            </div>
            <div id="right" className="flex_row align_center">
              {id && (
                <BttAction
                  title="삭제하기"
                  onClick={deleteEssay}
                  type="outlined"
                  className="margin_r_1x"
                />
              )}
              {location.pathname.includes('editEssay') && (
                <>
                  {
                    isAdmin ? (
                      <BttAction
                        type="filled_primary"
                        title="수정하기"
                        onClick={onSaveByAdmin}
                      />
                    ) : (
                        <BttAction
                          type="filled_primary"
                          title="수정하기"
                          onClick={saveEssay}
                        />
                      )
                  }
                </>
              )}
              {location.pathname.includes('write') && (
                <BttAction
                  type="filled_primary"
                  title="저장"
                  onClick={onSave}
                />
              )}
              {/* <img src={ic_search} alt="" /> */}
            </div>
          </MobileHeader>
          {isSaveByAdmin && <Redirect to="/saveByAdmin" />}
          {isSave && <Redirect push to="/save" />}
          {isSaveIns && <Redirect push to="/saveIns" />}
          {isDelete && <Redirect push to="/mypage" />}
        </>
      )}
    </GNB>
  );
});

withRouter.propTypes = {
  location: PropTypes.string.isRequired,
};
