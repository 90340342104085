import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmFD({ alarm, setAlarmRead }) {
  let mark = '예준';
  // let competition;
  let user;
  // let path;
  if (alarm.contentObject) {
    [, user] = alarm.contentObject;
    mark = user.username[0] + user.username[1];
    // path = `/user/${user.username}`;
    console.log(alarm.mark);
  }
  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id)}
      className={`col1 ${alarm.isRead}` ? 'white_bg' : 'white_600bg'}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender={user.username}
        msg="님이 백일장에 참여했습니다."
        content=""
      />
    </button>
  );
}

ListAlarmFD.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmFD;
