import React from 'react';
import UserPagePresenter from './UserPagePresenter';
import { useUserState } from '../../context/currentUserContext';

function UserPageContainer({ location }) {
  const { loaded, loged, userData } = useUserState();

  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <UserPagePresenter
          loged={loged}
          myData={userData}
          location={location}
        />
      </>
    )
  );
}

export default UserPageContainer;
