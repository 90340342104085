const theme = {
  color: {
    primary500: '#3838BF',
    primary400: '#6A6ACF ',
    primary300: '#9B9BDF',
    primary200: '#CDCDEF',
    primary100: '#EAEAFF',
    secondary: '#FDFAEC',
    tertiary700: '#2A2A28',
    tertiary500: '#5f5d5a',
    tertiary300: '#93908B',
    tertiary100: '#C7C3BC',
    white700: '#FFFFFF',
    vanilawhite: '#FCF6EE',
  },
  shape: {
    borderLight: '1px',
    borderBold: '2px',
    radiousRegular: '4px',
    roundFull: '50%',
    elavation50Block:`
      box-shadow: 0px 0px 16px rgba(0,0,0,0.08);
    `,
  },
  typeBlock: {
    h1Block: `
      font-size: 28px;
      line-height: 37px;
    `,
    h2Block: `
      font-size: 20px;
      line-height: 27px;
    `,
    subtitle1: `
      font-size: 15px;
      line-height: 21px;
    `,
    subtitle2: `
      font-size: 14px;
      line-height: 20px;
    `,
    body1: `
      font-size: 20px;
      line-height: 39px;
    `,
    body2: `
      font-size: 18px;
      line-height: 26px;
    `,
    body3: `
      font-size: 16px;
      line-height: 1.75;
    `,
    body4: `
      font-size: 14px;
      line-height: 21px;
    `,
    bttText: `
      font-size: 12px;
      line-height: 16px;
    `,
    bttTextHuge: `
      font-size: 20px;
      line-height: 20px;
    `,
    caption1: `
      font-size: 13px;
      line-height: 18px;
    `,
    caption2: `
      font-size: 11px;
      line-height: 15px;
    `,
  },
  // type object is legacy
  type: {
    light: 'sopoqa_han_sans_light',
    regular: 'sopoqa_han_sans_regular',
    bold: 'sopoqa_han_sans_bold',
    h1: {
      size: '28px',
      height: '37px',
    },
    h1Block: `
      font-size: 28px;
      line-height: 37px;
    `,
    h2: {
      size: '20px',
      height: '27px',
    },
    h2Block: `
      font-size: 20px;
      line-height: 27px;
    `,
    subtitle1: {
      size: '15px',
      height: '21px',
    },
    subtitle2: {
      size: '14px',
      height: '20px',
    },
    body1: {
      size: '20px',
      height: '39px',
    },
    body2: {
      size: '18px',
      height: '26px',
    },
    body3: {
      size: '16px',
      height: '24px',
    },
    body4: {
      size: '14px',
      height: '21px',
    },
    bttText: {
      size: '12px',
      height: '16px',
    },
    bttTextMiddle: {
      size: '14.8px',
      height: '14.8px',
    },
    bttTextHuge: {
      size: '20px',
      height: '20px',
    },
    caption1: {
      size: '13px',
      height: '18px',
    },
    caption2: {
      size: '11px',
      height: '15px',
    },
  },
  size: {
    grid: {
      unitR: 16,
      unitS: 8,
    },
    row1Block: `
      height: 100%
    `,
    cardBigHeight: '500px',
    cardMiddleHeight: '344px',
    bttHugeHeight: '80px',
    bttBigExtraHeight: '48px',
    bttBigHeight: '34px',
    bttRegularHeight: '32px',
    bttSmallHeight: '26px',
  },
  layout: {
    liveArea: {
      width: '1200px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    liveAreaSmall: {
      width: '948px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    sectionBlock: `
      width: 100%;
      margin-bottom: 80px;
      @media screen and (max-width: 480px) {
        margin-bottom: 68px;
      }
    `,
    sectionBttMoreBlock: `
      position: absoulte;
      right:0;
      bottom:0;
    `,
    flexRowBlock: `
      display: flex;
      flex-direction: row;
    `,
    flexRowAlignCenterBlock: `
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    flexColCenterBlock: `
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    flexRowCenterBlock: `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    `,
    containerBlock: `
      padding-top: 20px;
      padding-bottom: 0px;
    `,
    gridHorLine: `
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      height:1px;
      width:calc(100%);
      background-color: #9b9bdf;
    `,
    gridVerRightLine: `
      content: '';
      position: absolute;
      top: 0px;
      right:-20px;
      height: calc(100% - 20px);
      width: 1px;
      background-color: #9b9bdf;
    `,
    parHorLine: `
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      height:1px;
      width:calc(100%);
      background-color: #9b9bdf;
    `,
    parVerRightLine: `
      content: '';
      position: absolute;
      top: 0px;
      right:-20px;
      height: calc(100% - 0px);
      width: 1px;
      background-color: #9b9bdf;
    `,
  },
  component: {
    bttFilledPrimaryBlock: `
      background-color: #3838BF;
      color: #ffffff;
    `,
    bttFilledWhiteBlock: `
      background-color: #ffffff;
      color: #3838BF;
    `,
    bttOutlinedBlock: `
      color: #3838BF;
      border: solid 1px #cdcdef;
  `,
  },
};

export default theme;
