import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// Reducer for Dispatch Currently Writting Essay Object
import insReducer, { initialState } from '../reducer/insReducer';

// Context Definition
const CurrentInsContext = createContext();
const CurrentInsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(insReducer, initialState);
  return (
    <CurrentInsContext.Provider value={{ state, dispatch }}>
      {children}
    </CurrentInsContext.Provider>
  );
};

CurrentInsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Dispatch Definition
export const useInsDispatch = () => {
  const { dispatch } = useContext(CurrentInsContext);
  return dispatch;
};

// UseState Definition
export const useInsState = () => {
  const { state } = useContext(CurrentInsContext);
  return state;
};

export default CurrentInsProvider;
