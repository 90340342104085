import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BttContainer = styled.button`
  height: ${props => props.theme.size.height.regular};
  border-radius: ${props => `calc(${props.theme.size.height.regular} / 2)`};
  padding: 0 24px;
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryBlock.enabled : '')}
`;

const BttAction = ({ type, title, onClick, className }) => {
  return (
    <BttContainer
      type={type}
      onClick={onClick}
      className={`btt_txt ${className}`}
    >
      <p>{title}</p>
    </BttContainer>
  );
};

BttAction.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default BttAction;
