import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import { Link, Redirect } from 'react-router-dom';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import axios from 'axios';

import { 
    useUserState,
    useUserDispatch
  } from '../../context/currentUserContext';
import { sessionApi, adminApi } from '../../api/api';

import { LOG_IN_SUCCESS } from '../../actions';

// 이미지
import ic_naver from '../../assets/img/social/ic_naver.svg';
import ic_kakao from '../../assets/img/social/ic_kakao.svg';
import ic_google from '../../assets/img/social/ic_google.svg';
import ic_fb from '../../assets/img/social/ic_fb.svg';
import config from '../../config';

// Component
import ButtonActionBig from '../../components/Buttons/ButtonAction/BttActionBig';
import Loading from '../../components/Loading/Loading';

const RestLink = styled.div`
  cursor: pointer;
`;

const SocialImg = styled.img`
  width: 48px;
  height: 48px;
  margin: 16px;
  @media screen and (max-width: 480px) {
    width: 40px;
    height: 40px;
    margin: 8px;
  }
`;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // flexWrap: 'wrap',
  },
  margin: {
    width: '100%',
    marginBottom: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '32ch',
  },
}));

export default function Login({
  isForgotPW,
  setIsForgotPW,
  isSentLink,
  setIsSentLink,
  isRecievedLink,
  setIsRecievedLink
}) {
  const { testVer } = useUserState();
  const dispatch = useUserDispatch();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    id: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    redirect: null,
  });

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // ResetPassword관련

  console.log(testVer);

  const log = async () => {
    const data = {
      email: values.id,
      password: values.password,
    };
    const {gtag} = window;
    gtag('event', 'click', {
      'event_category' : 'SIGN',
      'event_label' : 'sign in'
    });
    await sessionApi.logIn(data).then(res => {
      if (res.status === 200) {
        console.log(res);
        currentUser.action.type = LOG_IN_SUCCESS;
        currentUser.username = res.data.username;
        currentUser.userData = res.data;
        dispatch(currentUser);
        setValues({ redirect: testVer==='original'?'/':'/main' });
      } else {
        console.log(res.status);
        window.alert('패스워드가 일치하지 않거나 존재하지 않는 계정입니다.');
      }
    },
    error => {
      console.log(error);
      const {gtag} = window;
        gtag('event', 'click', {
          'event_category' : 'SIGN',
          'event_label' : 'sign in failed'
        });
    },);
  };

  const handleChange = prop => e => {
    setValues({ ...values, [prop]: e.target.value });
    console.log(values);
    console.log(e.target);
    console.log(e.key);
    if (e.key === 'Enter') {
      log();
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = e => {
    e.preDefault();
  };

  const keyPressPassword = e => {
    if (e.key === 'Enter') {
      log();
    }
  };

  const recoverPWBtnHandler = async () => {
    const data = {
      email: values.id,
    };
    // Check moment of sending Email;
    setIsSentLink(true);
    const {gtag} = window;
    gtag('event', 'click', {
      'event_category' : 'SIGN',
      'event_label' : 'reset pw'
    });
    await adminApi.sendPWChangeEmail(data).then(res => {
      // Check moment of recieved Email;
      setIsRecievedLink(true);
      if(res.status === 200){
        window.alert('메일이 전송되었습니다.');
      }
      else if(res.status === 400){
        window.alert('존재하지 않는 계정입니다.');
      }
      else{
        window.alert('잘못된 접근입니다. 다시 시도해주세요.');
      }
    })
  }

  if (values.redirect) {
    return <Redirect to={values.redirect} />;
  }

  return (
    <div className={`${classes.root} flex_col align_center padding_2x`}>
      {!isForgotPW?(
        <>
          <h2 className="black700 bold">로그인</h2>
          <p className="body regular black700 margin_b_2x">
            아이디가 없으시다면
            {' '}
            <Link className="primary" to="/signUp">
              회원가입
            </Link>
            으로 이동하세요
          </p>
          <FormControl
            fullWidth
            className={`${classes.margin} margin_b_2x`}
            variant="outlined"
          >
            <input
              className="col1 big"
              id="outlined-adornment-id"
              value={values.id}
              onChange={handleChange('id')}
              placeholder="이메일"
              labelWidth={70}
            />
          </FormControl>
          <FormControl
            fullWidth
            className={clsx(classes.margin, classes.textField)}
          />
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <input
              className="col1 big"
              autoComplete="Hello"
              id="outlined-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onKeyPress={keyPressPassword}
              onChange={handleChange('password')}
              placeholder="비밀번호"
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              labelWidth={70}
            />
          </FormControl>
          <div className="flex_col col1 flex_center padding_b_4x bd_bottom_light">
            <FormControl fullWidth className="col1" variant="outlined">
              <ButtonActionBig
                className="margin_t_2x col1"
                size="large"
                color="primary"
                variant="contained"
                onClick={log}
                title="로그인"
                type="filled_primary"
              />
            </FormControl>
            <RestLink
              onClick={() => setIsForgotPW(true)}
              className='caption1 margin_1x primary regular'
            >
              비밀번호를 잊으셨나요?
            </RestLink>
          </div>
          <h2 className="black700 bold margin_b_1x margin_t_4x">소셜로 로그인</h2>
          <div className="flex_row col1 flex_center margin_b_4x">
            <a href={`${config.apiUrl}/api/auth/login/naver`}>
              <SocialImg
                border="0"
                src={ic_naver}
                alt="linkToNaver"
              />
            </a>
            <a href={`${config.apiUrl}/api/auth/login/kakao`}>
              <SocialImg
                border="0"
                src={ic_kakao}
                alt="linkToKakao"
              />
            </a>
            <a href={`${config.apiUrl}/api/auth/login/google`}>
              <SocialImg
                border="0"
                src={ic_google}
                alt="linkToGoogle"
              />
            </a>
            {/* <a href={`${config.apiUrl}/api/auth/login/facebook`}>
              <SocialImg
                border="0"
                src={ic_fb}
                alt="linkToFacebook"
              />
            </a> */}
          </div>
        </>
      ):(
        <>
          <h2 className="black700 bold margin_1x">비밀번호 재설정 링크 받기</h2>
          <FormControl
            fullWidth
            className={`${classes.margin} margin_b_2x`}
            variant="outlined"
          >
            <input
              className="col1 big"
              id="outlined-adornment-id"
              value={values.id}
              onChange={handleChange('id')}
              placeholder="이메일"
              labelWidth={70}
            />
          </FormControl>
          <FormControl fullWidth className="col1" variant="outlined">
            <ButtonActionBig
              className="margin_t_2x col1"
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {recoverPWBtnHandler()}}
              title="비밀번호 재설정 링크 보내기"
              type="filled_primary"
            />
          </FormControl>
          {
            (isSentLink&&!isRecievedLink)&&(
              <div>
                <p className='caption regular margin_t_4x margin_b_2x'>이메일을 전송중입니다.</p>
                <Loading />
              </div>
            )
          }
        </>
      )}
    </div>
  );
}
