import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILED,
  LOG_IN_MAINTAIN,
  LOAD_USER_DATA,
  LOG_OUT,
  SET_TEST_VER
} from '../actions';

export const initialState = {
  loged: false,
  loaded: false,
  username: null,
  userData: null,
  interestedTag: [],
  writeTags: [],
  testVer: 'original',
};

const userReducer = (state, currentUser) => {
  console.log('dispatched!');
  const counts = {};
  const countsArray = [];

  switch (currentUser.action.type) {
    case SET_TEST_VER:
      return {
        ...state,
        testVer: currentUser.testVer
      }
    case LOG_IN_SUCCESS:
      console.log(currentUser);
      // userData의 readTag로 관심태그어 추출
      // 주제어를 키값으로 카운트
      currentUser.userData.readTags.forEach(function (x) {
        if(x!==null){
          if (!counts[x.tagName]) {
            counts[x.tagName] = { count: 1 };
          } else {
            counts[x.tagName].count = counts[x.tagName].count + 1;
          }
        }  
      });
      // 위의 object를 배열로 변환(sorting을 위한)
      Object.keys(counts).forEach(key => {
        countsArray.push({
          tagName: key,
          count: counts[key].count,
        });
      });
      console.log(countsArray);

      return {
        ...state,
        loged: true,
        userData: currentUser.userData,
        username: currentUser.username,
        interestedTag: countsArray,
        writeTags: currentUser.userData.writeTags,
        testVer: currentUser.testVer
      };
    case LOG_IN_FAILED:
      console.log(LOG_IN_FAILED);
      return {
        ...state,
        loaded: true,
      };
    case LOG_OUT:
      return {
        loged: false,
        username: null,
        loaded: true,
        userData: null,
      };
    case LOG_IN_MAINTAIN:
      // 주제어를 키값으로 카운트
      console.log(currentUser);
      currentUser.userData.readTags.forEach(function (x) {
        if(x!==null){
          if (!counts[x.tagName]) {
            counts[x.tagName] = { count: 1 };
          } else {
            counts[x.tagName].count = counts[x.tagName].count + 1;
          }
        }    
      });
      // 위의 object를 배열로 변환(sorting을 위한)
      Object.keys(counts).forEach(key => {
        countsArray.push({
          tagName: key,
          count: counts[key].count,
        });
      });

      countsArray.sort(function (small, big) {
        return big.count - small.count;
      });
      console.log(countsArray);
      console.log(currentUser.userData.username);
      return {
        ...state,
        loaded: true,
        loged: true,
        load: true,
        username: currentUser.userData.username,
        userData: currentUser.userData,
        interestedTag: countsArray,
        writeTags: currentUser.userData.writeTags,
        testVer: currentUser.testVer
      };
    case LOAD_USER_DATA:
      // 주제어를 키값으로 카운트
      currentUser.userData.readTags.forEach(function (x) {
        if (!counts[x.tagName]) {
          counts[x.tagName] = { count: 1 };
        } else {
          counts[x.tagName].count = counts[x.tagName].count + 1;
        }
      });
      // 위의 object를 배열로 변환(sorting을 위한)
      Object.keys(counts).forEach(key => {
        countsArray.push({
          tagName: key,
          count: counts[key].count,
        });
      });
      return {
        ...state,
        loaded: true,
        loged: true,
        load: true,
        username: currentUser.userData.username,
        userData: currentUser.userData,
        interestedTag: countsArray,
        writeTags: currentUser.userData.writeTags
      };
    default:
  }
};

export default userReducer;
