import { lang } from 'moment';
import React from 'react';
import { useUserState } from '../../context/currentUserContext';
import FooterPresenter from './FooterPresenter';

function FooterContainer({}) {
  const { loaded, loged, username, userData, interestedTag } = useUserState();
  return(
    loaded && (
      <>
        <FooterPresenter loged={loged} />
      </>
    )
  );
}

export default FooterContainer;