import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  ${props => props.theme.layout.containerBlock}
`;

function ContainerO({ className, children }) {
  return (
    <Container className={`col1 bd_top_bold ${className}`}>
      {children}
    </Container>
  );
}

ContainerO.propTypes = {
  // className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContainerO;
