import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Grid = styled.div`
  position: relative;
  width: calc(
    (100% - ${props => `${48 * (props.colPC - 1)}px`}) / ${props => props.colPC}
  );
  margin-right: ${props => props.index % props.numCol === props.numCol - 1 ? '0px' : '48px'};
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    width: calc(
      (100% - ${props => `${32 * (props.colMb - 1)}px`}) / ${props => props.colMb}
    );
    margin-right: ${props =>
    props.index % props.numCol === props.numCol - 1 ? '0px' : '32px'};
    margin-bottom: 16px;
  }
  // Horizon Border
  &:before {
    ${props => props.theme.layout.gridHorLine}
  }
  // Vertical Border
  &:after {
    ${props =>
    props.index % props.numCol !== props.numCol - 1 &&
    props.theme.layout.gridVerRightLine}
  }
`;
function GridMatrix({ className, colPC, colMb, index, children, onClick }) {
  const numCol = isMobile ? colMb : colPC;

  return (
    <Grid
      className={className}
      key={index}
      index={index}
      colPC={colPC}
      colMb={colMb}
      numCol={numCol}
      onClick={onClick}
    >
      {children}
    </Grid>
  );
}

GridMatrix.propTypes = {
  // className: PropTypes.string.isRequired,
  colPC: PropTypes.number.isRequired,
  colMb: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default GridMatrix;
