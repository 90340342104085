import React, { useState, useEffect, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { ImageBlockConfig } from 'Dante2/package/es/components/blocks/image';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import config from '../../config';

import {
  SET_ESSAY_ID,
  SET_ESSAY_TITLE,
  SET_ESSAY_CONTENT,
  SET_ESSAY_TAGS,
  SET_IS_COMPETED,
  SET_COMPETITION_ID,
  SET_IS_BRANDED,
  SET_BRANDED_ID,
  SET_BRANDED_DATA,
  SET_COMPETITION_DATA,
  SET_NUM_CURRENT_WORDS,
  LOAD_USER_DATA,
  SET_IS_PUBLISHED,
  SET_ESSAY_CONTENT_TEXT,
} from '../../actions';

import { Prompt } from 'react-router';

// API
import { essayApi, mypageApi, competitionApi, brandedApi } from '../../api/api';

// Img
import ImgCat from '../../assets/img/ill/AboutCompetition/04.png';
import IcHome from '../../assets/img/ic/ic_home.svg';

// Context
import {
  useEssayState,
  useEssayDispatch,
} from '../../context/currentEssayContext';
import { useUserDispatch } from '../../context/currentUserContext';

// Component
import Loading from '../../components/Loading/Loading';
import BttBig from '../../components/Buttons/ButtonLink/BttBig';
import BannerWriteForCompetition from '../../components/Banner/BannerWriteForCompetition';
import BannerWriteForBranded from '../../components/Banner/BannerWriteForBranded';

// Styled Component
const Container = styled.div`
  //GNB와 간격 조정
  margin-top: 80px;
  @media screen and (max-width: 480px) {
   margin-top: -20px; 
  }
`;
const AmountGuide = styled.div`
  width: 160px;
  position: fixed;
  top: calc(171px + 84px);
  text-align: center;
  right: 48px;
  white-space: break-spaces;
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 60px;
    border-top: solid 1px ${props => props.theme.color.primary200};
    align-items: center;
    padding: 0px 20px;
    display: flex;
    top: unset;
    justify-content: space-between;
    bottom: 0 !important;
    left: 0 !important;
    text-align: left;
  }
`;

const Info = styled.div`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  background-color: #c7c3bc;
  border-radius: 10px;
  ${props => props.theme.layout.flexColCenterBlock}
  color: ${props => props.theme.color.white700};
`;

const Title = styled.input`
  margin-bottom: 64px;
  @media screen and (max-width: 480px) {
    margin-bottom: 24px;
  }
`;

const Editor = styled.div`
  width: 100%;
  min-height: 680px; 
  font-family: sopoqa_han_sans_light !important;
  padding: 6px 12px;
  margin-bottom: 10px !important;
  color: #2a2a28;
  font-size: 20px;
  line-height: 40px;
  white-space: break-spaces;
  outline: none;
`;

const TextArea = styled.textarea`
  border: none;
  width: 100%;
  min-height: 680px; 
  height: fit-content;
  font-family: sopoqa_han_sans_light !important;
  padding: 6px 12px;
  margin-bottom: 10px !important;
  color: #2a2a28;
  font-size: 20px;
  line-height: 40px;
  white-space: break-spaces;
  outline: none;
`;

const EditorContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
  textarea{
    border: none;
    width: 100%;
    padding: 0 !important;
    /* min-height: 680px;  */
    /* height: fit-content; */
    font-family: sopoqa_han_sans_light !important;
    padding: 6px 12px;
    margin-bottom: 10px !important;
    color: #2a2a28;
    font-size: 20px;
    line-height: 40px;
    white-space: break-spaces !important;
    word-break: break-all !important;
    outline: none !important;
    resize:'none' !important;
  }
`;

// Dante widget for image upload
const Dante = require('Dante2');

function Write({ location, username }) {
  /* Local State for managing writting essay
  - option for wheather save or publush
  - options for wheater set dueday or not
  - maximum words. (Global Setting for all user by blueblack)
  - check overflow of maximum words. save with overflow available, but publish with overflow is not available.
  */
  // prevent Prompt when nothing has change from initial state
  const [isChange, setIsChange] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numMaxWords, setNumMaxwords] = useState(2200);
  const [numCurrentWords, setNumCurrentWords] = useState(0);
  const [isOverFlow, setIsOverFlow] = useState(false);

  // currentEssayContext dispatch;
  const essayDispatch = useEssayDispatch();
  const userDispatch = useUserDispatch();
  

  // currentEssayContxt에서 갖고온 state;
  const {
    id,
    title,
    content,
    contentText,
    tags,
    isDueday,
    dueday,
    isCompeted,
    competitionId,
    competitionData,
    isAttemptSave
  } = useEssayState();

  // State for esssay before update context
  const [essayDataLocal, setEssayDataLocal] = useState({
    id: null,
    title: '',
    contentText: null,
    content: null,
    tags: [],
    competitionData: {},
    isCompeted: false,
    competitionId: null,
    isBranded: false,
    brandedId: null,
    brandedData: {},
    isPublished: false
  });

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // setState of currentEssaycontext;
  const setTitle = e => {
    console.log(e.target.value);
    setIsChange(true);
    // const dummy = e.target.value;
    setEssayDataLocal({ ...essayDataLocal, title: e.target.value });
    // console.log(essayDataLocal.title);
    essayDispatch({ type: SET_ESSAY_TITLE, value: e.target.value });
  };
  const setContent = data => {
    setIsChange(true);
    // setEssayDataLocal({ ...essayDataLocal, content: data });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: data });
  };

  // Get Essay Id in case of Modification
  const { search } = location; // 문자열 형식으로 결과값이 반환된다.
  const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환

  const getContentText = content => {
    let texts = '';
    content.blocks.forEach(block => {
      texts += block.text;
    });
    console.log(texts);
    return texts;
  };

  const chkStrLength = str => {
    let countKr = 0;
    let countEng = 0;
    let totalLength = 0;
    countKr = `${escape(str)}%u`.match(/%u/g).length - 1;
    countEng = str.length - countKr;
    totalLength = countEng + countKr;
    setNumCurrentWords(totalLength);
    return totalLength;
  };

  // Fetch Essay Data only In case of Modification
  const fetchData = async () => {
    if (queryObj['?essayId'] !== undefined) {
      setEssayDataLocal({ ...essayDataLocal, id: queryObj['?essayId'] });
    }
    if (queryObj['?competitionId'] !== undefined) {
      setEssayDataLocal({
        ...essayDataLocal,
        competitionId: queryObj['?competitionId'],
      });
    }
    if (queryObj['?brandedId'] !== undefined) {
      console.log(queryObj['?brandedId']);
      setEssayDataLocal({
        ...essayDataLocal,
        brandedId: queryObj['?brandedId'],
      });
    }

    try {
      // In case of Id Exist (not 1st writting)
      if (queryObj['?essayId'] !== undefined) {
        console.log('EDIT MODE');
        const body = [queryObj['?essayId']];

        await essayApi.getEssaysById(body).then(async essayData => {
          console.log(essayData.data[0]);
          const tags = essayData.data[0].tags.filter(tag => tag)
          setEssayDataLocal({
            ...essayDataLocal,
            id: essayData.data[0]._id,
            title: essayData.data[0].title,
            contentText: essayData.data[0].contentText,
            content: JSON.parse(essayData.data[0].content),
            tags: tags,
            isCompeted: essayData.data[0].isCompeted,
            isBranded: essayData.data[0].isBranded,
            isPublished: essayData.data[0].isPublished
          });
          const text = essayData.data[0].contentText;
          const lengthChar = chkStrLength(text);
          setNumCurrentWords(lengthChar);
          console.log("dispatch!!!!!");
          essayDispatch({ type: SET_NUM_CURRENT_WORDS, value: lengthChar });
          essayDispatch({
            type: SET_IS_COMPETED,
            value: essayData.data[0].isCompeted,
          });
          essayDispatch({
            type: SET_IS_PUBLISHED,
            value: essayData.data[0].isPublished,
          });
          essayDispatch({ type: SET_ESSAY_ID, value: essayData.data[0]._id });
          essayDispatch({
            type: SET_ESSAY_TITLE,
            value: essayData.data[0].title,
          });
          essayDispatch({
            type: SET_ESSAY_CONTENT,
            value: JSON.parse(essayData.data[0].content),
          });
          essayDispatch({
            type: SET_ESSAY_CONTENT_TEXT,
            value: essayData.data[0].contentText,
          });
          essayDispatch({
            type: SET_ESSAY_TAGS,
            value: tags,
          });

          if (
            essayData.data[0].competitionId !== null &&
            essayData.data[0].isCompeted
          ) {
            console.log(essayData.data[0]);
            console.log(essayData.data[0].competitionId);
            // In case of the essay participated Competition
            await competitionApi
              .getCompetitions([essayData.data[0].competitionId])
              .then(competition => {
                console.log(competition.data[0]);
                // BAD CODE: 위의 setState부분을 반복하지 않으면 에디터 데이터라 로드되지 않음.
                setEssayDataLocal({
                  ...essayDataLocal,
                  id: essayData.data[0]._id,
                  title: essayData.data[0].title,
                  contentText: essayData.data[0].contentText,
                  content: JSON.parse(essayData.data[0].content),
                  tags: tags,
                  isCompeted: true,
                  competitionData: competition.data[0],
                  competitionId: essayData.data[0].competitionId,
                });
                essayDispatch({
                  type: SET_IS_COMPETED,
                  value: true,
                });
                essayDispatch({
                  type: SET_COMPETITION_ID,
                  value: essayData.data[0].competitionId,
                });
                essayDispatch({
                  type: SET_COMPETITION_DATA,
                  value: competition.data[0],
                });
              });
          }
        });
      }
      // In case Write by Competition Banner
      else if (queryObj['?competitionId'] !== undefined) {
        await competitionApi
          .getCompetitions([queryObj['?competitionId']])
          .then(competition => {
            console.log(competition.data[0]);
            setEssayDataLocal({
              ...essayDataLocal,
              isCompeted: true,
              competitionId: queryObj['?competitionId'],
              competitionData: competition.data[0],
            });
            essayDispatch({
              type: SET_IS_COMPETED,
              value: true,
            });
            essayDispatch({
              type: SET_COMPETITION_ID,
              value: competition.data[0]._id,
            });
            essayDispatch({
              type: SET_COMPETITION_DATA,
              value: competition.data[0],
            });
          });
      }
      // In case of Branded Story
      else if (queryObj['?brandedId'] !== undefined) {
        await brandedApi
          .getBrandeds([queryObj['?brandedId']])
          .then(branded => {
            console.log(branded.data[0]);
            setEssayDataLocal({
              ...essayDataLocal,
              isBranded: true,
              brandedId: queryObj['?brandedId'],
              brandedData: branded.data[0],
            });
            essayDispatch({
              type: SET_IS_BRANDED,
              value: true,
            });
            essayDispatch({
              type: SET_BRANDED_ID,
              value: branded.data[0]._id,
            });
            essayDispatch({
              type: SET_BRANDED_DATA,
              value: branded.data[0],
            });
          });
      }

    } catch {
      // No Action
    } finally {
      setLoading(false);
    }
  };

  // Sync LocalEssayData with ContextEssayData
  const getEssayContext = () => {
    console.log('load context!');
    console.log(content);
    setEssayDataLocal({
      id,
      title,
      content,
      contentText,
      tags,
      competitionData,
      isCompeted,
      competitionId,
    });
  };

  const [isLimitInfo, setIsLimitInfo] = useState(false);

  useEffect(() => {
    fetchData();
    getEssayContext();
  }, []);


  function keyDownHandler(e) {
    if ((e.key === 'ArrowUp' && e.shiftKey)
      || (e.key === 'ArrowDown' && e.shiftKey)) {
      e.stopPropagation();
    }

    if (e.keyCode === 32) {
      console.log('space');
      e.stopPropagation();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler, true);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    }
  }, []);

  const onChangeEditor = (event) => {
    setIsChange(true);
    const {
      target: { value },
    } = event;
    console.log(value);
    setEssayDataLocal({
      ...essayDataLocal,
      contentText: value
    });
    setNumCurrentWords(value.length);
    essayDispatch({
      type: SET_NUM_CURRENT_WORDS,
      value: value.length,
    });
    essayDispatch({ type: SET_ESSAY_CONTENT, value: value });
    essayDispatch({ type: SET_ESSAY_CONTENT_TEXT, value: value });
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
          <Container
            className="flex_col col1 col_grid_mobile"
          >
            <Prompt
              when={!isAttemptSave && isChange}
              message={`작성중인 글이 저장되지 않았습니다. 현재 페이지를 떠나시겠습니까?`}
            />
            <div className="col_editor center_margin margin_b_4x">
              {essayDataLocal.isCompeted && (
                <BannerWriteForCompetition
                  className="margin_b_3x"
                  competition={essayDataLocal.competitionData}
                />
              )}
              {essayDataLocal.isBranded && (
                <BannerWriteForBranded
                  className="margin_b_3x"
                  branded={essayDataLocal.brandedData}
                />
              )}
              <Title
                className="title col1 margin_b_4x"
                style={{ padding: '0px !important' }}
                placeholder="제목"
                onChange={setTitle}
                value={essayDataLocal.title}
                type="text"
              />
              <EditorContainer className="editor col1 margin_b_2x">
                {/* <Editor
                  contentEditable={true}
                >
                  {essayDataLocal.contentText}
                </Editor> */}
                <TextareaAutosize
                  style={{resize:'none'}}
                  rows={4}
                  value={essayDataLocal.contentText}
                  onChange={onChangeEditor}
                  placeholder={`${username}님의 이야기...`}
                  cacheMeasurements
                />
              </EditorContainer>
              <AmountGuide>
                {
                  !isMobile &&
                  <>
                    <p
                      className={`body_s regular margin_b_1x ${isOverFlow ? 'primary' : 'black300'
                        }`}
                    >
                      {!isOverFlow
                        ? '2200자 이하의 글만 \n에세이로 출판 가능합니다.'
                        : '2200자가 넘었습니다. 출판전에 글자수를 줄여주세요.'}
                    </p>
                    <p className="body_s regular black300">
                      <span className="primary">{numCurrentWords}</span>
                            &nbsp;/&nbsp;
                          <span>{numMaxWords}</span>
                    </p>
                  </>
                }
                {
                  isMobile &&
                  <>
                    <img src={IcHome} alt='' />
                    {
                      isLimitInfo ?
                        <div
                          className={`body_s flex_row align_center regular ${isOverFlow ? 'primary' : 'black300'
                            }`}
                        >
                          <p>
                          {!isOverFlow
                            ? '2200자 이하의 글만 \n에세이로 출판 가능합니다.'
                            : '2200자가 넘었습니다. 출판전에 글자수를 줄여주세요.'}
                          </p>
                          <Info onClick={
                            () => {
                              setIsLimitInfo(!isLimitInfo);
                            }
                          }>
                            ?
                          </Info>
                        </div> :
                        <div className="body_s flex_row align_center regular black300">
                          <p>
                          <span className="primary">{numCurrentWords}</span>
                            &nbsp;/&nbsp;
                          <span>{numMaxWords}</span>
                          </p>       
                          <Info onClick={
                            () => {
                              setIsLimitInfo(!isLimitInfo);
                            }
                          }>
                            ?
                          </Info>
                        </div>
                    }
                  </>
                }

              </AmountGuide>
            </div>
          </Container>
        )}
      {isSave && <Redirect push to="/save" />}
      {isDelete && <Redirect push to="/mypage" />}
    </>
  );
}

export default Write;
