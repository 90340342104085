import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ResetPasswordForm from '../template/ResetPassword/ResetPasswordForm';

// 안녕
function ResetPassword({location}) {

  return (
    <Container maxWidth="sm" className="padding_2x whte_bg margin_t_2x">
      <Paper elevation={0} variant="">
        <ResetPasswordForm 
          location={location}
        />
      </Paper>
    </Container>
  );
}

export default ResetPassword;
