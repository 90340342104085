import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Competition from '../../../_models/competition';

const CardCompetitionStatus = ({ competition, to, className, username }) => (
  <Link to={to} className={`flex_col col1 ${className}`}>
    <div className="col1">
      <h2 className="primary bold margin_b_1x">{`${competition.month}월 ${competition.week}주차 백일장: ${competition.question}`}</h2>
    </div>
    <div className="flex_row col1">
      {competition.participantList.length > 0 ? (
        <>
          <p className="body black300 regular col1 margin_b_1x">{`${competition.participantList[0].username} 외 ${competition.participantList.length}명이 참여중입니다.`}</p>
          <div className="flex_row col1 margin_b_2x">
            {competition.participantList.map(user => {
              return (
                <div
                  className="avatar_m flex_col flex_center margin_r_1x"
                  key={user._id}
                >
                  {user.username[0] + user.username[1]}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        username !== null && (
          <p className="body_s black300 regular col1 margin_b_1x">
            {`'${username}'님 아직 참가자가 없습니다.`}
          </p>
        )
      )}
    </div>
  </Link>
);

CardCompetitionStatus.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default CardCompetitionStatus;
