import React from 'react';
import styled from 'styled-components';

// Component
// Container & Section
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ConainerO from '../../components/Layout/Container/ContainerO';

// Card
import CardBigCompetition from '../../components/Cards/Competition/CardBigCompetition';
import CardBigWaitCompetition from '../../components/Cards/Competition/CardBigWaitCompetition';

function CurrentCompetition({ competition, username, lastCompetitionDate }) {
  return (
    <>
      {competition !== null ? (
        <>
          <div className="col1 dv_left no_ver_line_mobile margin_b_2x_mobile">
            <CardBigCompetition
              competition={competition}
              username={username}
              key={competition.id}
            />
          </div>
        </>
      ) : (
          <>
            <div className="col1 dv_left no_ver_line_mobile margin_b_2x_mobile">
              <CardBigWaitCompetition lastCompetitionDate={lastCompetitionDate} />
            </div>
          </>
        )}
    </>
  );
}
export default CurrentCompetition;
