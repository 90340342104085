import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BttContainer = styled.button`
  width: 36px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 4px;
  }
`;
const Title = styled.p`
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.bttText.size};
  line-height: ${props => props.theme.type.bttText.height};
`;

const BttSquareIcon = ({ title, isOn, imgOn, imgOff, onClick }) => {
  return (
    <BttContainer onClick={onClick} className="flex_center">
      {isOn ? <img src={imgOn} alt="" /> : <img src={imgOff} alt="" />}
    </BttContainer>
  );
};

BttSquareIcon.propTypes = {
  title: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  imgOn: PropTypes.string.isRequired,
  imgOff: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BttSquareIcon;
