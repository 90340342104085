import React from 'react';
import styled from 'styled-components';
import ContainerC from '../Layout/Container/ContainerC';
import BttBasic from '../Buttons/ButtonLink/BttBasic';
import illust_cat from '../../assets/img/ill/illust_cat_at_window.png';

const Container = styled(ContainerC)`
   color: ${props => props.theme.color.brand.primary700};
   ${props => props.theme.size.cardPaddingBlock}
   height: 324px;
   position: relative;
   @media screen and (max-width: 480px) {
    height: auto;
   }
`;

const Overline = styled.p`
  ${props => props.theme.type.overline}
  margin-bottom: 8px;
`;

const Title = styled.p`
  ${props => props.theme.type.h3}
  margin-bottom: 24px;
`;

const ImgContainer = styled.div`
  position: absolute;
  left: calc((100% - 320px)/2);
  top: calc((100% - 274px)/2);
  width: 320px;
  height: 274px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  img{
    width: 252px;
    height: 240px;
  }
  @media screen and (max-width: 480px) {
    position: static;
    width: 100%;
    height: 200px;
    img{
      width: 190px;
      height: auto;
    }
  }
`;

const BannerAboutCompetition = ({}) => {
  return (
    <Container>
      <Overline>
        그런데 말이죠...
      </Overline>
      <Title>
        망월장이 무엇인가요?
      </Title>
      <BttBasic
        title={'망월장에 대해 알아보기'}
        type={'outlined'}
        to={'/AboutCompetition'}
      />
      <ImgContainer>
        <img src={illust_cat} alt='' />
      </ImgContainer>
    </Container>
  );
}

export default BannerAboutCompetition;