import React from 'react';
import styled from 'styled-components';

// Component
// Container & Section
import PartitionVer from '../../components/Layout/Grid/PartitionVer';

// Card
import CardCompetitionStatusSmall from '../../components/Cards/Competition/CardCompetitionStatusSmall';

// Img
import ImgGirlWritingFront from "../../assets/img/ill/img_girl_writing_front.png";

// Styled Component
const SectionTitleCol = styled.div`
  width: 200px;
`
const SectionContentCol = styled.div`
  position: relative;
  width: calc(100% - 200px);
  padding-left: 24px;
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0px;
  }
`

const ContentCol = styled.div`
  width: calc(100% - 164px);
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 0px;
  }
`

const ImgCol = styled.div`
  width: 164px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
`

function SetCompetitionJoin({
  competition,
  setIsCompeted,
  isCompeted,
  username,
  title
}) {
  return (
    <PartitionVer
      numRow={2}
      index={0}
      className="flex_row_col col1"
    >
      <SectionTitleCol>
        <h3 className="bold primary col1 margin_b_1x">백일장 참여</h3>
      </SectionTitleCol>
      
      <SectionContentCol className="flex_row_col">
        <ContentCol className='flex_col'>
          {competition !== null ? (
            <>
              <CardCompetitionStatusSmall
                className="row1"
                competition={competition}
                username={username}
              />
              <div className='flex_row body_s regular align_center'>
                <input
                  type="checkbox"
                  id="isCompeted"
                  name="isCompeted"
                  checked={isCompeted}
                  onChange={setIsCompeted}
                />
                <label htmlFor="isCompeted">{`"${title}"로 백일장 참여하기`}</label>
              </div>
            </>
        ):(
          <h2 className="regular">진행중인 백일장이 없습니다.</h2>
        )}
        </ContentCol>
        <ImgCol>
          <img style={{width:'87px', height: '157px'}} src={ImgGirlWritingFront} alt='' />
        </ImgCol>
      </SectionContentCol>
    </PartitionVer>
  );
}
export default SetCompetitionJoin;
