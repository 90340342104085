import React from 'react';
import BrandedDetailPresenter from './BrandedDetailPresenter';
import { useUserState } from '../../../context/currentUserContext';

function CompetitionDetailContainer({ location }) {
  const { loaded, username, userData } = useUserState();
  return (
    loaded && (
      <>
        <BrandedDetailPresenter
          username={username}
          userData={userData}
          location={location}
        />
      </>
    )
  )
}

export default CompetitionDetailContainer;