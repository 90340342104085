import React from 'react';
import styled from 'styled-components';

// img
import img_avatar_bl_bl from '../../assets/img/ill/illust_avatar_blueblack.png';

// components
import Tag from '../../components/Tag/Tag';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';

const Container = styled.div`
  /* position: fixed; */
  width: 100%;
  min-height: 100%;
  left: 0;
  top: ${props => props.isActive ? '0%' : '0%'};
  opacity: ${props => props.isActive ? '1' : '0'};
  pointer-events: ${props => props.isActive ? 'auto' : 'none'};
  transition: all 0.3s ease-in;
  background-color: ${props => props.theme.color.brand.primary700};
  padding-top: 200px;
`;

const Content = styled.div`
  width: 768px;
  margin-left: auto;
  margin-right: auto;
`;

const Top = styled.div`
  width: 100%;
  ${props => props.theme.layout.flexRowCenterBlock}
`;

const Title = styled.div`
  ${props => props.theme.type.h2};
  color: ${props => props.theme.color.ui.white700};
  font-family: ${props => props.theme.type.light};
`;

const Tags = styled.div`
  width: 100%;
  display: flex;
  padding-left: 12px;
  div{
    margin-right: 4px;
  }
`;

const AvatarBig = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  margin-right: 12px;
  background-image: url(${img_avatar_bl_bl});
  background-size: cover;
  background-position: center;
`;

const EssayPostGuide = ({
  isActive,
  targetUsername,
  essayList
}) => {
  return (
    <Container
      isActive={isActive}
    >
      <Content>
        <Top>
          <AvatarBig />
          <Title>
            {`“${targetUsername}님의 다른글도 읽어보세요.”`}
            <Tags>
              <Tag type="outlined_white">
                꾸준한 활동
              </Tag>
              <Tag type="outlined_white">
                새로운 글감 발굴
              </Tag>
              <Tag type="outlined_white">
                활발한 댓글러
              </Tag>
            </Tags>
          </Title>
        </Top>
        <Section>
            <SectionTitle
              title1={`${targetUsername}님의 다른 에세이`}
              isDark={true}
            />
            <ContainerO className="flex_row">
                  {essayList.map((essay, i) => (
                    <GridMatrix
                      colPC={2}
                      colMb={2}
                      index={i}
                      key={i}
                      onClick={()=>{
                        console.log('click');
                        const {gtag} = window;
                        gtag('event', 'click', {
                          'event_category' : 'user page behaviour',
                          'event_label' : 'read user essay'
                        });
                      }}          
                    >
                      <CardRegularEssay
                        to={`/post/${essay._id}`}
                        essay={essay}
                        isDark={true}
                      />
                    </GridMatrix>
                  ))}
            </ContainerO>
          </Section>
      </Content>
    </Container>
  )
}

export default EssayPostGuide;