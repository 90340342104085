import {
  SET_AD_ID,
  SET_AD_TITLE,
  SET_AD_CONTENT,
  SET_AD_TAGS,
  ADD_AD_TAGS,
  REMOVE_AD_TAGS,
  SET_NUM_CURRENT_WORDS_AD,
  RESET_AD,
  SET_MEDIATYPE,
  SET_AD_IMAGE,
} from '../actions';

export const initialState = {
  id: '',
  title: '',
  content: null,
  tags: [],
  numCurrentWords: 0,
  isPublish: false,
  mediaType: '',
  thumbImg:'',
};

const insReducer = (state, action) => {
  switch (action.type) {
    case SET_AD_ID:
      return {
        ...state,
        id: action.value,
      };
    case SET_AD_TITLE:
      // 타이틀 string을 value로 받음
      console.log(`${SET_AD_TITLE} ${action.value}`);
      return {
        ...state,
        title: action.value,
      };
    case SET_AD_CONTENT:
      // Content object를 value로 받음
      console.log(SET_AD_CONTENT);
      console.log(action.value);
      return {
        ...state,
        content: action.value,
      };
    case SET_AD_TAGS:
      return {
        ...state,
        tags: action.value,
      };
    case ADD_AD_TAGS:
      // 태그 object를 value로 받음
      console.log(ADD_AD_TAGS);
      console.log(action.value);
      return {
        ...state,
        tags: [...state.tags, action.value],
      };
    case REMOVE_AD_TAGS:
      // 태그 타이틀을 string을  value로 받음
      console.log(REMOVE_AD_TAGS);
      console.log(action.value);
      console.log(state.tags.filter(tag => tag.tagName !== action.value));
      return {
        ...state,
        tags: state.tags.filter(tag => tag.tagName !== action.value),
      };

    case SET_NUM_CURRENT_WORDS_AD:
      console.log(action.value);
      return {
        ...state,
        numCurrentWords: action.value,
      };
    case SET_MEDIATYPE:
      // Radio input의 value를 받음
      console.log(SET_MEDIATYPE);
      console.log(action.value);
      return {
        ...state,
        mediaType: action.value,
      };
    case SET_AD_IMAGE:
      console.log(SET_AD_IMAGE)
      console.log(action.value);
      return {
        ...state,
        thumbImg: action.value,
      }
    case RESET_AD:
      console.log('RESET_AD!!!!!!');
      return {
        title: '',
        content: null,
        tags: [],
        numCurrentWords: 0,
        isPublish: false,
        mediaType: '',
      };
    default:
      break;
  }
  return 0;
};

export default insReducer;
