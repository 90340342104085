import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BttTextMore from './BttTextMore';

const Container = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% - 12px);
`;

const BttTextSectionMore = ({ title, to, className }) => {
  return (
    <Container className={className}>
      <BttTextMore to={to} title={title} />
    </Container>
  );
};

BttTextSectionMore.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  // className: PropTypes.string.isRequired,
};

export default BttTextSectionMore;
