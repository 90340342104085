import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, withRouter, Redirect } from 'react-router-dom';

import logo_img from '../../assets/img/logo/blbl_logo.svg';

const Container = styled.div`
${props => (props.isHide?'display: none !important;':'')}
  margin-top: 160px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  ${
    props => {
      switch(props.mode) {
        case 'simple':
          return `
            width: calc(100%);
            padding-left: 48px;
            padding-right: 48px;
            @media screen and (max-width: 480px) {
              width: 100%;
            }
          `;
        case 'regular':
          return `
            width: 1200px;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: 480px) {
              width: 100%;
            }
          `;
      }
    }
  }
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: 480px) {
    padding: 40px 20px;
  }
  border-top: solid 1px ${props => props.theme.color.brand.primary300};
`;

const Logo = styled.img`
  width: 110px;
  height: 19px; 
`;

const Company = styled.div`
  width: 412px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 40px;
  }
  white-space: break-spaces;
  ${props => props.theme.type.body3}
`;

const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 412px);
  @media screen and (max-width: 480px) {
    width: 100%;
    padding-bottom: 120px;
  }
`;

const MenuColumn = styled.div`
  width: 165px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;

const Menu = styled(Link)`
  ${props => props.theme.type.body3}
  margin-bottom: 8px;
`;

const MenuExternal = styled.a`
  ${props => props.theme.type.body3}
  margin-bottom: 8px;
`;

const MenuA = styled.a`
  ${props => props.theme.type.body3}
  margin-bottom: 8px;
`;


export default withRouter(({ location, loged }) => {
  // state for setting simple version of GNB in case of writting page
  const [isSimpleMode, setIsSimpleMode] = useState(false);
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const isRouteWrite = location.pathname.includes('write');
    const isRouteEdit = location.pathname.includes('edit');
    const isRouteSave = location.pathname.includes('save');
    const isRouteMypage = location.pathname.includes('mypage');
    const isRoutePost = location.pathname.includes('post');

    if (isRouteWrite || isRouteSave ||isRouteEdit){
      setIsHide(true);
    }
    if (isRouteMypage || isRoutePost){
      setIsSimpleMode(true);
    }
    else{
      setIsSimpleMode(false);
    }

  },[location]);
  
  const CompanyIntro = `주식회사 이디그나 \n대표 박세현\n사업자등록번호 216-81-48971\n서울시 마포구 잔다리로 118 2층, 206호\n \n©Idigna Inc.\n`;
  

  return(
    <Container
      mode={isSimpleMode?'simple':'regular'}
      isHide={isHide}
      >
      <div className={'col1 flex_col flex_center'}>
        <Logo
          className={'margin_b_3x'}
          src={logo_img}
          alt=''
        />
      </div>
      <Company>
        {CompanyIntro}
      </Company>
      <MenuContainer>
        <MenuColumn>
          <MenuExternal 
            href={'https://lacy-ketchup-a5d.notion.site/FAQ-e98cbd963d8e4a7dbb2eca1bc424c798'}
            target={'_blank'}
          >FAQ</MenuExternal>
          <Menu to={'/ServiceAgreement'}>이용약관</Menu>
          <Menu to={'/privacyPolicy'}>개인정보 처리방침</Menu>
          <Menu>p@blue-black.life</Menu>
        </MenuColumn>
        <MenuColumn>
          {/* <Menu>진행 중인 망월장</Menu> */}
          <Menu to="/feed?tabId=editors">에디터스 픽</Menu>
          <Menu to="/AboutCompetition">망월장이 무엇인가요?</Menu>
          <Menu to={loged?"/write":"/signup?from=write"}>기록하기</Menu>
          {loged && <Menu to="/mypage">마이페이지</Menu>}
        </MenuColumn>
        <MenuColumn className={''}>
          {/* <Menu to="https://www.instagram.com/blue_black.life/">블루블랙 페이스북</Menu> */}
          <MenuA target='_blank' href="https://www.instagram.com/blue_black.life/">블루블랙 인스타그램</MenuA>
        </MenuColumn>
      </MenuContainer>
    </Container>
  );
});