import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CardWrapper from '../../Layout/Container/CardWrapper';
import TagList from "../Element/TagList";
import MarkEssay from '../Element/MarkEssay';

const Container = styled(CardWrapper)`
  /* height: 400px; */
  @media screen and (max-width: 480px) {
    
  }
`;

const Title = styled(Link)`
  cursor: pointer;
  width: calc(100% - 48px);
  ${props => props.theme.type.h5}
  margin-bottom: 48px;
  height: 52px;
  word-break: keep-all;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
  @media screen and (max-width: 480px) {
    -webkit-line-clamp: 2;
    height: 44px;
    margin-bottom: 16px;
  }
`;
const Title2 = styled(Title)`
  margin-bottom: 48px;
  @media screen and (max-width: 480px) {
    margin-bottom: 24px;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 202px;
  padding: 16px;
  background-color: ${ props => props.theme.color.brand.primary50};
  ${props => props.theme.layout.flexColCenterBlock}
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    padding: 16px;
    width: 100%;
    height: 246px;
  }
`;

const AutoSizedImage = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: fill;
  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
  }
`;

const Info = styled.p`
  ${props => props.theme.type.caption2}
  color: ${props => props.theme.color.ui.middle2};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const Keyword = styled.div`
  max-width: 86px;
  line-height: 18px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top:2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const CardRegularPrizedItem = ({ prizedItem, className, to }) => (
  <Container to={to} className={`flex_col col1 ${className}`}>
      <Title2 to={to} className="bold primary">{prizedItem.title}</Title2>
    <ImgContainer>
      <AutoSizedImage src={prizedItem.thumbnail} alt='' />
    </ImgContainer>
    {/* <TagList tagList={prizedItem.tags} /> */}
    <Info>{prizedItem.author}지음</Info>
    <Info>{prizedItem.publisher}</Info>
  </Container>
);

export default CardRegularPrizedItem;
