/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation
} from 'react-router-dom';

// API
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import { competitionApi, adminApi, alarmApi, tagApi } from '../../../api/api';

// Component
import Section from '../../../components/Layout/Section/Section';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import ContainerC from '../../../components/Layout/Container/ContainerC';
import Loading from '../../../components/Loading/Loading';
import BttActionHuge from '../../../components/Buttons/ButtonAction/BttActionHuge';
import ChipBasic from '../../../components/Chips/ChipBasic';

// Template
import CompetitionPrizeStatus from '../../../template/Admin/CompetitionPrizeStatus';

import config from '../../../config';

const TagInputContainer = styled.div`
  /* width: 100%; */
  position: relative;
  /* background-color: #fff; */
`;

const TagList = styled.div`
  position: absolute;
  z-index: 99;
  top: 52px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  ${props => props.theme.shape.elavation50Block}
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;
const TextInputRound = styled.input`
  height: 56px !important;
  border-radius: 4px;
  padding-left: 16px;
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;


// From Material UI
function CreatNewBrandedPresenter({ username, userData }) {
  // 이거 Container부분에도 있길래 나중에 쓰일까봐 남겨놓습니다.
  // un-used면 지우는게 맞습니다.

  // Local State
  const [currentCompetition, setCurrentCompetition] = useState(null);
  // const [previousCompetition, setPreviousCompetition] = useState({});
  // const [
  //   currentCompetitionEssayList,
  //   setCurrentCompetitionEssayList,
  // ] = useState({});
  const [branded, setNewBranded] = useState({
    question: '',
    description: '',
    startDate: null,
    endDate: null,
    image:'',
    prizeList: [
      {
        title: '',
        author: '',
        publisher: '',
        thumbnail: '',
        description: '',
        tags: [],
      },
    ],
    master: {
      masterType: '',
      name: '',
      about: '',
      profile: '',
      impression: ''
    }
  });

  const [isPreview, setIsPreview] = useState(false);
  const [previewTagList, setPreviewTagList] = useState([]);
  const [tagOnInput, setTagOnInput] = useState('');

  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });
  const [isShowMore, setIsShowMore] = useState(false);


  const SNACK_MSG_EXIST_COMPETITION = '이미 해당 주차에 브랜디드 스토리이 존재합니다.';
  const SNACK_MSG_COMPETITION_CREATED = '브랜디드 스토리이 생성되었습니다.';
  const SNACK_MSG_COMPETITION_END = '브랜디드 스토리이 종료되었습니다.';

  // SNACK BAR CONTROL
  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });

    // un-used 해결이 당장에 콘솔찍는 것 말고 생각이 안 나네요.. ㅠ
    console.log(snackBarControl);
  };


  const createBranded = async () => {
    console.log(branded);
    await adminApi.createBranded(branded).then(async res => {
      console.log('createCompetitionData: ', res);
      // 브랜디드 스토리이 이미 있으면
      if (res.data === 'Already exist in this week') {
        openSnackBar(SNACK_MSG_EXIST_COMPETITION);
        console.log('already competition exist');
      }
      // 브랜디드 스토리이 없으면 생성
      else {
        // 스넥바 메세지로 생성되었음을 피드백
        openSnackBar(SNACK_MSG_COMPETITION_CREATED);

        // 브랜디드 스토리 시작 알람 생성
        // await alarmApi.brandedStartAlarm(res.data);

        // 텍스트 필드 비우기
        setNewBranded({
          question: '',
          startDate: null,
          endDate: null,
          description: '',
          master: {
            masterType: '',
            name: '',
            about: '',
            profile: '',
            impression: ''
          },
          prizeList: [{
            title: '',
            thumbnail: '',
            description: '',
            tags: []
          }],
        });
      }
    });
  };

  const fileUpload = async targretFile => {
    const url = `${config.apiUrl}/upload`;
    const formData = new FormData();
    formData.append('file', targretFile);
    const header = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const result = await post(url, formData, header);
    return result;
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      width: 200,
      height: 56,
      borderRadius: '4px !important;'
    },
    label: {
      top: 4,
      left: 4
    }
  }));


  // Tag
  const addTag = tagName => {
    setIsPreview(false);
    setNewBranded({
      ...branded,
      prizeList: [{
        ...branded.prizeList[0],
        tags: [...branded.prizeList[0].tags, tagName],
      }],
    })
  };

  const removeTag = tagName => {
    setNewBranded({
      ...branded,
      prizeList: [{
        ...branded.prizeList[0],
        tags: branded.prizeList[0].tags.filter(tag => tag !== tagName),
      }],
    })
  };

  const onTagPreview = async string => {
    await tagApi.getTagsByString(string).then(tagList => {
      console.log(tagList.data);

      setPreviewTagList(tagList.data);
      if (tagList.data.length) {
        setIsPreview(true);
      }
    });
  };


  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  const classes = useStyles();

  return (
    <>
      <div className="flex_col margin_center col_grid_mobile router_area_top">
        <Section>
          <SectionTitle title1="새로운 브랜디드 스토리 생성하기" />
          <ContainerC>
            <div className="flex_row col1 margin_b_2x">
              <h3 className="bold black300 margin_b_tiny">브랜디드 스토리 질문</h3>
              <input
                className="col1 big bold primary"
                id="outlined-adornment-password-conform"
                placeholder="브랜디드 스토리 질문을 입력해주세요."
                type="text"
                defaultValue="test"
                value={branded.question}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    question: event.target.value,
                  })
                }
              />
            </div>
            <div className="flex_row col1 margin_b_2x">
              <h3 className="bold black300 margin_b_tiny">브랜디드 스토리 설명</h3>
              <input
                className="col1 big bold primary"
                id="outlined-adornment-password-conform"
                placeholder="브랜디드 스토리에 대한 설명을 입력해주세요."
                type="text"
                value={branded.description}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    description: event.target.value,
                  })
                }
              />
            </div>
            <div className="flex_row col1 margin_b_2x">
              <h3 className="bold col1 black300 margin_b_tiny">
                브랜디드 스토리 날짜
                </h3>
              <TextField
                id="date"
                label="시작 날짜"
                type="date"
                defaultValue={new Date()}
                value={branded.startDate}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    startDate: event.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="date"
                label="종료 날짜"
                type="date"
                defaultValue={new Date()}
                value={branded.endDate}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    endDate: event.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <input
              className="col1 big bold primary margin_b_1x"
              id="outlined-adornment-password-conform"
              placeholder="브랜드 스토리 이미지 업로드"
              type="file"
              onChange={event => {
                console.log(event);
                fileUpload(event.target.files[0]).then(res => {
                  setNewBranded({
                    ...branded,
                    image: res.data.url,
                  });
                  alert(`file uploaded: ${res.data.url}`);
                });
                console.log(event.target.files[0]);
              }}
            />
            <div className="flex_row space_between col1 margin_b_2x">
              <h3 className="bold col1 black300 margin_b_tiny">
                주최 브랜드
                </h3>
              <input
                className="col2_m big margin_b_1x"
                id="outlined-adornment-password-conform"
                placeholder="주최 브랜드명"
                type="text"
                value={branded.prizeList.name}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    master: {
                      ...branded.master,
                      name: event.target.value,
                    },
                  })
                }
              />
              <input
                className="col2_m big margin_b_1x"
                id="outlined-adornment-password-conform"
                placeholder="주최자 타입. celebrity | blueblack"
                type="text"
                value={branded.master.masterType}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    master: {
                      ...branded.master,
                      masterType: event.target.value,
                    },
                  })
                }
              />
              <textarea
                className="col1 big margin_b_1x"
                id="outlined-adornment-password-conform"
                placeholder="주최 브랜드 설명"
                type="text"
                value={branded.master.about}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    master: {
                      ...branded.master,
                      about: event.target.value,
                    },
                  })
                }
              />
              <input
                className="col1 big bold primary margin_b_1x"
                id="outlined-adornment-password-conform"
                placeholder="주최자 이미지 업로드"
                type="file"
                onChange={event => {
                  console.log(event);
                  fileUpload(event.target.files[0]).then(res => {
                    setNewBranded({
                      ...branded,
                      master: {
                        ...branded.master,
                        profile: res.data.url,
                      },
                    });
                    alert(`file uploaded: ${res.data.url}`);
                  });
                  console.log(event.target.files[0]);
                }}
              // onChange={e => console.log(e.target.files[0])}
              />
              <textarea
                className="col1 big"
                id="outlined-adornment-password-conform"
                placeholder="브랜디드 스토리 소감"
                type="text"
                value={branded.master.impression}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    master: {
                      ...branded.master,
                      impression: event.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="flex_row space_between col1 margin_b_2x">
              <h3 className="bold col1 black300 margin_b_tiny">
                상품 기본 정보
                </h3>
              <input
                className="col1 big margin_b_1x"
                id="outlined-adornment-password-conform"
                placeholder="상품명"
                type="text"
                value={branded.prizeList[0].title}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    prizeList: [{
                      ...branded.prizeList[0],
                      title: event.target.value,
                    }],
                  })
                }
              />
              <textarea
                className="col1 big"
                id="outlined-adornment-password-conform"
                placeholder="상품 설명"
                type="text"
                value={branded.prizeList[0].description}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    prizeList: [{
                      ...branded.prizeList[0],
                      description: event.target.value,
                    }],
                  })
                }
              />
            </div>
            <div className="flex_row space_between col1 margin_b_2x">
              <h3 className="bold col1 black300 margin_b_tiny">
                상품 상세 정보
                </h3>
              <input
                className="col3_m big margin_b_tiny"
                id="outlined-adornment-password-conform"
                placeholder="저자"
                type="text"
                value={branded.prizeList[0].author}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    prizeList: [{
                      ...branded.prizeList[0],
                      author: event.target.value,
                    }],
                  })
                }
              />
              <input
                className="col3_m big margin_b_tiny"
                id="outlined-adornment-password-conform"
                placeholder="출판사"
                type="text"
                value={branded.prizeList[0].publisher}
                onChange={event =>
                  setNewBranded({
                    ...branded,
                    prizeList: [{
                      ...branded.prizeList[0],
                      publisher: event.target.value,
                    }],
                  })
                }
              />
              <TagInputContainer className={'col3_m'}>
                <TextInputRound
                  className="col1"
                  type="text"
                  placeholder="주제어"
                  value={tagOnInput}
                  onChange={e => {
                    let world;
                    world = e.target.value;

                    if (world.replace(/\s/g, '').length > 0) {
                      setTagOnInput(world);
                      onTagPreview(world);
                    } else {
                      setTagOnInput('');
                    }
                    console.log(`current Tag on input${tagOnInput}`);
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      console.log('Enter!');
                      addTag(tagOnInput);
                      setTagOnInput('');
                    }
                  }}
                />
                {/* 주제어 자동완성 리스트 */}
                {isPreview && (
                  <>
                    <TagList>
                      <p className="caption black700 regular margin_b_1x margin_t_1x">
                        <span className="bold primary">*</span> 다른
                          글쓴이들이 사용하고 있는 주제어입니다.
                        </p>
                      {previewTagList.map(tag => (
                        <TagContainer
                          onClick={e => {
                            addTag(tag.tagName);
                            setTagOnInput('');
                          }}
                        >
                          <div className="tooltip_outlined_dark">{`${tag.tagName} (${tag.count})`}</div>
                          <div className="caption black300 regular">
                            <span className="bold">{tag.owner.username}</span>
                              님이 제시.
                            </div>
                        </TagContainer>
                      ))}
                    </TagList>
                    {/* preview를 닫기위한 fakeBackdrop */}
                    <FakeBackDrop
                      onClick={() => {
                        setIsPreview(false);
                      }}
                    />
                  </>
                )}
              </TagInputContainer>
            </div>
            <div className={"flex_col col1 margin_b_2x"}>
              <h3 className="bold col1 black300 margin_b_tiny">
                등록된 상품 주제어
                  </h3>
              <div className={'flex_row col1'}>
                {
                  branded.prizeList[0].tags.length === 0 &&
                  <p className="caption black300 regular">등록된 주제어가 없습니다.</p>
                }
                {
                  branded.prizeList[0].tags.map((tag) =>
                    <ChipBasic
                      title={tag}
                      onClickRemove={removeTag}
                      className={'margin_r_1x'}
                    />
                  )
                }
              </div>
            </div>
            <div className="flex_row col1 margin_b_2x">
              <h3 className="bold black300 margin_b_tiny">
                상품 이미지 등록
                </h3>
              <input
                className="col1 big bold primary"
                id="outlined-adornment-password-conform"
                placeholder="상품 이미지 업로드"
                type="file"
                // value={newCompetition.prizeList.thumbnail}
                onChange={event => {
                  console.log(event);
                  fileUpload(event.target.files[0]).then(res => {
                    setNewBranded({
                      ...branded,
                      prizeList: [{
                        ...branded.prizeList[0],
                        thumbnail: res.data.url,
                      }],
                    });
                    alert(`file uploaded: ${res.data.url}`);
                  });
                  console.log(event.target.files[0]);
                }}
              // onChange={e => console.log(e.target.files[0])}
              />
            </div>
            <BttActionHuge
              className="col1 margin_b_4x"
              title="브랜디드 스토리 생성하기"
              type="filled_primary"
              onClick={createBranded}
            />
            {/* 스낵바 */}
            <Snackbar
              open={snackBarControl.open}
              autoHideDuration={1000}
              onClose={closeSnackBar}
              TransitionComponent={Fade}
              message={snackBarControl.message}
            />
          </ContainerC>
        </Section>
      </div>
    </>
  );
}

CreatNewBrandedPresenter.propTypes = {
  username: PropTypes.string.isRequired,
  userData: PropTypes.string.isRequired
};

export default CreatNewBrandedPresenter;
