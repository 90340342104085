import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// Reducer for Dispatch Essay List Object
import themeTypeReducer, { initialState } from '../reducer/themeTypeReducer';

const ThemeTypeContext = createContext();
const ThemeTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeTypeReducer, initialState);
  return (
    <ThemeTypeContext.Provider value={{ state, dispatch}}>
      {children}
    </ThemeTypeContext.Provider>
  );
};

ThemeTypeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Dispatch Definition
export const useThemeTypeDispatch = () => {
  const { dispatch } = useContext(ThemeTypeContext);
  return dispatch;
};

// UseState Definition
export const useThemeTypeState = () => {
  const { state } = useContext(ThemeTypeContext);
  return state;
};

export default ThemeTypeProvider;