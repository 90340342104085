import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PreviewSmall from "../Element/PreviewSmall";
import CardWrapper from '../../Layout/Container/CardWrapper';
import MarkEssay from '../Element/MarkEssay';
import TagList from "../Element/TagList";
import WriterInfo from "../Element/WriterInfo";

const Container = styled(CardWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;


const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Link)`
  cursor: pointer;
  width: calc(100% - 48px);
  ${props => props.theme.type.h6}
  margin-bottom: 48px;
  height: 72px;
  word-break: keep-all;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
  @media screen and (max-width: 480px) {
    -webkit-line-clamp: 3;
    height: 60px;
    margin-bottom: 24px;
  }
`;

const CardRegularEssayNewTab = ({
  essay,
  className,
  to
}) => {

  const [markType, setMarkType] = useState(null);
  useEffect(() => {
    if(essay.isPrized===true){
      setMarkType('reward');
    }
    else if(essay.recommended===true){
      setMarkType('recommend');
    }
  },[essay]);
  
  return (
    <Container className={`${className}`}>
      <Top>
        <Title to={to} target={'_blank'} className="bold primary">
          {essay.title}
        </Title>
        <MarkEssay type={markType} />
      </Top>
      <Bottom>
        <PreviewSmall to={to} target={'_blank'}>
          {essay.contentText}
        </PreviewSmall>
        <TagList tagList={essay.tags} />
        <WriterInfo writer={essay.writer} />
      </Bottom>
    </Container>
  );
}

CardRegularEssayNewTab.propTypes = {
  // essay: PropTypes.instanceOf(Essay).isRequired,
  // className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardRegularEssayNewTab;
