import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  background-color: #fff;
  ${props => props.theme.shape.elavation50Block}
`;

function DropDownExposed({ children, className }) {
  return <Container className={className}>{children}</Container>;
}

DropDownExposed.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default DropDownExposed;
