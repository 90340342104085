import React, { useState, useEffect } from "react";


const allowedTags = [
  {
    id: "decorate-selection",
    label: "단락 꾸미기",
  },
  {
    id: "delete-selection",
    label: "단락 삭제",
  }
];

function SelectionControl({
  position,
  onSelect,
  close
}){

  const [positionValue,setPositionValue] = useState({ top: 0, left: 0 });
  const [command, setCommand] = useState("");
  const [items, setItems] = useState(allowedTags);
  const [selectedItem, setSelectedItem] = useState(null);

  // Set Position
  useEffect(() => {
    setPositionValue({ top: position.y, left: position.x })
  },[position]);

  // Listener Handling
  useEffect(() => {
    document.addEventListener("keydown", KeyDownHandler);
    return () => {
      document.removeEventListener("keydown", KeyDownHandler);
    }
  },[])

  return (
    <div 
      click={
        (e) => {
          e.stopPropagation();
          e.preventDefault();
        }
      }
      className="SelectMenu"
      style={positionValue}>
        <div className="Items">
          {items.map((item, key) => {
            // const selectedItem = selectedItem;
            const isSelected = items.indexOf(item) === selectedItem;
            return (
              <div
                className={isSelected ? "Selected" : null}
                key={key}
                role="button"
                tabIndex="0"
                onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onSelect(item)
                    }
                  }
                >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
  );

  function KeyDownHandler(e) {
    console.log(e.key);
    // close();
  }
}

export default SelectionControl;