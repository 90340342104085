import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AvatarM from '../../Avatar/AvatarM';
import Competition from '../../../_models/competition';

const CardCompetitionStatus = ({ competition, to, className, username }) => (
  <Link to={to} className={`flex_col space_between col1 ${className}`}>
    <div className="col1">
      <p className="body bold black700 margin_b_tiny">{`${competition.month}월 ${competition.week}주차 망월장`}</p>
      <h1 className="primary bold margin_b_2x">{competition.question}</h1>
    </div>
    <div className="flex_row col1">
      {competition.participantList.length > 0 ? (
        <>
          <p className="body black300 regular col1 margin_b_1x">
            {`${competition.participantList[0].username} 외 ${competition.participantList.length}명이 
            ${competition.isEnd?'참여하였습니다.':'참여중입니다.'}`}
            </p>
          <div className="flex_row col1 margin_b_3x">
            {competition.participantList.map(user => {
              return (
                <AvatarM
                  className="margin_avatar"
                  key={user._id}
                  writer={user}
                  to={`/user/${user.username}`}
                />
              );
            })}
          </div>
        </>
      ) : (
        username !== null && (
          <p className="body_s black300 regular col1 margin_b_1x">
            {`아직 참가자가 없습니다.`}
          </p>
        )
      )}
    </div>
  </Link>
);

CardCompetitionStatus.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default CardCompetitionStatus;
