import color from './color';
import shape from './shape';

const container = {
  filledPrimaryBlock: {
    enabled:`
      background-color: ${color.brand.primary700};
      color: ${color.brand.white700};
    `,
    hover:`
      background-color: ${color.brand.primary900};
      color: ${color.brand.white700};
    `,
    disabled:`
      background-color: ${color.brand.gray500};
      color: ${color.brand.white700};
    `
  },
  filledWhiteBlock: {
    enabled:`
      background-color: ${color.brand.white700};
      color: ${color.brand.primary700};
    `,
    hover:`
      background-color: ${color.brand.white700};
      color: ${color.brand.primary900};
    `,
    disabled:`
      background-color: ${color.brand.white500};
      color: ${color.brand.primary500};
    `
  },
  linedPrimaryBlock: {
    enabled:`
      border: solid ${color.brand.primary700} ${shape.borderLight};
      color: ${color.brand.primary700};
    `,
    hover:`
      border: solid ${color.brand.primary900} ${shape.borderLight};
      color: ${color.brand.primary900};
    `,
    disabled:`
      border: solid ${color.brand.primary500} ${shape.borderLight};
      color: ${color.brand.primary500};
    `
  },
  linedPrimaryLightBlock: {
    enabled:`
      border: solid ${color.brand.primary100} ${shape.borderLight};
      color: ${color.brand.primary700};
    `,
    hover:`
      border: solid ${color.brand.blueBlack100} ${shape.borderLight};
      color: ${color.brand.primary700};
    `,
    disabled:`
      border: solid ${color.brand.blueBlack100} ${shape.borderLight};
      color: ${color.brand.primary300};
    `
  },
  linedWhiteBlock: {
    enabled:`
      border: solid ${color.brand.white700} ${shape.borderLight} !important;
      color: ${color.brand.white700} !important;
    `,
    hover:`
      border: solid ${color.brand.white700} ${shape.borderLight};
      color: ${color.brand.white700};
    `,
    disabled:`
      border: solid ${color.brand.white500} ${shape.borderLight};
      color: ${color.brand.white500};
    `
  },
}

export default container;