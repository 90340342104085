import React from 'react';
import { useUserState } from '../../../context/currentUserContext';

import ManageBrandedPresenter from './ManageBrandedPresenter';

function ManageBrandedContainer() {
  const { loaded, username, loged } = useUserState();
  return (
    loaded && (
      <>
        <ManageBrandedPresenter
          username={username}
          loged={loged}
        />
      </>
    )
  );
}

export default ManageBrandedContainer;
