import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useUserState } from '../../context/currentUserContext';
import User from '../../_models/user'

const AvatarB = ({ to, writer }) => {
  const { userData } = useUserState();
  return (
    <Link
      to={
        // 로그인하고 글쓴이가 나일 경우
        userData !== null && userData._id === writer._id ? 'mypage' : to
      }
      className="avatar_b flex_col flex_center margin_r_tiny
  "
    >
      {writer &&  writer.username.length > 1 && writer.username[0] + writer.username[1]}
      {writer &&  writer.username.length == 1 && writer.username[0]}
    </Link>
  );
};

AvatarB.propTypes = {
  to: PropTypes.string.isRequired,
  writer: PropTypes.instanceOf(User).isRequired,
};

export default AvatarB;
