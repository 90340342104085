import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(Link)`
	width: 100%;
	word-break: keep-all;
	padding-left: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	height: 88px;
	white-space: pre-line;
	${props => props.theme.type.body3}
	color: ${props => props.isDark?props.theme.color.ui.white700:props.theme.color.brand.primary700} !important;
	margin-bottom: 24px;
	@media screen and (max-width: 480px) {
		-webkit-line-clamp: 4;
		height: 72px;
		margin-bottom: 16px;
	}
`;

const Preview = ({
	to,
	className,
	children,
	isDark
}) => (
  <Container
    to={to}
		className={className}
		isDark={isDark}
  >
    {children}
  </Container>
);

export default Preview;