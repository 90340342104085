import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component
import BttBasic from '../../Buttons/ButtonLink/BttBasic';
import PreviewSmall from "../Element/PreviewSmall";
import Essay from '../../../_models/essay';

// const getContentText = (content) =>{
//     let texts = '';
//     content.blocks.forEach((block)=>{
//         texts += block.text;
//     });
//     return texts;
// }

const Title = styled.p`
  height: 54px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const Keyword = styled.div`
  max-width: 86px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-top:2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const CardEssayOnProgress = ({ essay, className }) => (
  <div
    className={`card_regular flex_col align_center col1 space_between ${className}`}
  >
    <div className="flex_row col1 space_between margin_b_1x">
      <Title className="bold primary">
        {essay.title}
      </Title>
    </div>
    <div className="col1 margin_b_1x">
      <PreviewSmall>
        {essay.contentText}
      </PreviewSmall>
      <div className="flex_row tag_area_card">
        {essay.tags.map((tag, i) => {
          return (
            <Keyword
              className="tooltip_outlined_dark align_start"
              key={(tag!=null)?tag._id:'123'}
            >
              {tag.tagName}
            </Keyword>
          );
        })}
      </div>
    </div>
    <BttBasic
      className="padding_hor_2x"
      title="이어쓰기"
      to={`/write?essayId=${essay._id}`}
      type="filled_primary"
      doSomething={()=>{
        console.log('click');
        const {gtag} = window;
        gtag('event', 'click', {
          'event_category' : 'my page behaviour',
          'event_label' : 'continue to write'
        });
      }}
    />
  </div>
);

CardEssayOnProgress.propTypes = {
  essay: PropTypes.instanceOf(Essay).isRequired,
  className: PropTypes.string.isRequired,
};

export default CardEssayOnProgress;
