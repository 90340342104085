import React from 'react';
import { useUserState } from '../../../context/currentUserContext';

import ManageCompetitionPresent from './ManageCompetitionPresenter';

function ManageCompetitionContainer() {
  const { loaded, username, loged } = useUserState();
  return (
    loaded && (
      <>
        <ManageCompetitionPresent
          username={username}
          loged={loged}
        />
      </>
    )
  );
}

export default ManageCompetitionContainer;
