import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmSME({ alarm, setAlarmRead }) {
  // const test = '{test:"test"}';
  let essay;
  // let user;
  let scraper;
  // let scraped;
  // let comment;
  let mark = '기본';
  let path;

  if (alarm.contentObject) {
    [essay, scraper] = alarm.contentObject;
    mark = scraper.username[0] + scraper.username[1];
    path = `/user/${scraper.username}`;
    console.log(alarm.mark);
  }

  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id, path)}
      className={`col1 ${alarm.isRead ? 'white_bg' : 'white_600bg'}`}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender={scraper.username}
        msg="님이 회원님의 글을 스크랩하였습니다."
        content={essay.title}
      />
    </button>
  );
}

ListAlarmSME.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmSME;
