import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AvatarM from '../../Avatar/AvatarM';
import Moment from 'react-moment';
import Competition from '../../../_models/competition';

const CardBrandedStatus = ({ branded, to, className, username }) => (
  <Link to={to} className={`flex_col space_between col1 ${className}`}>
    <div className="col1">
      <h1 className="primary bold margin_b_2x">{branded.question}</h1>
      <p className="body black300 regular col1 margin_b_1x">
        <strong>시작일: </strong>
        <Moment format="MM월 DD일">
          {`${branded.startDate}`}
        </Moment>
        <span>&nbsp;|&nbsp;</span>
        <strong>종료일: </strong>
        <Moment format="MM월 DD일">
          {branded.endDate}
        </Moment>
      </p>
    </div>
    <div className="flex_row col1">
    {branded.participantList.length > 0 ? (
        <>
          <p className="body black300 regular col1 margin_b_1x">
            {`${branded.participantList[0].username} 외 ${branded.participantList.length}명이 
            ${branded.isEnd?'참여하였습니다.':'참여중입니다.'}`}
            </p>
          <div className="flex_row col1 margin_b_3x">
            {branded.participantList.map(user => {
              return (
                <AvatarM
                  className="margin_avatar"
                  key={user._id}
                  writer={user}
                  to={`/user/${user.username}`}
                />
              );
            })}
          </div>
        </>
      ) : (
        username !== null && (
          <p className="body_s black300 regular col1 margin_b_1x">
            {`아직 참가자가 없습니다.`}
          </p>
        )
      )}
    </div>
  </Link>
);

CardBrandedStatus.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default CardBrandedStatus;