import React, { useEffect } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import TagListOnPost from '../../template/EssayPost/TagListOnPost';

const Dante = require('Dante2');

const Container = styled.div`
  margin-top: 48px;
  margin-bottom: 120px;
  @media screen and (max-width: 480px) {
    margin-top: 60px;
    margin-bottom: 110px;
  }
`;

const EditorContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
  border: none;
  width: 100%;
  /* min-height: 680px;  */
  /* height: fit-content; */
  font-family: sopoqa_han_sans_light !important;
  padding: 6px 12px;
  margin-bottom: 48px !important;
  color: #2a2a28;
  font-size: 20px;
  line-height: 40px;
  white-space: break-spaces !important;
  word-break: break-all !important;
  outline: none !important;
  resize:'none' !important;
  // No scroll
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
  textarea{
    border: none;
    width: 100%;
    min-height: 680px; 
    /* height: fit-content; */
    font-family: sopoqa_han_sans_light !important;
    padding: 6px 12px;
    margin-bottom: 10px !important;
    color: #2a2a28;
    font-size: 20px;
    line-height: 40px;
    white-space: break-spaces !important;
    word-break: break-all !important;
    outline: none !important;
    resize:'none' !important;
    // No scroll
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
  }
  textarea::-webkit-scrollbar {
    display: none !important;
  }
`;



function EssayPostBody({ essayData }) {
  return (
    <div className="col body essay_content regular black700 col1 bd_bottom_light">
      <EditorContainer className="editor col1">
        {essayData != null && (
          // <Dante
          //   read_only
          //   content={JSON.parse(essayData.content)}
          //   onChange={editor => {
          //     // console.log(editor);
          //   }}
          // />
          // <TextareaAutosize
          //   style={{boxSizing: 'border-box', resize: 'none', overflow: 'overlay'}}
          //   rows={4}
          //   value={essayData.contentText}
          //   cacheMeasurements
          //   readOnly
          // />
          <>
            {essayData.contentText}
          </>
        )}
      </EditorContainer>
      <div className="flex_row flex_center margin_ver_2x">
        <TagListOnPost
          tagList={essayData.tags}
        />
      </div>
    </div>
  );
}

export default EssayPostBody;
