import {
  SET_THEME_TYPE
} from '../actions';

export const initialState = {
  themeType: 'light'
};

const themeTypeReducer = (state, body) => {
  console.log(body.action.type);
  switch (body.action.type) {
    case SET_THEME_TYPE:
      return {
        themeType: body.themeType
      }
    default:
  }
};

export default themeTypeReducer;