import React from 'react';
import { isMobile } from 'react-device-detect';

// Component
// Container & Section
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import Section from '../../components/Layout/Section/Section';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Card
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import CardRegularEssayNewTab from '../../components/Cards/Essay/CardRegularEssayNewTab';

// Button
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';

function RecentEssayList({ essayList }) {
  return (
    <Section>
      <SectionTitle
        title1="최신 에세이"
        title2="작성일을 기준으로 최신 에세이가 올라옵니다."
        />
      <ContainerO className="flex_row col1 col_m">
        {essayList.map((essay, i) => {
          return (
            <GridMatrix
              colPC={5}
              colMb={2}
              index={i}
              key={i}
              onClick={() => {
                console.log('click');
                const { gtag } = window;
                gtag('event', 'click', {
                  'event_category': 'essay card click',
                  'event_label': 'recent'
                });
              }}
            >
              {
                isMobile ?
                  <CardRegularEssayNewTab
                    to={`/post/${essay._id}`}
                    essay={essay}
                    key={essay._id}
                  /> :
                  <CardRegularEssay
                    to={`/post/${essay._id}`}
                    essay={essay}
                    key={essay._id}
                  />
              }

            </GridMatrix>
          );
        })}
        {essayList.length === 0 && (
          <GridMatrix
            colPC={1}
            colMb={1}
            index={0}
            key={0}
            className="padding_4x"
          >
            <h1 className="text_center regular black300">
              에세이가 존재하지 않습니다.
            </h1>
          </GridMatrix>
        )}
      </ContainerO>
      <BttTextSectionMore to="/feed?tabId=recent" title="최근 에세이 더보기" />
    </Section>
  );
}
export default RecentEssayList;