import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const List = styled.div`
  height: 108px;
  width: 100%;
  padding: 16 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  cursor: pointer;
  ${props =>
    `border-bottom: solid ${props.theme.color.primary200} ${props.theme.shape.borderLight};`}
  @media screen and (max-width: 480px) {
    height: auto;
    width: 100%;
    padding: 16px 0px;
  }
`;

function ListBasic({ 
  children,
  className
 }) {
  return <List className={className}>{children}</List>;
}

ListBasic.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListBasic;