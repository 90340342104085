import React, { useState } from 'react';
import styled from 'styled-components';

// Component
import CardRegularPrizedEssay from '../../components/Cards/Essay/CardRegularPrizedEssay';
import CardCompetitionStatus from '../../components/Cards/Competition/CardCompetitionStatus';
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';
// Container & Section
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ContainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import GridMatrixSingle from '../../components/Layout/Grid/GridMatrixSingle';

// Img
import imgCharacter from "../../assets/img/ill/character_male.png";

const Container = styled.div`
  height: 321px;
  padding: 20px 0px;
  @media screen and (max-width: 480px){
    height: auto;
  }
`

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: calc(100%);
  }
`

const UserList = styled.div`
  .user{
    margin-right: 8px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 480px){
    .user{
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
`

const ImgSection = styled.div`
  position: relative;
  width: 200px; 
  height: 100%;
  img {
    position: absolute;
    width: 186px;
    height: auto;
    right:0;
    bottom:0;
  }
  @media screen and (max-width: 480px){
    width: 100%; 
    height: 86px;
    margin-bottom: 16px;
    img {
      width: 102px;
      height: auto;
    }
  }
`
const Title = styled.p`
  cursor: pointer;
  display: inline-block;
  color: ${props => props.theme.color.primary500};
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.h1.size};
  line-height: ${props => props.theme.type.h1.height};
  margin-bottom: 24px;
`;

function CurrentCompetitionStatus({ previouscompetition }) {
  const [index, setIndex] = useState(0);
  const [prizedList, setPrizedList] = useState([]);

  return (
    <>
      <SectionTitle title1="지난 망월장" />
      {previouscompetition.map(competition => (
        <Section>
          <ContainerO className="flex_row">
            <div
              style={{ justifyContent: 'stretch' }}
              className="col_p40 flex_col col1_mobile"
            >
              <GridMatrixSingle
                position="left"
                className="row1 margin_b_2x_mobile"
              >
                <CardCompetitionStatus
                  to={`competition/${competition.year}-${competition.month}-${competition.week}`}
                  className="row1 margin_b_2x_mobile"
                  competition={competition}
                />
              </GridMatrixSingle>
            </div>
            <div className="col_p60 col1_mobile col_m flex_row margin_r_0">
              {
                competition.prizedEssayList.length!==0 ? competition.prizedEssayList.map((essay, i) => {
                  return (
                    <GridMatrix colPC={3} colMb={1} index={i} key={i}>
                      <CardRegularPrizedEssay to="/post" essay={essay} />
                    </GridMatrix>
                  );
                }):
                <GridMatrix colPC={1} colMb={1} index={0}>
                  <Container className="space_between flex_row_col col1">
                    <ContentSection>
                      <Title>
                        당선작을 선정중입니다.<br/>
                        조금만 기다려주세요.
                      </Title>
                      {/* <p className="caption black300 regular col1 margin_b_tiny">
                        {`엄선된 질문을 준비중입니다.`}
                      </p>   */}
                    </ContentSection>
                    <ImgSection className='flex_col flex_center'>
                      {/* <img
                        src={imgCharacter}
                        alt=''
                      /> */}
                    </ImgSection>
                  </Container>
                </GridMatrix>
              }
            </div>
          </ContainerO>
          <BttTextSectionMore
            title={`${competition.month}월 ${competition.week}주차 망월장 더보기`}
            to={`competition/${competition.year}-${competition.month}-${competition.week}`}
          />
        </Section>
      ))}
    </>
  );
}

export default CurrentCompetitionStatus;
