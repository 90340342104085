import React, { Fragment } from 'react';

// Component
// Container & Section
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ConainerO from '../../components/Layout/Container/ContainerO';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';

// Card
import CardPickedEssay from '../../components/Cards/Essay/CardPickedEssay';

// Button
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';

function EditorsPick({
    essayList,
    num
  }) {
  let list = [];
  essayList.forEach((essay,i) => {
    if(i<num){
      list.push(essay);
    }
  })
  return (
    <>
      <SectionTitle
        title1={'에디터스 픽'}
        title2="블루블랙의 에디터가 추천하는 다양한 글을 보여드립니다"
      />
      <ConainerO className="flex_row col_m">
        {essayList.length > 1 ? (
          <>
          {
            list.map((essay,i) => (
              <GridMatrix
                colPC={num}
                colMb={num/2}
                index={i}
                key={i}
                onClick={()=>{
                  console.log('click');
                  const {gtag} = window;
                  gtag('event', 'click', {
                    'event_category' : 'essay card click',
                    'event_label' : 'editor'
                  });
                }}
              >
                <CardPickedEssay
                  to={`/post/${essay._id}`}
                  essay={essay}
                  className={''}
                />
              </GridMatrix>
            ))
          }
          </>
        ) : (
          <GridMatrix
            colPC={1}
            colMb={1}
            index={0}
            key={0}
            className="padding_4x"
          >
            <h1 className="text_center regular black300">
              선택된 에세이가 없습니다.
            </h1>
          </GridMatrix>
        )}
        {}
        <BttTextSectionMore
          to="/feed?tabId=editors"
          title="에디터스 픽 더보기"
        />
      </ConainerO>
    </>
  );
}
export default EditorsPick;
