import React, { useState } from 'react';
import styled from 'styled-components';

// Component
import CardRegularPrizedEssay from '../../components/Cards/Essay/CardRegularPrizedEssay';
import CardCompetitionStatus from '../../components/Cards/Competition/CardCompetitionStatus';
import BttTextSectionMore from '../../components/Buttons/ButtonLink/BttTextSectionMore';
// Container & Section
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ContainerC from '../../components/Layout/Container/ContainerC';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import GridMatrixSingle from '../../components/Layout/Grid/GridMatrixSingle';

// Img
import imgCharacter from "../../assets/img/ill/character_male.png";

const Container = styled.div`
  height: 321px;
  padding: 20px 0px;
  @media screen and (max-width: 480px){
    height: auto;
  }
`

const ContentSection = styled.div`
  width: calc(100% - 200px);
  @media screen and (max-width: 480px){
    width: calc(100%);
  }
`

const UserList = styled.div`
  .user{
    margin-right: 8px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 480px){
    .user{
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
`

const ImgSection = styled.div`
  position: relative;
  width: 200px; 
  height: 100%;
  img {
    position: absolute;
    width: 186px;
    height: auto;
    right:0;
    bottom:0;
  }
  @media screen and (max-width: 480px){
    width: 100%; 
    height: 86px;
    margin-bottom: 16px;
    img {
      width: 102px;
      height: auto;
    }
  }
`
const Title = styled.p`
  cursor: pointer;
  display: inline-block;
  color: ${props => props.theme.color.primary500};
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.h1.size};
  line-height: ${props => props.theme.type.h1.height};
  margin-bottom: 24px;
`;

function PreviousCompetitionStatusAdmin({ previouscompetition }) {
  const [index, setIndex] = useState(0);
  const [prizedList, setPrizedList] = useState([]);

  return (
    <>
      {previouscompetition.map(competition => (
        <Section>
          <ContainerC className="flex_row">
            <div
              style={{ justifyContent: 'stretch' }}
              className="col1 flex_col col1_mobile"
            >
              <CardCompetitionStatus
                to={`competitionDetail/${competition.year}-${competition.month}-${competition.week}`}
                className="row1 margin_b_2x_mobile"
                competition={competition}
              />
            </div>
          </ContainerC>
          <BttTextSectionMore
            className={'margin_t_2x'}
            title={`${competition.month}월 ${competition.week}주차 백일장 자세히`}
            to={`competitionDetail/${competition.year}-${competition.month}-${competition.week}`}
          />
        </Section>
      ))}
    </>
  );
}

export default PreviousCompetitionStatusAdmin;
