/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import config from '../../../config/';
import { competitionApi, adminApi, alarmApi } from '../../../api/api';

// Component
import ContainerC from '../../../components/Layout/Container/ContainerC';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import CardFullCompetitionStatus from '../../../components/Cards/Competition/CardFullCompetitionStatus';
import Loading from '../../../components/Loading/Loading';
import PreviousCompetitionStatusAdmin from '../../../template/Competition/PreviousCompetitionStatusAdmin';


function ManageCompetitionPresent({
}){

  // Local State
  const [currentCompetition, setCurrentCompetition] = useState(null);
  const [previousCompetition, setPreviousCompetition] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // 진행중 백일장
      await competitionApi.competitionCurrent().then(currentCompetition => {
        if (currentCompetition.status !== 404) {
          setCurrentCompetition([currentCompetition.data]);
        }
        console.log(currentCompetition);
      });
      console.log(currentCompetition);

      // 지난 백일장
      await competitionApi.competitionPrevious().then(previousCompetition => {
        if (previousCompetition.status !== 404) {
          setPreviousCompetition(previousCompetition.data);
        }
        console.log(previousCompetition.data);
      });
    } catch {
    } finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
      <Loading />
      ) : (
        <div className="flex_col col1 col_grid_mobile">
          <SectionTitle title1="모든 망월장" />
          {
            currentCompetition!==null && (
              <PreviousCompetitionStatusAdmin
                previouscompetition={currentCompetition}
              />  
            )
          }
          {
            previousCompetition!==null && (
              <PreviousCompetitionStatusAdmin
                previouscompetition={previousCompetition}
              />     
            )
          }
        </div>
      )}
    </>
  );
}

export default ManageCompetitionPresent;