import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ic_close from '../../../assets/img/ic/ic_remove_black.svg';

const BttContainer = styled.button`
  width: 48px;
  height: 48px;
`;

const BttClose = ({ onClick }) => {
  return (
    <BttContainer
      onClick={onClick}
    >
      <img src={ic_close} alt='/' />
    </BttContainer>
  );
};

BttClose.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default BttClose;
