import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CurrentUserProvider from './context/currentUserContext';
import CurrentEssayProvider from './context/currentEssayContext';
import CurrentInsProvider from './context/currentInsContext';
import EssayListProvider from './context/essayListContext';
import ThemeTypeProvider from './context/themeTypeContext';
import './assets/css/layout.css';
import './assets/css/color.css';
import './assets/css/type.css';
import './assets/css/size.css';
import './assets/css/shape.css';
import './assets/css/img.css';
import './assets/css/components.css';
import './assets/css/form.css';
import './assets/css/editor.css';

ReactDOM.render(
  <ThemeTypeProvider>
    <EssayListProvider>
      <CurrentUserProvider>
        <CurrentEssayProvider>
          <CurrentInsProvider>
            <App />
          </CurrentInsProvider>
        </CurrentEssayProvider>
      </CurrentUserProvider>
    </EssayListProvider>
  </ThemeTypeProvider>
  ,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
