import React from "react";
import ContentEditable from "react-contenteditable";

// import "./styles.css";
// import EditableBlock from "./EditableBlock";
import EditableBlock from "./EditableBlock";
import EditableBlockHook from "./EditableBlockHook";

import uid from "./utils/uid";
import { setCaretToEnd } from "./utils/caretHelpers";

const initialBlock = { id: uid(), html: "", tag: "p" };

class EditablePage extends React.Component {
  constructor(props) {
    super(props);
    this.updatePageHandler = this.updatePageHandler.bind(this);
    this.addBlockHandler = this.addBlockHandler.bind(this);
    this.deleteBlockHandler = this.deleteBlockHandler.bind(this);
    this.updateIsOnSelect = this.updateIsOnSelect.bind(this);
    this.state = { 
      blocks: [initialBlock], // 에디터 블록
      isOnSelect: false, //  블록들을 선택하려하는지 판단
    };
  }

  updatePageHandler(updatedBlock) {
    const blocks = this.state.blocks;
    const index = blocks.map((b) => b.id).indexOf(updatedBlock.id);
    const updatedBlocks = [...blocks];
    updatedBlocks[index] = {
      ...updatedBlocks[index],
      tag: updatedBlock.tag,
      html: updatedBlock.html
    };
    this.setState({ blocks: updatedBlocks });
    console.log('update');
  }

  addBlockHandler(currentBlock) {
    const newBlock = { id: uid(), html: "", tag: "p" };
    const blocks = this.state.blocks;
    const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
    const updatedBlocks = [...blocks];
    updatedBlocks.splice(index + 1, 0, newBlock);
    this.setState({ blocks: updatedBlocks }, () => {
      currentBlock.ref.nextElementSibling.focus();
    });
  }

  deleteBlockHandler(currentBlock) {
    // Only delete the block, if there is a preceding one
    const previousBlock = currentBlock.ref.previousElementSibling;
    if (previousBlock) {
      const blocks = this.state.blocks;
      const index = blocks.map((b) => b.id).indexOf(currentBlock.id);
      console.log('blocks');
      console.log(blocks);
      let updatedBlocks = [...blocks];
      console.log('updated blocks');
      console.log(updatedBlocks);
      updatedBlocks.splice(index, 1);
      console.log('spliced updated blocks');
      console.log(updatedBlocks);
      if(!updatedBlocks[0]){
        console.log('no block');
        updatedBlocks = [initialBlock];
        console.log(updatedBlocks);
        this.setState({ blocks: updatedBlocks });
      }else{
        this.setState({ blocks: updatedBlocks }, () => {
          setCaretToEnd(previousBlock);
          previousBlock.focus();
        });
      }
      
    }
  }

  updateIsOnSelect(value){
    this.setState({isOnSelect: value});
  }



  render() {
    return (
      <>
        <div>
          <button
            onMouseDown={
              ()=>{
                this.updateIsOnSelect(true);
              }
            }
          >
            select on
          </button>
          <button
            onMouseDown={
              ()=>{
                this.updateIsOnSelect(false);
              }
            }
          >
            select off
          </button>
        </div>
        <div
          className="Page col1"
          style={{outline:'none'}}
          onKeyDown={
            ()=>{
              console.log('key down from parent');
            }
          }
          contentEditable={this.state.isOnSelect}
        >
          {this.state.blocks.map((block, key) => {
            return (
              <EditableBlock
                key={key}
                index={key}
                id={block.id}
                tag={block.tag}
                html={block.html}
                updatePage={this.updatePageHandler}
                addBlock={this.addBlockHandler}
                deleteBlock={this.deleteBlockHandler}
                onSelectBlock={this.updateIsOnSelect}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default EditablePage;
