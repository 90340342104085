import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// Api
import { insApi, tagApi } from '../../api/api';

// Component
import Loading from '../../components/Loading/Loading';
import BttActionBig from '../../components/Buttons/ButtonAction/BttActionBig';
import BttBig from '../../components/Buttons/ButtonLink/BttBig';

// Container
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ContainerC from '../../components/Layout/Container/ContainerC';

// Template
import SetBasicAdInfo from '../../template/Save/SetBasicAdInfo';

// Context
import { useInsState, useInsDispatch } from '../../context/currentInsContext';

import {
  ADD_AD_TAGS,
  REMOVE_AD_TAGS,
  RESET_AD,
  SET_MEDIATYPE,
} from '../../actions';

function SaveInsPresenter() {
  // currentEssayContxt에서 갖고온 state;
  const { id, title, content, tags, numCurrentWords, mediaType, thumbImg } = useInsState();
  const adDispatch = useInsDispatch();

  const numMaxWords = 2200;

  // Local State
  const [loading, setLoading] = useState(true);
  const [isRedirect, setIsRedirect] = useState(false);
  const [previewTagList, setPreviewTagList] = useState([]);
  const [isPreview, setIsPreview] = useState(false);

  const fetchData = async () => {
    console.log('start fetch');

    setLoading(false);
    console.log('finally!');
  };

  useEffect(() => {
    fetchData();
  }, []);

  // adContext값 변경 함수
  // Tag
  const addTag = tagName => {
    setIsPreview(false);
    adDispatch({ type: ADD_AD_TAGS, value: { tagName } });
  };

  const removeTag = tagName => {
    adDispatch({ type: REMOVE_AD_TAGS, value: tagName });
  };

  // Tag Preview
  const onTagPreview = async string => {
    await tagApi.getTagsByString(string).then(tagList => {
      console.log(tagList.data);

      setPreviewTagList(tagList.data);
      if (tagList.data.length) {
        setIsPreview(true);
      }
    });
  };

  const getContentText = contentSample => {
    let texts = '';
    contentSample.blocks.forEach(block => {
      texts += block.text;
    });
    return texts;
  };

  const adData = {
    adId: id,
    title,
    content,
    contentText: '',
    tagNames: tags,
    mediaType,
    thumbImg,
  };

  // const saveAd = async () => {

  //   adData.contentText = getContentText(adData.content);
  //   console.log(adData);

  //   /*
  //   @우석 06/19
  //   Redirect to mypage after save
  //   */

  //   await insApi.saveAd(adData).then(async res => {
  //     await mypageApi.getMyInfo().then(res => {
  //       console.log(res);
  //       const currentUser = {
  //         action: {
  //           type: null,
  //         },
  //         username: null,
  //       };
  //       if (res.data !== '') {
  //         currentUser.userData = res.data;
  //         currentUser.action.type = LOG_IN_MAINTAIN;
  //         userDispatch(currentUser);
  //         window.alert('작성하신 에세이가 마이페이지에 저장되었습니다.');
  //         essayDispatch({ type: RESET_ESSAY, value: '' });
  //         setIsRedirect(true);
  //       }
  //     });
  //   });
  // };

  if (isRedirect) {
    return <Redirect to="/ins" />;
  }

  const setMediaType = mediaTypeToSet => {
    adDispatch({ type: SET_MEDIATYPE, value: mediaTypeToSet });
  };

  const publishIns = async () => {
    adData.isPublished = true;

    adData.contentText = getContentText(adData.content);
    console.log(adData);

    if (numMaxWords < numCurrentWords) {
      window.alert(
        '최대 글자수(2200)이하의 광고만 출판 가능합니다. 출판을 원하시면 글을 다시 수정해주세요.',
      );
    } else {
      /* Redirect to mypage after save */
      await insApi.saveInspire(adData).then(async res => {
        console.log(res);
        window.alert(
          '작성하신 광고가 게재되었습니다. 게재된 광고를 Inspire에서 확인하세요.',
        );
        adDispatch({ type: RESET_AD, value: '' });
        setIsRedirect(true);
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex_col col_narrow col_grid_mobile router_area_top center_margin margin_b_6x">
          {`${numCurrentWords}/${numMaxWords}`}
          <Section>
            <SectionTitle title1={`${title}저장하기`} />
            <ContainerC className="flex_col col1">
              {/* 기본정보 설정 */}
              <SetBasicAdInfo
                selectedTag={tags}
                addTag={addTag}
                removeTag={removeTag}
                onTagPreview={onTagPreview}
                previewTagList={previewTagList}
                isPreview={isPreview}
                setIsPreview={setIsPreview}
                mediaType={mediaType}
                setMediaType={setMediaType}
              />
              {/* 백일장 참여여부 설정 */}
              {/* <SetCompetitionJoin
                competition={currentCompetition}
                setIsCompeted={setIsCompeted}
                isCompeted={isCompeted}
                username={userData.username}
              /> */}
              {/* 저장 정보 서머리 */}
              {/* <EssaySaveInfoSummary /> */}
            </ContainerC>
          </Section>
          {/* 액션바 */}
          <div className="flex_row col1 space_between">
            <BttBig
              type="outlined"
              title="다시 수정하기"
              to="/writeAd"
              className="margin_r_1x"
            />
            <div className="flex_row">
              <BttActionBig
                onClick={() => {
                  publishIns();
                }}
                type="filled_primary"
                title="게재하기"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SaveInsPresenter;
