import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import { Link, Redirect } from 'react-router-dom';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import { useUserDispatch } from '../../context/currentUserContext';
import { api, mypageApi, sessionApi } from '../../api/api';
import { LOG_IN_SUCCESS } from '../../actions';

import Modal from '../../components/Modal/Modal';

// 이미지
import ic_naver from '../../assets/img/social/ic_naver.svg';
import ic_kakao from '../../assets/img/social/ic_kakao.svg';
import ic_fb from '../../assets/img/social/ic_fb.svg';
import ic_google from '../../assets/img/social/ic_google.svg';

// Component
import ButtonActionBig from '../../components/Buttons/ButtonAction/BttActionBig';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    width: '100%',
    marginBottom: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '32ch',
  },
}));

export default function InputAdornments({ from, location }) {
  // Local State
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isExist, setIsExist] = useState(false);
  const [socialEmail, setSocialEmail] = useState('');
  const [socialUserName, setSocialUserName] = useState('');
  const [isRedirect, setIsRedirect] = useState(false);
  const dispatch = useUserDispatch();
  const classes = useStyles();
  const [signupData, setsignupData] = React.useState({
    email: '',
    password: '',
    nickName: '',
    showPassword: false,
    redirect: null,
    // showModal: false,
    modalVisible: false,
  });

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  const fetchData = async () => {
    try {
     await sessionApi.kakaoValid().then(async res => {
       console.log("valid res:", res);
      if(res.data === 'connected'){
        // 이미 존재하는 경우+연동 되어있는 경우
        setIsExist(true);
        setIsRedirect(true);
      }
      else if(res.data === 'not connected'){
        // 존재하나 연동안된경우
        await sessionApi.getCurrentAccount().then(res => {
          console.log(res.data);
          setSocialEmail(res.data);
        });
        setIsExist(true);
      }
       else{
         // 존재하지 않는경우
         // 회원가입필요
         console.log("res", res.data);
         setSocialEmail(res.data.email);
         setSocialUserName(res.data.profile.username);
       }
     })
    } catch {
     console.log('fetch error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = () => {
    // setModalVisible(true);
    setsignupData({ modalVisible: true });
  };
  const closeModal = () => {
    // setModalVisible(false);
    setsignupData({ modalVisible: false });
  };

  const syncToSocial = async () => {
    const data = {
      social: 'kakao',
    }
    await sessionApi.syncToSocial(data).then(res => {
      console.log(res.data);
    })
    // await sessionApi.logOut().then(res => {
    //   console.log('logout');
    //   console.log(res);
    // })
    // window.alert('연동이 성공했습니다. 다시 로그인해주세요.');
    setIsRedirect(true);
  };

  const socialRegister = async () => {
    const data = {
      username: signupData.nickName,
    };

    await mypageApi.usernameChecker(data).then(async res => {
      if(res.data === 'no exist'){
        // 이름 결정
        await sessionApi.kakaoRegister(data).then(async res => {
          if(res.data === "success"){
            // 가입 성공
            window.alert('회원가입이 성공했습니다. 다시 로그인해주세요.');
            await sessionApi.logOut().then(res=>{
              console.log('logout');
              console.log(res)
            });
            setIsRedirect(true);
          }
          else{
            // 가입 실패
            window.alert('해당 닉네임이 이미 존재합니다.');
          }
        })
      }
      else{
        // 이름 이미 있음
        window.alert('해당 닉네임이 이미 존재합니다.');
      }
    })

    // if (!email) {
    //   console.log(signupData.email);
    //   window.alert('유요한 이메일 주소가 아닙니다.');
    // } else if (
    //   !(
    //     // At Lest One Character include special char
    //     (
    //       (lowercase != null || uppercase != null || special != null) &&
    //       // Minmun length 6
    //       length != null &&
    //       // At List One Number
    //       number != null
    //     )
    //   )
    // ) {
    //   window.alert('비밀번호는 6자리 이상의 문자.숫자 조합으로 입력해주세요.');
    // } else if (!pwMatched) {
    //   window.alert('입력한 패스워드가 일치하지 않습니다.');
    // } else {
    //   await sessionApi.register(data).then(
    //     res => {
    //       if (res.data === 'OU') {
    //         window.alert('이미 사용중인 유저네임입니다.');
    //       }
    //       if (res.data === 'OE') {
    //         window.alert('이미 사용중인 이메일입니다.');
    //       }
    //       if (res.status === 200) {
    //         openModal();
    //         console.log('ggg');
    //       }
    //     },
    //     error => {
    //       console.log(error);
    //     },
    //   );
    // }
  };

  const handleChange = prop => event => {
    setsignupData({ ...signupData, [prop]: event.target.value });
    // console.log(signupData);
  };

  const handlePWChange = prop => event => {
    setPasswordConfirm(event.target.value);
  };

  const handleClickShowPassword = () => {
    setsignupData({ ...signupData, showPassword: !signupData.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  if (signupData.redirect) {
    return <Redirect to={signupData.redirect} />;
  }
  if (isRedirect) {
    return <Redirect to="/" />;
  }
  return (
    
    <div className={`${classes.root} flex_col align_center padding_2x`}>
      {isExist ? (
        <>
          <p className="body regular big black700 margin_b_2x">
            이미 해당 이메일로 가입이 되어 있습니다.
            아래의 계정으로 계속합니다.
          </p>
          <p className="body regular big black700 margin_b_2x">
            {`${socialEmail}`}
          </p>
          
          <div className="col1 padding_b_4x bd_bottom_light">
            <FormControl fullWidth className="col1" variant="outlined">
              <ButtonActionBig
                title="계속하기"
                type="filled_primary"
                className="margin_t_2x col1"
                onClick={syncToSocial}
              />
            </FormControl>
          </div>
        </>
      ) : (
        <>
          <h2 className="black700 bold">회원가입</h2>
          <p className="body regular big black700 margin_b_2x">
            {from === 'write' ? (
              <>회원가입 완료후 에세이를 작성하실 수 있습니다.</>
        ) : (
          <>
            {`${socialEmail} 계정으로 회원가입을 진행합니다.`}
          </>
        )}
          </p>
          <FormControl
            fullWidth
            className={`${classes.margin} margin_b_2x`}
            variant="outlined"
          >
            <input
              className="col1 big"
              id="outlined-adornment-id"
              placeholder="유저네임"
              value={signupData.nickName}
              onChange={handleChange('nickName')}
            />
          </FormControl>
          <div className="col1 padding_b_4x bd_bottom_light">
            <FormControl fullWidth className="col1" variant="outlined">
              <ButtonActionBig
                title="회원가입"
                type="filled_primary"
                className="margin_t_2x col1"
                onClick={socialRegister}
              />
            </FormControl>
          </div>
        </>
)}
    </div>
  );
}
