import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BttContainer = styled(Link)`
  height: ${props => props.theme.size.bttRegularSmall};
  padding: 0 8px;
  border-radius: 4px;
  min-width: 64px;
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryBlock.enabled : '')}
`;

const BttSub = ({ type, title, className, onClick }) => {
  return (
    <BttContainer
      type={type}
      onClick={onClick}
      className={`btt_txt ${className}`}
    >
      <p>{title}</p>
    </BttContainer>
  );
};

BttSub.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BttSub;
