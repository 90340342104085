import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useScrollPosition from '@react-hook/window-scroll'

// API
import queryString from 'querystring';
import { essayApi } from '../../api/api';

// Component
import TabContainer from '../../components/Tabs/Tabcontainer';
import TabBasic from '../../components/Tabs/TabBasic';
import Loading from '../../components/Loading/Loading';
import LoadingSmall from '../../components/Loading/LoadingSmall';
import FeedGrid from '../../template/Feed/FeedGrid';

function EssayFeedPresenter({
  loged,
  username,
  userData,
  interestedTag,
  location,
}) {
  // GET TAB ID
  const { search } = location; // 문자열 형식으로 결과값이 반환된다.
  const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환

  console.log(queryObj['?tabId']);

  let tabList = [];

  // Tab List When User Loged
  if (loged) {
    tabList = [
      { name: '최근 에세이', id: 'recent' },
      { name: '추천 주제어', id: 'recommend' },
      { name: '팔로워 에세이', id: 'follower' },
      { name: '에디터스 픽', id: 'editors' },
      { name: '인기 에세이', id: 'popular' },
    ];
  }
  // Tab List When User Not Loged
  else {
    tabList = [
      { name: '최근 에세이', id: 'recent' },
      { name: '에디터스 픽', id: 'editors' },
      { name: '인기 에세이', id: 'popular' },
    ];
  }

  /*
  Local State for load data fetch from api of
  - Recent Essay,
  - Interested Essay,
  - Editors picked Essay,
  - Follower's Essay,
  - Follower List who write New Essay
  */

  const [activeTab, setActiveTab] = useState(tabList[0].id);
  const [recentEssayList, setRecentEssayList] = useState([]);
  const [recentEssayPage, setRecentEssayPage] = useState(1);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [isEssayListEnd, setIsEssayListEnd] = useState(false);
  const essayListSize = 30;
  const [interestedEssayList, setInterestedEssayList] = useState([]);
  const [editorsPickList, setEditorsPickList] = useState([]);
  const [popularEssayList, setPopularEssayList] = useState([]);
  const [followerList, setFollowerList] = useState([]);
  const [followerEssayList, setFollowerEssayList] = useState([]);
  const [newFollowerEssayList, setNewFollowerEssayList] = useState([]);
  const [newWriterList, setNewWriterList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      // Recent Essay
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [],
          pages: recentEssayPage,
          size: essayListSize,
        })
        .then(essayList => {
          if (essayList.status !== 404) {
            setRecentEssayList(essayList.data);
          }
        });

      // Check Session
      if (loged) {
        // Get Interested Tag
        const tags = [];
        interestedTag.forEach(tag => {
          tags.push(tag.tagName);
        });
        // Get Recent Essay by Interested Tag
        await essayApi
          .publishedEssayList({
            sort: 'recent',
            tags: [tags],
            pages: 1,
            size: 5,
          })
          .then(essayList => {
            if (essayList.status !== 404) {
              setInterestedEssayList(essayList.data);
              console.log(essayList.data);
            }
          });
        // Get Follower's Recent Essay
        await essayApi.followerEssayList(1, 10).then(essayList => {
          setFollowerEssayList(essayList.data);
          essayList.data.forEach(essay => {
            console.log(essay);
            if (!essay.readerList.includes(userData._id)) {
              setNewFollowerEssayList([...newFollowerEssayList, essay]);
              setNewWriterList([...newWriterList, essay.writer]);
            }
          });
        });
        // Get Popular Essay
        await essayApi
          .publishedEssayList({
            sort: 'like',
            tags: [],
            pages: 1,
            size: 30,
          })
          .then(essayList => {
            if (essayList.status !== 404) {
              setPopularEssayList(essayList.data);
            }
          });
      }

      // Get Editor's Essay
      await essayApi.coveredEssayList().then(essayList => {
        if (essayList.status !== 404) {
          setEditorsPickList(essayList.data.reverse());
        }
      });
    } 
    catch(e) {
      console.log(e);
    } 
    finally {
      setLoading(false);
      console.log('finally!');
    }
  };

  const refreshRecentEssay = async () => {
    await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [],
          pages: recentEssayPage+1,
          size: essayListSize,
        })
        .then(essayList => {
          if (essayList.status !== 404) {
            setIsRefreshLoading(false);
            setRecentEssayPage(recentEssayPage+1);
            let tempEssayList = [...recentEssayList,...essayList.data];
            setRecentEssayList(tempEssayList);
            if(essayList.data.length<essayListSize){
              setIsEssayListEnd(true);
            }
          }
        });
  }
  const scrollY = useScrollPosition(30);
  useEffect(() => {
    if(scrollY!==0){
      checkIsBottom();
    }
  },[scrollY]);

  const checkIsBottom = () =>{
    const {scrollY} = window;
    if(!loading){
      let contentHeight = document.getElementById("tabed_contents").clientHeight;
      if(contentHeight - scrollY < 800){
        if(activeTab === 'recent'&&!isRefreshLoading&&!isEssayListEnd){
          console.log('refresh!');
          setIsRefreshLoading(true);
          refreshRecentEssay();
        }
      }
    }
  }

  

  useEffect(() => {
    // Get Current Tab from QUERY PARAMS of URL
    setActiveTab(queryObj['?tabId']);

    // Set Follower List
    const followerListByName = [];
    if (userData !== null) {
      userData.followerList.forEach(user => {
        followerListByName.push(user.username);
      });
      setFollowerList(userData.followerList);
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex_col col1 router_area_top">
          {/* TABS */}
          <TabContainer className="margin_b_2x">
            {tabList.map((tab, i) => (
              <TabBasic
                title={tab.name}
                selected={tab.id === activeTab}
                className="col4"
                to={`/feed/?tabId=${tab.id}`}
                onClick={() => setActiveTab(tabList[i].id)}
                key={i}
              />
            ))}
          </TabContainer>
          {/* TABED CONTENT */}
          <div id="tabed_contents" className="col1 col_grid_mobile">
            {/* RECENT ESSAY */}
            {activeTab === 'recent' && (
              <FeedGrid
                type="recent"
                title="가장 최근 에세이"
                listTitle={[]}
                essayList={recentEssayList}
                colPC={5}
                colMb={2}
              />
            )}
            {/* RECOMMENDED ESSAY */}
            {activeTab === 'recommend' && (
              interestedTag.length > 0 ?
                <FeedGrid
                  type="tag"
                  listTitle={interestedTag}
                  title="에 관한 에세이"
                  essayList={interestedEssayList}
                  colPC={5}
                  colMb={2}
                />
                :
                <h2 className="black300 bold">아직 추천 주제어가 없습니다.</h2>
                )
              }
            {/* FOLLOWER'S ESSAY */}
            {activeTab === 'follower' &&
              (followerEssayList.length > 0 ? (
                <>
                  {
                    newWriterList.length > 0 &&
                    <FeedGrid
                      type="user"
                      listTitle={newWriterList}
                      title="님이 최근에 에세이를 작성하였습니다."
                      essayList={newFollowerEssayList}
                      colPC={5}
                      colMb={2}
                    />
                  }
                  <FeedGrid
                    type="user"
                    listTitle={[]}
                    title="모든 팔로워의 에세이"
                    essayList={followerEssayList}
                    colPC={5}
                    colMb={2}
                  />
                </>
              ) : (
                <h2 className="black300 bold">아직 팔로워가 없습니다.</h2>
              ))}
            {/* EDITORS PICK */}
            {activeTab === 'editors' && (
              <FeedGrid
                listTitle={[]}
                title="블루블랙 에디터가 직접 고른 에세이"
                essayList={editorsPickList}
                colPC={5}
                colMb={2}
              />
            )}
            {/* POPULAR ESSAY */}
            {activeTab === 'popular' && (
              <FeedGrid
                listTitle={[]}
                title="최근 관심을 받은 에세이"
                essayList={popularEssayList}
                colPC={5}
                colMb={2}
              />
            )}
            {
              isRefreshLoading &&
              <LoadingSmall />
            }
            
          </div>
        </div>
      )}
    </>
  );
}

export default EssayFeedPresenter;
