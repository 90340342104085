import 'date-fns';
import React, { useState } from 'react';
import styled from 'styled-components';

// Component
// Container & Section
import PartitionVer from '../../components/Layout/Grid/PartitionVer';
import PartitionHor from '../../components/Layout/Grid/PartitionHor';
import BttTextMore from '../../components/Buttons/ButtonLink/BttTextMore';

// Chip
import ChipBasic from '../../components/Chips/ChipBasic';

// Styled Component
const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;
const TagInputContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
`;
const TagList = styled.div`
  position: absolute;
  z-index: 99;
  top: 52px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  ${props => props.theme.shape.elavation50Block}
`;
const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;

function SetBasicEssayInfo({
  selectedTag,
  addTag,
  removeTag,
  onTagPreview,
  previewTagList,
  isPreview,
  setIsPreview,
  setMediaType,
}) {
  const [tagOnInput, setTagOnInput] = useState('');
  const [mediaTypeSample, setMediaTypeSample] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  // const [isPreview, setIsPreview] = useState(false);

  return (
    <PartitionVer numRow={3} index={0} className="flex_row col1">
      <PartitionHor
        colPC={2}
        colMb={2}
        index={0}
        className="flex_col align_end"
      >
        <h3 className="bold primary col1">주제어 등록 (최대 5개)</h3>
        <p className="body_s col1 regular black400 margin_b_tiny">
          글의 내용을 나타낼 수 있는 주제어를 등록하주세요.
        </p>
        <TagInputContainer>
          <input
            className="col1 regular"
            type="text"
            placeholder="주제어를 입력해주세요."
            value={tagOnInput}
            onChange={e => {
              const word = e.target.value;

              if (word.replace(/\s/g, '').length > 0) {
                setTagOnInput(word);
                onTagPreview(word);
              } else {
                setTagOnInput('');
              }
              console.log(`current Tag on input${tagOnInput}`);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                console.log('Enter!');
                addTag(tagOnInput);
                setTagOnInput('');
              }
            }}
          />
          {/* 주제어 자동완성 리스트 */}
          {isPreview && (
            <>
              <TagList>
                <p className="caption black700 regular margin_b_1x margin_t_1x">
                  <span className="bold primary">*</span>
                  다른 글쓴이들이 사용하고 있는 주제어입니다.
                </p>
                {previewTagList.map(tag => (
                  <TagContainer
                    onClick={() => {
                      addTag(tag.tagName);
                      setTagOnInput('');
                    }}
                  >
                    <div className="tooltip_outlined_dark">{`${tag.tagName} (${tag.count})`}</div>
                    <div className="caption black300 regular">
                      <span className="bold">{tag.owner.username}</span>
                      님이 제시.
                    </div>
                  </TagContainer>
                ))}
              </TagList>
              {/* preview를 닫기위한 fakeBackdrop */}
              <FakeBackDrop
                onClick={() => {
                  setIsPreview(false);
                }}
              />
            </>
          )}
        </TagInputContainer>
        <div className="col1" />
        <div className="flex_row col1 row_regular align_center justify_end margin_b_1x">
          <BttTextMore title="주제어 모두 보기" to="" />
        </div>
        <p className="body_s col1 regular black400 margin_b_tiny">
          선택한 주제어
        </p>
        <div className="flex_row row_regular align_center col1">
          {selectedTag.map(tag => {
            return (
              <ChipBasic
                className="margin_r_tiny"
                title={tag.tagName}
                onClickRemove={removeTag}
              />
            );
          })}
        </div>
      </PartitionHor>
      <PartitionHor
        colPC={2}
        colMb={2}
        index={1}
        className="flex_col align_end"
      >
        <h3 className="bold primary">MediaType 설정</h3>
        <p className="body_s regular black400 margin_b_tiny">
          게재할 광고의 MediaType을 지정합니다.
        </p>
        <div className="row_regular margin_b_1x" />
        <p className="body_s col1 regular black400 margin_b_tiny">
          MediaType 입력
          <br />
          <input
            type="text"
            placeholder="MediaType을 입력해주세요."
            value={mediaTypeSample}
            onChange={e => {
              const word = e.target.value;

              if (word.replace(/\s/g, '').length > 0) {
                setMediaTypeSample(word);
              } else {
                setMediaTypeSample('');
              }
              console.log(`current Tag on input${mediaTypeSample}`);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                console.log('Enter!');
                setMediaType(mediaTypeSample);
                setSelectedMedia(mediaTypeSample);
                setMediaTypeSample('');
              }
            }}
          />
        </p>
        <p className="body_s col1 regular black400 margin_b_tiny">
          선택된 mediaType
        </p>
        <div className="flex_row row_regular align_center col1">
          {selectedMedia}
        </div>
      </PartitionHor>
    </PartitionVer>
  );
}

export default SetBasicEssayInfo;
