import React, { useState } from 'react';
import PropTypes from 'prop-types';
import User from '../../_models/user';
import Comment from '../../_models/comment';

import BttActionBig from '../Buttons/ButtonAction/BttActionBig';

const InputChildComment = ({ userData, postComment, className, id }) => {
  // const user = new User();
  const [childComment, setChildComment] = useState('');
  // Comment
  const onChildCommentChange = event => {
    setChildComment(event.target.value);
  };

  return (
    <div
      className={`flex_row input_comment space_between col1 padding_ver_1x ${className}`}
    >
      <div className="avatar_b flex_col flex_center caption regular">
        {userData.username[0] + userData.username[1]}
        {console.log(userData.username)}
      </div>
      <div
        style={{ width: 'calc(100% - 60px)' }}
        className="flex_col align_end"
      >
        <textarea
          placeholder="댓글을 남겨주세요."
          className="col1 comment body regykar padding_tiny primary margin_b_1x"
          value={childComment}
          onChange={onChildCommentChange}
        />
        <BttActionBig
          type="outlined"
          onClick={() => {
            // setCommentId(id);
            postComment(id, childComment);
            setChildComment('');
          }}
          title="댓글달기"
        />
      </div>
    </div>
  );
};

InputChildComment.propTypes = {
  userData: PropTypes.instanceOf(User).isRequired,
  // onCommentChange: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  // comment: PropTypes.instanceOf(Comment).isRequired,
};

export default InputChildComment;
