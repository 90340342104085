/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Essay from '../../../_models/essay';

import PreviewMedium from "../Element/PreviewMedium";
import CardWrapper from '../../Layout/Container/CardWrapper';
import MarkEssay from '../Element/MarkEssay';
import TagList from "../Element/TagList";
import WriterInfo from "../Element/WriterInfo";

const Container = styled(CardWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;


const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Link)`
  cursor: pointer;
  width: calc(100% - 48px);
  ${props => props.theme.type.h5}
  margin-bottom: 48px;
  height: 78px;
  word-break: keep-all;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
  @media screen and (max-width: 480px) {
    -webkit-line-clamp: 3;
    height: 66px;
    margin-bottom: 32px;
  }
`;

const CardRegularEssay = ({ essay, className, to }) => (
  <Container className={`${className}`}>
    <Top>
      <Title to={to} className="bold primary">
        {essay.title}
      </Title>
      <MarkEssay type={'recommend'}/>
    </Top> 
    <CardBottom>
      <PreviewMedium to={to}>
        {essay.contentText}
      </PreviewMedium>
      <TagList tagList={essay.tags} />
      <WriterInfo writer={essay.writer}/>
    </CardBottom> 
  </Container>
);

CardRegularEssay.propTypes = {
  essay: PropTypes.instanceOf(Essay).isRequired,
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CardRegularEssay;
