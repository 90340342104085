import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(Link)`
	width: 100%;
	word-break: keep-all;
	padding-left: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	/* width: 86px; */
	height: 124px;
	white-space: pre-line;
	font-family: ${props => props.theme.type.regular};
	${props => props.theme.type.body3}
	color: ${props => props.theme.color.ui.background};
	@media screen and (max-width: 480px) {
		-webkit-line-clamp: 3;
		height: 72px;
	}
`;

const Preview5lines = ({
	to,
	className,
	children
}) => (
  <Container
    to={to}
    className={className}
  >
    {children}
  </Container>
);

export default Preview5lines;