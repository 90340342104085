import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LiveAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.theme.layout.liveArea.width};
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
const LiveArea = ({ children, className }) => (
  <LiveAreaContainer className={className}>{children}</LiveAreaContainer>
);

LiveArea.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default LiveArea;
