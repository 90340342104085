import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

// Component
import BttBasic from '../../Buttons/ButtonLink/BttBasic';
import AvatarM from '../../Avatar/AvatarM';
import Essay from '../../../_models/essay';

const Title = styled.h2`
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const CardEssayVote = ({ essay, className }) => (
  <div className={`card_regular flex_col col1 space_between ${className}`}>
    <div className="flex_row col1 space_between margin_b_1x">
      <Title style={{ height: '66px' }} className="bold primary">
        {essay.title}
      </Title>
      <div className="flex_col flex_center" />
    </div>
    <div className="col1">
      <div className="flex_row align_center col1 margin_b_2x">
        <AvatarM
          className={'margin_r_tiny'}
          to={`/user/${essay.writer.username}`}
          writer={essay.writer} />
        <div>
          <p className="caption regular black300">{essay.writer.username}</p>
          <p className="caption regular black300">
            <Moment format="MM월 DD일">{essay.datePublish}</Moment>
          </p>
        </div>
      </div>
      <BttBasic to={`/post/${essay._id}`} type="outlined" title="펼쳐보기" />
    </div>
  </div>
);

CardEssayVote.propTypes = {
  essay: PropTypes.instanceOf(Essay).isRequired,
  className: PropTypes.string.isRequired,
};

export default CardEssayVote;
