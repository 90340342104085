import React from 'react';
import WriteNotionTestPresenter from './WriteNotionTestPresenter';
import { useUserState } from '../../context/currentUserContext';


function WriteNotionTestContainer({ location }) {
  const { loaded, username } = useUserState();
  console.log(username);
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged.

          Write Presenter Mainly Consist of Editor for Writting.
          Dante Editor2(Medium Immitation) is used for Editor library.
          https://github.com/michelson/dante2
        */}
        <WriteNotionTestPresenter username={username} location={location} />
      </>
    )
  );
}
export default WriteNotionTestContainer;
