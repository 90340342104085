import React from 'react';
import AdminPresenter from './AdminMainPresenter';
import { useUserState } from '../../../context/currentUserContext';

function AdminMainContainer() {
  const { loaded, username, userData, interestedTag } = useUserState();
  return (
    loaded && (
      <>
        <AdminPresenter
          userData={userData}
        />
      </>
    )
  );
}

export default AdminMainContainer;
