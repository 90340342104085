import React from 'react';
import SignUpPresenter from './SignUpPresenter';
import { useUserState } from '../../context/currentUserContext';

function SignUpContainer({ location }) {
  // const { loged, loaded, username, userData, interestedTag } = useUserState();
  return (
    <>
      <SignUpPresenter location={location} />
    </>
  );
}
export default SignUpContainer;
