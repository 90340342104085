import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ListBasic from './ListBasic';
import { Link } from 'react-router-dom';

import CardWrapper from '../../components/Layout/Container/CardWrapper';
import PreviewSmall from "../../components/Cards/Element/PreviewSmall";
import MarkEssay from '../../components/Cards/Element/MarkEssay';
import TagList from "../../components/Cards/Element/TagList";
import WriterInfo from "../../components/Cards/Element/WriterInfo";


const Container = styled(Link)`
  height: auto;
  width: 100%;
  padding: 16 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  cursor: pointer;
  ${props =>
    `border-bottom: solid ${props.theme.color.primary200} ${props.theme.shape.borderLight};`}
  @media screen and (max-width: 480px) {
    height: auto;
    width: 100%;
    padding: 16px 0px;
  }
`;

const Wrapper = styled(CardWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
`;


const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;


const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Link)`
  cursor: pointer;
  width: calc(100% - 48px);
  ${props => props.theme.type.h4}
  margin-bottom: 16px;
  height: auto;
  word-break: keep-all;
  overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
  @media screen and (max-width: 480px) {
    -webkit-line-clamp: 3;
    height: 60px;
    margin-bottom: 24px;
  }
`;


const Section = styled.div`
  width: 100%;
  border-right: ${props => props.isBorder ? `solid 1px ${props.theme.color.primary200}` : 'none'};
`;

function ListEssay({
  essay,
  to
}) {

  const [markType, setMarkType] = useState(null);
  useEffect(() => {
    if (essay.isPrized === true) {
      setMarkType('reward');
    }
    else if (essay.recommended === true) {
      setMarkType('recommend');
    }
  }, [essay]);

  return (
    <Container to={to}>
      <Section isBorder={true}>
        <Wrapper>
          <Top>
            <Title to={to} className="bold primary">
              {essay.title}
            </Title>
            <MarkEssay type={markType} />
          </Top>
          <Bottom>
            <PreviewSmall to={to}>
              {essay.contentText}
            </PreviewSmall>
            <TagList tagList={essay.tags} />
            <WriterInfo writer={essay.writer} />
          </Bottom>
        </Wrapper>
      </Section>
    </Container>
  );
}

export default ListEssay;
