import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// material UI
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';

// api
import { adminApi, brandedApi, tagApi } from '../../../api/api';

// component
import BttActionHuge from '../../../components/Buttons/ButtonAction/BttActionHuge';
import Loading from '../../../components/Loading/Loading';
import Section from '../../../components/Layout/Section/Section';
import SectionTitle from '../../../components/Layout/Title/SectionTitle';
import ContainerC from '../../../components/Layout/Container/ContainerC';
import ChipBasic from '../../../components/Chips/ChipBasic';
import config from '../../../config';

const TagInputContainer = styled.div`
  /* width: 100%; */
  position: relative;
  /* background-color: #fff; */
`;

const TagList = styled.div`
  position: absolute;
  z-index: 99;
  top: 52px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  ${props => props.theme.shape.elavation50Block}
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;
const TextInputRound = styled.input`
  height: 56px !important;
  border-radius: 4px;
  padding-left: 16px;
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: 200,
    height: 56,
    borderRadius: '4px !important;'
  },
  label: {
    top: 4,
    left: 4
  }
}));

function EditBrandedPresenter({ username, userData, location }) {
  // SNACK BAR CONTROL
  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });

  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  // Local State
  const [loading, setLoading] = useState(true);
  const [branded, setBranded] = useState({});
  const [tagListForPost, setTagListForPost] = useState([]);
  const [newBranded, setNewBranded] = useState({
    _id: '',
    question: '',
    description: '',
    startDate: '',
    endDate: '',
    image:'',
    prizeList: [
      {
        title: '',
        author: '',
        publisher: '',
        thumbnail: '',
        description: '',
        tags: [],
      },
    ],
    master: {
      masterType: '',
      name: '',
      about: '',
      profile: '',
      impression: ''
    }
  });
  const [isPreview, setIsPreview] = useState(false);
  const [previewTagList, setPreviewTagList] = useState([]);
  const [tagOnInput, setTagOnInput] = useState('');

  const file = null;

  // get competition ID
  const url = location.pathname.replace('/admin/editBranded/', '');
  const id = url.split('-')[0];

  const fetchData = async () => {
    try {
      console.log('id: ', id);

      await brandedApi.getBrandeds([id]).then(res => {
        console.log('res', res.data[0]);
        setBranded(res.data[0]);
        setNewBranded(res.data[0]);
        let tagNameList = [];
        res.data[0].prizeList[0].tags.map((tag) => {
          tagNameList.push(tag.tagName);
        });
        setTagListForPost(tagNameList);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      console.log('finally');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {

  }, [tagListForPost]);

  const fileUpload = async targetFile => {
    const url = `${config.apiUrl}/upload`;
    const formData = new FormData();
    formData.append('file', targetFile);
    const header = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const result = await post(url, formData, header);
    return result;
  };

  // Tag
  const addTag = tagName => {
    setIsPreview(false);
    setNewBranded({
      ...newBranded,
      prizeList: [{
        ...newBranded.prizeList[0],
        tags: [...newBranded.prizeList[0].tags, tagName],
      }],
    })
  };

  const removeTag = tagName => {
    setNewBranded({
      ...newBranded,
      prizeList: [{
        ...newBranded.prizeList[0],
        tags: newBranded.prizeList[0].tags.filter(tag => tag !== tagName),
      }],
    })
  };

  const onTagPreview = async string => {
    await tagApi.getTagsByString(string).then(tagList => {
      console.log(tagList.data);

      setPreviewTagList(tagList.data);
      if (tagList.data.length) {
        setIsPreview(true);
      }
    });
  };


  const modifyCompetition = async () => {
    openSnackBar('백일장이 수정되었습니다.');
    console.log(tagListForPost);

    let brandedBody = {
      ...newBranded,
      prizeList: [{
        ...newBranded.prizeList[0],
        tags: tagListForPost,
      }],
    };

    console.log(brandedBody);

    await adminApi.modifyBrandedByAdmin(brandedBody).then(res => {
      console.log(res);
    });
  };

  const classes = useStyles();

  const stringToDate = (date) => {
    return date.split('T')[0];
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
          <div className="flex_col col_narrow margin_center col_grid_mobile router_area_top">
            <Section>
              <SectionTitle title1="백일장 수정하기" />
              <ContainerC>
                <div className="flex_row col1 margin_b_2x">
                  <h3 className="bold black300 margin_b_tiny">브랜디드 스토리 질문</h3>
                  <input
                    className="col1 big bold primary"
                    id="outlined-adornment-password-conform"
                    placeholder="브랜디드 스토리 질문을 입력해주세요."
                    type="text"
                    defaultValue="test"
                    value={newBranded.question}
                    onChange={event => {
                      console.log(event.target.value);
                      setNewBranded({
                        ...newBranded,
                        question: event.target.value,
                      });
                    }
                    }
                  />
                </div>
                <div className="flex_row col1 margin_b_2x">
                  <h3 className="bold black300 margin_b_tiny">브랜디드 스토리 설명</h3>
                  <textarea
                    className="col1 big bold primary"
                    id="outlined-adornment-password-conform"
                    placeholder="브랜디드 스토리에 대한 설명을 입력해주세요."
                    type="text"
                    value={newBranded.description}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        description: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex_row col1 margin_b_2x">
                  <h3 className="bold col1 black300 margin_b_tiny">
                    브랜디드 스토리 날짜
                </h3>
                  <TextField
                    id="date"
                    label="시작 날짜"
                    type="date"
                    defaultValue={new Date()}
                    value={stringToDate(newBranded.startDate)}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        startDate: event.target.value,
                      })
                    }
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="date"
                    label="종료 날짜"
                    type="date"
                    defaultValue={new Date()}
                    value={stringToDate(newBranded.endDate)}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        endDate: event.target.value,
                      })
                    }
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <input
                  className="col1 big bold primary margin_b_1x"
                  id="outlined-adornment-password-conform"
                  placeholder="브랜드 스토리 이미지 업로드"
                  type="file"
                  onChange={event => {
                    console.log(event);
                    fileUpload(event.target.files[0]).then(res => {
                      setNewBranded({
                        ...newBranded,
                        image: res.data.url,
                      });
                      alert(`file uploaded: ${res.data.url}`);
                    });
                    console.log(event.target.files[0]);
                  }}
                />
                <div className="flex_row space_between col1 margin_b_2x">
                  <h3 className="bold col1 black300 margin_b_tiny">
                    주최 브랜드
                </h3>
                  <input
                    className="col2_m big margin_b_1x"
                    id="outlined-adornment-password-conform"
                    placeholder="주최 브랜드명"
                    type="text"
                    value={newBranded.master.name}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        master: {
                          ...newBranded.master,
                          name: event.target.value,
                        },
                      })
                    }
                  />
                  <input
                    className="col2_m big margin_b_1x"
                    id="outlined-adornment-password-conform"
                    placeholder="주최자 타입. celebrity | blueblack"
                    type="text"
                    value={newBranded.master.masterType}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        master: {
                          ...newBranded.master,
                          masterType: event.target.value,
                        },
                      })
                    }
                  />
                  <textarea
                    className="col1 big margin_b_1x"
                    id="outlined-adornment-password-conform"
                    placeholder="주최 브랜드 설명"
                    type="text"
                    value={newBranded.master.about}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        master: {
                          ...newBranded.master,
                          about: event.target.value,
                        },
                      })
                    }
                  />
                  <input
                    className="col1 big bold primary margin_b_1x"
                    id="outlined-adornment-password-conform"
                    placeholder="주최자 이미지 업로드"
                    type="file"
                    onChange={event => {
                      console.log(event);
                      fileUpload(event.target.files[0]).then(res => {
                        setNewBranded({
                          ...newBranded,
                          master: {
                            ...newBranded.master,
                            profile: res.data.url,
                          },
                        });
                        alert(`file uploaded: ${res.data.url}`);
                      });
                      console.log(event.target.files[0]);
                    }}
                  // onChange={e => console.log(e.target.files[0])}
                  />
                  <textarea
                    className="col1 big"
                    id="outlined-adornment-password-conform"
                    placeholder="브랜디드 스토리 소감"
                    type="text"
                    value={newBranded.master.impression}
                    onChange={event =>
                      setNewBranded({
                        ...branded,
                        master: {
                          ...newBranded.master,
                          impression: event.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="flex_row space_between col1 margin_b_2x">
                  <h3 className="bold col1 black300 margin_b_tiny">
                    상품 기본 정보
                </h3>
                  <input
                    className="col1 big margin_b_1x"
                    id="outlined-adornment-password-conform"
                    placeholder="상품명"
                    type="text"
                    value={newBranded.prizeList[0].title}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        prizeList: [{
                          ...newBranded.prizeList[0],
                          title: event.target.value,
                        }],
                      })
                    }
                  />
                  <textarea
                    className="col1 big"
                    id="outlined-adornment-password-conform"
                    placeholder="상품 설명"
                    type="text"
                    value={newBranded.prizeList[0].description}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        prizeList: [{
                          ...newBranded.prizeList[0],
                          description: event.target.value,
                        }],
                      })
                    }
                  />
                </div>
                <div className="flex_row space_between col1 margin_b_2x">
                  <h3 className="bold col1 black300 margin_b_tiny">
                    상품 상세 정보
                </h3>
                  <input
                    className="col3_m big margin_b_tiny"
                    id="outlined-adornment-password-conform"
                    placeholder="저자"
                    type="text"
                    value={newBranded.prizeList[0].author}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        prizeList: [{
                          ...newBranded.prizeList[0],
                          author: event.target.value,
                        }],
                      })
                    }
                  />
                  <input
                    className="col3_m big margin_b_tiny"
                    id="outlined-adornment-password-conform"
                    placeholder="출판사"
                    type="text"
                    value={branded.prizeList[0].publisher}
                    onChange={event =>
                      setNewBranded({
                        ...newBranded,
                        prizeList: [{
                          ...newBranded.prizeList[0],
                          publisher: event.target.value,
                        }],
                      })
                    }
                  />
                  <TagInputContainer className={'col3_m'}>
                    <TextInputRound
                      className="col1"
                      type="text"
                      placeholder="주제어"
                      value={tagOnInput}
                      onChange={e => {
                        let world;
                        world = e.target.value;

                        if (world.replace(/\s/g, '').length > 0) {
                          setTagOnInput(world);
                          onTagPreview(world);
                        } else {
                          setTagOnInput('');
                        }
                        console.log(`current Tag on input${tagOnInput}`);
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          console.log('Enter!');
                          addTag(tagOnInput);
                          setTagOnInput('');
                        }
                      }}
                    />
                    {/* 주제어 자동완성 리스트 */}
                    {isPreview && (
                      <>
                        <TagList>
                          <p className="caption black700 regular margin_b_1x margin_t_1x">
                            <span className="bold primary">*</span> 다른
                          글쓴이들이 사용하고 있는 주제어입니다.
                        </p>
                          {previewTagList.map(tag => (
                            <TagContainer
                              onClick={e => {
                                addTag(tag.tagName);
                                setTagOnInput('');
                              }}
                            >
                              <div className="tooltip_outlined_dark">{`${tag.tagName} (${tag.count})`}</div>
                              <div className="caption black300 regular">
                                <span className="bold">{tag.owner.username}</span>
                              님이 제시.
                            </div>
                            </TagContainer>
                          ))}
                        </TagList>
                        {/* preview를 닫기위한 fakeBackdrop */}
                        <FakeBackDrop
                          onClick={() => {
                            setIsPreview(false);
                          }}
                        />
                      </>
                    )}
                  </TagInputContainer>
                </div>
                <div className={"flex_col col1 margin_b_2x"}>
                  <h3 className="bold col1 black300 margin_b_tiny">
                    등록된 상품 주제어
                  </h3>
                  <div className={'flex_row col1'}>
                    {/* {
                      branded.prizeList[0].tags.length === 0 &&
                      <p className="caption black300 regular">등록된 주제어가 없습니다.</p>
                    }
                    {
                      branded.prizeList[0].tags.map((tag) =>
                        <ChipBasic
                          title={tag}
                          onClickRemove={removeTag}
                          className={'margin_r_1x'}
                        />
                      )
                    } */}
                  </div>
                </div>
                <div className="flex_row col1 margin_b_2x">
                  <h3 className="bold black300 margin_b_tiny">
                    상품 이미지 등록
                </h3>
                  <input
                    className="col1 big bold primary"
                    id="outlined-adornment-password-conform"
                    placeholder="상품 이미지 업로드"
                    type="file"
                    // value={newCompetition.prizeList.thumbnail}
                    onChange={event => {
                      console.log(event);
                      fileUpload(event.target.files[0]).then(res => {
                        setNewBranded({
                          ...branded,
                          prizeList: [{
                            ...branded.prizeList[0],
                            thumbnail: res.data.url,
                          }],
                        });
                        alert(`file uploaded: ${res.data.url}`);
                      });
                      console.log(event.target.files[0]);
                    }}
                  // onChange={e => console.log(e.target.files[0])}
                  />
                </div>
                <BttActionHuge
                  className="col1"
                  title="백일장 수정하기"
                  type="filled_primary"
                  onClick={modifyCompetition}
                />
                {/* 스낵바 */}
                <Snackbar
                  open={snackBarControl.open}
                  autoHideDuration={1000}
                  onClose={closeSnackBar}
                  TransitionComponent={Fade}
                  message={snackBarControl.message}
                />
              </ContainerC>
            </Section>
          </div>
        )}
    </>
  );
}

export default EditBrandedPresenter;
