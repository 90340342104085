import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useUserState } from '../../context/currentUserContext';
import User from '../../_models/user'

const AvatarH = ({ to, writer, className}) => {
  const { userData } = useUserState();
  return (
    <Link
      to={
        // 로그인하고 글쓴이가 나일 경우
        userData !== null && userData._id === writer._id ? 'mypage' : to
      }
      className={"avatar_h flex_col flex_center "}
    >
      {writer &&  writer.username.length > 1 && writer.username[0] + writer.username[1]}
      {writer &&  writer.username.length == 1 && writer.username[0]}
    </Link>
  );
};

AvatarH.propTypes = {
  to: PropTypes.string.isRequired,
  writer: PropTypes.instanceOf(User).isRequired,
};

export default AvatarH;
