import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmSRT({ alarm, setAlarmRead }) {
  const mark = 'B';
  let tag;
  if (alarm.contentObject) {
    [tag] = alarm.contentObject;
    console.log(alarm.mark);
  }

  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id)}
      className={`col1 ${alarm.isRead}` ? 'white_bg' : 'white_600bg'}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
      >
        {/* <p className='body regular black700'>주제어 OO에 관심이 있으신가요? OO주제로 써보기.</p> */}
        {alarm.contentObject && (
          <>
            <p className="body regular black700">
              주제어
              <span className="bold">{tag.tagName}</span>
              <>에 관심이 있으신가요?</>
              <span className="bold">{tag.tagName}</span>
              주제로 써보기.
            </p>
            <p className="body bold primary">{}</p>
          </>
        )}
      </ListAlarmBasic>
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender=""
        msg={`주제어 ${tag.tagName}에 관심이 있으신가요?`}
        content={`주제어 ${tag.tagName}로 글쓰러 가기`}
      />
    </button>
  );
}

ListAlarmSRT.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmSRT;
