import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BttContainer = styled.button`
  height: 26px;
  padding: 0 8px;
  border-radius: 4px;
  min-width: 64px;
  ${props =>
    (props.type === 'filled_primary'
      ? props.theme.container.filledPrimaryBlock.enabled
      : '') +
    (props.type === 'filled_white'
      ? props.theme.container.filledWhiteBlock.enabled
      : '') +
    (props.type === 'outlined' ? props.theme.container.linedPrimaryLightBlock.enabled : '')}

  @media screen and (max-width: 480px) {
    height: 40px;
  }
`;

const BttSub = ({ type, title, onClick, className }) => {
  return (
    <BttContainer
      type={type}
      onClick={onClick}
      className={`btt_txt ${className}`}
    >
      <p>{title}</p>
    </BttContainer>
  );
};

export default BttSub;
