import React from 'react';
import SaveByAdminPresenter from './SaveByAdminPresenter';
import { useUserState } from '../../context/currentUserContext';

function SaveByAdminContainer() {
  const { loged, loaded, username, userData, interestedTag } = useUserState();
  return (
    loged && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <SaveByAdminPresenter
          loged={loged}
          username={username}
          userData={userData}
          interestedTag={interestedTag}
        />
      </>
    )
  );
}
export default SaveByAdminContainer;
