import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Moment from 'react-moment';
import ListBasic from '../ListBasic';

const Container = styled(ListBasic)`
@media screen and (max-width: 480px) {
  align-items: flex-start;
  
}
`;

const MarkAlarm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 64px;
  }
`;

const ContentAlarm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: calc(100% - 108px);
  @media screen and (max-width: 480px) {
    width: calc(100% - 64px);
  }
`;

const Date = styled.div`
  /* width: 100%; */
  color: ${props => props.theme.color.tertiary300};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.caption1.size};
  line-height: ${props => props.theme.type.caption1.height};
`
const Msg = styled.div`
  color: ${props => props.theme.color.tertiary300};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.body3.size};
  line-height: ${props => props.theme.type.body3.height};
  span{
    color: ${props => props.theme.color.tertiary500};
    font-family: ${props => props.theme.type.bold};
  }
  @media screen and (max-width: 480px) {
    font-size: ${props => props.theme.type.caption1.size};
    line-height: ${props => props.theme.type.caption1.height};
  }
`

const Content = styled.div`
  color: ${props => props.theme.color.tertiary700};
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.body3.size};
  line-height: ${props => props.theme.type.body3.height};
  @media screen and (max-width: 480px) {
    font-size: ${props => props.theme.type.caption1.size};
    line-height: ${props => props.theme.type.caption1.height};
  }
`

function ListAlarmBasic({
  markAlarm,
  date,
  sender,
  msg,
  content
}) {
  return (
    <Container>
      <>
        <MarkAlarm>
          <div className="avatar_m">{markAlarm}</div>
        </MarkAlarm>
        <ContentAlarm>
          <Msg>
            <span>
              {sender}
            </span>
            {msg}
          </Msg>
          <Content>
            {content}
          </Content>
          <Date>
            <Moment format="MM월 DD일">{date}</Moment>
          </Date>
        
        </ContentAlarm>
      </>
    </Container>
  );
}

ListAlarmBasic.propTypes = {
  markAlarm: PropTypes.string.isRequired,
};

export default ListAlarmBasic;