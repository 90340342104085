import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  ${props => props.theme.layout.sectionBlock}
  position: relative;
`;

function Section({ className, children }) {
  return <Container className={`col1 ${className}`}>{children}</Container>;
}

Section.propTypes = {
  // className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
