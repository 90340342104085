import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Grid = styled.div`
  position: relative;
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-bottom: 20px;
  // Horizon Border
  &:before {
    ${props => props.theme.layout.gridHorLine}
  }
  // Vertical Border
  &:after {
    ${props => {
        switch(props.position){
          case 'left':
            return props.theme.layout.gridVerRightLine;
          case 'center':
            return props.theme.layout.gridVerRightLine;
          case 'right':
            break;
          default:
            break;
        }
      }
    }
  } 
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-right: 0px;
    &:after {
      display: none;
    }
  }
`;

function GridMatrixSingle({ className, position, children }) {
  return (
    <Grid position={position} className={className}>
      {children}
    </Grid>
  );
}

GridMatrixSingle.propTypes = {
  className: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GridMatrixSingle;
