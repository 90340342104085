import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Part = styled.div`
  position: relative;
  width: ${props =>
    `calc((100% - ${40*(props.colPC-1)}px)/${props.colPC})`
  };
  margin-right: ${props =>
    props.index % props.numCol === props.numCol - 1 ? '0px' : '40px'
  };
  @media screen and (max-width: 480px) {
    width: ${props =>
      `calc((100% - ${40*(props.colMb-1)}px)/${props.colMb})`
    }
  }
  // Vertical Border
  &:before {
    ${props =>
      props.numCol !== 1 &&
      props.index % props.numCol !== props.numCol - 1 &&
      props.theme.layout.parVerRightLine}
  }
`;

function PartitionHor({ colPC, colMb, index, children, className }) {
  const numCol = isMobile ? colMb : colPC;

  return (
    <Part
      colPC={colPC}
      colMb={colMb}
      index={index}
      numCol={numCol}
      key={index}
      className={className}
    >
      {children}
    </Part>
  );
}

PartitionHor.propTypes = {
  colPC: PropTypes.number.isRequired,
  colMb: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default PartitionHor;
