import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { mypageApi, alarmApi } from '../../api/api';

// Component
import Loading from '../../components/Loading/Loading';
import LoadingSmall from '../../components/Loading/LoadingSmall';
import ListAlarmCS from '../../components/List/Alarm/ListAlarmCS';
import ListAlarmCD from '../../components/List/Alarm/ListAlarmCD';
import ListAlarmFD from '../../components/List/Alarm/ListAlarmFD';
import ListAlarmSWT from '../../components/List/Alarm/ListAlarmSWT';
import ListAlarmSRT from '../../components/List/Alarm/ListAlarmSRT';
import ListAlarmCME from '../../components/List/Alarm/ListAlarmCME';
import ListAlarmCMC from '../../components/List/Alarm/ListAlarmCMC';
import ListAlarmSME from '../../components/List/Alarm/ListAlarmSME';
import ListAlarmFU from '../../components/List/Alarm/ListAlarmFU';
import ListAlarmLME from '../../components/List/Alarm/ListAlarmLME';
import ListAlarmCSE from '../../components/List/Alarm/ListAlarmCSE';
import ListAlarmMD from '../../components/List/Alarm/ListAlarmMD';
import BttActionBig from '../../components/Buttons/ButtonAction/BttActionBig';

function AlarmsPresenter({ history }) {
  const [alarmList, setAlarmList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [extraLoading, setExtraLoading] = useState(false);

  // const [alarmSize, setAlarmSize] = useState(10);
  const alarmSize = 100;

  const [currentPage, setCurrentPage] = useState(1);

  // 알람을 눌렀을 때 이동할 path
  const [path, setPath] = useState(null);

  // 알람 불러오기
  const loadAlarm = () => {
    if (currentPage !== 1) {
      setExtraLoading(true);
    }

    // 알람 사이즈 및 현재 페이징 넘버 state로 관리
    mypageApi.getAlarmList(currentPage, alarmSize).then(list => {
      console.log(list.data);
      // Array State를 push하는 방식
      console.log([...alarmList, ...list.data]);
      setAlarmList([...alarmList, ...list.data]);
      if (currentPage !== 1) {
        setExtraLoading(false);
      }
    });
  };

  // 알람 갱신하기
  const updateAlarm = () => {
    setCurrentPage(currentPage + 1);
    loadAlarm();
  };

  const fetchData = async () => {
    try {
      await loadAlarm();
    } catch {
      console.log('error detected')
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setAlarmRead = async (alarmId, newPath) => {
    console.log('click');
    const body = {
      isRead: true,
    };

    // 알람 읽음에 대한 처리 후에 인자로 받은 path로 state setting
    setPath(newPath);
    await alarmApi.setAlarmRead(alarmId, body).then(res => console.log(res));
  };

  // path가 존재하는 경우 해당 path 로 라우팅
  // ListAlarmCME.js를 참고하시면 됩니다.
  if (path) {
    history.push('/alarm');
    return <Redirect to={path} />;
  }

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.color.white700};
    width: 100%auto;
    padding: 32px;
    @media screen and (max-width: 480px) {
      padding: 16px;
    }
  `;

  return (
    <div className="flex_col col_narrow center_margin col_grid_mobile">
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {alarmList.length === 0 ? (
            <p className="body_s regular black300">발생한 알람이 없습니다.</p>
          ) : (
            alarmList.map(alarm => {
              switch (alarm.type) {
                case 'CS':
                  return (
                    <ListAlarmCS setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'CD':
                  return (
                    <ListAlarmCD setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'FD':
                  return (
                    <ListAlarmFD setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'FU':
                  return (
                    <ListAlarmFU setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'SWT':
                  return (
                    <ListAlarmSWT setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'CME':
                  return (
                    <ListAlarmCME setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'CMC':
                  return (
                    <ListAlarmCMC setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'SRT':
                  return (
                    <ListAlarmSRT setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'CSE':
                  return (
                    <ListAlarmCSE setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'LME':
                  return (
                    <ListAlarmLME setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'SME':
                  return (
                    <ListAlarmSME setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                case 'MD':
                  return (
                    <ListAlarmMD setAlarmRead={setAlarmRead} alarm={alarm} />
                  );
                default:
                  // 원래는 break;였는데 map의 특성상 return이 필요하다고 합니다.
                  return null;
              }
            })
          )}
          {extraLoading ? <LoadingSmall /> : <div />}
          {alarmList.length > alarmSize && (
            <BttActionBig
              className="col1 margin_t_2x"
              title="이전 알람 더보기"
              type="outlined"
              onClick={updateAlarm}
            />
          )}
        </Container>
      )}
    </div>
  );
}

AlarmsPresenter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

export default AlarmsPresenter;
