/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ic_more from '../../../assets/img/ic/ic_more.svg';

const BttTextMore = ({ title, to }) => (
  <Link to={to} className="btt_txt">
    <p>{title}</p>
    &nbsp;
    >
    {/* <img style={{ marginLeft: '4px' }} src={ic_more} alt="" /> */}
  </Link>
);

BttTextMore.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default BttTextMore;
