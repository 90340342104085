import React from 'react';
import * as _ from 'lodash';

// Component
import CardFullCurrentCompetitionAdmin from '../../components/Cards/Competition/CardFullCurrentCompetitionAdmin';
import CardRegularEssay from '../../components/Cards/Essay/CardRegularEssay';
import CardEssayPrize from '../../components/Cards/Essay/CardEssayPrize';

// Container & Section
import Section from '../../components/Layout/Section/Section';
import SectionTitle from '../../components/Layout/Title/SectionTitle';
import ContainerO from '../../components/Layout/Container/ContainerO';
import ContainerC from '../../components/Layout/Container/ContainerC';
import GridMatrix from '../../components/Layout/Grid/GridMatrix';
import GridMatrixSingle from '../../components/Layout/Grid/GridMatrixSingle';

import BttActionBig from '../../components/Buttons/ButtonAction/BttActionBig';
import { adminApi } from '../../api/api';

function CompetitionPrizeStatus({
    competition,
    endCompetition,
    isShowMore,
    setIsShowMore
  }) {
  const { essayList } = competition;
  console.log(essayList);
  const numEssay = essayList.length;

  let top10ByVote = _.cloneDeep(essayList);
  let top10ByVotePerView = _.cloneDeep(essayList);

  top10ByVote = top10ByVote.sort((a,b) => {
    return b.voteCount - a.voteCount; 
  });

  top10ByVotePerView = top10ByVotePerView.sort((a,b) => {
    return b.voteCount/b.viewCount - a.voteCount/a.viewCount; 
  });

  // top10ByVote = essayList.sort(())
  return (
    <>
      <Section>
        <SectionTitle title1="진행중 백일장" />
        <ContainerC>
          <CardFullCurrentCompetitionAdmin
            competition={competition}
            endCompetition={endCompetition}
            isShowMore={isShowMore}
            setIsShowMore={setIsShowMore}
          >
            {
              //  가장 최근 백일장이 종료된후 새로운 백일장이 생성되지 않았을경우
              competition.isEnd ? (
                <>
                  <p className="body bold black700 margin_t_2x">
                    백일장이 마감되었습니다. 새로운 백일장을 등록해주세요
                  </p>
                </>
              ) : (
                <p className="body bold black700 margin_t_2x">{`현재 (${numEssay})개의 참여작이 있습니다. 수상자를 선정해 주세요`}</p>
              )
            }
          </CardFullCurrentCompetitionAdmin>
        </ContainerC>
        <div className="flex_row col1 margin_t_1x">
          <p className="body bold black700 margin_t_4x col1 text_center margin_b_2x">득표수 TOP 10</p>
          {top10ByVote.map((essay, i) => {
            console.log(essay);
            return (
              <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                {
                  // 가장 최근 백일장이 종료된후 새로운 백일장이 생성되지 않았을경우
                  competition.isEnd ? (
                    <CardRegularEssay to="/post" essay={essay} />
                  ) : (
                    <CardEssayPrize to="/post" essay={essay} />
                  )
                }
              </GridMatrix>
            );
          })}
          <p className="body bold black700 margin_t_4x col1 text_center margin_b_2x">득표수/조회수 TOP 10</p>
          {top10ByVotePerView.map((essay, i) => {
            console.log(essay);
            return (
              <GridMatrix colPC={4} colMb={2} index={i} key={i}>
                {
                  // 가장 최근 백일장이 종료된후 새로운 백일장이 생성되지 않았을경우
                  competition.isEnd ? (
                    <CardRegularEssay to="/post" essay={essay} />
                  ) : (
                    <CardEssayPrize to="/post" essay={essay} />
                  )
                }
              </GridMatrix>
            );
          })}
        </div>
      </Section>
    </>
  );
}

export default CompetitionPrizeStatus;
