/* eslint-disable camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import { Link, Redirect } from 'react-router-dom';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import { useUserState, useUserDispatch } from '../../context/currentUserContext';
import { api, sessionApi } from '../../api/api';
import { LOG_IN_SUCCESS } from '../../actions';

import Modal from '../../components/Modal/Modal';

// 이미지
import ic_naver from '../../assets/img/social/ic_naver.svg';
import ic_kakao from '../../assets/img/social/ic_kakao.svg';
import ic_fb from '../../assets/img/social/ic_fb.svg';
import ic_google from '../../assets/img/social/ic_google.svg';
import config from '../../config';

// Component
import ButtonActionBig from '../../components/Buttons/ButtonAction/BttActionBig';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    width: '100%',
    marginBottom: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '32ch',
  },
}));

const SocialImg = styled.img`
  width: 48px;
  height: 48px;
  margin: 16px;
  @media screen and (max-width: 480px) {
    width: 40px;
    height: 40px;
    margin: 8px;
  }
`;

export default function InputAdornments({ from }) {
  // User Context to Check Test Version
  const { testVer } = useUserState();
  // Local State
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isTOSModal, setIsTOSModal] = useState(false);  // TOS == 이용약관
  const dispatch = useUserDispatch();
  const classes = useStyles();
  const [signupData, setsignupData] = React.useState({
    email: '',
    emailCheck: '',
    password: '',
    nickName: '',
    showPassword: false,
    redirect: null,
    // showModal: false,
    modalVisible: false,
  });
  
  function getModalStyle() {
    const top = 50;
    const left = 50;
    
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const [modalStyle] = React.useState(getModalStyle);

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  const openModal = () => {
    // setModalVisible(true);
    setsignupData({ modalVisible: true });
  };
  const closeModal = () => {
    // setModalVisible(false);
    setsignupData({ modalVisible: false });
  };

  const openTOSModal = () =>{
    console.log("123");
    setIsTOSModal(true);
  };

  const closeTOSModal = () =>{
    setIsTOSModal(false);
  };

  const localRegister = async () => {
    // Email Condition
    const email =
      signupData.email.includes('@') && signupData.email.includes('.');
    // PW Condition
    const special = signupData.password.match(/[!@#$%^&*_]+/g);
    const length = signupData.password.match(/[A-Za-z\d!@#$%^&*_]{6,}/g);
    const number = signupData.password.match(/[0-9]+/g);
    const lowercase = signupData.password.match(/[a-z]+/g);
    const uppercase = signupData.password.match(/[A-Z]+/g);
    const pwMatched = signupData.password === passwordConfirm;

    const data = {
      username: signupData.nickName,
      email: signupData.email,
      social: 'local',
      password: signupData.password,
    };

    if (!email) {
      console.log(signupData.email);
      window.alert('유효한 이메일 주소가 아닙니다.');
    } 
    // else if(signupData.email !== signupData.emailCheck){
    //   window.alert('가입하실 이메일을 다시 확인해주세요.');
    // }
    else if (
      !(
        // At Lest One Character include special char
        (
          (lowercase != null || uppercase != null || special != null) &&
          // Minmun length 6
          length != null &&
          // At List One Number
          number != null
        )
      )
    ) {
      window.alert('비밀번호는 6자리 이상의 문자.숫자 조합으로 입력해주세요.');
    } else if (!pwMatched) {
      window.alert('입력한 패스워드가 일치하지 않습니다.');
    } else {
      await sessionApi.register(data).then(
        res => {
          if (res.data === 'OU') {
            window.alert('이미 사용중인 유저네임입니다.');
          }
          if (res.data === 'OE') {
            window.alert('이미 사용중인 이메일입니다.');
          }
          if (res.status === 200) {
            openModal();
            const {gtag} = window;
            gtag('event', 'click', {
              'event_category' : 'sign up',
              'event_label' : `sign with email: ${testVer}`
            });
          } 
        },
        error => {
          console.log(error);
          const {gtag} = window;
            gtag('event', 'click', {
              'event_category' : 'sign up',
              'event_label' : 'sign up failed'
            });
        },
      );
    }
  };

  const handleChange = prop => event => {
    setsignupData({ ...signupData, [prop]: event.target.value });
    // console.log(signupData);
  };

  const handlePWChange = prop => event => {
    setPasswordConfirm(event.target.value);
  };

  const handleClickShowPassword = () => {
    setsignupData({ ...signupData, showPassword: !signupData.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  if (signupData.redirect) {
    return <Redirect to={signupData.redirect} />;
  }

  return (
    <div className={`${classes.root} flex_col align_center padding_2x white_bg`}>
      <div>
        {signupData.modalVisible ? (
          signupData.modalVisible && (
            <Modal
              visible={signupData.modalVisible}
              closable
              maskClosable
              onClose={closeModal}
              closePath="/"
              redirectPath="/signIn"
              buttonName="로그인"
            >
              <h2>블루블랙의 회원이 되신 것을 진심으로 축하드립니다!!!</h2>
              <p>가입된 아이디로 로그인 바랍니다.</p>
            </Modal>
          )
        ) : (
          <Link to="/" />
        )}
      </div>
      <h2 className="black700 bold margin_b_1x">회원가입</h2>
      <p className="body regular big text_center black700 margin_b_2x">
        {from === 'write' ? (
          <>
            회원가입 완료후 에세이를 작성하실 수 있습니다.
          </>
        ) : (
          <>
            이미 가입이 되어있다면&nbsp;
            <Link className="primary" to="/signIn">
              로그인
            </Link>
            으로 이동하세요
          </>
        )}
      </p>
      <FormControl
        fullWidth
        className={`${classes.margin} margin_b_2x`}
        variant="outlined"
      >
        <input
          className="col1 big"
          id="outlined-adornment-id"
          placeholder="유저네임"
          value={signupData.nickName}
          onChange={handleChange('nickName')}
        />
      </FormControl>

      <FormControl
        fullWidth
        className={`${classes.margin} margin_b_2x`}
        variant="outlined"
      >
        <input
          className="col1 big"
          id="outlined-adornment-email"
          placeholder="이메일"
          value={signupData.email}
          onChange={handleChange('email')}
        />
      </FormControl>
      {/* <FormControl
        fullWidth
        className={`${classes.margin} margin_b_2x`}
        variant="outlined"
      >
        <input
          className="col1 big"
          id="outlined-adornment-email-check"
          placeholder="이메일 확인"
          value={signupData.emailCheck}
          onChange={handleChange('emailCheck')}
        />
      </FormControl> */}
      <FormControl fullWidth className={classes.margin} variant="outlined">
        <input
          className="col1 big"
          id="outlined-adornment-password"
          type={signupData.showPassword ? 'text' : 'password'}
          placeholder="비밀번호"
          value={signupData.password}
          onChange={handleChange('password')}
        />
      </FormControl>
      <FormControl fullWidth className={classes.margin} variant="outlined">
        <input
          className="col1 big"
          id="outlined-adornment-password-conform"
          type={signupData.showPassword ? 'text' : 'password'}
          placeholder="비밀번호 확인"
          value={passwordConfirm}
          onChange={handlePWChange()}
        />
      </FormControl>
      <div className="col1 padding_b_4x bd_bottom_light">
        <FormControl fullWidth className="col1" variant="outlined">
          <ButtonActionBig
            title="회원가입"
            type="filled_primary"
            className="margin_t_2x col1"
            onClick={localRegister}
          />
        </FormControl>
        {from === 'write' && 
          <p className={'caption margin_t_2x regular text_center'}>
            이미 가입이 되어있다면&nbsp;
            <Link className="primary bold" to="/signIn">
              로그인
            </Link>
            으로 이동하세요
          </p>
        }
        <p className='caption margin_t_2x regular text_center'>
          회원가입을 하실경우 블루블랙의 
          {' '}
          <Link 
            to='/privacyPolicy'
            className='primary bold'
          >
            개인정보처리

          </Link>
          {' '}
          방침에 동의하신걸로 간주합니다.
        </p>
      </div>
      <h2 className="black700 bold margin_b_1x margin_t_1x">소셜로 가입</h2>
      <div className="flex_row col1 flex_center margin_b_4x">
        <a
          onClick={
            () => {
              const {gtag} = window;
              gtag('event', 'click', {
                'event_category' : 'sign up',
                'event_label' : `sign with naver: ${testVer}`
              });
            }
          }
          href={`${config.apiUrl}/api/auth/login/naver`}
        >
          <SocialImg src={ic_naver} alt="" />
        </a>
        <a
          onClick={
            () => {
              const {gtag} = window;
              gtag('event', 'click', {
                'event_category' : 'sign up',
                'event_label' : `sign with kakao: ${testVer}`
              });
            }
          } 
          href={`${config.apiUrl}/api/auth/login/kakao`}
        >
          <SocialImg src={ic_kakao} alt="" />
        </a>
        <a
          onClick={
            () => {
              const {gtag} = window;
              gtag('event', 'click', {
                'event_category' : 'sign up',
                'event_label' : `sign with google: ${testVer}`
              });
            }
          }
          href={`${config.apiUrl}/api/auth/login/google`}>
          <SocialImg src={ic_google} alt="" />
        </a>
        {/* <a href={`${config.apiUrl}/api/auth/login/facebook`}>
          <SocialImg className="" src={ic_fb} alt="" />
        </a> */}
      </div>
      {/* 개인정보 방침 모달 부분 */}
      <Modal
        closable
        maskClosable
        visible={isTOSModal}
        onClose={closeTOSModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <div>
            <p className='margin_b_2x'>
              '이디그나'('http://blue-black.life/'이하 '블루블랙')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        
              '이디그나'('블루블랙') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다. 
              {' '}
              <br />
              ○ 본 방침은부터 2020년 10월 1일부터 시행됩니다.
            </p>
        
            <h2>1. 개인정보의 처리 목적</h2>
            <p className='margin_b_2x'>
              '이디그나'('http://blue-black.life/'이하 '블루블랙')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
        
              가. 홈페이지 회원가입 및 관리
        
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증 등을 목적으로 개인정보를 처리합니다.
        
        
              나. 마케팅 및 광고에의 활용
        
              신규 서비스(제품) 개발 및 맞춤 서비스 제공 등을 목적으로 개인정보를 처리합니다.
            </p>
        
            <h2>2. 개인정보 파일 현황</h2>
            <h3>1. 개인정보 파일명 : 개인정보 처리</h3>
            <p className='margin_b_2x'>
              개인정보 항목 : 이메일
              수집방법 : 홈페이지
              보유근거 : 회원관리
              보유기간 : 영구
              관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
