import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LiveArea from '../../../components/Layout/LiveArea';

// Api
import { essayApi } from '../../../api/api';

// Component
import Loading from '../../../components/Loading/Loading';
import CardRegularEssay from '../../../components/Cards/Essay/CardRegularEssay';
import FeedGrid from '../../../template/Feed/FeedGrid';

const Container = styled.div`
  width: 100%;
`;

function ExploreEssayPresenter() {
  const [loading, setLoading] = useState(true);
  const [recentEssayList, setRecentEssayList] = useState([]);

  const fetchData = async () =>{
    try {
      // Recent Essay
      await essayApi
        .publishedEssayList({
          sort: 'recent',
          tags: [],
          pages: 1,
          size: 10000,
        })
        .then(essayList => {
          if (essayList.status !== 404) {
            setRecentEssayList(essayList.data);
          }
        });

    } catch {

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  },[])

  return (
    <Container>
      {
        loading? (
          <Loading />
        ) : (
          <FeedGrid
            type="recent"
            title="가장 최근 에세이"
            listTitle={[]}
            essayList={recentEssayList}
            colPC={4}
            colMb={2}
          />
        )
      }
    </Container>
  )
}

export default ExploreEssayPresenter;