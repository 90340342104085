import color from '../token/color';

const shape = {
  borderLight: '1px',
  borderBold: '2px',
  radiousRegular: '4px',
  roundFull: '50%',
  elavation50Block: `
    box-shadow: 0px 0px 16px rgba(0,0,0,0.08);
  `,
  borderBlock: {
    primary300: {
      top: `
        border-top: solid 1px ${color.brand.primary300};
      `,
      right: `
        border-right: solid 1px ${color.brand.primary300};
      `,
      bottom: `
        border-bottom: solid 1px ${color.brand.primary300};
      `,
      left: `
        border-left: solid 1px ${color.brand.primary300};
      `,
    }
  }
}

export default shape;