import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const T1 = styled.p`
 ${props => props.theme.type.h5};
  color: ${props => props.theme.color.ui.strong};
`;

const T2 = styled.p`
  ${props => props.theme.type.subtitle1};
  color: ${props => props.theme.color.ui.strong};
`;

function SectionTitle({ className, children, title1, title2 }) {
  return (
    <Container>
      <T1>{title1}</T1>
      <T2>{title2}</T2>
      {children}
    </Container>
  );
}

SectionTitle.propTypes = {
  title1: PropTypes.string.isRequired,
  // title2: PropTypes.string.isRequired,
};

export default SectionTitle;
