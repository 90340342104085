import React from 'react';
import PropTypes from 'prop-types';
import Alarm from '../../../_models/alarm';

// Component
import ListAlarmBasic from './ListAlarmBasic';

function ListAlarmCS({ alarm, setAlarmRead }) {
  const mark = 'B';
  let competition;
  let date;
  let path;

  if (alarm.contentObject) {
    [competition] = alarm.contentObject;
    date = `${competition.year}-${competition.month}-${competition.week}`;
    path = `/competition/${date}`;
  }

  return (
    <button
      type="button"
      onClick={() => setAlarmRead(alarm._id, path)}
      className={`col1 ${alarm.isRead ? 'white_bg' : 'white_600bg'}`}
    >
      <ListAlarmBasic
        markAlarm={mark}
        date={alarm.Date}
        sender=""
        msg={`${competition.month}월 ${competition.week}주차 백일장이 시작되었습니다.`}
        content={competition.question}
      />
    </button>
  );
}

ListAlarmCS.propTypes = {
  alarm: PropTypes.instanceOf(Alarm).isRequired,
  setAlarmRead: PropTypes.func.isRequired,
};

export default ListAlarmCS;
