import React from 'react';
import AlarmsPresenter from './AlarmsPresenter';
import { useUserState } from '../../context/currentUserContext';

function AlarmsContainer({ history }) {
  const { loged } = useUserState();
  return (
    loged && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <AlarmsPresenter history={history} />
      </>
    )
  );
}
export default AlarmsContainer;
