import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BttContainer = styled.button`
  height: ${props => props.theme.size.height.small};
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  ${props => props.theme.type.btt1}
  color: ${props =>
    (props.color === 'primary' ? props.theme.color.brand.primary500 : '') +
    (props.color === 'gray' ? props.theme.color.ui.middle2 : '')};
`;

const BttTextMore = ({ title, onClick, color, className }) => (
  <BttContainer
    onClick={onClick}
    color={color}
    className={className}
  >
    <p>{title}</p>
  </BttContainer>
);

BttTextMore.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired
};

export default BttTextMore;
