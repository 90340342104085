import React from 'react';
import styled from 'styled-components';

// Component
import LiveArea from '../../components/Layout/LiveArea';
import ContainerO from '../../components/Layout/Container/ContainerO';
import BttHuge from '../../components/Buttons/ButtonLink/BttHuge';

import img1 from '../../assets/img/ill/AboutCompetition/01.png';
import img2 from '../../assets/img/ill/AboutCompetition/02.png';
import img3 from '../../assets/img/ill/AboutCompetition/03.png';
import img4 from '../../assets/img/ill/AboutCompetition/04.png';

const Container = styled(LiveArea)`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  @media screen and (max-width: 480px) {
    padding:0 20px;
    margin-bottom: 144px;
  }
`

const Title = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.h3};
  color: ${props => props.theme.color.brand.primary700};
`;

const SubTitle = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  font-family: ${props => props.theme.type.bold};
  ${props => props.theme.type.h5};
  color: ${props => props.theme.color.brand.primary700};
  @media screen and (max-width: 480px) {
      /* margin-top: 20px; */
      position: static;
      height: 108px;
      width: 100%;
  }
`;

const Btt = styled(BttHuge)`
  width: 600px;
  margin-bottom: 40px;
  @media screen and (max-width: 480px) {
    width: 240px;
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
  border-bottom: solid 1px ${props => props.theme.color.brand.primary300};
  img{
    width: 500px;
    height: 460px;
  }
  img.cat{
    width: 500px;
    height: 340px;
  }
  @media screen and (max-width: 480px) { 
    img{
      width: calc(100% - 40px) !important;
      height: auto !important;
    }   
    height: auto;
  }
`;

function AboutCompetitionPresent (){
  return(
    <Container className={'router_area_top'}>
      <Title>
        망월장이 무엇인가요?
      </Title>
      <ContainerO>
        <Section>
          <SubTitle>
            달밤에 모여 친목을 도모하고 <br />
            글짓기 솜씨를 겨루는 것을 <br />
            망월장이라고 합니다.
          </SubTitle>
          <img src={img1} alt={''} />
        </Section>
        <Section>
          <SubTitle>
            블루블랙은 <br />
            매달 첫째 주, 셋째 주 <br />
            망월장을 엽니다.
          </SubTitle>
          <img src={img2} alt={''} />
        </Section>
        <Section>
          <SubTitle>
          엄선된 질문이 <br />
          달빛처럼 떠오르는 날에 <br />
          여러분의 글솜씨를 마음껏 <br />
          펼쳐보시기 바랍니다.
          </SubTitle>
          <img src={img3} alt={''} />
        </Section>
        <Section>
          <SubTitle>
          그럼 망월장 <br />
          구경하러 가볼까요?
          </SubTitle>
          <img className={'cat'} src={img4} alt={''} />
          <Btt
            type={'filled_primary'}
            title={'망월장 구경가기'}
            to={'/competitions'}
          />
        </Section>
      </ContainerO>
    </Container>
    )
}

export default AboutCompetitionPresent;