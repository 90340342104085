import React from 'react';
import EditBrandedPresenter from './EditBrandedPresenter';
import { useUserState } from '../../../context/currentUserContext';

function EditBrandedContainer({location}) {
  const { loaded, username, userData } = useUserState();
  return (
    loaded && (
      <>
        <EditBrandedPresenter
          username={username}
          userData={userData}
          location={location}
        />
      </>
    )
  );
}

export default EditBrandedContainer;
