import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonBasic from '../../components/Buttons/ButtonLink/BttBasic';
import BttIconWithStatus from '../../components/Buttons/ButtonAction/BttIconwithStatus';
import BttSquareIcon from '../../components/Buttons/ButtonAction/BttSquareIcon';
import { essayApi, alarmApi } from '../../api/api';
import ic_like_outlined from '../../assets/img/ic/interaction/ic_like_outline.svg';
import ic_like_filled from '../../assets/img/ic/interaction/ic_like_filled.svg';
import ic_scrap_outlined from '../../assets/img/ic/interaction/ic_scrap.svg';
import ic_scrap_filled from '../../assets/img/ic/interaction/ic_scrap.svg';
import ic_share_outlined from '../../assets/img/ic/interaction/ic_share.svg';
import ic_share_filled from '../../assets/img/ic/interaction/ic_share.svg';
import ic_follow_outlined from '../../assets/img/ic/interaction/ic_follow_outlined.svg';
import ic_follow_filled from '../../assets/img/ic/interaction/ic_follow_filled.svg';
import ic_more_tool from '../../assets/img/ic/ic_more_tool_gray.svg';
import DropDownExposed from '../../components/Container/DropDownExposed';

// Styled Component
const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 120px;
  @media screen and (max-width: 480px) {
    margin-bottom: 80px;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 36px);
  font-size: ${props => props.theme.type.caption1.size};
  font-family: ${props => props.theme.type.regular};
`;


function EssayPostUserActionBar({
  essayData,
  likeUpDown,
  scrapEssay,
  isLike,
  isScrap,
}) {
  return (
    <Container>
      <Action>
        <BttIconWithStatus
          title={`${essayData.userListLike.length}명의 공감`}
          onClick={likeUpDown}
          isOn={isLike}
          imgOff={ic_like_outlined}
          imgOn={ic_like_filled}
        />
        <BttIconWithStatus
          title={`${essayData.scrapCount}번의 스크랩`}
          onClick={scrapEssay}
          isOn={isScrap}
          imgOff={ic_scrap_outlined}
          imgOn={ic_scrap_filled}
        />
      </Action>
      <BttSquareIcon
        onClick={() => { }}
        isOn={false}
        imgOff={ic_share_outlined}
        imgOn={ic_share_filled}
      />
    </Container>
  );
}

export default EssayPostUserActionBar;
