// SESSION MANAGING
export const LOG_IN_SUCCESS = 'login success';
export const LOG_IN_FAILED = 'login failed';
export const LOG_IN_MAINTAIN = 'login maintin';
export const LOAD_USER_DATA = 'load user data';
export const LOG_OUT = 'log out';
export const REGISTER_SUCCESS = 'register success';
export const REGISTER_FAILED = 'register success';
export const SET_TEST_VER = 'set test version';

// ESSSAY LIST DATA MANAGING
export const FIRST_LOADED = 'main page loaded firstly';

// ESSAY DATA MANAGING
export const SET_ESSAY_ID = 'set essay ID';
export const SET_ESSAY_TITLE = 'set essay title';
export const SET_ESSAY_CONTENT_TEXT = 'set essay content text';
export const SET_ESSAY_CONTENT = 'set essay content';
export const ADD_ESSAY_TAGS = 'add essay tag';
export const SET_ESSAY_TAGS = 'set essay tags';
export const REMOVE_ESSAY_TAGS = 'remove essay tag';
export const SET_IS_PUBLISHED = 'set is publised';
export const SET_IS_DUEDAY = 'set is dueday';
export const SET_DUEDAY = 'set dueday';
export const SET_IS_COMPETED = 'set is competed';
export const SET_COMPETITION_ID = 'set competition id';
export const SET_COMPETITION_DATA = 'set competition data';
export const SET_IS_BRANDED = 'set is branded';
export const SET_BRANDED_ID = 'set branded id';
export const SET_BRANDED_DATA = 'set branded data';
export const SET_NUM_CURRENT_WORDS = 'set current words';
export const RESET_ESSAY = 'reset essay';
export const SET_ATTEMPT_SAVE = 'set attemp save';

// AD DATA MANAGING
export const SET_AD_ID = 'set essay ID';
export const SET_AD_TITLE = 'set essay title';
export const SET_AD_CONTENT = 'set essay content';
export const ADD_AD_TAGS = 'add essay tag';
export const SET_AD_TAGS = 'set essay tags';
export const REMOVE_AD_TAGS = 'remove essay tag';
export const SET_NUM_CURRENT_WORDS_AD = 'set current words';
export const RESET_AD = 'reset essay';
export const SET_MEDIATYPE = 'set mediatype';
export const SET_AD_IMAGE = 'set image';

// THEME MANAGING
export const SET_THEME_TYPE = 'set theme type';
