import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import { Link, Redirect } from 'react-router-dom';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import queryString from 'query-string';

import { useUserDispatch } from '../../context/currentUserContext';
import { sessionApi, adminApi } from '../../api/api';

import { LOG_IN_SUCCESS } from '../../actions';

// 이미지
import ic_naver from '../../assets/img/social/ic_naver.svg';
import ic_kakao from '../../assets/img/social/ic_kakao.svg';
import ic_google from '../../assets/img/social/ic_google.svg';
import ic_fb from '../../assets/img/social/ic_fb.svg';

// Component
import ButtonActionBig from '../../components/Buttons/ButtonAction/BttActionBig';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    width: '100%',
    marginBottom: '16px',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '32ch',
  },
}));

export default function ResetPasswordForm(location) {
  
  const query = queryString.parse(location.location.search);
  console.log('query: ',query);

  const {token} = query;
  const {email} = query;

  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    passwordConfirmed: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    redirect: null,
  });

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };


  if (values.redirect) {
    return <Redirect to={values.redirect} />;
  }

  
  const resetPWHandler = async() => {
    if(values.id !== values.password){
      window.alert('비밀번호가 일치하지 않습니다. 다시 확인 해 주세요.');
    }
    else if(values.id === '' || values.password === ''){
      window.alert('변경하실 비밀번호를 입력해주세요.');
    }
    else{

      const data = {
        token,
        email,
        password: values.password,
      }

      console.log('data:', data);
      await adminApi.setPasswordWithToken(data).then(res => {
        if(res.status === 200){
          window.alert('비밀번호 변경이 완료되었습니다.');
          setValues({ redirect: '/' });
        }
        else if(res.status === 400){
          window.alert('패스워드가 유효하지 않습니다.');
        }
        else if(res.status === 401){
          window.alert('토큰과 이메일이 일치하지 않습니다.');
        }
        else if(res.status === 402){
          window.alert('토큰이 올바르지 않습니다.');
        }
        else if(res.status === 403){
          window.alert('토큰이 만료되었습니다. 다시 비밀번호 변경 이메일을 요청해주세요.');
        }
        else if(res.status === 404){
          window.alert('아이디가 존재하지 않습니다.');
        }
      })
    }
  }
  const handleChange = prop => e => {
    setValues({ ...values, [prop]: e.target.value });
    console.log(e.target.value);
    console.log(e.key);
    // if (e.key === 'Enter') {
    //   resetPWHandler();
    // }
  };

  return (
    <div className={`${classes.root} flex_col align_center padding_2x`}>
      <h2 className="black700 bold margin_b_2x">패스워드 변경</h2>
      <FormControl
        fullWidth
        className={`${classes.margin} margin_b_2x`}
        variant="outlined"
      >
        <input
          className="col1 big"
          id="new-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.id}
          onChange={handleChange('id')}
          placeholder="새 패스워드"
          labelWidth={70}
        />
      </FormControl>
      <FormControl
        fullWidth
        className={clsx(classes.margin, classes.textField)}
      />
      <FormControl fullWidth className={classes.margin} variant="outlined">
        <input
          className="col1 big"
          autoComplete="Hello"
          id="new-password-confirmed"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onKeyPress={() => {}}
          onChange={handleChange('password')}
          placeholder="새 패스워드 확인"
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {}}
                onMouseDown={() => {}}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={70}
        />
      </FormControl>
      <div className="col1 padding_b_4x bd_bottom_light">
        <FormControl fullWidth className="col1" variant="outlined">
          <ButtonActionBig
            className="margin_t_2x col1"
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {resetPWHandler()}}
            title="패스워드 변경"
            type="filled_primary"
          />
        </FormControl>
      </div>
    </div>
  );
}
