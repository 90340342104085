import React, { useState, useEffect } from 'react';
// import {isMobile} from "react-device-detect";

// API
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import {
  essayApi,
  alarmApi,
  mypageApi,
  userpageApi,
  adminApi,
  competitionApi,
  insApi,
} from '../../api/api';

// Context
import {
  useUserState,
  useUserDispatch,
} from '../../context/currentUserContext';

import { LOAD_USER_DATA } from '../../actions';

// Component
import CommentList from '../../components/Comment/CommentList';
import InputComment from '../../components/Comment/InputComment';
import Loading from '../../components/Loading/Loading';

// From Material UI

// Templeate
import EssayPostTop from '../../template/EssayPost/EssayPostTop';
import EssayPostBody from '../../template/EssayPost/EssayPostBody';
import EssayPostAdminActionBar from '../../template/EssayPost/EssayPostAdminActionBar';
import EssayPostUserActionBar from '../../template/EssayPost/EssayPostUserActionBar';
import EssayPostStatusBar from '../../template/EssayPost/EssayPostStatusBar';
import EssayPostVoterActionBar from '../../template/EssayPost/EssayPostVoterActionBar';

import Preview from '../../components/Helmet/Preview';

// Editor
const Dante = require('Dante2');

function InsPostPresenter({ loged, location, userData, history }) {
  const dispatch = useUserDispatch();

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // Local State
  const [loading, setLoading] = useState(true);
  const [insData, setInsData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [comment, setComment] = useState('');
  const [isLike, setIsLike] = useState(false);
  const [isScrap, setIsScrap] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isEditorPick, setIsEditorPick] = useState(false);
  const [isPrized, setIsPrized] = useState(false);
  const [isVoted, setVote] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });

  // MSG preset for snackbar when user interaction occured
  const SNACK_MSG_COMMENT = '댓글이 등록되었습니다.';
  const SNACK_MSG_LIKE = '위 에세이에 공감을 보냈습니다.';
  const SNACK_MSG_LIKE_CANCEL = '위 에세이 공감을 취소하였습니다.';
  const SNACK_MSG_SCRAP = '위 에세이를 마이노트에 스크랩하였습니다.';
  const SNACK_MSG_SCRAP_CANCEL = '위 에세이 스크랩을 취소하였습니다.';
  const SNACK_MSG_FOLLOW = '위 에세이 작가를 팔로우하였습니다.';
  const SNACK_MSG_FOLLOW_CANCEL = '위 에세이 작가 팔로우를 취소하였습니다.';
  const SNACK_MSG_EDITOR = '위 에세이가 에디터픽으로 선정되었습니다.';
  const SNACK_MSG_EDITOR_CANCEL = '해당 에세이 에디터픽이 취소되었습니다.';
  const SNACK_MSG_PRIZED = '위 에세이를 백일장 당선작으로 선정하였습니다.';
  const SNACK_MSG_PRIZED_CANCEL = '해당 에세이의 백일장 당선이 취소되었습니다.';
  const SNACK_MSG_VOTE = '위 에세이를 투표하였습니다.';
  const SNACK_MSG_VOTE_CANCEL = '해당 에세이의 투표가 취소되었습니다.';

  // GET POST ID
  console.log(location);
  const id = location.pathname.replace('/inspire/', '');
  const data = {
    id,
  };

  // FECTHING DATA
  const fetchData = async () => {
    try {
      // await adminApi.checkIsAdmin(userData.email).then(res =>{
      //   setIsAdmin(res.data);
      // });
      if (userData.role === 'admin') {
        setIsAdmin(true);
      }
      console.log(userData);
      console.log(data);
      await insApi.getInspireById(data).then(async res => {
        console.log(res);
        setInsData(res.data);

        insData.data.userListLike.forEach(user => {
          if (user._id === userData._id) {
            setIsLike(true);
          }
        });

        insData.data.voteUserList.forEach(user => {
          if (user._id === userData._id) {
            setVote(true);
          }
        });

        if (insData.data.recommended) {
          setIsEditorPick(true);
        }

        if (insData.data.isPrized) {
          setIsPrized(true);
        }

        userData.scrapList.forEach(essay => {
          if (essay._id === insData.data._id) {
            console.log('yes!');
            setIsScrap(true);
          }
        });

        userData.followingList.forEach(user => {
          if (user._id === insData.data.writer._id) {
            setIsFollow(true);
          }
        });
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // USER INTERACTION
  // Like
  const likeUpDown = async () => {
    const data = {
      id: insData._id,
    };
    await essayApi.essayLikeCount(data).then(async res => {
      console.log(res.data);
      setInsData(res.data);
      setIsLike(false);
      console.log(isLike);
      res.data.userListLike.forEach(user => {
        if (user._id === userData._id) {
          setIsLike(true);
          console.log(isLike);
        }
      });
      if (isLike) {
        openSnackBar(SNACK_MSG_LIKE_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_LIKE);
        // call like alarm api
        await alarmApi.likeAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
      }
    });
  };

  const voteEssay = async () => {
    const data = {
      essayID: insData._id,
    };
    await essayApi.essayVote(data).then(res => {
      console.log(res.data);
      setInsData(res.data);
      setVote(!isVoted);
    });
    if (isVoted) {
      openSnackBar(SNACK_MSG_VOTE_CANCEL);
    } else {
      openSnackBar(SNACK_MSG_VOTE);
    }
  };

  // Scarp
  const scrapEssay = async () => {
    const data = {
      essayId: insData._id,
    };
    await essayApi.essayScrap(data).then(async res => {
      setInsData(res.data);
      setIsScrap(!isScrap);

      if (isScrap) {
        openSnackBar(SNACK_MSG_SCRAP_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_SCRAP);
        // call like alarm api
        await alarmApi.scrapAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        console.log(info);
        dispatch(currentUser);
      });
    });
  };

  // follow
  const followWriter = async () => {
    const username = {
      targetName: insData.writer.username,
    };
    const userId = {
      targetUserId: insData.writer._id,
    };
    await userpageApi.followUser(username).then(async res => {
      console.log(res.data);
      setIsFollow(!isFollow);
      if (isFollow) {
        openSnackBar(SNACK_MSG_FOLLOW_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_FOLLOW);
        // call like alarm api
        await alarmApi.followAlarm(userId).then(newer => {
          console.log(newer.data);
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
      });
    });
  };

  // The More Options
  const showMoreTool = () => {
    if (insData.isCompeted && insData.isPublished) {
      window.alert('백일장 출품작은 수정 또는 삭제할 수 없습니다.');
    } else {
      setIsShowMore(!isShowMore);
    }
  };

  const deleteEssay = async () => {
    const data = {
      essayId: insData._id,
    };

    await essayApi.essayDelete(data).then(async res => {
      console.log(res);
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
        window.alert('에세이가 삭제되었습니다.');
        history.goBack();
        // 에세이 삭제
      });
    });

    // window.alert("에세이가 삭제되었습니다.");
    // history.go(-1);
  };

  const goBack = () => {
    history.go(-2);
  };

  const setEditorPick = async () => {
    await adminApi.setCoverEssay(id).then(res => {
      setInsData(res.data);
      setIsEditorPick(!isEditorPick);
      if (isEditorPick) {
        openSnackBar(SNACK_MSG_EDITOR_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_EDITOR);
      }
      console.log(res);
    });
  };

  const setCompetitionPrized = async () => {
    await competitionApi.competitionCurrent().then(async competition => {
      if (competition.data.prizedEssayList.length >= 3) {
        console.log(
          `Current prized essays are ${competition.data.prizedEssayList.length}`,
        );
      } else {
        await adminApi.setPrizeEssay(id).then(res => {
          setInsData(res.data);
          setIsPrized(!isPrized);
          if (isPrized) {
            openSnackBar(SNACK_MSG_PRIZED_CANCEL);
          } else {
            openSnackBar(SNACK_MSG_PRIZED);
          }
          console.log(res);
        });
      }
    });
  };

  // Comment
  const onCommentChange = event => {
    setComment(event.target.value);
  };

  const postComment = async () => {
    const data = {
      essayId: id,
      content: comment,
    };
    await essayApi.postComment(data).then(async res => {
      openSnackBar(SNACK_MSG_COMMENT);
      console.log(res);
      await alarmApi.commentAlarm({ commentID: res.data }).then(newer => {
        setComment('');
        console.log(newer.data);
        setInsData(newer.data.contentObject[0]);
      });
    });
  };

  // SNACK BAR CONTROL
  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex_col col_narrow center_margin router_area_top col_grid_mobile margin_b_2x">
            <Preview
              description={insData.contentText}
              thumbnail="https://user-images.githubusercontent.com/51117133/95839762-5bac7000-0d7e-11eb-94db-d3cf555cd9d6.png"
              title={insData.title}
            />
            {/* 에세이 타이틀 */}
            <EssayPostTop essayData={insData} />
            {/* 에세이 본문 */}
            <EssayPostBody essayData={insData} />
            {/* 에세이 기타 정보 */}
            {/* <EssayPostStatusBar insData={insData} /> */}
            {/* 투표자 기능 */}
            {loged &&
              userData &&
              insData.isCompeted &&
              userData._id !== insData.writer._id && (
                <EssayPostVoterActionBar
                  isVoted={isVoted}
                  voteEssay={voteEssay}
                />
              )}
            {/* 어드민 기능 */}
            {loged && isAdmin && (
              <EssayPostAdminActionBar
                setEditorPick={setEditorPick}
                setCompetitionPrized={setCompetitionPrized}
                essayData={insData}
                isPrized={isPrized}
                isEditorPick={isEditorPick}
              />
            )}
            {/* 액션바 */}
            {loged && (
              <EssayPostUserActionBar
                likeUpDown={likeUpDown}
                scrapEssay={scrapEssay}
                followWriter={followWriter}
                deleteEssay={deleteEssay}
                essayData={insData}
                userData={userData}
                isLike={isLike}
                isScrap={isScrap}
                isFollow={isFollow}
                showMoreTool={showMoreTool}
                isShowMore={isShowMore}
              />
            )}
            {/* 커멘트 */}
            <div className="flex_col col">
              {loged && userData && (
                <InputComment
                  className="margin_b_2x"
                  id={id}
                  userData={userData}
                  onCommentChange={onCommentChange}
                  postComment={postComment}
                  comment={comment}
                />
              )}
              {insData.comments && (
                <div className="flex_col_reverse col1 margin_b_8x">
                  {insData.comments.map((comment, i) => (
                    <CommentList key={i} comment={comment} className="" />
                  ))}
                </div>
              )}
            </div>
            {/* 스낵바 */}
            <Snackbar
              open={snackBarControl.open}
              autoHideDuration={1000}
              onClose={closeSnackBar}
              TransitionComponent={Fade}
              message={snackBarControl.message}
            />
          </div>
        </>
      )}
    </>
  );
}

export default InsPostPresenter;
