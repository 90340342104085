import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
// Reducer for Dispatch Current User Object
import userReducer, { initialState } from '../reducer/userReducer';

// Context Definition
const CurrentUserContext = createContext();
const CurrentUserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  return (
    <CurrentUserContext.Provider value={{ state, dispatch }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Dispatch Definition
export const useUserDispatch = () => {
  const { dispatch } = useContext(CurrentUserContext);
  return dispatch;
};

// UseState Definition
export const useUserState = () => {
  const { state } = useContext(CurrentUserContext);
  return state;
};

export default CurrentUserProvider;
