import React from 'react';
import EssayPostPresenter from './EssayPostPresenter';
import { useUserState } from '../../context/currentUserContext';

function EssayPostContainer({ location, history }) {
  const { loged, loaded, userData } = useUserState();
  return (
    loaded && (
      <>
        {/* Presenter render after loaded user data,
          'loaded' event includes when users are not loged 
        */}
        <EssayPostPresenter
          loged={loged}
          location={location}
          history={history}
          userData={userData}
        />
      </>
    )
  );
}

export default EssayPostContainer;
