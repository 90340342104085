import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Competition from '../../../_models/competition';

// Component
import BttBig from '../../Buttons/ButtonLink/BttBig';
import BttActionBig from '../../Buttons/ButtonAction/BttActionBig';
import AvatarM from '../../Avatar/AvatarM';
import Modal from '../../Modal/Modal';

const Reward = styled.div`
  white-space: pre-line;
  text-align:center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  img{
    max-width: 186px;
    height: auto;
  }
  padding: 32px 64px;
  @media screen and (max-width: 480px) {
    padding: 32px 16px;
  }
`;

const Title = styled.p`
  font-family: ${props => props.theme.type.bold};
  font-size: ${props => props.theme.type.h1.size};
  color: ${props => props.theme.color.primary500};
  word-break: keep-all;
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const Exp = styled.p`
  font-family: ${props => props.theme.type.regular};
  font-size: ${props => props.theme.type.body2.size};
  line-height: ${props => props.theme.type.body2.height};
  word-break: keep-all;
  padding: 0 64px;
  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  button {
    width: 216px;
  }
  a {
    width: 216px;
  }
`;

const AutoSizedImage = styled.img`
  width: auto;
  height: auto;
  min-width: 180px !important;
  max-width: 500px !important;
  max-height: 300px !important;
  @media screen and (max-width: 480px) {
    min-width: 180px !important;
    max-width: 100% !important;
    max-height: 264px !important;
  }
`

const CardFullCompetitionStatus = ({
  loged,
  competition,
  to,
  className,
  username,
  isLinkToCompetitionPage
}) => {
  // Local State
  const [isRedirect, setIsRedirect] = useState(false);
  const [isToLogin, setIsToLogin] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rewardImageAspect, setRewardImageAepect] = useState('vertical');

  const url = require('url');
  const http = require('http');
  
  // const sizeOf = require('image-size');
  
  // const imgUrl = competition.prizeItem.thumbnail;
  // const options = url.parse(imgUrl);
  
  // http.get(options, function (response) {
  //   const chunks = [];
  //   response.on('data', function (chunk) {
  //     console.log(chunk);
  //     chunks.push(chunk);
  //   }).on('end', function() {
  //     const buffer = Buffer.concat(chunks);
  //     console.log(sizeOf(buffer));
  //   });
  // });

  // sizeOf(competition.prizeItem.thumbnail, function(err,dimensions){
  //   if(dimension.width<dimension.height){
  //     setRewardImageAepect('vertical');
  //   }
  //   else {
  //     setRewardImageAepect('horizon');
  //   }
  // })

  const openModal = () => {
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };

  const moveToWrite = competitionId => {
    console.log('moveToWrite!');

    // competitionId가 인자값으로 들어오는데 쓰이는 부분이 없지만 함부로 지우기는 뭐해서 콘솔 찍었습니다.
    console.log(competitionId);

    // 아래 있는 코드 2줄이 비활성화라 안 쓰이는 value값들 import하는 것 지워버렸습니다.
    // essayDispatch({type: SET_IS_COMPETED, value: true});
    // essayDispatch({type: SET_COMPETITION_ID, value: competitionId});
    if (loged) {
      setIsRedirect(true);
    } else {
      setIsToLogin(true);
    }
  };

  // isRedirect가 true일경우 essayContext값이 갱신된 상태에서 write로 이동
  // const history = useHistory();
  if (isRedirect) {
    return <Redirect to={`/write?competitionId=${competition._id}`} />;
  }
  if (isToLogin) {
    window.alert('로그인이 필요한 서비스입니다.');
    return <Redirect to="/signIn" />;
  }

  return (
    <div className={`flex_col flex_center col1 ${className}`}>
      <p className="body bold black700 margin_b_tiny">{`${competition.month}월 ${competition.week}주차 망월장`}</p>
      <Title className="primary bold margin_b_2x">{competition.question}</Title>
      <Exp className="margin_b_2x">{competition.description}</Exp>
      <div className="flex_row col1 flex_center bd_bottom_light padding_2x">
        {competition.isEnd ? (
          <div />
        ) : (
          <>
            {competition.participantList && competition.participantList.length > 0 ? (
              <>
                <Exp className="black300 text_center col1 margin_b_1x">{`${competition.participantList[0].username} 외 ${competition.participantList.length-1}명이 참여중입니다.`}</Exp>
                <div className="flex_row flex_center col1 margin_b_2x">
                  {competition.participantList.map(user => {
                    return (
                      <AvatarM
                        className={'margin_avatar'}
                        to={`/user/${user.username}`}
                        writer={user}
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              username !== null && (
                <p className="body_s black300 text_center regular col1 margin_b_3x">
                  {`'${username}'님 아직 참가자가 없습니다.`}
                  <br />
                  {`${competition.month}월 ${competition.week}주차의 망월장의 첫번째 참가자가 되어 보세요!`}
                </p>
              )
            )}
          </>
        )}
        <ActionBar>
          {
            !competition.isEnd&&(
              <BttActionBig
                onClick={() => moveToWrite(competition._id)}
                type="filled_primary"
                title="위 질문으로 에세이 작성하기"
                className='margin_4px'
              />
            )
          }
          {
            isLinkToCompetitionPage&&(
              <BttBig
                to={`/competition/${competition.year}-${competition.month}-${competition.week}`}
                type="outlined"
                title="모든 참여작 보러가기"
                className='margin_4px'
              />
            )
          }
        </ActionBar>
      </div>
      <Reward className="padding_2x">
        {/* <p className="body regular black700 margin_b_tiny">
          망월장 상품: &nbsp;
          <span className='bold'>{competition.prizeItem.title}</span>
        </p> */}
        <AutoSizedImage className="margin_b_1x" src={competition.prizeItem.thumbnail} alt="" />
        <p className="caption regular black300">{competition.prizeItem.description}</p>
      </Reward>
    </div>
  );
};

CardFullCompetitionStatus.propTypes = {
  competition: PropTypes.instanceOf(Competition).isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default CardFullCompetitionStatus;
