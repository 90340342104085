import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import queryString from 'querystring';
import Moment from 'react-moment';
// import {isMobile} from "react-device-detect";
import TextareaAutosize from 'react-textarea-autosize';

// Theme
import themeNew from '../../assets/theme/themeNew';
import {useThemeTypeDispatch} from '../../context/themeTypeContext';

// Hook
import useScrollPosition from '@react-hook/window-scroll'

// API
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import {
  essayApi,
  alarmApi,
  mypageApi,
  userpageApi,
  adminApi,
  competitionApi,
  brandedApi,
} from '../../api/api';

// Context
import {
  useUserState,
  useUserDispatch,
} from '../../context/currentUserContext';

import { LOAD_USER_DATA, SET_THEME_TYPE } from '../../actions';

// Component
import CommentList from '../../components/Comment/CommentList';
import InputComment from '../../components/Comment/InputComment';
import SignAttractByComment from '../../components/Comment/SignAttractByComment';
import Loading from '../../components/Loading/Loading';

// From Material UI

// Templeate
import EssayPostTop from '../../template/EssayPost/EssayPostTop';
import EssayPostBody from '../../template/EssayPost/EssayPostBody';
import EssayPostAdminActionBar from '../../template/EssayPost/EssayPostAdminActionBar';
import EssayPostUserActionBar from '../../template/EssayPost/EssayPostUserActionBar';
import EssayPostVoterActionBar from '../../template/EssayPost/EssayPostVoterActionBar';
import BannerVoteForBranded from '../../components/Banner/BannerVoteForBranded';
import BannerVoteForCompetition from '../../components/Banner/BannerVoteForCompetition';
import EssayPostGuide from '../../template/Assistant/EssayPostGuide';

import Preview from '../../components/Helmet/Preview';
// Editor
const Dante = require('Dante2');

const Container = styled.div`
//GNB와 간격 조정
margin-top: 40px;
@media screen and (max-width: 480px) {
 margin-top: -20px; 
}
`;

const FakeBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`;

const EditorContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
  textarea{
    border: none;
    width: 100%;
    min-height: 680px; 
    height: fit-content;
    font-family: sopoqa_han_sans_light !important;
    padding: 6px 12px;
    margin-bottom: 10px !important;
    color: #2a2a28;
    font-size: 20px;
    line-height: 40px;
    white-space: break-spaces;
    outline: none;
  }
`;

function EssayPostPresenter({ loged, location, userData, history }) {
  const dispatch = useUserDispatch();

  const currentUser = {
    action: {
      type: null,
    },
    username: null,
    userData: null,
  };

  // Local State
  const [loading, setLoading] = useState(true);
  const [essayData, setEssayData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [comment, setComment] = useState('');

  const [isLike, setIsLike] = useState(false);
  const [isScrap, setIsScrap] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isEditorPick, setIsEditorPick] = useState(false);
  const [isPrized, setIsPrized] = useState(false);
  const [isBrandedPrized, setIsBrandedPrized] = useState(false);
  const [isVoted, setVote] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isBranded, setIsBranded] = useState(false);
  const [isCompeted, setIsCompeted] = useState(false);
  const [branded, setBranded] = useState(false);
  const [competition, setCompetition] = useState(false);
  const [userEssayList, setUserEssayList] = useState([]);

  const [snackBarControl, setsnackBarControl] = React.useState({
    open: false,
    Transition: Fade,
    message: '',
  });

  // MSG preset for snackbar when user interaction occured
  const SNACK_MSG_COMMENT = '댓글이 등록되었습니다.';
  const SNACK_MSG_COMMENT_DELETE = '댓글이 삭제되었습니다.';
  const SNACK_MSG_LIKE = '위 에세이에 공감을 보냈습니다.';
  const SNACK_MSG_LIKE_CANCEL = '위 에세이 공감을 취소하였습니다.';
  const SNACK_MSG_SCRAP = '위 에세이를 마이노트에 스크랩하였습니다.';
  const SNACK_MSG_SCRAP_CANCEL = '위 에세이 스크랩을 취소하였습니다.';
  const SNACK_MSG_FOLLOW = '위 에세이 작가를 팔로우하였습니다.';
  const SNACK_MSG_FOLLOW_CANCEL = '위 에세이 작가 팔로우를 취소하였습니다.';
  const SNACK_MSG_EDITOR = '위 에세이가 에디터픽으로 선정되었습니다.';
  const SNACK_MSG_EDITOR_CANCEL = '해당 에세이 에디터픽이 취소되었습니다.';
  const SNACK_MSG_PRIZED = '위 에세이를 백일장 당선작으로 선정하였습니다.';
  const SNACK_MSG_PRIZED_CANCEL = '해당 에세이의 백일장 당선이 취소되었습니다.';
  const SNACK_MSG_BRANDED_PRIZED = '위 에세이를 브랜디드 스토리 당선작으로 선정하였습니다.';
  const SNACK_MSG_BRANDED_PRIZED_CANCEL = '해당 에세이의 브랜디드 스토리 당선이 취소되었습니다.';
  const SNACK_MSG_VOTE = '위 에세이를 투표하였습니다.';
  const SNACK_MSG_VOTE_CANCEL = '해당 에세이의 투표가 취소되었습니다.';

  // Intit Scroll Hook
  const scrollY = useScrollPosition(60);
  const scrollOffset = isMobile ? 200 : 800;
  const [isEssayRead, setIsEssayRead] = useState(false);

  // Intit Section Ref
  const lastDiv = useRef(null);

  const themeTypeDispatch = useThemeTypeDispatch();

  // // Scroll Hook
  // useEffect(() => {
    
  //   if (lastDiv.current !== null) {
  //     console.log(lastDiv.current.offsetTop - scrollY);
  //     if (scrollY < lastDiv.current.offsetTop - scrollOffset) {
  //       setIsEssayRead(false);
  //       themeNew.switchTheme(themeNew,'light');
  //       let body = {
  //         action: {
  //           type: SET_THEME_TYPE
  //         },
  //         themeType: 'light'
  //       }
  //       themeTypeDispatch(body);
  //     }
  //     else {
  //       setIsEssayRead(true);
  //       themeNew.switchTheme(themeNew,'dark');
  //       window.localStorage.setItem('themeType', 'dark');
  //       themeNew.switchTheme(themeNew,'dark');
  //       let body = {
  //         action: {
  //           type: SET_THEME_TYPE
  //         },
  //         themeType: 'dark'
  //       }
  //       themeTypeDispatch(body);
  //     }
  //   }
  // }, [scrollY]);


  // SNACK BAR CONTROL
  const openSnackBar = msg => {
    setsnackBarControl({
      open: true,
      message: msg,
    });
  };

  const closeSnackBar = () => {
    setsnackBarControl({
      ...snackBarControl,
      open: false,
    });
  };

  // GET POST ID
  const id = location.pathname.replace('/post/', '');
  const data = {
    essayId: id,
  };


  useEffect(() => {
    const { search } = location; // 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search); // 문자열의 쿼리스트링을 Object로 변환
    console.log(queryObj);
    if (queryObj["?is_from_email"]) {
      console.log("this page is opened from email");
      const { gtag } = window;
      gtag('event', 'click', {
        'event_category': 'comment email open',
        'event_label': `${id}`
      });
    }

  }, [location])

  // FECTHING DATA
  const fetchData = async () => {
    console.log(location.pathname);
    try {
      if (loged) {
        if (userData.role === 'admin') {
          setIsAdmin(true);
        }
        console.log('userData:', userData);
        console.log(data);
      }
      await essayApi.getPostedEssay(data).then(async essayData => {
        console.log(essayData);
        setEssayData(essayData.data);

        if (essayData === '') {
          window.alert('에세이가 삭제되었습니다.');
          history.goBack();
        }

        essayData.data.userListLike.forEach(user => {
          if (user._id === userData._id) {
            setIsLike(true);
          }
        });

        essayData.data.voteUserList.forEach(user => {
          if (user._id === userData._id) {
            setVote(true);
          }
        });

        if (essayData.data.recommended) {
          setIsEditorPick(true);
        }

        if (essayData.data.isPrized) {
          setIsPrized(true);
        }

        userData.scrapEssayList.forEach(essay => {
          if (essay._id === essayData.data._id) {
            console.log('yes!');
            setIsScrap(true);
          }
        });

        userData.followingList.forEach(user => {
          if (user._id === essayData.data.writer._id) {
            setIsFollow(true);
          }
        });

        // 망월장 브랜디드 스토리 정보 확인
        if (essayData.data.isCompeted) {
          setIsCompeted(true);
          competitionApi.getCompetitions([essayData.data.competitionId]).then(async competition => {
            console.log(competition);
            setCompetition(competition.data[0]);
          });
        }
        // 망월장 브랜디드 스토리 정보 확인
        if (essayData.data.isBranded) {
          setIsBranded(true);
          brandedApi.getBrandeds([essayData.data.brandedId]).then(async branded => {
            setBranded(branded.data[0]);
          });
        }

        // 작성자의 다른글 호출
        essayApi.getEssaysById(essayData.data.writer.essayList.reverse())
          .then(essayList => {
            console.log(essayList.data);
            let list = essayList.data.filter((item,i) => {
              return i<4;
            });
            setUserEssayList(list);
          });

      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('load essay!');
    setLoading(true);
    fetchData();
  }, [location]);

  // USER INTERACTION
  // Like
  const likeUpDown = async () => {
    const data = {
      essayId: essayData._id,
    };
    await essayApi.essayLikeCount(data).then(async res => {
      console.log(res.data);
      setEssayData(res.data);
      setIsLike(false);
      console.log(isLike);
      res.data.userListLike.forEach(user => {
        if (user._id === userData._id) {
          setIsLike(true);
          console.log(isLike);
        }
      });
      if (isLike) {
        openSnackBar(SNACK_MSG_LIKE_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_LIKE);
        // Send Event to GA
        const { gtag } = window;
        gtag('event', 'click', {
          'event_category': 'user interaction on a post',
          'event_label': 'like'
        });
        // call like alarm api
        await alarmApi.likeAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
      }
    });
  };

  const voteEssay = async () => {
    const data = {
      essayID: essayData._id,
    };
    await essayApi.essayVote(data).then(res => {
      console.log(res.data);
      setEssayData(res.data);
      setVote(!isVoted);
    });
    if (isVoted) {
      openSnackBar(SNACK_MSG_VOTE_CANCEL);
    } else {
      openSnackBar(SNACK_MSG_VOTE);
      // Send Event to GA
      const { gtag } = window;
      gtag('event', 'click', {
        'event_category': 'user interaction on a post',
        'event_label': 'vote'
      });
    }
  };

  // Scarp
  const scrapEssay = async () => {
    const data = {
      essayId: essayData._id,
    };
    await essayApi.essayScrap(data).then(async res => {
      setEssayData(res.data);
      setIsScrap(!isScrap);

      if (isScrap) {
        openSnackBar(SNACK_MSG_SCRAP_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_SCRAP);
        // call like alarm api
        await alarmApi.scrapAlarm({ essayId: res.data._id }).then(newer => {
          console.log(newer.data);
        });
        // Send Event to GA
        const { gtag } = window;
        gtag('event', 'click', {
          'event_category': 'user interaction on a post',
          'event_label': 'scrap'
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        console.log(info);
        dispatch(currentUser);
      });
    });
  };

  // follow
  const followWriter = async () => {
    const username = {
      targetName: essayData.writer.username,
    };
    const userId = {
      targetUserId: essayData.writer._id,
    };
    await userpageApi.followUser(username).then(async res => {
      console.log(res.data);
      setIsFollow(!isFollow);
      if (isFollow) {
        openSnackBar(SNACK_MSG_FOLLOW_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_FOLLOW);
        // call like alarm api
        await alarmApi.followAlarm(userId).then(newer => {
          console.log(newer.data);
        });
      }
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
      });
    });
  };

  // The More Options
  const showMoreTool = () => {
    // if (essayData.isCompeted && essayData.isPublished) {
    //   window.alert('백일장 출품작은 수정 또는 삭제할 수 없습니다.');
    // } else {
    //   setIsShowMore(!isShowMore);
    // }
    setIsShowMore(!isShowMore);
  };

  const deleteEssay = async () => {
    const data = {
      essayId: essayData._id,
    };

    await essayApi.essayDelete(data).then(async res => {
      console.log(res);
      await mypageApi.getMyInfo().then(info => {
        currentUser.action.type = LOAD_USER_DATA;
        currentUser.username = info.data.username;
        currentUser.userData = info.data;
        dispatch(currentUser);
        window.alert('에세이가 삭제되었습니다.');
        history.goBack();
        // 에세이 삭제
      });
    });

    // window.alert("에세이가 삭제되었습니다.");
    // history.go(-1);
  };

  const setEditorPick = async () => {
    await adminApi.setCoverEssay(id).then(res => {
      setEssayData(res.data);
      setIsEditorPick(!isEditorPick);
      if (isEditorPick) {
        openSnackBar(SNACK_MSG_EDITOR_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_EDITOR);
      }
      console.log(res);
    });
  };

  const setCompetitionPrized = async () => {
    await competitionApi.competitionLast().then(async competition => {
      console.log(competition.data);
      if (competition.data.prizedEssayList.length >= 3) {
        console.log(
          `Current prized essays are ${competition.data.prizedEssayList.length}`,
        );
      }
      else {
        await adminApi.setPrizeEssay(id).then(res => {
          setEssayData(res.data);
          setIsPrized(!isPrized);
          if (isPrized) {
            openSnackBar(SNACK_MSG_PRIZED_CANCEL);
          } else {
            openSnackBar(SNACK_MSG_PRIZED);
          }
          console.log(res);
        });
      }
    });
  };

  const setBrandedPrized = async () => {
    console.log('test');
    await adminApi.setPrizeBrandedEssay(id).then(res => {
      setEssayData(res.data);
      setIsBrandedPrized(!isPrized);
      if (isPrized) {
        openSnackBar(SNACK_MSG_BRANDED_PRIZED_CANCEL);
      } else {
        openSnackBar(SNACK_MSG_BRANDED_PRIZED);
      }
      console.log(res);
    });
  };

  // Comment
  const onCommentChange = event => {
    setComment(event.target.value);
  };

  const deleteComment = async commentId => {
    const data = {
      essayId: id,
      commentId,
    };
    await essayApi.deleteComment(data).then(res => {
      openSnackBar(SNACK_MSG_COMMENT_DELETE);
      console.log(res);
      setEssayData(res.data);
    });
  };

  const postComment = async () => {
    const data = {
      essayId: id,
      content: comment,
    };
    await essayApi.postComment(data).then(async res => {
      openSnackBar(SNACK_MSG_COMMENT);
      console.log(res);
      await alarmApi.commentAlarm({ commentID: res.data }).then(newer => {
        setComment('');
        console.log(newer.data);
        setEssayData(newer.data.contentObject[0]);
        const { gtag } = window;
        gtag('event', 'click', {
          'event_category': 'post comment',
          'event_label': `${id}`
        });
      });

      console.log("userData", userData);
      console.log("essayData", essayData);


      const emailData = {
        title: `${userData.username}님이 ${essayData.writer.username}님의 글 \"${essayData.title}\"에 댓글을 남겼습니다.`,
        content:
          `
        <div
        style="
          width: 100%;
          background-color: #fcf6ee;
          box-sizing: border-box;
          padding:32px;
          max-width: 640px;
        "
      >
        <div
          style="
            width: 100%;
            background-color: #fcf6ee;
            color: #262626;
            padding:64px 0px;
            box-sizing: border-box;
            "
        >
          <div style="
            border-top: solid 2px #3838bf;
            border-bottom: solid 2px #3838bf;
            padding: 24px 0px;
            box-sizing: border-box;
            margin:0;
            margin-bottom: 80px;
          ">
            <img
            src="https://ci3.googleusercontent.com/proxy/69SmyFBcGUIjqkwK5MANGugcOmTvSndw27zf5NVTZi1S3r0z-gMGwz451LPVcMBjX54EFjxhA5w4MTlQbNI=s0-d-e1-ft#https://img.stibee.com/48428_1623393469.png"
            style="width:100%;display:inline;vertical-align:bottom;max-width:100%;border-width:0px;border-color:initial;text-align:justify"
            width="100%"
            class="CToWUd a6T"
            tabindex="0">
          </div>
          <p style="
            font-size: 14.8px;
            color: #93908b;
            box-sizing: border-box;
            margin:0;
            margin-bottom: 32px;
          ">
            ${userData.username}님이 ${essayData.writer.username}님의 글 \"${essayData.title}\"에 댓글을 남겼습니다.
          </p>
          <p style="
            font-family: SpoqaHanSans;
            font-size: 13px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #93908b;
            margin:0;
            margin-bottom: 16px;
          ">
            ${essayData.datePublish.split('-')[1] + '월' + essayData.datePublish.split('-')[2][0] + essayData.datePublish.split('-')[2][1] + '일'}
          </p>
          <p
            style="
            font-size: 20px;
            font-weight: 300;
            line-height: 28.6px;
            color: #93908b;
            margin-bottom: 48px;
            box-sizing: border-box;
          "
          >
            ${comment}
          </p>
          <div style="
            width: 100%;
            text-align: center;
          ">
              <a 
              style="
                width: 80%;
                font-size: 16px;
                height: 48px;
                padding: 14px 0px;
                text-decoration: none;
                border-radius: 24px;
                background-color: #3838bf;
                color: #fff;
                text-align: center;
                box-sizing: border-box;
                font-weight: 600;
                margin:0 auto 24px auto;
                text-align: center;
              "
              target="_blank"
              href="http://blue-black.life/post/${essayData._id}?is_from_email=true"
            >
              글 바로가기
            </a>
            <div style="
              font-family: SpoqaHanSans;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              text-align: center;
              color: #3838bf;
              border-top: solid 1px #9b9bdf;
              padding: 32px 0px;
              margin-top: 96px;
            ">
              <p style="margin:0;">
                밤과 낮이 만나는 시간,
                에세이 커뮤니티 블루블랙
              </p>
              <p style="margin:0;">
                www.blue-black.life
              </p>
            </div>
            <p style="
              font-size: 11px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.27;
              letter-spacing: normal;
              text-align: center;
              color: #5f5d5a;
            ">
              (주)이디그나 | essay@blue-black.life  
            </p>
          </div>
        </div>
      </div>
        `,
        sendTo: essayData.writer.email,
      }
      await alarmApi.alarmEmailing(emailData).then(res => {
        console.log(res);
      })

    });
  };

  const postChildComment = async (id, childComment) => {
    console.log(id);
    const data = {
      commentId: id,
      content: childComment,
    };
    await essayApi.postChildComment(data).then(async res => {
      openSnackBar(SNACK_MSG_COMMENT);
      console.log(res);
      await alarmApi
        .childCommentAlarm({ childCommentID: res.data })
        .then(newer => {
          setComment('');
          console.log(newer.data);
          setEssayData(newer.data.contentObject[0]);
        });
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
          <>
            <Container className="flex_col col_narrow center_margin col_grid_mobile margin_b_2x">
              <Preview
                description={essayData && essayData.contentText}
                // thumbnail="https://user-images.githubusercontent.com/51117133/95839710-49cacd00-0d7e-11eb-8472-02019194f0f8.png"
                title={essayData.title}
              />
              {/* 에세이 타이틀 */}
              <EssayPostTop
                loged={loged}
                essayData={essayData}
                userData={userData}
                deleteEssay={deleteEssay}
                setIsShowMore={setIsShowMore}
                isShowMore={isShowMore}
                history={history}
              />
              {/* 에세이 본문 */}
              <EssayPostBody essayData={essayData} />
              {/* 에세이 기타 정보 */}
              {/* <EssayPostStatusBar essayData={essayData} /> */}
              {/* 어드민 기능 */}
              {loged && isAdmin && (
                <EssayPostAdminActionBar
                  setEditorPick={setEditorPick}
                  setCompetitionPrized={setCompetitionPrized}
                  setBrandedPrized={setBrandedPrized}
                  essayData={essayData}
                />
              )}
              {/* 망월장 || 브랜디드 스토리 투표 */}
              {
                loged &&
                userData &&
                essayData.isCompeted &&
                userData._id !== essayData.writer._id &&
                <BannerVoteForCompetition
                  competition={competition}
                  voteEssay={voteEssay}
                  isVoted={isVoted}
                />
              }
              {
                loged &&
                userData &&
                essayData.isBranded &&
                userData._id !== essayData.writer._id &&
                <BannerVoteForBranded
                  branded={branded}
                  voteEssay={voteEssay}
                />
              }
              {/* 액션바 */}
              {loged && (
                <EssayPostUserActionBar
                  likeUpDown={likeUpDown}
                  scrapEssay={scrapEssay}
                  followWriter={followWriter}
                  deleteEssay={deleteEssay}
                  essayData={essayData}
                  userData={userData}
                  isLike={isLike}
                  isScrap={isScrap}
                  isFollow={isFollow}
                  showMoreTool={showMoreTool}
                  isShowMore={isShowMore}
                />
              )}
              {/* 커멘트 */}
              <div className="flex_col col">
                {loged && userData && (
                  <InputComment
                    className="margin_b_2x"
                    id={id}
                    userData={userData}
                    onCommentChange={onCommentChange}
                    postComment={postComment}
                    comment={comment}
                  />
                )}
                {!loged && !userData && (
                  <SignAttractByComment
                    className="margin_b_2x"
                  />
                )}
                {essayData.comments && (
                  <>
                    <div className="flex_col_reverse col1 margin_b_8x">
                      {essayData.comments.map((commentSchema, i) => (
                        <CommentList
                          key={i}
                          commentSchema={commentSchema}
                          className=""
                          userData={userData}
                          postChildComment={postChildComment}
                          isAdmin={isAdmin}
                          deleteComment={deleteComment}
                          loged={loged}
                        />
                      ))}
                    </div>
                    <div className='flex_col col1'>
                      {!loged && !userData && (
                        <SignAttractByComment
                          className="margin_b_2x"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              {/* Last Div Check */}
              <div
                ref={lastDiv}
                id={'last_div'}
              />
              {/* 스낵바 */}
              <Snackbar
                open={snackBarControl.open}
                autoHideDuration={1000}
                onClose={closeSnackBar}
                TransitionComponent={Fade}
                message={snackBarControl.message}
              />
              {/* 에세이 어씨스턴드 */}
              {/* <EssayPostGuide
                isActive={isEssayRead}
                targetUsername={essayData.writer.username}
                essayList={userEssayList}
              /> */}
            </Container>
          </>
        )}
    </>
  );
}

export default EssayPostPresenter;
